import React, {useEffect, useState} from "react";
import {Grid, Typography, Box, Modal, CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {connect} from "react-redux";
import {
  getPatientInvoiceList,
} from "../redux/actions/userDataActions";

import FormButton from "../components/FormButton";
import FormSelectize from "./FormSelectize";
import {MOBILE_VIEW} from "./Sidebar";

import styles from "./SelectPatientInvoiceModal.module.css";

import CloseIcon from '@mui/icons-material/CloseOutlined';

const SelectPatientInvoiceModal = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState(null);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.modalVisible) {
      setVisible(true);
    } else {
      setInvoice(null);
      setError(null);
    }
  }, [props.modalVisible]);

  useEffect(() => {
    setLoading(false);
  }, [props.data])

  const onCloseClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onClose();
    }, [100]);
  };

  const onSubmitClick = () => {
    let errorText = {};
    if (invoice == null) {
      errorText = {invoice: "Select invoice"};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    props.onSubmit(invoice);
  };

  return (
    <Modal
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
      hideBackdrop={true}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{md: 8, xs: 4}}
      >
        <Grid
          onClick={() => onCloseClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle}/>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            Choose Invoice
          </Typography>
        </Grid>

        {loading ?
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress/>
          </Grid>
          :
          <>
            <Grid item md={12} xs={12} mt={3}>
              <FormSelectize
                page={"SelectPatientInvoiceModal"}
                value={invoice}
                label={"INVOICE"}
                multiple={false}
                addOption={false}
                onChange={(val) => {
                  setInvoice(val);
                }}
                options={props.data}
                error={error?.invoice}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection={MOBILE_VIEW ? "column" : "row"}
              alignItems={"center"}
              justifyContent={"space-around"}
            >
              <FormButton
                btnStyleView={style.btnStyleView}
                btnType={"btn1"}
                title={"Link"}
                onSubmit={onSubmitClick}
              />
            </Grid>
          </>
        }
      </Grid>
    </Modal>
  );
};

export default SelectPatientInvoiceModal;

const style = {
  btnStyleView: {
    height: 40,
    minWidth: 100,
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
};
