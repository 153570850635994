import React, {useEffect, useState} from "react";
import {
  Grid,
  Typography,
  Modal,
  CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Chip,
} from "@mui/material";
import moment from "moment";

import FormButton from "../components/FormButton";
import Loader from "./Loader";
import AddEditLabelModal from "./AddEditLabelModal";
import ConfirmAlertBox from "./ConfirmAlertBox";

import {connect} from "react-redux";
import {
  listLabel,
  deleteLabel,
  updateApiStatus
} from "../redux/actions/userDataActions";
import {DELETE_LABEL_SUCCESS} from "../redux/actions/types";

import styles from "./LabelModal.module.css";

import CloseIcon from '@mui/icons-material/CloseOutlined';
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExclaimIcon from "@mui/icons-material/ErrorOutlineOutlined";

import {UTC_OFFSET} from "../constants";

const LabelModal = (props) => {
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLabelData, setSelectedLabelData] = useState(null);
  const [type, setType] = useState('');
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);

  useEffect(() => {
    if (props.apiStatus == DELETE_LABEL_SUCCESS && confirmBoxVisible) {
      setSelectedLabelData(null);
      setConfirmBoxVisible(false);
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if (props.modalVisible) {
      // let data = {
      //   orderBy: 'name',
      //   orderSort: 'ASC',
      // }
      // props.listLabel(JSON.stringify(data));
      setVisible(true);
    }
  }, [props.modalVisible]);

  const onCloseClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onCancel();
    }, [100]);
  };

  const onAddEditLabelBtnClick = (val, item) => {
    setSelectedLabelData(item);
    setType(val);
    setModalVisible(true);
  };

  const onAddEditLabel = () => {
    setSelectedLabelData(null);
    setModalVisible(false);
  };

  const onCancelLabel = () => {
    setSelectedLabelData(null);
    setModalVisible(false);
  };

  const onDeleteLabelBtnClick = (item) => {
    setSelectedLabelData(item);
    setConfirmBoxVisible(true);
  };

  const onConfirmDeleteLabel = () => {
    let data = {
      labelUid: selectedLabelData?.uid,
    };
    props.deleteLabel(JSON.stringify(data));
  };

  const onCancelDeleteLabel = () => {
    setSelectedLabelData(null);
    setConfirmBoxVisible(false);
  };

  return (
    <>
      <Modal
        disableBackdropClick
        disableScrollLock={true}
        open={props.modalVisible}
        className={styles.modelView}
        onClose={onCloseClick}
      >
        <Grid
          container
          item
          md={6}
          xs={11}
          className={[
            styles.modalSize,
            visible ? styles.modalVisibleSize : styles.modalInitialSize,
          ]}
          p={{md: 2, xs: 2}}
        >
          {props.labelLoading ? (
            <Loader/>
          ) : (
            <>
              <Grid
                item
                md={12}
                xs={12}
                mt={2}
                container
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <TableContainer className={styles.tableView}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Grid
                            item md={12} xs={12}
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            mt={2}
                          >
                            <Grid item md={3} xs={3}>
                              <FormButton
                                title="Add New Label"
                                btnStyleView={style.btnStyleView}
                                onSubmit={() => onAddEditLabelBtnClick('add', null)}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={6}
                              className={styles.iconView}
                              onClick={() => onCloseClick()}
                            >
                              <CloseIcon sx={style.iconStyle}/>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableHead>
                      <TableRow>
                        <TableCell>Label</TableCell>
                        <TableCell>Short Form</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.labelLoading ? (
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            <CircularProgress/>
                          </TableCell>
                        </TableRow>
                      ) : props.labelList?.length > 0 ? (
                        props.labelList?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <Box
                                  className={styles.statusBgColor}
                                  sx={{
                                    backgroundColor: item?.color,
                                  }}>
                                </Box>
                                <Box pl={1}>
                                  {item?.name}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>{item?.short_form}</TableCell>
                            <TableCell>
                              <Box className={styles.iconMainView}>
                                <Box
                                  onClick={(e) =>
                                    onAddEditLabelBtnClick("edit", item)
                                  }
                                  className={styles.actionIconView}
                                >
                                  <EditIcon fontSize="small"/>
                                </Box>
                                <Box
                                  onClick={(e) => onDeleteLabelBtnClick(item)}
                                  ml={1}
                                  className={styles.actionIconView}
                                >
                                  <DeleteIcon fontSize="small"/>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            <Typography className={styles.noMatchFoundText}>
                              No Labels Found
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}
        </Grid>
      </Modal>

      <ConfirmAlertBox
        confirmBox={confirmBoxVisible}
        icon={<ExclaimIcon sx={{height: 80, width: 80, color: '#ffc700'}}/>}
        title={"Are you sure?"}
        content={"You won't be able to revert this!"}
        btnLoading={props.labelBtnLoading}
        firstBtn={"Yes, delete it!"}
        secondBtn={"Cancel"}
        onConfirmClick={onConfirmDeleteLabel}
        onCancelClick={onCancelDeleteLabel}
      />

      <AddEditLabelModal
        type={type}
        data={selectedLabelData}
        modalVisible={modalVisible}
        onCancel={onCancelLabel}
        onSubmit={onAddEditLabel}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    listLabel: (data) => dispatch(listLabel(data)),
    deleteLabel: (data) => dispatch(deleteLabel(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    labelList: state.userData.labelList,
    labelLoading: state.userData.labelLoading,
    apiStatus: state.userData.apiStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabelModal);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
};
