import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  Grow,
} from "@mui/material";

import FormButton from "./FormButton";
import FormTextArea from "./FormTextArea";

import styles from "./ConfirmAlertBox.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      {...props}
      style={
        {
          // transformOrigin: '0 1'
        }
      }
      {...(true ? { timeout: 500 } : {})}
    />
  );
});

const ConfirmAlertBox = (props) => {
  const [note, setNote] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setNote("");
    setError("");
  }, [props.confirmBox]);

  const onConfirmClick = () => {
    if (props?.textInputMandatory == true) {
      if (note.trim() == "") {
        setError(props?.textInputPlaceholder + " Required");
        return;
      }
    }
    props.onConfirmClick(note);
  };

  const onCancelClick = () => {
    if (!props.btnLoading) {
      props.onCancelClick();
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={props.confirmBox}
      onClose={onCancelClick}
      sx={style.mainBoxView}
    >
      <Box sx={{ width: "80%" }}>
        <DialogContent>
          <Box display={"flex"} justifyContent={"center"}>
            {props?.icon}
          </Box>
          <Box mt={3}>
            <Typography className={styles.titleText}>{props?.title}</Typography>
          </Box>
          <Box mt={3}>
            <Typography className={styles.contentText}>
              {props?.content}
            </Typography>
          </Box>

          {props?.showTextInput == true ? (
            <Box mt={3}>
              <FormTextArea
                page={"ConfirmAlert"}
                error={error}
                value={note}
                setValue={setNote}
                minRows={3}
                placeholder={props?.textInputPlaceholder}
              />
            </Box>
          ) : null}
        </DialogContent>
        <Grid
          item
          md={12}
          xs={12}
          container
          mt={{ md: 2, xs: 1 }}
          mb={3}
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <FormButton
            btnStyleView={style.btnStyleView}
            loading={props.btnLoading}
            title={props.firstBtn}
            btnType={"btn1"}
            onSubmit={onConfirmClick}
          />
          <FormButton
            btnStyleView={style.btnStyleView}
            title={props.secondBtn}
            btnType={"btn2"}
            onSubmit={onCancelClick}
          />
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ConfirmAlertBox;

const style = {
  mainBoxView: {
    "& .MuiDialog-paper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 1,
      width: { md: "35%", xs: "90%" },
      minHeight: { md: "50%", xs: "38%" },
    },
  },
  btnStyleView: {
    height: 40,
    width: "48%",
  },
};
