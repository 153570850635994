import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";
import moment from "moment";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import TabButtons from "../../components/TabButtons";
import AddEditConsultationNotes from "../../components/AddEditConsultationNotes";
import ConsultationNotes from "../../components/ConsultationNotes";

import { connect } from "react-redux";
import {
  getPatientDetail,
  deletePatient,
  addConsultationNote,
  updateConsultationNote,
  deleteConsultationNote,
  updateApiStatus,
  getAllDoctor,
} from "../../redux/actions/userDataActions";

import AddEditPatientModal from "../../components/AddEditPatientModal";
import AppointmentList from "../../components/AppointmentList";
import FormButton from "../../components/FormButton";
import ConfirmAlertBox from "../../components/ConfirmAlertBox";

import styles from "./PatientView.module.css";

import NoteIcon from '@mui/icons-material/NoteAltOutlined';
import AppointmentIcon from '@mui/icons-material/CalendarMonthOutlined';
import ExclaimIcon from '@mui/icons-material/ErrorOutlineOutlined';

import {
  DELETE_CONSULTATION_NOTE_SUCCESS,
  DELETE_PATIENT_SUCCESS,
  UPDATE_CONSULTATION_NOTE_SUCCESS,
  UPDATE_PATIENT_SUCCESS,
} from "../../redux/actions/types";

const patientTabsList = [
  {
    label: "Consultation Notes",
    value: "consultation_notes",
    icon: <NoteIcon />,
    iconType: "fill",
  },
  {
    label: "Appointments",
    value: "appointments",
    icon: <AppointmentIcon />,
    iconType: "fill",
  },
];

const PatientView = (props) => {
  const [patientUid, setPatientUid] = useState("");
  const [patientDetail, setPatientDetail] = useState(null);
  const [selectedTab, setSelectedTab] = useState("consultation_notes");
  const [selectedConsultationNote, setSelectedConsultationNote] =
    useState(null);
  const [selectedConsultationNoteId, setSelectedConsultationNoteId] =
    useState("");
  const [editPatientModalVisible, setEditPatientModalVisible] = useState(false);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [deleteType, setDeleteType] = useState("");

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let patient_uid = params.get("id");
    if (patient_uid) {
      setPatientUid(patient_uid);
      let data = {
        patientUid: patient_uid,
      };
      props.getPatientDetail(JSON.stringify(data));
    }
  }, []);

  useEffect(() => {
    setPatientDetail(props.patientDetail);
  }, [props.patientDetail]);

  useEffect(() => {
    if (
      props.apiStatus == DELETE_CONSULTATION_NOTE_SUCCESS &&
      confirmBoxVisible
    ) {
      setSelectedConsultationNoteId("");
      setDeleteType("");
      setConfirmBoxVisible(false);
      props.updateApiStatus("");
    } else if (props.apiStatus == UPDATE_CONSULTATION_NOTE_SUCCESS) {
      setSelectedConsultationNote(null);
      props.updateApiStatus("");
    } else if (props.apiStatus == DELETE_PATIENT_SUCCESS && confirmBoxVisible) {
      setDeleteType("");
      setConfirmBoxVisible(false);
      props.updateApiStatus("");
      window.location.href = "patients";
    }
  }, [props.apiStatus]);

  useEffect(() => {
    getAllDoctorList();
  }, []);

  const getAllDoctorList = () => {
    props.getAllDoctor();
  };

  const onSelectTab = (item) => {
    setSelectedTab(item?.value);
  };

  const onAddEditConsultationNote = (data) => {
    if (selectedConsultationNote) {
      props.updateConsultationNote(JSON.stringify(data));
    } else {
      props.addConsultationNote(JSON.stringify(data));
    }
  };

  const onEditConsultationNoteBtnClick = (data) => {
    setSelectedConsultationNote(data);
  };

  const onCancelConsultationNote = () => {
    setSelectedConsultationNote(null);
  };

  const onDeleteConsultationNoteBtnClick = (data) => {
    setDeleteType("consultation_note");
    setSelectedConsultationNoteId(data?.id);
    setConfirmBoxVisible(true);
  };

  const onConfirmDelete = () => {
    if (deleteType == "consultation_note") {
      let data = {
        patientUid: patientUid,
        consultationNoteId: selectedConsultationNoteId,
      };
      props.deleteConsultationNote(JSON.stringify(data));
    } else if (deleteType == "patient") {
      let data = {
        patientUid: patientUid,
      };
      props.deletePatient(JSON.stringify(data));
    }
  };

  const onCancelDelete = () => {
    if (deleteType == "consultation_note") {
      setSelectedConsultationNoteId("");
    }
    setDeleteType("");
    setConfirmBoxVisible(false);
  };

  const onEditPatientBtnClick = () => {
    setPatientDetail(JSON.parse(JSON.stringify(props.patientDetail)));
    setEditPatientModalVisible(true);
  };

  const onEditPatient = () => {
    setEditPatientModalVisible(false);
  };

  const onCancelEditPatient = () => {
    setEditPatientModalVisible(false);
  };

  const onDeletePatientBtnClick = () => {
    setDeleteType("patient");
    setConfirmBoxVisible(true);
  };

  if (props.patientDetailLoading || patientDetail == null) {
    return (
      <Box
        style={{ minHeight: window.innerHeight - 140 }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container page={"patients"}>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Grid
          item
          md={4}
          xs={12}
          container
          display={"flex"}
          flexDirection={"column"}
          className={styles.cardView}
          p={3}
        >
          <Grid item md={12} xs={12} pt={2}>
            <Typography className={styles.detailText}>Details</Typography>
          </Grid>

          <Grid item md={12} xs={12} mt={1} mb={3}>
            <Box className={styles.borderView} />
          </Grid>

          <Grid item md={12} xs={12}>
            <Box
              mb={2}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <Typography className={styles.detailLabelText}>
                First Name:
              </Typography>
              <Typography ml={1} className={styles.detailValueText}>
                {patientDetail?.first_name}
              </Typography>
            </Box>

            {patientDetail?.last_name ? (
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>
                  Last Name:
                </Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {patientDetail?.last_name}
                </Typography>
              </Box>
            ) : null}

            <Box
              mb={2}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <Typography className={styles.detailLabelText}>
                Status:
              </Typography>
              <Grid ml={1} className={styles.detailValueStatusView}>
                <Typography className={styles.detailValueStatusText}>
                  {patientDetail?.status}
                </Typography>
              </Grid>
            </Box>

            {patientDetail?.dob ? (
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>DOB:</Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {moment(patientDetail.dob).format("DD-MM-YYYY")}
                </Typography>
              </Box>
            ) : null}

            <Box
              mb={2}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <Typography className={styles.detailLabelText}>
                Gender:
              </Typography>
              <Typography ml={1} className={styles.detailValueText}>
                {patientDetail?.dob ? moment().diff(moment(patientDetail.dob), 'years') : ''}{patientDetail?.gender}
              </Typography>
            </Box>

            <Box
              mb={2}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <Typography className={styles.detailLabelText}>
                Mobile:
              </Typography>
              <Typography ml={1} className={styles.detailValueText}>
                {patientDetail?.mobile}
              </Typography>
            </Box>

            {patientDetail?.whatsapp ? (
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>
                  Whatsapp:
                </Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {patientDetail?.whatsapp}
                </Typography>
              </Box>
            ) : null}

            {patientDetail?.email ? (
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>
                  Email:
                </Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {patientDetail?.email}
                </Typography>
              </Box>
            ) : null}

            {patientDetail?.address ? (
              <Box mb={2} display={"flex"} flexDirection={"row"}>
                <Typography className={styles.detailLabelText}>
                  Address:
                </Typography>
                <Typography ml={2} className={styles.detailValueText}>
                  {patientDetail?.address}
                </Typography>
              </Box>
            ) : null}

            {patientDetail?.doctor_name ? (
              <Box mb={2} display={"flex"} flexDirection={"row"}>
                <Typography className={styles.detailLabelText}>
                  Referred By:
                </Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {patientDetail?.doctor_name}
                  <br />
                  {patientDetail?.doctor_mobile}
                  <br />
                  {patientDetail?.doctor_hospital}
                </Typography>
              </Box>
            ) : null}

            <Grid
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid md={4} xs={12}>
                <FormButton
                  title={"Edit"}
                  btnType={"btn1"}
                  btnStyleView={style.btnStyleView}
                  onSubmit={onEditPatientBtnClick}
                />
              </Grid>
              <Grid ml={2} md={4} xs={12}>
                <FormButton
                  title={"Delete"}
                  btnType={"btn4"}
                  btnStyleView={style.btnStyleView}
                  onSubmit={onDeletePatientBtnClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          md={7.8}
          xs={12}
          mt={{ md: 0, xs: 2 }}
          display={"flex"}
          flexDirection={"column"}
        >
          <Grid item md={12} xs={12} display={"flex"} flexDirection={"row"}>
            <TabButtons
              selectedTab={selectedTab}
              data={patientTabsList}
              onButtonClick={onSelectTab}
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            mt={2}
          >
            {selectedTab == "consultation_notes" ? (
              <Box>
                <Box className={styles.cardView} p={3}>
                  <AddEditConsultationNotes
                    data={selectedConsultationNote}
                    patientUid={patientUid}
                    onAddEdit={onAddEditConsultationNote}
                    onCancel={onCancelConsultationNote}
                  />
                </Box>

                <Box className={styles.cardView} p={3} mt={2}>
                  <ConsultationNotes
                    patientUid={patientUid}
                    onEdit={onEditConsultationNoteBtnClick}
                    onDelete={onDeleteConsultationNoteBtnClick}
                  />
                </Box>
              </Box>
            ) : selectedTab == "appointments" ? (
              <Box className={styles.cardView} px={3}>
                <AppointmentList patientUid={patientUid} />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      {patientDetail != null ? (
        <AddEditPatientModal
          type={"edit"}
          data={patientDetail}
          modalVisible={editPatientModalVisible}
          doctorList={props.allDoctorList}
          onCancel={onCancelEditPatient}
          onSubmit={onEditPatient}
        />
      ) : null}

      <ConfirmAlertBox
        confirmBox={confirmBoxVisible}
        icon={<ExclaimIcon sx={{height: 80, width: 80, color: '#ffc700'}}/>}
        title={"Are you sure?"}
        content={"You won't be able to revert this!"}
        btnLoading={props.consultationNoteBtnLoading}
        firstBtn={"Yes, delete it!"}
        secondBtn={"Cancel"}
        onConfirmClick={onConfirmDelete}
        onCancelClick={onCancelDelete}
      />

      <AlertMsg />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    patientDetailLoading: state.userData.patientDetailLoading,
    patientDetail: state.userData.patientDetail,
    consultationNoteBtnLoading: state.userData.consultationNoteBtnLoading,
    allDoctorList: state.userData.allDoctorList,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPatientDetail: (data) => dispatch(getPatientDetail(data)),
    deletePatient: (data) => dispatch(deletePatient(data)),
    addConsultationNote: (data) => dispatch(addConsultationNote(data)),
    updateConsultationNote: (data) => dispatch(updateConsultationNote(data)),
    deleteConsultationNote: (data) => dispatch(deleteConsultationNote(data)),
    getAllDoctor: (data) => dispatch(getAllDoctor(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientView);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: '#AAA',
    height: 120,
    width: 120
  }
};
