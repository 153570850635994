import React, {useEffect, useState} from "react";
import {
  Grid,
  Typography,
  Modal,
  CircularProgress, Box,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";

import Container from "../../components/Container";
import FormSelectize from "../../components/FormSelectize";
import FormTextArea from "../../components/FormTextArea";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import AddEditLabelModal from "../../components/AddEditLabelModal";
import AlertMsg from "../../components/AlertMsg";
import FormDatePicker from "../../components/FormDatePicker";

import {capitalizeFirstLetter} from "../../assets/functions/common";

import {connect} from "react-redux";
import {
  addEnquiry,
  updateApiStatus,
  updateEnquiry,
  getEnquiryDetail,
  listLabel,
} from "../../redux/actions/userDataActions";
import {
  ADD_ENQUIRY_FAIL,
  ADD_ENQUIRY_SUCCESS,
  UPDATE_ENQUIRY_FAIL,
  UPDATE_ENQUIRY_SUCCESS,
} from "../../redux/actions/types";

import {LABEL_COLOR} from "../../constants";

import styles from "./AddEditEnquiry.module.css";

const AddEditEnquiry = (props) => {
  const [loading, setLoading] = useState(true);
  const [enquiryUid, setEnquiryUid] = useState(window.location.pathname.split("/").length >= 3 ? window.location.pathname.split("/").pop() : "");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [location, setLocation] = useState("");
  const [label, setLabel] = useState([]);
  const [note, setNote] = useState("");
  const [error, setError] = useState(null);
  const [labelModalVisible, setLabelModalVisible] = useState(false);
  const [selectedLabelData, setSelectedLabelData] = useState(null);
  const [status, setStatus] = useState("enquiry");
  const [receivedDate, setReceivedDate] = useState(dayjs(new Date()));
  const [nextFollowup, setNextFollowup] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let mobile = params.get("mobile");
    if (mobile != null) {
      if (mobile) {
        mobile = encodeURIComponent(params.get("mobile")).replace("%20", "+");
        setWhatsapp(mobile);
        setEnquiryUid("");
        setName("");
        setMobile(mobile);
        setLocation("");
        setLabel([]);
        setLoading(false);
        setError(null);
      }
    } else {
      let status_val = "enquiry";
      if(window.location.pathname.split("/").length >= 3) {
        let status_path = window.location.pathname.split("/")[1];
        const arr = status_path.split("_");
        if (arr.length >= 2) {
          const name = arr.slice(0, 1).concat(arr.slice(1).join(" "));
          status_val = name[1];
        }
      }
      let data = {
        enquiryUid: enquiryUid,
        status: status_val,
      };
      setStatus(status_val)
      props.getEnquiryDetail(JSON.stringify(data));
    }
  }, []);

  useEffect(() => {
    let data = {
      orderBy: 'name',
      orderSort: 'ASC',
    }
    props.listLabel(JSON.stringify(data));
  },[])

  useEffect(() => {
    if (
      (props.apiStatus == ADD_ENQUIRY_SUCCESS ||
        props.apiStatus == UPDATE_ENQUIRY_SUCCESS)
    ) {
      if(props.apiStatus == UPDATE_ENQUIRY_SUCCESS) {
        navigate('/' + status + '/' + enquiryUid)
      }
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if(props.enquiryDetail != null && enquiryUid) {
      let data = props.enquiryDetail;
      setName(data?.name);
      setMobile(data?.mobile);
      setWhatsapp(data?.whatsapp);
      setLocation(data?.location);
      setLabel(data?.label)
      if (data?.received_date) {
        setReceivedDate(dayjs(data.received_date));
      } else {
        setReceivedDate("");
      }
      if (data?.next_follow_up) {
        setNextFollowup(dayjs(data.next_follow_up));
      } else {
        setNextFollowup("");
      }
      setLoading(props.enquiryDetailLoading);
    } else {
      setName("");
      setLocation("");
      setNote("");
      setLabel([]);
      setNextFollowup("");
      setLoading(false);
    }
  },[props.enquiryDetail])

  const onSubmitClick = () => {
    let errorText = {};
    if (mobile == "") {
      errorText = {mobile: "Please enter mobile"};
    } else {
      if (!/^[+]*[ 0-9]+$/.test(mobile)) {
        errorText = {...errorText, mobile: "Invalid mobile"};
      }
    }
    if (whatsapp != "" && whatsapp != null) {
      if (!/^[+]*[ 0-9]+$/.test(whatsapp)) {
        errorText = {...errorText, whatsapp: "Invalid whatsapp"};
      }
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    onSubmit();
  };

  const onSubmit = () => {
    let data = {
      enquiryUid: enquiryUid,
      name: name,
      mobile: mobile,
      whatsapp: whatsapp,
      label: label,
      location: location,
      note: note,
      status: status,
      receivedDate: receivedDate ? dayjs(receivedDate).format("YYYY-MM-DD") : "",
      nextFollowup: nextFollowup ? dayjs(nextFollowup).format("YYYY-MM-DD") : "",
    };

    if (enquiryUid) {
      props.updateEnquiry(JSON.stringify(data));
    } else {
      props.addEnquiry(JSON.stringify(data));
    }
  };

  const onChange = (val, type) => {
    let errorText = error;
    if (type == "name") {
      val = capitalizeFirstLetter(val)
      setName(val);
    } else if (type == "mobile") {
      if (val != "") {
        if (!/^[+]*[ 0-9]+$/.test(val)) {
            errorText = {...errorText, mobile: "Invalid mobile"};
        } else {
          errorText = {...error, mobile: ""};
        }
      } else {
        errorText = {...error, mobile: "Please enter mobile"};
      }
      setMobile(val);
    } else if (type == "whatsapp") {
      if (val != "" && val != null) {
        if (!/^[+]*[ 0-9]+$/.test(val)) {
          errorText = {...errorText, whatsapp: "Invalid whatsapp"};
        } else {
          errorText = {...error, whatsapp: ""};
        }
      }
      setWhatsapp(val);
    } else if (type == "location") {
      setLocation(val);
    }
    setError(errorText);
  };

  const onLabelChange = (val) => {
    setLabel(val);
  };

  const onAddLabel = (val) => {
    setSelectedLabelData({
      uid: "",
      name: val,
      shortForm: "",
      color: LABEL_COLOR
    })
    setLabelModalVisible(true)
  };

  const onAddEditLabel = () => {
    setLabel([...label, props.labelList[0]])
    setLabelModalVisible(false);
  };

  const onCancelLabel = () => {
    setLabelModalVisible(false);
  };

  if (loading) {
    return (
      <Box
        style={{minHeight: window.innerHeight - 140}}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress/>
      </Box>
    );
  }

  return (

    <Container page={status}>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        className={styles.cardView}
        p={4}
      >

        <Grid item md={12} xs={12} mb={4}
              display={"flex"}
              alignItems={"flex-start"}>
          <Typography className={styles.titleText}>
            {enquiryUid ? "Edit " : "Add "} {status == 'enquiry' ? 'Enquiry' : 'Follow Up'}
          </Typography>
        </Grid>

        <Grid item md={5.8} xs={12} mt={3}>
          <FormTextInput
            page={"AddEditEnquiry"}
            required={false}
            error={error?.name}
            value={name}
            setValue={(val) => onChange(val, "name")}
            label={"NAME"}
          />
        </Grid>
        <Grid item md={5.8} xs={12} mt={3}>
          <FormTextInput
            page={"AddEditEnquiry"}
            required={true}
            error={error?.mobile}
            value={mobile}
            setValue={(val) => onChange(val, "mobile")}
            label={"MOBILE"}
          />
        </Grid>
        <Grid item md={5.8} xs={12} mt={3}>
          <FormTextInput
            page={"AddEditEnquiry"}
            required={false}
            error={error?.whatsapp}
            value={whatsapp}
            setValue={(val) => onChange(val, "whatsapp")}
            label={"WHATSAPP"}
          />
        </Grid>
        <Grid item md={5.8} xs={12} mt={3}>
          <FormSelectize
            page={"AddEditEnquiry"}
            value={label}
            label={"LABEL"}
            multiple={true}
            addOption={true}
            required={false}
            onChange={(val) => onLabelChange(val)}
            options={props.labelList}
            loading={props.labelLoading}
            disabled={false}
            error={error?.label}
            onAdd={onAddLabel}
          />
        </Grid>
        <Grid item md={5.8} xs={12} mt={3}>
          <FormTextInput
            page={"AddEditEnquiry"}
            required={false}
            error={error?.location}
            value={location}
            setValue={(val) => onChange(val, "location")}
            label={"LOCATION"}
          />
        </Grid>
        <Grid item md={5.8} xs={12} mt={3}>
          <FormDatePicker
            page={"AddEditEnquiry"}
            label={"RECEIVED DATE"}
            value={receivedDate}
            required={false}
            inputFormat="DD-MM-YYYY"
            maxDate={dayjs()}
            onChange={(val) => setReceivedDate(val)}
            error={error?.receivedDate}
          />
        </Grid>

        {status == 'followup' ?
          <Grid item md={5.8} xs={12} mt={3}>
            <FormDatePicker
              page={"AddEditEnquiry"}
              label={"NEXT FOLLOWUP"}
              value={nextFollowup}
              required={false}
              minDate={dayjs().add(1, 'day')}
              inputFormat="DD-MM-YYYY"
              onChange={(val) => setNextFollowup(val)}
              error={error?.nextFollowup}
            />
          </Grid>
          : null}

        {enquiryUid ? null :
          <Grid item md={12} xs={12} mt={4}>
            <FormTextArea
              page={'AddEditEnquiry'}
              required={false}
              error={error?.note}
              value={note}
              setValue={setNote}
              label={'NOTE'}
            />
          </Grid>}

        <Grid item md={12} xs={12}
              display={"flex"}
              justifyContent={"flex-end"}
              mt={3}>
          <FormButton
            loading={props.enquiryBtnLoading}
            btnStyleView={style.btnStyleView}
            btnType={"btn1"}
            title={enquiryUid ? "Update" : "Submit"}
            onSubmit={onSubmitClick}
          />
        </Grid>
      </Grid>

      <AddEditLabelModal
        type={'add'}
        data={selectedLabelData}
        modalVisible={labelModalVisible}
        onCancel={onCancelLabel}
        onSubmit={onAddEditLabel}
      />

      <AlertMsg />

    </Container>

  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addEnquiry: (data) => dispatch(addEnquiry(data)),
    getEnquiryDetail: (data) => dispatch(getEnquiryDetail(data)),
    updateEnquiry: (data) => dispatch(updateEnquiry(data)),
    listLabel: (data) => dispatch(listLabel(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    enquiryBtnLoading: state.userData.enquiryBtnLoading,
    labelList: state.userData.labelList,
    labelLoading: state.userData.labelLoading,
    apiStatus: state.userData.apiStatus,
    enquiryDetail: state.userData.enquiryDetail,
    enquiryDetailLoading: state.userData.enquiryDetailLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditEnquiry);

const style = {
  btnStyleView: {
    height: 40,
    width: "10%",
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
};
