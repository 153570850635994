import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";

import { connect } from "react-redux";

import Navbar from "./Navbar";
import Footer from "./Footer";
import GlobalSearch from "./GlobalSearch";

import Sidebar, {
  DRAWER_WIDTH,
  MOBILE_VIEW,
  SMALL_DRAWER_WIDTH,
} from "./Sidebar";

import { NAVBAR_HEIGHT, FOOTER_HEIGHT } from "../constants";

const Container = (props) => {
  const [drawerHoverOpen, setDrawerHoverOpen] = useState(!props.drawerOpen);
  const [search, setSearch] = useState('');

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (document.getElementById("navbarView")) {
      if (position >= NAVBAR_HEIGHT / 2) {
        document.getElementById("navbarView").style.boxShadow =
          "6px 4px 30px 0 rgba(38,60,85,.12)";
      } else {
        document.getElementById("navbarView").style.boxShadow = "unset";
      }
    }
  };

  const onSearch = (val) => {
    setSearch(val);
  };

  return (
    <Box sx={{ display: { md: "flex" } }}>
      <CssBaseline />
      <Navbar
        {...props}
        onSearch={onSearch}
      />
      <Sidebar
        {...props}
        drawerHoverOpen={drawerHoverOpen}
        setDrawerHoverOpen={setDrawerHoverOpen}
      />
      <Box
        component="main"
        open={props.drawerOpen}
        style={{
          width:
            window.innerWidth -
            (MOBILE_VIEW ? 0 : 20) -
            (props.drawerOpen
              ? MOBILE_VIEW
                ? 0
                : DRAWER_WIDTH
              : SMALL_DRAWER_WIDTH),
          marginLeft: props.drawerOpen
            ? MOBILE_VIEW
              ? 0
              : DRAWER_WIDTH
            : SMALL_DRAWER_WIDTH,
          transition: "0.3s",
        }}
        sx={{
          flexGrow: 1,
        }}
      >
        <Toolbar />
        <Box
          px={typeof props?.p != "undefined" ? props.p : MOBILE_VIEW ? 2 : 4}
          pt={typeof props?.p != "undefined" ? props.p : MOBILE_VIEW ? 2 : 4}
          style={{
            minHeight: window.innerHeight - NAVBAR_HEIGHT - FOOTER_HEIGHT,
            backgroundColor: props?.bgColor ? props?.bgColor : 'transparent'
          }}
        >
          {search ?
            <GlobalSearch
              search={search}
            />
            :
            props.children}
        </Box>
        <Box
          px={typeof props?.p != "undefined" ? props.p : MOBILE_VIEW ? 2 : 4}
          style={{
            height: FOOTER_HEIGHT,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerOpen: state.userData.drawerOpen,
    userName: state.userData.userName,
  };
};

export default connect(mapStateToProps, null)(Container);
