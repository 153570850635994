import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";

import { connect } from "react-redux";

import FormButton from "./FormButton";
import FormTextArea from "./FormTextArea";

const AddEditEnquiryNotes = (props) => {
  const [note, setNote] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.data != null) {
      setNote(props.data?.note);
    } else {
      setNote("");
    }
    setError(null);
  }, [props.data]);

  const onAddEditNote = () => {
    let errorText = {};
    if (note == "") {
      errorText = { note: "Please enter note" };
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      enquiryUid: props?.enquiryUid,
      enquiryStatus: props?.enquiryStatus,
      note: note,
    };

    if (props.data != null) {
      data = { ...data, enquiryNoteId: props.data?.id };
    }

    setNote("");
    setError(null);

    props.onAddEdit(data);
  };

  const onCancelNote = () => {
    props.onCancel();
  };

  return (
    <Grid item md={12} xs={12}>
      <FormTextArea
        page={"EnquiryNotes"}
        error={error?.note}
        value={note}
        setValue={setNote}
        placeholder={"Notes"}
      />

      <Box
        mt={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <FormButton
          loading={props.enquiryNoteBtnLoading}
          title={props.data != null ? "Update" : "Add"}
          btnType={"btn1"}
          btnStyleView={style.btnStyleView}
          onSubmit={onAddEditNote}
        />
        {props.data != null ? (
          <Box ml={2}>
            <FormButton
              title={"Cancel"}
              btnType={"btn2"}
              btnStyleView={style.btnStyleView}
              onSubmit={onCancelNote}
            />
          </Box>
        ) : null}
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    enquiryNoteBtnLoading: state.userData.enquiryNoteBtnLoading,
  };
};

export default connect(mapStateToProps, null)(AddEditEnquiryNotes);

const style = {
  btnStyleView: {
    height: 40,
  }
}
