import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Modal, Divider } from "@mui/material";
import moment from "moment";

import FormButton from "../components/FormButton";

import { connect } from "react-redux";
import {
  addPatient,
  updateApiStatus,
  updatePatient,
} from "../redux/actions/userDataActions";

import styles from "./PatientExistDetailModal.module.css";

import CloseIcon from '@mui/icons-material/CloseOutlined';

const PatientExistDetailModal = (props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.modalVisible) {
      setVisible(true);
    }
  }, [props.modalVisible]);

  const onCancelClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onCancel();
    }, [100]);
  };

  return (
    <Modal
      disableBackdropClick
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
      hideBackdrop={true}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{ md: 4, xs: 4 }}
      >
        <Grid
          onClick={() => onCancelClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle} />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            Existing Patient Detail
          </Typography>

          <Grid item md={12} xs={12} mt={3}>
            {props.patientExistDetail?.map((x, i) => {
              return (
                <Grid container item xs={12}>
                  <Grid item xs={12} md={8}>
                    <Typography className={styles.nameText}>
                      {x?.first_name} {x?.last_name}
                    </Typography>
                    <Typography className={styles.ageText}>
                      Gender: {x?.gender}
                    </Typography>
                    {x?.dob ?
                    <Typography className={styles.ageText}>
                      DOB: {moment(x.dob, "YYYY-MM-DD").format("DD-MM-YYYY")}
                    </Typography>
                      : null}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={styles.mobileText}>
                      Mobile: {x?.mobile}
                    </Typography>
                    {x?.whatsapp ? (
                      <Typography className={styles.whatsappText}>
                        Whatsapp: {x.whatsapp}
                      </Typography>
                    ) : null}
                    {x?.email ? (
                      <Typography className={styles.emailText}>
                        Email: {x.email}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {x?.address ? (
                      <Typography className={styles.addressText}>
                        Address: {x.address}
                      </Typography>
                    ) : null}
                    {x?.referred_by ? (
                      <Typography mt={1} className={styles.referredText}>
                        Referred By: {x?.doctor_name}, {x?.doctor_mobile},{" "}
                        {x?.doctor_hospital}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={12} my={2}>
                    <Divider />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          mt={3}
          display={"flex"}
          flexDirection="row"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item md={2} xs={2}>
            <FormButton
              loading={props.patientBtnLoading}
              btnStyleView={style.btnStyleView}
              btnType={"btn1"}
              title={"Continue"}
              onSubmit={props.onSubmit}
            />
          </Grid>
          <Grid ml={{ md: 2, xs: 4 }} item md={2} xs={2}>
            <FormButton
              btnStyleView={style.btnStyleView}
              btnType={"btn2"}
              title={"Cancel"}
              onSubmit={onCancelClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPatient: (data) => dispatch(addPatient(data)),
    updatePatient: (data) => dispatch(updatePatient(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    patientExistDetail: state.userData.patientExistDetail,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientExistDetailModal);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
};
