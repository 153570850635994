import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Menu from "@mui/material/Menu";

import { connect } from "react-redux";
import { updateDrawerOpen } from "../redux/actions/userDataActions";

import Dashboard from "../containers/Dashboard/Dashboard";

import { ReactComponent as LogoIcon } from "../assets/img/logo.svg";
import MenuToggleOnIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import MenuToggleOffIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';

import MobileLogo from '../assets/img/logo.png';

import DashboardIcon from '@mui/icons-material/HomeOutlined';
import UserIcon from '@mui/icons-material/PersonOutlineOutlined';
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';
import DoctorIcon from '@mui/icons-material/LocalHospitalOutlined';
import InvoiceIcon from '@mui/icons-material/DescriptionOutlined';
import ProductCategoryIcon from '@mui/icons-material/CategoryOutlined';
import ProductIcon from '@mui/icons-material/Inventory2Outlined';
import ProductEnquiryIcon from '@mui/icons-material/HelpCenterOutlined';
import EnquiryMainIcon from '@mui/icons-material/FileCopyOutlined';
import EnquiryIcon from '@mui/icons-material/ArticleOutlined';
import CalculateIcon from '@mui/icons-material/CalculateOutlined';
import IncomeExpenseIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AudioGraphIcon from '@mui/icons-material/GraphicEq';
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';

import { THEME_COLOR, THEME_COLOR2, THEME_COLOR3 } from "../constants";

import styles from "./Sidebar.module.css";
import {Grid} from "@mui/material";
import AudioGraph from "../containers/AudioGraph/AudioGraph";

export const MOBILE_VIEW = window.innerWidth < 600;
export const DRAWER_WIDTH = 240;
export const SMALL_DRAWER_WIDTH = MOBILE_VIEW ? 0 : 70;
const ICON_SiZE = 20;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
  backgroundColor: THEME_COLOR2,
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  boxShadow: MOBILE_VIEW
    ? "0 0.625rem 1.25rem rgba(147, 158, 170, 0.5)"
    : "unset",
  ...(MOBILE_VIEW
    ? { position: "fixed", top: 0, bottom: 0, zIndex: 1201 }
    : null),
});

const closedMixin = (theme, hoverOpen) => ({
  backgroundColor: THEME_COLOR2,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: hoverOpen ? DRAWER_WIDTH : SMALL_DRAWER_WIDTH,
  boxShadow:
    hoverOpen || MOBILE_VIEW
      ? "0 0.625rem 1.25rem rgba(147, 158, 170, 0.5)"
      : "unset",
  ...(hoverOpen || MOBILE_VIEW
    ? {
        position: "fixed",
        top: 0,
        bottom: 0,
        zIndex: 1201,
      }
    : null),
});

const SlideDrawer = styled(MuiDrawer)(({ theme, open, drawerHoverOpen }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open &&
    (drawerHoverOpen || !drawerHoverOpen || MOBILE_VIEW) && {
      ...closedMixin(theme, drawerHoverOpen),
      "& .MuiDrawer-paper": closedMixin(theme, drawerHoverOpen),
    }),
}));

const Sidebar = (props) => {
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    let url_val = window.location.pathname.split("/").pop();
    if (url_val == 'enquiry' || url_val == 'followup') {
      setOpenSubMenu('enquiries')
    }
  }, [])

  let MENU_LIST = [
    {
      page: "dashboard",
      label: "Dashboard",
      icon: (
        <Box>
          <DashboardIcon/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{ path: { fill: THEME_COLOR } }}>
          <DashboardIcon/>
        </Box>
      ),
      href: "/",
    },];

  if (props.userType == 'admin') {
    MENU_LIST = [...MENU_LIST, {
      page: "users",
      label: "Users",
      icon: (
        <Box>
          <UserIcon/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <UserIcon/>
        </Box>
      ),
      href: "/users",
    },];
  }

  MENU_LIST = [...MENU_LIST, {
      page: "doctors",
      label: "Doctors",
      icon: (
        <Box sx={{ path: { fill: '#677788' } }}>
          <DoctorIcon/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <DoctorIcon/>
        </Box>
      ),
      href: "/doctors",
    },
    {
      page: "patients",
      label: "Patients",
      icon: (
        <Box>
          <UserIcon/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <UserIcon/>
        </Box>
      ),
      href: "/patients",
    },
    {
      page: "appointments",
      label: "Appointments",
      icon: (
        <Box>
          <CalendarIcon/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <CalendarIcon/>
        </Box>
      ),
      href: "/appointments",
    },
    {
      page: "invoices",
      label: "Invoices",
      icon: (
        <Box>
          <InvoiceIcon/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <InvoiceIcon/>
        </Box>
      ),
      href: "/invoices",
    },
    // {
    //   page: "product_categories",
    //   label: "Categories",
    //   icon: (
    //     <Box>
    //       <ProductCategoryIcon/>
    //     </Box>
    //   ),
    //   activeIcon: (
    //     <Box sx={{path: {fill: THEME_COLOR}}}>
    //       <ProductCategoryIcon/>
    //     </Box>
    //   ),
    //   href: "/categories",
    // },
    // {
    //   page: "products",
    //   label: "Products",
    //   icon: (
    //     <Box>
    //       <ProductIcon/>
    //     </Box>
    //   ),
    //   activeIcon: (
    //     <Box sx={{path: {fill: THEME_COLOR}}}>
    //       <ProductIcon/>
    //     </Box>
    //   ),
    //   href: "/products",
    // },
    // {
    //   page: "product_enquiry",
    //   label: "Product Enquiry",
    //   icon: (
    //     <Box>
    //       <ProductEnquiryIcon/>
    //     </Box>
    //   ),
    //   activeIcon: (
    //     <Box sx={{path: {fill: THEME_COLOR}}}>
    //       <ProductEnquiryIcon/>
    //     </Box>
    //   ),
    //   href: "/product_enquiry",
    // },
    {
      page: "enquiries",
      label: "Enquiries",
      subMenu: true,
      icon: (
        <Box>
          <EnquiryMainIcon/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <EnquiryMainIcon/>
        </Box>
      ),
      items: [
        {
          page: "enquiry",
          label: "Enquiry",
          icon: (
            <Box>
              <EnquiryIcon/>
            </Box>
          ),
          activeIcon: (
            <Box sx={{path: {fill: THEME_COLOR}}}>
              <EnquiryIcon/>
            </Box>
          ),
          href: "/enquiry",
        },
        {
          page: "followup",
          label: "Follow Up",
          icon: (
            <Box>
              <EnquiryIcon/>
            </Box>
          ),
          activeIcon: (
            <Box sx={{path: {fill: THEME_COLOR}}}>
              <EnquiryIcon/>
            </Box>
          ),
          href: "/followup",
        },
      ]
    },
    {
      page: "price_calculator",
      label: "Price Calculator",
      icon: (
        <Box>
          <CalculateIcon/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <CalculateIcon/>
        </Box>
      ),
      href: "/price_calculator",
    },
    {
      page: "income_expense",
      label: "Income/Expense",
      icon: (
        <Box>
          <IncomeExpenseIcon/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <IncomeExpenseIcon/>
        </Box>
      ),
      href: "/income_expense",
    },
    {
      page: "audio_graph",
      label: "Audio Graph",
      icon: (
        <Box>
          <AudioGraphIcon/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <AudioGraphIcon/>
        </Box>
      ),
      href: "/audio_graph",
    },
  ];

  useEffect(() => {
    props.setDrawerHoverOpen(false);
    if(window.location.pathname.split("/").pop() == 'appointments') {
      props.updateDrawerOpen(false);
    }
  }, [])

  const handleDrawerToggle = () => {
    if (props.drawerHoverOpen) {
    } else {
      props.updateDrawerOpen(!props.drawerOpen);
    }
  };

  const handleToggleBtnClick = () => {
    if (props.drawerHoverOpen && !MOBILE_VIEW) {
      props.setDrawerHoverOpen(false);
    }
    props.updateDrawerOpen(!props.drawerOpen);
  };

  const onMenuClick = (page) => {
    if(page == openSubMenu) {
      setOpenSubMenu(null)
    } else {
      setOpenSubMenu(page)
    }
  }

  const DrawerView = () => {
    return (
      <>
        <DrawerHeader
          sx={{ justifyContent: "space-between", alignItems: "center", mb: 1 }}
        >
          <Box px={2} sx={{ display: "flex", alignItems: "center" }}>
            {props.drawerOpen || props.drawerHoverOpen ? (
                <LogoIcon height={50}/>
              ) :
              <Box className={styles.imgView}>
                <img alt="" src={MobileLogo} className={styles.imgStyle}/>
              </Box>
            }
          </Box>
          <IconButton onClick={handleToggleBtnClick}>
            {MOBILE_VIEW ? (
              <CloseIcon sx={{color:'#5a8dee'}}/>
            ) : props.drawerOpen ? (
              <MenuToggleOnIcon sx={{color:'#5a8dee'}} />
            ) : props.drawerHoverOpen ? (
              <MenuToggleOffIcon sx={{color:'#5a8dee'}} />
            ) : null}
          </IconButton>
        </DrawerHeader>
        <List>
          {MENU_LIST?.map((menu, index) => (
            <>
            <ListItem
              key={index}
              onClick={() => menu?.href ? navigate(menu?.href) : null}
              disablePadding
              sx={{
                display: "block",
                color: THEME_COLOR3,
                mb: 0.4,
              }}
            >
              <ListItemButton
                onClick={() => menu?.subMenu ? onMenuClick(menu?.page) : null}
                className={styles.listItemBtn}
                sx={{
                  justifyContent:
                    props.drawerOpen || props.drawerHoverOpen
                      ? "initial"
                      : "center",
                  backgroundColor:
                    props.page == menu?.page ? "#dbe4f3" : "transparent",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                    "& .MuiBox-root": {
                      lineHeight: "0 !important",
                    },
                    mr: props.drawerOpen || props.drawerHoverOpen ? 1.2 : 0,
                  }}
                >
                  {props.page == menu?.page ? menu?.activeIcon : menu?.icon}
                </ListItemIcon>
                {props.drawerOpen || props.drawerHoverOpen ? (
                  <ListItemText
                    primary={menu?.label}
                    sx={{
                      opacity:
                        props.drawerOpen || props.drawerHoverOpen ? 1 : 0,
                      color:
                        props.page == menu?.page ? THEME_COLOR : THEME_COLOR3,
                      "& .MuiTypography-root": {
                        fontFamily:
                          props.page == menu?.page
                            ? "IBMPlexSansMedium"
                            : "IBMPlexSansRegular",
                      },
                    }}
                  />
                ) : null}
                {menu?.subMenu ? ((props.drawerOpen || props.drawerHoverOpen) ? (openSubMenu == menu?.page ? <ExpandLessIcon /> : <ExpandMoreIcon />) : null) : ''}
              </ListItemButton>
            </ListItem>

              {menu?.subMenu ?
                <Collapse in={menu?.subMenu ? (openSubMenu == menu?.page) : false} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {menu?.items?.map((sub, index2) => (
                      <ListItem
                        key={index2}
                        onClick={() => sub?.href ? navigate(sub?.href) : null}
                        disablePadding
                        sx={{
                          display: "block",
                          color: THEME_COLOR3,
                          mb: 0.4,
                        }}
                      >
                        <ListItemButton
                          className={styles.subListItemBtn}
                          sx={{
                            pl: 4,
                            justifyContent:
                              props.drawerOpen || props.drawerHoverOpen
                                ? "initial"
                                : "center",
                            backgroundColor:
                              props.page == sub?.page ? "#dbe4f3" : "transparent",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              justifyContent: "center",
                              "& .MuiBox-root": {
                                lineHeight: "0 !important",
                              },
                              mr: props.drawerOpen || props.drawerHoverOpen ? 1.2 : 0,
                            }}
                          >
                            {props.page == sub?.page ? sub?.activeIcon : sub?.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={sub?.label}
                            sx={{
                              opacity:
                                props.drawerOpen || props.drawerHoverOpen ? 1 : 0,
                              color:
                                props.page == sub?.page ? THEME_COLOR : THEME_COLOR3,
                              "& .MuiTypography-root": {
                                fontFamily:
                                  props.page == sub?.page
                                    ? "IBMPlexSansMedium"
                                    : "IBMPlexSansRegular",
                              },
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
                : null}
            </>
          ))}
        </List>
      </>
    );
  };

  return (
    <Box
      sx={
        !MOBILE_VIEW
          ? { position: props.drawerOpen ? "absolute" : "fixed", zIndex: 1203 }
          : null
      }
      component="nav"
      onMouseOver={() =>
        MOBILE_VIEW
          ? null
          : !props.drawerOpen &&
            document.getElementById("sidebarDrawer")?.offsetWidth ==
              SMALL_DRAWER_WIDTH
          ? props.setDrawerHoverOpen(true)
          : null
      }
      onMouseLeave={() =>
        MOBILE_VIEW
          ? null
          : !props.drawerOpen && props.drawerHoverOpen
          ? props.setDrawerHoverOpen(false)
          : null
      }
    >
      <SlideDrawer
        id={"sidebarDrawer"}
        variant={"permanent"}
        open={props.drawerOpen}
        onClose={handleDrawerToggle}
        drawerHoverOpen={props.drawerHoverOpen}
      >
        <DrawerView />
      </SlideDrawer>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    userType: state.userData.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDrawerOpen: (data) => dispatch(updateDrawerOpen(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
