import React from "react";
import { Box, Chip, Tooltip } from "@mui/material";

import styles from "./LabelListHover.module.css";

const LabelListHover = (props) => {
  return (
    <Box mt={0.5} className={styles.labelView} sx={props.labelViewStyle}>
      {props.labels?.map((label, i) => {
        return (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  marginBottom: "2px !important",
                },
              },
            }}
            title={label?.name}
            placement="top"
          >
            <Chip
              key={i}
              sx={{
                backgroundColor: label?.color,
                mr: 0.5,
                mb: 0.5,
              }}
              className={styles.labelText}
              label={label?.short_form}
              size="small"
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default LabelListHover;
