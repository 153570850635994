import React, {useEffect, useState, useRef} from "react";
import {
  Grid,
  Typography,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";

import FormButton from "../components/FormButton";
import FormTextInput from "./FormTextInput";
import FormSelectize from "./FormSelectize";
import FormSelectBox from "./FormSelectBox";
import FormDatePicker from "./FormDatePicker";

import {connect} from "react-redux";
import {
  getAllPatient,
  addIncomeExpense,
  updateApiStatus,
  updateIncomeExpense,
} from "../redux/actions/userDataActions";
import {
  ADD_INCOME_EXPENSE_FAIL,
  ADD_INCOME_EXPENSE_SUCCESS,
  UPDATE_INCOME_EXPENSE_FAIL,
  UPDATE_INCOME_EXPENSE_SUCCESS,
} from "../redux/actions/types";

import styles from "./AddEditIncomeExpenseModal.module.css";

import {THEME_COLOR} from "../constants";

import CloseIcon from '@mui/icons-material/CloseOutlined';
import DownArrowIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const type_list = [
  {
    label: "Income",
    value: "income",
  },
  {
    label: "Expense",
    value: "expense",
  },
];

const AddEditIncomeExpenseModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [incomeExpenseUid, setIncomeExpenseUid] = useState("");
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [patient, setPatient] = useState(null);
  const [type, setType] = useState(null);
  const [addedDate, setAddedDate] = useState(dayjs(new Date()));
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);

  const titleRef = useRef(null);

  useEffect(() => {
    props.getAllPatient();
  }, []);

  useEffect(() => {
    if (
      (props.apiStatus == ADD_INCOME_EXPENSE_SUCCESS || props.apiStatus == UPDATE_INCOME_EXPENSE_SUCCESS) &&
      props.modalVisible
    ) {
      props.updateApiStatus("");
      props.onSubmit();
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if (props.data != null) {
      setIncomeExpenseUid(props.data?.uid);
      setTitle(props.data?.title);
      setAmount(props.data?.amount);
      setAddedDate(dayjs(props.data?.added_date));
      if (props.data?.patient_uid != null) {
        let patient_list = props?.allPatientList.filter(
          (x) => x.uid == props.data?.patient_uid
        );
        if (patient_list.length) {
          setPatient(patient_list[0]);
        }
      }
      if (props.data?.type) {
        let list = type_list.filter(
          (x) => x.value == props.data?.type
        );
        if (list.length) {
          setType(list[0]);
        }
      }
      setLoading(false);
    } else {
      setIncomeExpenseUid("");
      setTitle("");
      setAmount("");
      setAddedDate(dayjs(new Date()))
      setPatient(null);
      setType(null);
      setError(null);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.modalVisible) {
      if (props.type == "add") {
        setLoading(false);
      }
      setVisible(true);
    } else {
      setIncomeExpenseUid("");
      setTitle("");
      setAmount("");
      setAddedDate(dayjs(new Date()))
      setPatient(null);
      setType(null);
      setError(null);
    }
  }, [props.modalVisible]);

  useEffect(() => {
    if(visible) {
      if(titleRef?.current) {
        titleRef?.current?.focus()
      }
    }
  },[visible])

  const onSubmitClick = () => {
    let errorText = {};
    if (title == "") {
      errorText = {title: "Please enter title"};
    }
    if (amount == "" || amount == 0) {
      errorText = {...errorText, amount: "Please enter amount"};
    } else {
      if (!/^[0-9.]{1,100}$/.test(amount)) {
        errorText = {...errorText, amount: "Invalid amount"};
      }
    }
    if (type == null) {
      errorText = {...errorText, type: "Please choose type"};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    onSubmit();
  };

  const onSubmit = () => {
    let data = {
      incomeExpenseUid: incomeExpenseUid,
      title: title,
      amount: amount,
      patientUid: patient != null ? patient?.uid : '',
      type: type?.value,
      addedDate: dayjs(addedDate).format("YYYY-MM-DD")
    };

    if (props.type == "add") {
      props.addIncomeExpense(JSON.stringify(data));
    } else {
      props.updateIncomeExpense(JSON.stringify(data));
    }
  };

  const onCancelClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onCancel();
    }, [100]);
  };

  const onChange = (val, type_val) => {
    let errorText = error;
    if (type_val == "title") {
      if (val != "") {
        errorText = {...error, title: ""};
      } else {
        errorText = {...error, title: "Please enter title"};
      }
      setTitle(val);
    } else if (type_val == "amount") {
      if (val != "" && val != 0) {
        if (!/^[0-9.]{1,100}$/.test(val)) {
          errorText = {...errorText, amount: "Invalid amount"};
        } else {
          errorText = {...error, amount: ""};
        }
      } else {
        errorText = {...error, amount: "Please enter amount"};
      }
      setAmount(val);
    } else if (type_val == "type") {
      setType(val);
    }
    setError(errorText);
  };

  return (
    <Modal
      onClose={onCancelClick}
      disableBackdropClick
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{md: 8, xs: 4}}
      >
        <Grid
          onClick={() => onCancelClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle}/>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            {props?.type == "add" ? "Add " : "Edit "} Income/Expense
          </Typography>
        </Grid>

        {loading ? (
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress/>
          </Grid>
        ) : (
          <>
            <Grid item md={12} xs={12} mt={3}>
              <FormTextInput
                page={"AddEditIncomeExpenseModal"}
                inputRef={titleRef}
                required={true}
                error={error?.title}
                value={title}
                setValue={(val) => onChange(val, "title")}
                label={"TITLE"}
              />
            </Grid>
            <Grid item md={12} xs={12} mt={1}>
              <FormTextInput
                page={"AddEditIncomeExpenseModal"}
                required={true}
                error={error?.amount}
                value={amount}
                setValue={(val) => onChange(val, "amount")}
                label={"AMOUNT"}
              />
            </Grid>
            <Grid item md={12} xs={12} mt={1}>
              <FormSelectize
                page={"AddEditIncomeExpenseModal"}
                value={patient}
                label={"PATIENT"}
                multiple={false}
                addOption={false}
                onChange={(val) => {
                  setPatient(val);
                }}
                options={props?.allPatientList}
                error={error?.patient}
              />
            </Grid>
            <Grid item md={12} xs={12} mt={1}>
              <FormSelectBox
                page={"AddEditIncomeExpenseModal"}
                value={type}
                setValue={setType}
                label={"TYPE"}
                required={true}
                height={40}
                rightIcon={<DownArrowIcon sx={{color: "#677788"}}/>}
                data={type_list}
                error={error?.type}
              />
            </Grid>
            <Grid item md={12} xs={12} mt={1}>
              <FormDatePicker
                page={"AddEditIncomeExpenseModal"}
                label={"DATE"}
                value={addedDate}
                required={true}
                inputFormat="DD-MM-YYYY"
                maxDate={dayjs()}
                onChange={(val) => setAddedDate(val)}
                error={error?.addedDate}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection="row"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item md={2} xs={2}>
                <FormButton
                  loading={props.incomeExpenseBtnLoading}
                  btnStyleView={style.btnStyleView}
                  btnType={"btn1"}
                  title={props.type == "add" ? "Submit" : "Update"}
                  onSubmit={onSubmitClick}
                />
              </Grid>
              <Grid ml={{md: 2, xs: 4}} item md={2} xs={2}>
                <FormButton
                  btnStyleView={style.btnStyleView}
                  btnType={"btn2"}
                  title={"Cancel"}
                  onSubmit={onCancelClick}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllPatient: (data) => dispatch(getAllPatient(data)),
    addIncomeExpense: (data) => dispatch(addIncomeExpense(data)),
    updateIncomeExpense: (data) => dispatch(updateIncomeExpense(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    allPatientList: state.userData.allPatientList,
    incomeExpenseBtnLoading: state.userData.incomeExpenseBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditIncomeExpenseModal);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
};
