import React, {useEffect, useState} from "react";
import {
  Grid,
  Box,
  Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from "@mui/material";

import Container from "../../components/Container";
import FormTextInput from "../../components/FormTextInput";
import AlertMsg from "../../components/AlertMsg";

import styles from "./PriceCalculator.module.css";

import "../../assets/css/custom.css";

const PriceCalculator = (props) => {
  const [mrp, setMrp] = useState('');
  const [customerDiscount, setCustomerDiscount] = useState('');
  const [customerToPay, setCustomerToPay] = useState(0);
  const [error, setError] = useState(null);
  const [amount, setAmount] = useState('');
  const [dbdPerc2, setDbdPerc2] = useState(7.50);
  const [dbdPerc4, setDbdPerc4] = useState(6.50);
  const [inclGst2Perc, setInclGst2Perc] = useState('');
  const [inclGst4Perc, setInclGst4Perc] = useState('');
  const [dbdAmount2, setDbdAmount2] = useState('');
  const [dbdAmount4, setDbdAmount4] = useState('');
  const [processingFee2, setProcessingFee2] = useState(767);
  const [processingFee4, setProcessingFee4] = useState(767);
  const [dpFromCustomer2, setDpFromCustomer2] = useState('');
  const [dpFromCustomer4, setDpFromCustomer4] = useState('');
  const [emiAmount2, setEmiAmount2] = useState('');
  const [emiAmount4, setEmiAmount4] = useState('');
  const [emiTenure2, setEmiTenure2] = useState('');
  const [emiTenure4, setEmiTenure4] = useState('');
  const [emiError, setEmiError] = useState(null);

  useEffect(() => {
    if(amount != '') {
      let dbdVal2 = dbdPerc2/100;
      let dbdVal4 = dbdPerc4/100;
      let grossTenure2 = 10;
      let grossTenure4 = 12;
      let advanceTenure2 = 2;
      let advanceTenure4 = 4;

      let incl_gst2 = '';
      let incl_gst4 = '';
      let incl_gst2_perc = '';
      let incl_gst4_perc = '';
      incl_gst2 = parseFloat((dbdVal2) * (18/100)) + parseFloat(dbdVal2)
      incl_gst4 = parseFloat((dbdVal4) * (18/100)) + parseFloat(dbdVal4)
      incl_gst2_perc = parseFloat((dbdPerc2) * (18/100)) + parseFloat(dbdPerc2)
      incl_gst4_perc = parseFloat((dbdPerc4) * (18/100)) + parseFloat(dbdPerc4)
      setInclGst2Perc(incl_gst2_perc);
      setInclGst4Perc(incl_gst4_perc);

      let dbd_amount2 = '';
      let dbd_amount4 = '';
      if(incl_gst2 != '') {
        dbd_amount2 = amount * incl_gst2;
      }
      if(incl_gst4 != '') {
        dbd_amount4 = amount * incl_gst4;
      }
      setDbdAmount2(dbd_amount2);
      setDbdAmount4(dbd_amount4);

      let db_from_customer2 = parseFloat((amount/grossTenure2) * (advanceTenure2)) + parseFloat(processingFee2);
      let db_from_customer4 = parseFloat((amount/grossTenure4) * (advanceTenure4)) + parseFloat(processingFee4);
      setDpFromCustomer2(db_from_customer2);
      setDpFromCustomer4(db_from_customer4);

      let emi_amount2 = amount/grossTenure2;
      let emi_amount4 = amount/grossTenure4;
      setEmiAmount2(emi_amount2);
      setEmiAmount4(emi_amount4);

      let emi_tenure2 = parseFloat(grossTenure2) - parseFloat(advanceTenure2);
      let emi_tenure4 = parseFloat(grossTenure4) - parseFloat(advanceTenure4);
      setEmiTenure2(emi_tenure2);
      setEmiTenure4(emi_tenure4);

    } else {
      setInclGst2Perc('');
      setInclGst4Perc('');
      setDbdAmount2('');
      setDbdAmount4('');
      setDpFromCustomer2('');
      setDpFromCustomer4('');
      setEmiAmount2('');
      setEmiAmount4('');
      setEmiTenure2('');
      setEmiTenure4('');
    }
  },[amount])

  const onChange = (val, type) => {
    let errorText = error;
    let total = 0;

    if (type == "mrp") {
      if (val != "") {
        if (!/^[0-9.]{1,100}$/.test(val)) {
          errorText = {...errorText, mrp: "Invalid MRP"};
        } else {
          if (val && customerDiscount) {
            let dis = val * (customerDiscount / 100)
            total = parseFloat(val) - parseFloat(dis)
          }
          errorText = {...errorText, mrp: ""};
        }
      } else {
        errorText = {...errorText, mrp: "Enter MRP"};
      }
      setMrp(val);
    } else if (type == "customer_discount") {
      if (val != "") {
        if (!/^[0-9.]{1,100}$/.test(val)) {
          errorText = {...errorText, customerDiscount: "Invalid Customer Discount"};
        } else {
          if (mrp && val) {
            let dis = mrp * (val / 100)
            total = parseFloat(mrp) - parseFloat(dis)
          }
          errorText = {...errorText, customerDiscount: ""};
        }
      } else {
        errorText = {...errorText, customerDiscount: "Enter Customer Discount"};
      }
      setCustomerDiscount(val);
    }
    setCustomerToPay(total);

    setError(errorText);
  };

  const onChangeEmi = (val, type) => {
    let emiErrorText = emiError;

    if (type == "amount") {
      if (val != "") {
        if (!/^[0-9.]{1,100}$/.test(val)) {
          emiErrorText = {...emiErrorText, amount: "Invalid Amount"};
        } else {
          emiErrorText = {...emiErrorText, amount: ""};
        }
      } else {
        emiErrorText = {...emiErrorText, amount: "Enter Amount"};
      }
      setAmount(val);
    }
    setEmiError(emiErrorText);
  };

  return (
    <Container page={"price_calculator"}>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        className={styles.cardView}
        p={4}
      >

        <Grid item md={12} xs={12} mb={4}
              display={"flex"}
              alignItems={"flex-start"}>
          <Typography className={styles.titleText}>
            Price Calculator
          </Typography>
        </Grid>

        <Grid item md={5.8} xs={12} mt={3}>
          <FormTextInput
            page={"PriceCalculator"}
            required={true}
            error={error?.mrp}
            value={mrp}
            setValue={(val) => onChange(val, "mrp")}
            label={"MRP"}
          />
        </Grid>

        <Grid item md={5.8} xs={12} mt={3}>
          <FormTextInput
            page={"PriceCalculator"}
            required={true}
            error={error?.customerDiscount}
            value={customerDiscount}
            setValue={(val) => onChange(val, "customer_discount")}
            label={"Customer Discount(%)"}
          />
        </Grid>

        <Grid item md={5.8} xs={12} mt={3}/>

        <Grid item md={5.8} xs={12} mt={3}
              display={'flex'} flexDirection={'row'} alignItems={'center'}
              justifyContent={"flex-start"}>
          <Grid item md={5.8} xs={12}>
            <Typography className={styles.customerToPayTitleText}>Customer to Pay:</Typography>
          </Grid>
          <Grid item md={5.8} xs={12}
                display={'flex'} alignItems={'center'}
                justifyContent={"flex-end"}>
            <Typography className={styles.customerToPayValueText}>₹{customerToPay.toFixed(2)}</Typography>
          </Grid>
        </Grid>

      </Grid>

      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        className={styles.cardView}
        p={4}
        mt={3}
      >

        <Grid item md={12} xs={12}
              display={"flex"}
              alignItems={"flex-start"}>
          <Typography className={styles.titleText}>
            EMI Calculator
          </Typography>
        </Grid>

        <Grid item md={5.8} xs={12} mt={3} mb={2}>
          <FormTextInput
            page={"PriceCalculator"}
            required={true}
            error={emiError?.amount}
            value={amount}
            setValue={(val) => onChangeEmi(val, "amount")}
            label={"Amount"}
          />
        </Grid>

        <TableContainer className={styles.tableView}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>SCHEME</TableCell>
                <TableCell>10 BY 2</TableCell>
                <TableCell>12 BY 4</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>DBD%</TableCell>
                <TableCell>{dbdPerc2}%</TableCell>
                <TableCell>{dbdPerc4}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>INCL GST</TableCell>
                <TableCell>{inclGst2Perc ? inclGst2Perc?.toFixed(2) + '%' : '-'}</TableCell>
                <TableCell>{inclGst4Perc ? inclGst4Perc?.toFixed(2) + '%' : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.valueText}>DBD AMOUNT</TableCell>
                <TableCell className={styles.dbdAmountValueText}>{dbdAmount2 ? Math.round(dbdAmount2) : '-'}</TableCell>
                <TableCell className={styles.dbdAmountValueText}>{dbdAmount4 ? Math.round(dbdAmount4) : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PROCESSING FEE</TableCell>
                <TableCell>{processingFee2 ? processingFee2 : '-'}</TableCell>
                <TableCell>{processingFee4 ? processingFee4 : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.valueText}>DP FROM CUSTOMER</TableCell>
                <TableCell className={styles.valueText}>{dpFromCustomer2 ? Math.round(dpFromCustomer2) : '-'}</TableCell>
                <TableCell className={styles.valueText}>{dpFromCustomer4 ? Math.round(dpFromCustomer4) : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.valueText}>EMI AMOUNT</TableCell>
                <TableCell className={styles.valueText}>{emiAmount2 ? Math.round(emiAmount2) : '-'}</TableCell>
                <TableCell className={styles.valueText}>{emiAmount4 ? Math.round(emiAmount4) : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>EMI TENURE</TableCell>
                <TableCell>{emiTenure2 ? Math.round(emiTenure2) : '-'}</TableCell>
                <TableCell>{emiTenure4 ? Math.round(emiTenure4) : '-'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>


      </Grid>
      <AlertMsg/>
    </Container>
  );
};

export default PriceCalculator;
