import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  Modal,
  Fade,
  Radio,
  RadioProps,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  styled,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";

import { connect } from "react-redux";
import {
  addPatient,
  getAllDoctor,
  updateApiStatus,
  updatePatient,
} from "../redux/actions/userDataActions";
import {
  ADD_PATIENT_FAIL,
  ADD_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
  UPDATE_PATIENT_SUCCESS,
} from "../redux/actions/types";

import FormButton from "../components/FormButton";
import FormTextInput from "./FormTextInput";
import FormTextArea from "./FormTextArea";
import FormDateField from "./FormDateField";
import PatientExistDetailModal from "./PatientExistDetailModal";
import FormSelectize from "./FormSelectize";

import {capitalizeFirstLetter} from "../assets/functions/common";

import styles from "./AddEditPatientModal.module.css";

import { THEME_COLOR } from "../constants";

import CloseIcon from "@mui/icons-material/CloseOutlined";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: THEME_COLOR,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: THEME_COLOR,
  },
});

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const AddEditPatientModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [patientUid, setPatientUid] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("M");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [email, setEmail] = useState("");
  const [referredBy, setReferredBy] = useState(null);
  const [sameAsMobile, setSameAsMobile] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [patientExistDetailModalVisible, setPatientExistDetailModalVisible] =
    useState(false);

  const firstNameRef = useRef(null);

  useEffect(() => {
    if (
      (props.apiStatus == ADD_PATIENT_SUCCESS ||
        props.apiStatus == UPDATE_PATIENT_SUCCESS) &&
      props.modalVisible
    ) {
      if (patientExistDetailModalVisible) {
        setPatientExistDetailModalVisible(false);
      }
      props.updateApiStatus("");
      props.onSubmit();
    } else if (
      (props.apiStatus == ADD_PATIENT_FAIL ||
        props.apiStatus == UPDATE_PATIENT_FAIL) &&
      props.patientExistDetail?.length > 0 &&
      props.modalVisible &&
      !patientExistDetailModalVisible
    ) {
      setPatientExistDetailModalVisible(true);
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if (props.data != null) {
      setPatientUid(props.data?.uid);
      setFirstName(props.data?.first_name);
      setLastName(props.data?.last_name);
      if (props.data?.dob) {
        setDob(dayjs(props.data.dob));
        let age_val = "";
        let birthDate = moment(props.data.dob);
        age_val = moment().diff(birthDate, 'years');
        setAge(age_val);
      } else {
        setDob("");
        setAge("");
      }
      setGender(props.data?.gender);
      setAddress(props.data?.address);
      setMobile(props.data?.mobile);
      setWhatsapp(props.data?.whatsapp ? props.data.whatsapp : "");
      setEmail(props.data?.email);
      if (typeof props.doctorList != "undefined") {
        if (props.doctorList?.length) {
          if (props.data?.doctor_uid) {
            let doctor_data = props.doctorList?.filter(
              (x) => x.uid == props.data?.doctor_uid
            );
            if (doctor_data.length) {
              setReferredBy(doctor_data[0]);
            }
          }
        }
      }

      setLoading(false);
    } else {
      setPatientUid("");
      setFirstName("");
      setLastName("");
      setDob("");
      setAge("");
      setGender("M");
      setAddress("");
      setMobile("");
      setWhatsapp("");
      setEmail("");
      setReferredBy(null);
      setError(null);
    }
  }, [props.data]);

  useEffect(() => {
    if (typeof props.doctorList != "undefined") {
      setDoctorList(props.doctorList);
    }
  }, [props.doctorList]);

  useEffect(() => {
    if (typeof props.doctorList == "undefined") {
      setDoctorList(props.allDoctorList);
    }
  }, [props.allDoctorList]);

  useEffect(() => {
    if (props.modalVisible && typeof props.doctorList == "undefined") {
      getAllDoctorList();
    }
  }, [props.modalVisible]);

  const getAllDoctorList = () => {
    props.getAllDoctor();
  };

  useEffect(() => {
    if (props.modalVisible) {
      if (props.type == "add") {
        setLoading(false);
      }
      setVisible(true);
    } else {
      setPatientUid("");
      setFirstName("");
      setLastName("");
      setDob("");
      setAge("");
      setGender("M");
      setAddress("");
      setMobile("");
      setWhatsapp("");
      setEmail("");
      setReferredBy("");
      setError(null);
    }
  }, [props.modalVisible]);

  useEffect(() => {
    if(visible) {
      if(firstNameRef?.current) {
        firstNameRef?.current?.focus()
      }
    }
  },[visible]);

  useEffect(() => {
    if (sameAsMobile) {
      setWhatsapp(mobile);
    }
  }, [sameAsMobile]);

  useEffect(() => {
    if (mobile == whatsapp && mobile.length == 10) {
      setSameAsMobile(true);
    }
  }, [mobile, whatsapp]);

  useEffect(() => {
    if (sameAsMobile) {
      setWhatsapp(mobile);
    }
  }, [sameAsMobile, mobile]);

  const onSubmitClick = () => {

    let errorText = {};
    if (firstName == "") {
      errorText = { firstName: "Please enter first name" };
    }
    if (mobile == "") {
      errorText = { ...errorText, mobile: "Please enter mobile" };
    } else {
      if (!/^[+]*[ 0-9]+$/.test(mobile)) {
        errorText = { ...errorText, mobile: "Invalid mobile" };
      }
    }
    if (whatsapp != "") {
      if (!/^[+]*[ 0-9]+$/.test(whatsapp)) {
        errorText = { ...errorText, whatsapp: "Invalid whatsapp" };
      }
    }
    if (email) {
      if (
        !/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$/.test(
          email
        )
      ) {
        errorText = { ...errorText, email: "Please enter valid email address" };
      }
    }

    if(dob) {
      let dob_val = dayjs(dob).format("YYYY-MM-DD");
      let today = dayjs().format("YYYY-MM-DD");
      if (dob_val == 'Invalid Date') {
      } else if (dob_val > today) {
        errorText = { ...errorText, dob: "Dob is invalid" };
      }
    }

    if (age) {
      if ((!/^[0-9]{1,100}$/.test(age)) || (age > 150)) {
        errorText = { ...errorText, age: "Invalid Age" };
      }
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    onSubmit(1);
  };

  const onSubmit = (existCheck) => {
    let refer_by = "";
    if (referredBy != null) {
      refer_by = referredBy?.uid;
    }

    let dob_val = "";

    if (dob) {
      dob_val = dayjs(dob).format("YYYY-MM-DD");
    } else if (age) {
      let currentTime = new Date();
      let year = currentTime.getFullYear();
      let birth_year = parseInt(year) - parseInt(age);
      dob_val = birth_year + '-01-01';
    }

    if(dob_val == 'Invalid Date') {
      dob_val = "";
      setDob("");
      if (age) {
        let currentTime = new Date();
        let year = currentTime.getFullYear();
        let birth_year = parseInt(year) - parseInt(age);
        dob_val = birth_year + '-01-01';
      }
    }

    let data = {
      patientUid: patientUid,
      firstName: firstName,
      lastName: lastName,
      dob: dob_val,
      age: age,
      gender: gender,
      address: address,
      mobile: mobile,
      whatsapp: whatsapp,
      email: email,
      referredBy: refer_by,
      existCheck: existCheck,
    };

    if (props.type == "add") {
      if (props.data?.enquiry_uid) {
        data = {
          ...data,
          enquiryUid: props.data?.enquiry_uid,
        };
      }
      props.addPatient(JSON.stringify(data));
    } else {
      props.updatePatient(JSON.stringify(data));
    }
  };

  const onCancelClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onCancel();
    }, [100]);
  };

  const onChange = (val, type) => {
    let errorText = error;
    if (type == "firstName") {
      if (val != "") {
        errorText = { ...error, firstName: "" };
      } else {
        errorText = { ...error, firstName: "Please enter first name" };
      }
      val = capitalizeFirstLetter(val)
      setFirstName(val);
    } else if (type == "lastName") {
      val = capitalizeFirstLetter(val)
      setLastName(val);
    } else if (type == "dob") {
      setDob(val);
    } else if (type == "age") {
      setAge(val);
    } else if (type == "mobile") {
      if (val != "") {
        if (!/^[+]*[ 0-9]+$/.test(val)) {
          errorText = { ...errorText, mobile: "Invalid mobile" };
        } else {
          errorText = { ...error, mobile: "" };
        }
      } else {
        errorText = { ...error, mobile: "Please enter mobile" };
      }
      setMobile(val);
    } else if (type == "whatsapp") {
      if (val != "") {
        if (!/^[+]*[ 0-9]+$/.test(val)) {
          errorText = { ...errorText, whatsapp: "Invalid whatsapp" };
        } else {
          errorText = { ...error, whatsapp: "" };
        }
      }
      setWhatsapp(val);
    } else if (type == "email") {
      if (val != "") {
        if (
          !/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$/.test(
            val
          )
        ) {
          errorText = { ...error, email: "Please enter valid email address" };
        } else {
          errorText = { ...error, email: "" };
        }
      }
      setEmail(val);
    } else if (type == "address") {
      setAddress(val);
    } else if (type == "referredBy") {
      setReferredBy(val);
    }
    setError(errorText);
  };

  return (
    <Modal
      onClose={onCancelClick}
      disableBackdropClick
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{ md: 8, xs: 4 }}
      >
        <Grid
          onClick={() => onCancelClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle} />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            {props?.type == "add" ? "Add " : "Edit "} Patient
          </Typography>
        </Grid>

        {loading || props.allDoctorListLoading ? (
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item md={5.8} xs={12} mt={3}>
              <FormTextInput
                page={"AddEditPatientModal"}
                required={true}
                inputRef={firstNameRef}
                error={error?.firstName}
                value={firstName}
                setValue={(val) => onChange(val, "firstName")}
                label={"FIRST NAME"}
              />
            </Grid>
            <Grid item md={5.8} xs={12} mt={3}>
              <FormTextInput
                page={"AddEditPatientModal"}
                required={false}
                error={error?.lastName}
                value={lastName}
                setValue={(val) => onChange(val, "lastName")}
                label={"LAST NAME"}
              />
            </Grid>
            <Grid item md={5.8} xs={12} mt={3}>
              <FormDateField
                page={"AddEditPatientModal"}
                label={"DATE OF BIRTH"}
                value={dob}
                inputFormat="DD-MM-YYYY"
                required={false}
                onChange={(val) => onChange(val, "dob")}
                error={error?.dob}
              />
            </Grid>
            <Grid item md={5.8} xs={12} mt={3}>
              <FormTextInput
                page={"AddEditPatientModal"}
                required={false}
                error={error?.age}
                value={age}
                setValue={(val) => onChange(val, "age")}
                label={"AGE"}
              />
            </Grid>
            <Grid item md={12} xs={12} mt={3}>
              <FormControl>
                <Grid
                  item
                  md={12}
                  xs={12}
                  className={styles.genderView}
                  display={"flex"}
                  flexDirection={"row"}
                >
                  <Typography className={styles.genderText}>GENDER</Typography>
                  <Box>
                    <Typography
                      className={styles.requiredText}
                      mt={-0.3}
                      pl={0.3}
                    >
                      *
                    </Typography>
                  </Box>
                </Grid>
                <RadioGroup
                  onChange={(e) => setGender(e.target.value)}
                  value={gender}
                  defaultValue={gender}
                  aria-labelledby="demo-customized-radios"
                  name="customized-radios"
                  className={styles.genderRadioView}
                >
                  <FormControlLabel
                    value="M"
                    control={<BpRadio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="F"
                    control={<BpRadio />}
                    label="Female"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={5.8} xs={12} mt={3}>
              <FormTextInput
                page={"AddEditPatientModal"}
                required={true}
                error={error?.mobile}
                value={mobile}
                setValue={(val) => onChange(val, "mobile")}
                label={"MOBILE"}
              />
            </Grid>
            <Grid item md={5.8} xs={12} mt={3}>
              <FormTextInput
                page={"AddEditPatientModal"}
                required={false}
                error={error?.email}
                value={email}
                type={email}
                setValue={(val) => onChange(val, "email")}
                label={"EMAIL"}
              />
            </Grid>
            <Grid item md={5.8} xs={12} mt={3}>
              <FormTextInput
                page={"AddEditPatientModal"}
                disabled={sameAsMobile}
                required={false}
                error={error?.whatsapp}
                value={whatsapp}
                setValue={(val) => onChange(val, "whatsapp")}
                label={"WHATSAPP"}
              />
            </Grid>
            <Grid
              item
              md={5.8}
              xs={12}
              mt={3}
              height={{ xs: "fit-content", md: "60px" }}
            >
              <FormControlLabel
                sx={{
                  mt: { xs: 0, md: "23px" },
                  "& .MuiTypography-root": {
                    color: "#888 !important",
                    fontFamily: "IBMPlexSansRegular !important",
                    fontSize: "12px !important",
                  },
                }}
                onChange={(e) => {
                  setSameAsMobile(e.target.checked);
                  if (!e.target.checked) {
                    setWhatsapp("");
                  }
                }}
                checked={sameAsMobile}
                control={<Checkbox size="small" sx={{ color: "#999" }} />}
                label={"SAME AS MOBILE"}
                labelPlacement="end"
              />
            </Grid>
            <Grid item md={12} xs={12} mt={3}>
              <FormSelectize
                page={"AddEditPatientModal"}
                value={referredBy}
                label={"REFERRED BY"}
                multiple={false}
                addOption={false}
                onChange={(val) => {
                  setReferredBy(val);
                }}
                options={doctorList}
                error={error?.referredBy}
              />
            </Grid>
            <Grid item md={12} xs={12} mt={3}>
              <FormTextArea
                page={"AddEditPatientModal"}
                required={false}
                error={error?.address}
                value={address}
                setValue={(val) => onChange(val, "address")}
                label={"ADDRESS"}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection="row"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item md={2} xs={2}>
                <FormButton
                  loading={props.patientBtnLoading}
                  btnStyleView={style.btnStyleView}
                  btnType={"btn1"}
                  title={props.type == "add" ? "Submit" : "Update"}
                  onSubmit={onSubmitClick}
                />
              </Grid>
              <Grid ml={{ md: 2, xs: 4 }} item md={2} xs={2}>
                <FormButton
                  btnStyleView={style.btnStyleView}
                  btnType={"btn2"}
                  title={"Cancel"}
                  onSubmit={onCancelClick}
                />
              </Grid>
            </Grid>
          </>
        )}
        <PatientExistDetailModal
          modalVisible={patientExistDetailModalVisible}
          onCancel={() => setPatientExistDetailModalVisible(false)}
          onSubmit={() => onSubmit(0)}
        />
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPatient: (data) => dispatch(addPatient(data)),
    updatePatient: (data) => dispatch(updatePatient(data)),
    getAllDoctor: (data) => dispatch(getAllDoctor(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    patientBtnLoading: state.userData.patientBtnLoading,
    patientExistDetail: state.userData.patientExistDetail,
    allDoctorListLoading: state.userData.allDoctorListLoading,
    allDoctorList: state.userData.allDoctorList,
    apiStatus: state.userData.apiStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditPatientModal);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: "#69809a",
    height: 15,
    width: 15,
  },
};
