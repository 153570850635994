import * as actionTypes from "./types";

//login
export const loginAdmin = data => ({
  type: actionTypes.LOGIN_ADMIN,
  payload: data,
});

export const loginAdminSuccess = data => ({
  type: actionTypes.LOGIN_ADMIN_SUCCESS,
  payload: data,
});

export const loginAdminFail = data => ({
  type: actionTypes.LOGIN_ADMIN_FAIL,
  payload: data,
});

//register
export const registerAdmin = data => ({
  type: actionTypes.REGISTER_ADMIN,
  payload: data,
});

export const registerAdminSuccess = data => ({
  type: actionTypes.REGISTER_ADMIN_SUCCESS,
  payload: data,
});

export const registerAdminFail = data => ({
  type: actionTypes.REGISTER_ADMIN_FAIL,
  payload: data,
});

//patients
export const addPatient = data => ({
  type: actionTypes.ADD_PATIENT,
  payload: data,
});

export const addPatientSuccess = data => ({
  type: actionTypes.ADD_PATIENT_SUCCESS,
  payload: data,
});

export const addPatientFail = data => ({
  type: actionTypes.ADD_PATIENT_FAIL,
  payload: data,
});

export const updatePatient = data => ({
  type: actionTypes.UPDATE_PATIENT,
  payload: data,
});

export const updatePatientSuccess = data => ({
  type: actionTypes.UPDATE_PATIENT_SUCCESS,
  payload: data,
});

export const updatePatientFail = data => ({
  type: actionTypes.UPDATE_PATIENT_FAIL,
  payload: data,
});

export const listPatient = data => ({
  type: actionTypes.LIST_PATIENT,
  payload: data,
});

export const listPatientSuccess = data => ({
  type: actionTypes.LIST_PATIENT_SUCCESS,
  payload: data,
});

export const listPatientFail = data => ({
  type: actionTypes.LIST_PATIENT_FAIL,
  payload: data,
});

export const deletePatient = data => ({
  type: actionTypes.DELETE_PATIENT,
  payload: data,
});

export const deletePatientSuccess = data => ({
  type: actionTypes.DELETE_PATIENT_SUCCESS,
  payload: data,
});

export const deletePatientFail = data => ({
  type: actionTypes.DELETE_PATIENT_FAIL,
  payload: data,
});

export const getPatientDetail = data => ({
  type: actionTypes.GET_PATIENT_DETAIL,
  payload: data,
});

export const getPatientDetailSuccess = data => ({
  type: actionTypes.GET_PATIENT_DETAIL_SUCCESS,
  payload: data,
});

export const getPatientDetailFail = data => ({
  type: actionTypes.GET_PATIENT_DETAIL_FAIL,
  payload: data,
});

export const addConsultationNote = data => ({
  type: actionTypes.ADD_CONSULTATION_NOTE,
  payload: data,
});

export const addConsultationNoteSuccess = data => ({
  type: actionTypes.ADD_CONSULTATION_NOTE_SUCCESS,
  payload: data,
});

export const addConsultationNoteFail = data => ({
  type: actionTypes.ADD_CONSULTATION_NOTE_FAIL,
  payload: data,
});

export const listConsultationNote = data => ({
  type: actionTypes.LIST_CONSULTATION_NOTE,
  payload: data,
});

export const listConsultationNoteSuccess = data => ({
  type: actionTypes.LIST_CONSULTATION_NOTE_SUCCESS,
  payload: data,
});

export const listConsultationNoteFail = data => ({
  type: actionTypes.LIST_CONSULTATION_NOTE_FAIL,
  payload: data,
});

export const updateConsultationNote = data => ({
  type: actionTypes.UPDATE_CONSULTATION_NOTE,
  payload: data,
});

export const updateConsultationNoteSuccess = data => ({
  type: actionTypes.UPDATE_CONSULTATION_NOTE_SUCCESS,
  payload: data,
});

export const updateConsultationNoteFail = data => ({
  type: actionTypes.UPDATE_CONSULTATION_NOTE_FAIL,
  payload: data,
});

export const deleteConsultationNote = data => ({
  type: actionTypes.DELETE_CONSULTATION_NOTE,
  payload: data,
});

export const deleteConsultationNoteSuccess = data => ({
  type: actionTypes.DELETE_CONSULTATION_NOTE_SUCCESS,
  payload: data,
});

export const deleteConsultationNoteFail = data => ({
  type: actionTypes.DELETE_CONSULTATION_NOTE_FAIL,
  payload: data,
});

//appointment
export const getAllPatient = data => ({
  type: actionTypes.GET_ALL_PATIENT,
  payload: data,
});

export const getAllPatientSuccess = data => ({
  type: actionTypes.GET_ALL_PATIENT_SUCCESS,
  payload: data,
});

export const getAllPatientFail = data => ({
  type: actionTypes.GET_ALL_PATIENT_FAIL,
  payload: data,
});

export const addAppointment = data => ({
  type: actionTypes.ADD_APPOINTMENT,
  payload: data,
});

export const addAppointmentSuccess = data => ({
  type: actionTypes.ADD_APPOINTMENT_SUCCESS,
  payload: data,
});

export const addAppointmentFail = data => ({
  type: actionTypes.ADD_APPOINTMENT_FAIL,
  payload: data,
});

export const updateAppointment = data => ({
  type: actionTypes.UPDATE_APPOINTMENT,
  payload: data,
});

export const updateAppointmentSuccess = data => ({
  type: actionTypes.UPDATE_APPOINTMENT_SUCCESS,
  payload: data,
});

export const updateAppointmentFail = data => ({
  type: actionTypes.UPDATE_APPOINTMENT_FAIL,
  payload: data,
});

export const getAppointment = data => ({
  type: actionTypes.GET_APPOINTMENT,
  payload: data,
});

export const getAppointmentSuccess = data => ({
  type: actionTypes.GET_APPOINTMENT_SUCCESS,
  payload: data,
});

export const getAppointmentFail = data => ({
  type: actionTypes.GET_APPOINTMENT_FAIL,
  payload: data,
});

export const listAppointment = data => ({
  type: actionTypes.LIST_APPOINTMENT,
  payload: data,
});

export const listAppointmentSuccess = data => ({
  type: actionTypes.LIST_APPOINTMENT_SUCCESS,
  payload: data,
});

export const listAppointmentFail = data => ({
  type: actionTypes.LIST_APPOINTMENT_FAIL,
  payload: data,
});

export const updateAppointmentStatus = data => ({
  type: actionTypes.UPDATE_APPOINTMENT_STATUS,
  payload: data,
});

export const updateAppointmentStatusSuccess = data => ({
  type: actionTypes.UPDATE_APPOINTMENT_STATUS_SUCCESS,
  payload: data,
});

export const updateAppointmentStatusFail = data => ({
  type: actionTypes.UPDATE_APPOINTMENT_STATUS_FAIL,
  payload: data,
});

export const getPatientInvoiceList = data => ({
  type: actionTypes.GET_PATIENT_INVOICE_LIST,
  payload: data,
});

export const getPatientInvoiceListSuccess = data => ({
  type: actionTypes.GET_PATIENT_INVOICE_LIST_SUCCESS,
  payload: data,
});

export const getPatientInvoiceListFail = data => ({
  type: actionTypes.GET_PATIENT_INVOICE_LIST_FAIL,
  payload: data,
});

export const updateInvoiceAppointment = data => ({
  type: actionTypes.UPDATE_INVOICE_APPOINTMENT,
  payload: data,
});

export const updateInvoiceAppointmentSuccess = data => ({
  type: actionTypes.UPDATE_INVOICE_APPOINTMENT_SUCCESS,
  payload: data,
});

export const updateInvoiceAppointmentFail = data => ({
  type: actionTypes.UPDATE_INVOICE_APPOINTMENT_FAIL,
  payload: data,
});

//invoice
export const getAppointmentInvoice = data => ({
  type: actionTypes.GET_APPOINTMENT_INVOICE,
  payload: data,
});

export const getAppointmentInvoiceSuccess = data => ({
  type: actionTypes.GET_APPOINTMENT_INVOICE_SUCCESS,
  payload: data,
});

export const getAppointmentInvoiceFail = data => ({
  type: actionTypes.GET_APPOINTMENT_INVOICE_FAIL,
  payload: data,
});

export const addAppointmentInvoice = data => ({
  type: actionTypes.ADD_APPOINTMENT_INVOICE,
  payload: data,
});

export const addAppointmentInvoiceSuccess = data => ({
  type: actionTypes.ADD_APPOINTMENT_INVOICE_SUCCESS,
  payload: data,
});

export const addAppointmentInvoiceFail = data => ({
  type: actionTypes.ADD_APPOINTMENT_INVOICE_FAIL,
  payload: data,
});

export const updateAppointmentInvoice = data => ({
  type: actionTypes.UPDATE_APPOINTMENT_INVOICE,
  payload: data,
});

export const updateAppointmentInvoiceSuccess = data => ({
  type: actionTypes.UPDATE_APPOINTMENT_INVOICE_SUCCESS,
  payload: data,
});

export const updateAppointmentInvoiceFail = data => ({
  type: actionTypes.UPDATE_APPOINTMENT_INVOICE_FAIL,
  payload: data,
});

export const deleteAppointmentInvoice = data => ({
  type: actionTypes.DELETE_APPOINTMENT_INVOICE,
  payload: data,
});

export const deleteAppointmentInvoiceSuccess = data => ({
  type: actionTypes.DELETE_APPOINTMENT_INVOICE_SUCCESS,
  payload: data,
});

export const deleteAppointmentInvoiceFail = data => ({
  type: actionTypes.DELETE_APPOINTMENT_INVOICE_FAIL,
  payload: data,
});

export const addAppointmentInvoicePaymentMethod = data => ({
  type: actionTypes.ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD,
  payload: data,
});

export const addAppointmentInvoicePaymentMethodSuccess = data => ({
  type: actionTypes.ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD_SUCCESS,
  payload: data,
});

export const addAppointmentInvoicePaymentMethodFail = data => ({
  type: actionTypes.ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD_FAIL,
  payload: data,
});

export const getInvoice = data => ({
  type: actionTypes.GET_INVOICE,
  payload: data,
});

export const getInvoiceSuccess = data => ({
  type: actionTypes.GET_INVOICE_SUCCESS,
  payload: data,
});

export const getInvoiceFail = data => ({
  type: actionTypes.GET_INVOICE_FAIL,
  payload: data,
});

//dashboard
export const getDashboard = data => ({
  type: actionTypes.GET_DASHBOARD,
  payload: data,
});

export const getDashboardSuccess = data => ({
  type: actionTypes.GET_DASHBOARD_SUCCESS,
  payload: data,
});

export const getDashboardFail = data => ({
  type: actionTypes.GET_DASHBOARD_FAIL,
  payload: data,
});

//doctors
export const addDoctor = data => ({
  type: actionTypes.ADD_DOCTOR,
  payload: data,
});

export const addDoctorSuccess = data => ({
  type: actionTypes.ADD_DOCTOR_SUCCESS,
  payload: data,
});

export const addDoctorFail = data => ({
  type: actionTypes.ADD_DOCTOR_FAIL,
  payload: data,
});

export const getAllDoctor = data => ({
  type: actionTypes.GET_ALL_DOCTOR,
  payload: data,
});

export const getAllDoctorSuccess = data => ({
  type: actionTypes.GET_ALL_DOCTOR_SUCCESS,
  payload: data,
});

export const getAllDoctorFail = data => ({
  type: actionTypes.GET_ALL_DOCTOR_FAIL,
  payload: data,
});

export const updateDoctor = data => ({
  type: actionTypes.UPDATE_DOCTOR,
  payload: data,
});

export const updateDoctorSuccess = data => ({
  type: actionTypes.UPDATE_DOCTOR_SUCCESS,
  payload: data,
});

export const updateDoctorFail = data => ({
  type: actionTypes.UPDATE_DOCTOR_FAIL,
  payload: data,
});

export const listDoctor = data => ({
  type: actionTypes.LIST_DOCTOR,
  payload: data,
});

export const listDoctorSuccess = data => ({
  type: actionTypes.LIST_DOCTOR_SUCCESS,
  payload: data,
});

export const listDoctorFail = data => ({
  type: actionTypes.LIST_DOCTOR_FAIL,
  payload: data,
});

export const deleteDoctor = data => ({
  type: actionTypes.DELETE_DOCTOR,
  payload: data,
});

export const deleteDoctorSuccess = data => ({
  type: actionTypes.DELETE_DOCTOR_SUCCESS,
  payload: data,
});

export const deleteDoctorFail = data => ({
  type: actionTypes.DELETE_DOCTOR_FAIL,
  payload: data,
});

export const getDoctorDetail = data => ({
  type: actionTypes.GET_DOCTOR_DETAIL,
  payload: data,
});

export const getDoctorDetailSuccess = data => ({
  type: actionTypes.GET_DOCTOR_DETAIL_SUCCESS,
  payload: data,
});

export const getDoctorDetailFail = data => ({
  type: actionTypes.GET_DOCTOR_DETAIL_FAIL,
  payload: data,
});

//users
export const addUser = data => ({
  type: actionTypes.ADD_USER,
  payload: data,
});

export const addUserSuccess = data => ({
  type: actionTypes.ADD_USER_SUCCESS,
  payload: data,
});

export const addUserFail = data => ({
  type: actionTypes.ADD_USER_FAIL,
  payload: data,
});

export const updateUser = data => ({
  type: actionTypes.UPDATE_USER,
  payload: data,
});

export const updateUserSuccess = data => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  payload: data,
});

export const updateUserFail = data => ({
  type: actionTypes.UPDATE_USER_FAIL,
  payload: data,
});

export const listUser = data => ({
  type: actionTypes.LIST_USER,
  payload: data,
});

export const listUserSuccess = data => ({
  type: actionTypes.LIST_USER_SUCCESS,
  payload: data,
});

export const listUserFail = data => ({
  type: actionTypes.LIST_USER_FAIL,
  payload: data,
});

export const deleteUser = data => ({
  type: actionTypes.DELETE_USER,
  payload: data,
});

export const deleteUserSuccess = data => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  payload: data,
});

export const deleteUserFail = data => ({
  type: actionTypes.DELETE_USER_FAIL,
  payload: data,
});

export const updateUserPassword = data => ({
  type: actionTypes.UPDATE_USER_PASSWORD,
  payload: data,
});

export const updateUserPasswordSuccess = data => ({
  type: actionTypes.UPDATE_USER_PASSWORD_SUCCESS,
  payload: data,
});

export const updateUserPasswordFail = data => ({
  type: actionTypes.UPDATE_USER_PASSWORD_FAIL,
  payload: data,
});

//invoices
export const listInvoice = data => ({
  type: actionTypes.LIST_INVOICE,
  payload: data,
});

export const listInvoiceSuccess = data => ({
  type: actionTypes.LIST_INVOICE_SUCCESS,
  payload: data,
});

export const listInvoiceFail = data => ({
  type: actionTypes.LIST_INVOICE_FAIL,
  payload: data,
});

export const addInvoice = data => ({
  type: actionTypes.ADD_INVOICE,
  payload: data,
});

export const addInvoiceSuccess = data => ({
  type: actionTypes.ADD_INVOICE_SUCCESS,
  payload: data,
});

export const addInvoiceFail = data => ({
  type: actionTypes.ADD_INVOICE_FAIL,
  payload: data,
});

export const getPatientInvoice = data => ({
  type: actionTypes.GET_PATIENT_INVOICE,
  payload: data,
});

export const getPatientInvoiceSuccess = data => ({
  type: actionTypes.GET_PATIENT_INVOICE_SUCCESS,
  payload: data,
});

export const getPatientInvoiceFail = data => ({
  type: actionTypes.GET_PATIENT_INVOICE_FAIL,
  payload: data,
});

//products
export const addProduct = data => ({
  type: actionTypes.ADD_PRODUCT,
  payload: data,
});

export const addProductSuccess = data => ({
  type: actionTypes.ADD_PRODUCT_SUCCESS,
  payload: data,
});

export const addProductFail = data => ({
  type: actionTypes.ADD_PRODUCT_FAIL,
  payload: data,
});

export const updateProduct = data => ({
  type: actionTypes.UPDATE_PRODUCT,
  payload: data,
});

export const updateProductSuccess = data => ({
  type: actionTypes.UPDATE_PRODUCT_SUCCESS,
  payload: data,
});

export const updateProductFail = data => ({
  type: actionTypes.UPDATE_PRODUCT_FAIL,
  payload: data,
});

export const listProduct = data => ({
  type: actionTypes.LIST_PRODUCT,
  payload: data,
});

export const listProductSuccess = data => ({
  type: actionTypes.LIST_PRODUCT_SUCCESS,
  payload: data,
});

export const listProductFail = data => ({
  type: actionTypes.LIST_PRODUCT_FAIL,
  payload: data,
});

export const deleteProduct = data => ({
  type: actionTypes.DELETE_PRODUCT,
  payload: data,
});

export const deleteProductSuccess = data => ({
  type: actionTypes.DELETE_PRODUCT_SUCCESS,
  payload: data,
});

export const deleteProductFail = data => ({
  type: actionTypes.DELETE_PRODUCT_FAIL,
  payload: data,
});

export const getAllProductCategory = data => ({
  type: actionTypes.GET_ALL_PRODUCT_CATEGORY,
  payload: data,
});

export const getAllProductCategorySuccess = data => ({
  type: actionTypes.GET_ALL_PRODUCT_CATEGORY_SUCCESS,
  payload: data,
});

export const getAllProductCategoryFail = data => ({
  type: actionTypes.GET_ALL_PRODUCT_CATEGORY_FAIL,
  payload: data,
});

export const updateProductStatus = data => ({
  type: actionTypes.UPDATE_PRODUCT_STATUS,
  payload: data,
});

export const updateProductStatusSuccess = data => ({
  type: actionTypes.UPDATE_PRODUCT_STATUS_SUCCESS,
  payload: data,
});

export const updateProductStatusFail = data => ({
  type: actionTypes.UPDATE_PRODUCT_STATUS_FAIL,
  payload: data,
});

//product category
export const addProductCategory = data => ({
  type: actionTypes.ADD_PRODUCT_CATEGORY,
  payload: data,
});

export const addProductCategorySuccess = data => ({
  type: actionTypes.ADD_PRODUCT_CATEGORY_SUCCESS,
  payload: data,
});

export const addProductCategoryFail = data => ({
  type: actionTypes.ADD_PRODUCT_CATEGORY_FAIL,
  payload: data,
});

export const updateProductCategory = data => ({
  type: actionTypes.UPDATE_PRODUCT_CATEGORY,
  payload: data,
});

export const updateProductCategorySuccess = data => ({
  type: actionTypes.UPDATE_PRODUCT_CATEGORY_SUCCESS,
  payload: data,
});

export const updateProductCategoryFail = data => ({
  type: actionTypes.UPDATE_PRODUCT_CATEGORY_FAIL,
  payload: data,
});

export const listProductCategory = data => ({
  type: actionTypes.LIST_PRODUCT_CATEGORY,
  payload: data,
});

export const listProductCategorySuccess = data => ({
  type: actionTypes.LIST_PRODUCT_CATEGORY_SUCCESS,
  payload: data,
});

export const listProductCategoryFail = data => ({
  type: actionTypes.LIST_PRODUCT_CATEGORY_FAIL,
  payload: data,
});

export const deleteProductCategory = data => ({
  type: actionTypes.DELETE_PRODUCT_CATEGORY,
  payload: data,
});

export const deleteProductCategorySuccess = data => ({
  type: actionTypes.DELETE_PRODUCT_CATEGORY_SUCCESS,
  payload: data,
});

export const deleteProductCategoryFail = data => ({
  type: actionTypes.DELETE_PRODUCT_CATEGORY_FAIL,
  payload: data,
});

//product enquiry
export const listProductEnquiry = data => ({
  type: actionTypes.LIST_PRODUCT_ENQUIRY,
  payload: data,
});

export const listProductEnquirySuccess = data => ({
  type: actionTypes.LIST_PRODUCT_ENQUIRY_SUCCESS,
  payload: data,
});

export const listProductEnquiryFail = data => ({
  type: actionTypes.LIST_PRODUCT_ENQUIRY_FAIL,
  payload: data,
});

//enquiry
export const getEnquiryByMobile = data => ({
  type: actionTypes.GET_ENQUIRY_BY_MOBILE,
  payload: data,
});

export const getEnquiryByMobileSuccess = data => ({
  type: actionTypes.GET_ENQUIRY_BY_MOBILE_SUCCESS,
  payload: data,
});

export const getEnquiryByMobileFail = data => ({
  type: actionTypes.GET_ENQUIRY_BY_MOBILE_FAIL,
  payload: data,
});

export const addEnquiry = data => ({
  type: actionTypes.ADD_ENQUIRY,
  payload: data,
});

export const addEnquirySuccess = data => ({
  type: actionTypes.ADD_ENQUIRY_SUCCESS,
  payload: data,
});

export const addEnquiryFail = data => ({
  type: actionTypes.ADD_ENQUIRY_FAIL,
  payload: data,
});

export const updateEnquiry = data => ({
  type: actionTypes.UPDATE_ENQUIRY,
  payload: data,
});

export const updateEnquirySuccess = data => ({
  type: actionTypes.UPDATE_ENQUIRY_SUCCESS,
  payload: data,
});

export const updateEnquiryFail = data => ({
  type: actionTypes.UPDATE_ENQUIRY_FAIL,
  payload: data,
});

export const listEnquiry = data => ({
  type: actionTypes.LIST_ENQUIRY,
  payload: data,
});

export const listEnquirySuccess = data => ({
  type: actionTypes.LIST_ENQUIRY_SUCCESS,
  payload: data,
});

export const listEnquiryFail = data => ({
  type: actionTypes.LIST_ENQUIRY_FAIL,
  payload: data,
});

export const moveToFollowup = data => ({
  type: actionTypes.MOVE_TO_FOLLOWUP,
  payload: data,
});

export const moveToFollowupSuccess = data => ({
  type: actionTypes.MOVE_TO_FOLLOWUP_SUCCESS,
  payload: data,
});

export const moveToFollowupFail = data => ({
  type: actionTypes.MOVE_TO_FOLLOWUP_FAIL,
  payload: data,
});

//enquiry detail
export const getEnquiryDetail = data => ({
  type: actionTypes.GET_ENQUIRY_DETAIL,
  payload: data,
});

export const getEnquiryDetailSuccess = data => ({
  type: actionTypes.GET_ENQUIRY_DETAIL_SUCCESS,
  payload: data,
});

export const getEnquiryDetailFail = data => ({
  type: actionTypes.GET_ENQUIRY_DETAIL_FAIL,
  payload: data,
});

export const addEnquiryNote = data => ({
  type: actionTypes.ADD_ENQUIRY_NOTE,
  payload: data,
});

export const addEnquiryNoteSuccess = data => ({
  type: actionTypes.ADD_ENQUIRY_NOTE_SUCCESS,
  payload: data,
});

export const addEnquiryNoteFail = data => ({
  type: actionTypes.ADD_ENQUIRY_NOTE_FAIL,
  payload: data,
});

export const listEnquiryNote = data => ({
  type: actionTypes.LIST_ENQUIRY_NOTE,
  payload: data,
});

export const listEnquiryNoteSuccess = data => ({
  type: actionTypes.LIST_ENQUIRY_NOTE_SUCCESS,
  payload: data,
});

export const listEnquiryNoteFail = data => ({
  type: actionTypes.LIST_ENQUIRY_NOTE_FAIL,
  payload: data,
});

export const updateEnquiryNote = data => ({
  type: actionTypes.UPDATE_ENQUIRY_NOTE,
  payload: data,
});

export const updateEnquiryNoteSuccess = data => ({
  type: actionTypes.UPDATE_ENQUIRY_NOTE_SUCCESS,
  payload: data,
});

export const updateEnquiryNoteFail = data => ({
  type: actionTypes.UPDATE_ENQUIRY_NOTE_FAIL,
  payload: data,
});

export const deleteEnquiryNote = data => ({
  type: actionTypes.DELETE_ENQUIRY_NOTE,
  payload: data,
});

export const deleteEnquiryNoteSuccess = data => ({
  type: actionTypes.DELETE_ENQUIRY_NOTE_SUCCESS,
  payload: data,
});

export const deleteEnquiryNoteFail = data => ({
  type: actionTypes.DELETE_ENQUIRY_NOTE_FAIL,
  payload: data,
});

//label
export const addLabel = data => ({
  type: actionTypes.ADD_LABEL,
  payload: data,
});

export const addLabelSuccess = data => ({
  type: actionTypes.ADD_LABEL_SUCCESS,
  payload: data,
});

export const addLabelFail = data => ({
  type: actionTypes.ADD_LABEL_FAIL,
  payload: data,
});

export const updateLabel = data => ({
  type: actionTypes.UPDATE_LABEL,
  payload: data,
});

export const updateLabelSuccess = data => ({
  type: actionTypes.UPDATE_LABEL_SUCCESS,
  payload: data,
});

export const updateLabelFail = data => ({
  type: actionTypes.UPDATE_LABEL_FAIL,
  payload: data,
});

export const listLabel = data => ({
  type: actionTypes.LIST_LABEL,
  payload: data,
});

export const listLabelSuccess = data => ({
  type: actionTypes.LIST_LABEL_SUCCESS,
  payload: data,
});

export const listLabelFail = data => ({
  type: actionTypes.LIST_LABEL_FAIL,
  payload: data,
});

export const deleteLabel = data => ({
  type: actionTypes.DELETE_LABEL,
  payload: data,
});

export const deleteLabelSuccess = data => ({
  type: actionTypes.DELETE_LABEL_SUCCESS,
  payload: data,
});

export const deleteLabelFail = data => ({
  type: actionTypes.DELETE_LABEL_FAIL,
  payload: data,
});

//income/expense
export const addIncomeExpense = data => ({
  type: actionTypes.ADD_INCOME_EXPENSE,
  payload: data,
});

export const addIncomeExpenseSuccess = data => ({
  type: actionTypes.ADD_INCOME_EXPENSE_SUCCESS,
  payload: data,
});

export const addIncomeExpenseFail = data => ({
  type: actionTypes.ADD_INCOME_EXPENSE_FAIL,
  payload: data,
});

export const updateIncomeExpense = data => ({
  type: actionTypes.UPDATE_INCOME_EXPENSE,
  payload: data,
});

export const updateIncomeExpenseSuccess = data => ({
  type: actionTypes.UPDATE_INCOME_EXPENSE_SUCCESS,
  payload: data,
});

export const updateIncomeExpenseFail = data => ({
  type: actionTypes.UPDATE_INCOME_EXPENSE_FAIL,
  payload: data,
});

export const deleteIncomeExpense = data => ({
  type: actionTypes.DELETE_INCOME_EXPENSE,
  payload: data,
});

export const deleteIncomeExpenseSuccess = data => ({
  type: actionTypes.DELETE_INCOME_EXPENSE_SUCCESS,
  payload: data,
});

export const deleteIncomeExpenseFail = data => ({
  type: actionTypes.DELETE_INCOME_EXPENSE_FAIL,
  payload: data,
});

export const listIncomeExpense = data => ({
  type: actionTypes.LIST_INCOME_EXPENSE,
  payload: data,
});

export const listIncomeExpenseSuccess = data => ({
  type: actionTypes.LIST_INCOME_EXPENSE_SUCCESS,
  payload: data,
});

export const listIncomeExpenseFail = data => ({
  type: actionTypes.LIST_INCOME_EXPENSE_FAIL,
  payload: data,
});

//global search
export const globalSearch = data => ({
  type: actionTypes.GLOBAL_SEARCH,
  payload: data,
});

export const globalSearchSuccess = data => ({
  type: actionTypes.GLOBAL_SEARCH_SUCCESS,
  payload: data,
});

export const globalSearchFail = data => ({
  type: actionTypes.GLOBAL_SEARCH_FAIL,
  payload: data,
});

export const updateAlertMsg = data => ({
  type: actionTypes.UPDATE_ALERT_MSG,
  payload: data,
});

export const updateDrawerOpen = (data) => ({
  type: actionTypes.UPDATE_DRAWER_OPEN,
  payload: data,
});

export const updateApiData = data => ({
  type: actionTypes.UPDATE_API_DATA,
  payload: data,
});

export const updateApiStatus = data => ({
  type: actionTypes.UPDATE_API_STATUS,
  payload: data,
});

export const logOut = () => ({
  type: actionTypes.LOG_OUT,
});
