import React from 'react';
import Button from '@mui/material/Button';

import styles from "./FormButton.module.css"

const FormButton = (props) => {

  let btnStyle = styles.buttonView;
  let btnHoverStyle = props?.buttonHoverView ? props.buttonHoverView : styles.buttonHoverView;

  if (props?.btnType == 'btn2') {
    btnStyle = styles.button2View;
    btnHoverStyle = styles.button2HoverView;
  } else if (props?.btnType == 'btn3') {
    btnStyle = styles.button3View;
    btnHoverStyle = styles.button3HoverView;
  } else if (props?.btnType == 'btn4') {
    btnStyle = styles.button4View;
    btnHoverStyle = styles.button4HoverView;
  } else if (props?.btnType == 'btn5') {
    btnStyle = styles.button5View;
    btnHoverStyle = styles.button5HoverView;
  }

  return (
    <Button
      startIcon={props?.startIcon}
      disableElevation={true}
      disabled={props?.loading}
      className={[btnStyle, styles.btnView, btnHoverStyle]}
      sx={props?.btnStyleView}
      onClick={props.onSubmit}
      variant="contained"
      size="medium">
      {props?.loading ? 'Please Wait' : props.title}
    </Button>
  )
};

export default FormButton;
