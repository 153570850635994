import React from "react";
import { Box, Typography, TextField, Grid } from "@mui/material";

import styles from "./FormTextArea.module.css";

const FormTextArea = (props) => {
  return (
    <Grid item md={12} xs={12}>
      {props.label ? (
        <Grid item md={12} xs={12} container>
          <Typography className={styles.labelText}>{props.label}</Typography>
          {props?.required ?
            <Box>
              <Typography className={styles.requiredText} mt={-0.3} pl={0.3}>*</Typography>
            </Box>
            : null}
        </Grid>
      ) : null}
      <Grid
        sx={[
          style.textInputView,
          props?.disabled
            ? style.disabledTextInputBgView
            : style.textInputBgView,
        ]}
        className={[props.error ? styles.errorBorderStyle : styles.borderStyle]}
        item
        md={12}
        xs={12}
        container
      >
        <TextField
          placeholder={props?.placeholder}
          required={props?.required ? true : false}
          fullWidth
          multiline
          disabled={props?.disabled ? props?.disabled : false}
          minRows={props?.minRows ? props.minRows : 5}
          variant={props?.variant ? props?.variant : "standard"}
          value={props.value}
          type={props?.type ? props.type : "text"}
          onChange={(e) => props.setValue(e.target.value)}
          sx={{
            "& .MuiInput-root": {
              "&:before, :after, :hover:not(.Mui-disabled):before": {
                borderBottom: 0,
              },
              transition: "0.2s",
              paddingRight: 1,
            },
            "& .Mui-focused": {
              border: props?.error
                ? "unset !important"
                : "1px solid #5a8dee !important",
              borderRadius: 1,
              transition: "0.2s",
              paddingLeft: 1,
            },
            input: {
              "&::placeholder": {
                color: "#CCC",
                fontSize: 14,
                opacity: 1,
              },
            },
          }}
          inputProps={{
            autoComplete: "none",
            sx: style.textInputValue,
          }}
        />
      </Grid>
      {props.error ? (
        <Box mt={0.7} className={styles.errorView}>
          <Typography className={styles.errorText}>{props.error}</Typography>
        </Box>
      ) : null}
    </Grid>
  );
};

export default FormTextArea;

const style = {
  textInputView: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFF",
    border: "1px solid #CCC",
    borderRadius: 1,
  },
  textInputBgView: {
    backgroundColor: "#FFF",
  },
  disabledTextInputBgView: {
    backgroundColor: "#e9ecee",
  },
  textInputValue: {
    fontFamily: "IBMPlexSansRegular",
    color: "#000",
    fontSize: 14,
    px: 1.5,
  },
};
