import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Popover,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import Container from "../../components/Container";
import Page from "../../components/Page";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import FormSelectBox from "../../components/FormSelectBox";
import AddEditPatientModal from "../../components/AddEditPatientModal";
import UseDebounce from "../../components/UseDebounce";
import ConfirmAlertBox from "../../components/ConfirmAlertBox";
import AlertMsg from "../../components/AlertMsg";
import { MOBILE_VIEW } from "../../components/Sidebar";

import { connect } from "react-redux";
import { DELETE_PATIENT_SUCCESS } from "../../redux/actions/types";
import {
  listPatient,
  deletePatient,
  updateApiStatus,
  getAllDoctor,
} from "../../redux/actions/userDataActions";

import styles from "./Patients.module.css";

import "../../assets/css/custom.css";

import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PlusIcon from '@mui/icons-material/AddOutlined';
import ExclaimIcon from '@mui/icons-material/ErrorOutlineOutlined';

const status_list = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];

const actionList = ["View"];

const Patients = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState("");
  const [statusList, setStatusList] = useState(status_list);
  const [statusFilter, setStatusFilter] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [selectedPatientData, setSelectedPatientData] = useState(null);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);

  const debouncedSearch = UseDebounce(search, 500);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == DELETE_PATIENT_SUCCESS && confirmBoxVisible) {
      setSelectedPatientData(null);
      setConfirmBoxVisible(false);
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  useEffect(() => {
    setTotalListCount(props.patientTotalList);
  }, [props.patientTotalList]);

  useEffect(() => {
    setListCount(props.patientList?.length);
  }, [props.patientList?.length]);

  useEffect(() => {
    getPatientList(1);
  }, [debouncedSearch]);

  useEffect(() => {
    getAllDoctorList();
  }, []);

  const getAllDoctorList = () => {
    props.getAllDoctor();
  };

  const getPatientList = (page) => {
    let data = {
      page: page,
      searchPatient: search,
    };
    props.listPatient(JSON.stringify(data));
    setPageNum(page);
  };

  const onAddEditPatientBtnClick = (e, val, item) => {
    e.stopPropagation();
    setSelectedPatientData(item);
    setType(val);
    setModalVisible(true);
  };

  const onAddEditPatient = () => {
    setSelectedPatientData(null);
    setModalVisible(false);
  };

  const onCancelPatient = () => {
    setSelectedPatientData(null);
    setModalVisible(false);
  };

  const onSelectPage = (page) => {
    getPatientList(page);
  };

  const onSearchPatients = (val) => {
    setSearch(val);
  };

  const onDeletePatientBtnClick = (e, item) => {
    e.stopPropagation();
    setSelectedPatientData(item);
    setConfirmBoxVisible(true);
  };

  const onConfirmDeletePatient = () => {
    let data = {
      patientUid: selectedPatientData?.uid,
    };
    props.deletePatient(JSON.stringify(data));
  };

  const onCancelDeletePatient = () => {
    setSelectedPatientData(null);
    setConfirmBoxVisible(false);
  };

  const onViewClick = (data) => {
    navigate("/patient_view?id=" + data?.uid);
  };

  const onWhatsappClick = (e, mobile) => {
    e.stopPropagation();
    let link = 'https://wa.me/'+[mobile]
    window.open(link)
  };

  // const onActionOptionClick = (type, data) => {
  //   if (type == 'View') {
  //     navigate("/patient_view?id=" + data.uid)
  //   }
  // };

  return (
    <Container page={"patients"}>
      <Grid container item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={styles.cardView}
        >
          <TableContainer className={styles.tableView}>
            <Table aria-label="simple table">
              {/*<TableHead>*/}
              {/*  <TableRow>*/}
              {/*    <TableCell colSpan={11}>*/}
              {/*      <Grid item md={12} xs={12} pt={2} pb={4}>*/}
              {/*        <Typography className={styles.searchFilterText}>Search Filter</Typography>*/}
              {/*      </Grid>*/}
              {/*      <Grid item md={3} xs={3}>*/}
              {/*        <FormSelectBox*/}
              {/*          page={'Patients'}*/}
              {/*          value={statusFilter}*/}
              {/*          setValue={setStatusFilter}*/}
              {/*          height={40}*/}
              {/*          rightIcon={<DownArrowIcon height={12} width={12}/>}*/}
              {/*          data={statusList}*/}
              {/*          placeholder={'Select Status'}*/}
              {/*        />*/}
              {/*      </Grid>*/}
              {/*    </TableCell>*/}
              {/*  </TableRow>*/}
              {/*</TableHead>*/}

              <TableHead>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Grid
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"flex-end"}
                      mt={2}
                    >
                      <Grid item md={2} xs={2}>
                        <FormTextInput
                          page={"Patients"}
                          value={search}
                          setValue={(val) => onSearchPatients(val)}
                          height={40}
                          width={150}
                          placeholder={"Search.."}
                        />
                      </Grid>
                      <Grid item md={2} xs={2} ml={2}>
                        <FormButton
                          title={
                            MOBILE_VIEW ? (
                              <PlusIcon/>
                            ) : (
                              "Add New Patient"
                            )
                          }
                          startIcon={
                            MOBILE_VIEW ? null : (
                              <PlusIcon/>
                            )
                          }
                          btnStyleView={style.btnStyleView}
                          onSubmit={(e) =>
                            onAddEditPatientBtnClick(e, "add", null)
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Whatsapp</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.patientLoading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : props.patientList?.length > 0 ? (
                  props.patientList?.map((item, index) => (
                    <TableRow
                      key={index}
                      onClick={(e) => onViewClick(item)}
                      className={styles.tableRowView}
                    >
                      <TableCell>{item?.first_name}</TableCell>
                      <TableCell>{item?.last_name ? item.last_name : '-'}</TableCell>
                      <TableCell>{item?.dob ? moment().diff(moment(item.dob), 'years') : ''}{item?.gender}</TableCell>
                      <TableCell>{item?.address ? item.address : "-"}</TableCell>
                      <TableCell>{item?.mobile}</TableCell>
                      <TableCell>
                        {item?.whatsapp ?
                          <Box
                            className={styles.whatsappNumberView}
                            onClick={(e) => onWhatsappClick(e, item.whatsapp)}>
                            {item.whatsapp}
                          </Box>
                          : '-'}
                      </TableCell>
                      <TableCell>
                        <Box className={styles.iconMainView}>
                          <Box
                            onClick={(e) =>
                              onAddEditPatientBtnClick(e, "edit", item)
                            }
                            className={styles.iconView}
                          >
                            <EditIcon fontSize="small" />
                          </Box>
                          <Box
                            onClick={(e) => onDeletePatientBtnClick(e, item)}
                            ml={1}
                            className={styles.iconView}
                          >
                            <DeleteIcon fontSize="small" />
                          </Box>
                          {/*<ActionButtonList*/}
                          {/*  data={item}*/}
                          {/*  actionList={actionList}*/}
                          {/*  onActionOptionClick={onActionOptionClick}*/}
                          {/*/>*/}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography className={styles.noMatchFoundText}>
                        No Patients found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={{ md: "row", xs: "column" }}
            alignItems={"center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{ md: "flex-start", xs: "unset" }}
              mt={2}
              mb={{ md: 2, xs: 0 }}
              pl={{ md: 2, xs: 0 }}
            >
              <Typography className={styles.showingEntriesText}>
                Showing{" "}
                {listCount == 0
                  ? 0
                  : pageNum == 1
                  ? 1
                  : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
                to{" "}
                {listCount == 0
                  ? 0
                  : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
                of {totalListCount} entries
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{ md: "flex-end", xs: "unset" }}
              mt={2}
              mb={2}
              pr={{ md: 2, xs: 0 }}
            >
              <Page
                totalPages={props.patientTotalPages}
                pageNum={pageNum}
                onSelectPage={onSelectPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AddEditPatientModal
        type={type}
        data={selectedPatientData}
        modalVisible={modalVisible}
        doctorList={props.allDoctorList}
        onCancel={onCancelPatient}
        onSubmit={onAddEditPatient}
      />

      <ConfirmAlertBox
        confirmBox={confirmBoxVisible}
        icon={<ExclaimIcon sx={{height: 80, width: 80, color: '#ffc700'}}/>}
        title={"Are you sure?"}
        content={"You won't be able to revert this!"}
        btnLoading={props.patientBtnLoading}
        firstBtn={"Yes, delete it!"}
        secondBtn={"Cancel"}
        onConfirmClick={onConfirmDeletePatient}
        onCancelClick={onCancelDeletePatient}
      />

      <AlertMsg />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    patientTotalPages: state.userData.patientTotalPages,
    patientTotalList: state.userData.patientTotalList,
    patientList: state.userData.patientList,
    patientLoading: state.userData.patientLoading,
    patientBtnLoading: state.userData.patientBtnLoading,
    allDoctorList: state.userData.allDoctorList,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listPatient: (data) => dispatch(listPatient(data)),
    deletePatient: (data) => dispatch(deletePatient(data)),
    getAllDoctor: (data) => dispatch(getAllDoctor(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Patients);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
};
