import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Tooltip
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

import { connect } from "react-redux";
import {
  listEnquiry,
  moveToFollowup,
  updateApiStatus,
  listLabel
} from "../../redux/actions/userDataActions";
import {
  ADD_PATIENT_SUCCESS,
  MOVE_TO_FOLLOWUP_SUCCESS,
} from "../../redux/actions/types";

import Container from "../../components/Container";
import Page from "../../components/Page";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import AddEnquiryModal from "../../components/AddEnquiryModal";
import UseDebounce from "../../components/UseDebounce";
import AlertMsg from "../../components/AlertMsg";
import { MOBILE_VIEW } from "../../components/Sidebar";
import ConfirmAlertBox from "../../components/ConfirmAlertBox";
import AddEditPatientModal from "../../components/AddEditPatientModal";
import LabelModal from "../../components/LabelModal";
import LabelListHover from "../../components/LabelListHover";
import FormSelectize from "../../components/FormSelectize";
import FormSelectBox from "../../components/FormSelectBox";

import styles from "./Enquiry.module.css";

import "../../assets/css/custom.css";

import EditIcon from "@mui/icons-material/EditOutlined";
import PlusIcon from "@mui/icons-material/AddOutlined";
import ExclaimIcon from "@mui/icons-material/ErrorOutlineOutlined";
import MoveIcon from '@mui/icons-material/InputOutlined';
import DownArrowIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const type_list = [
  {
    label: "Enquiry",
    value: "enquiry",
  },
  {
    label: "FollowUp",
    value: "followup",
  },
  {
    label: "Patient",
    value: "patient",
  },
];

const Enquiry = (props) => {
  const [enquiryStatus, setEnquiryStatus] = useState(
    window.location.pathname.split("/").pop()
  );
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [confirmBoxData, setConfirmBoxData] = useState(null);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [patientModalVisible, setPatientModalVisible] = useState(false);
  const [labelModalVisible, setLabelModalVisible] = useState(false);
  const [labelFilter, setLabelFilter] = useState(null);
  const [type, setType] = useState(null);

  const debouncedSearch = UseDebounce(search, 500);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == MOVE_TO_FOLLOWUP_SUCCESS) {
      setConfirmBoxVisible(false);
      setConfirmBoxData(null);
      props.updateApiStatus("");
      window.location.href = "/followup";
    }
  }, [props.apiStatus]);

  useEffect(() => {
    let data = {
      orderBy: 'name',
      orderSort: 'ASC',
    }
    props.listLabel(JSON.stringify(data));
  }, [])

  useEffect(() => {
    setTotalListCount(props.enquiryTotalList);
  }, [props.enquiryTotalList]);

  useEffect(() => {
    setListCount(props.enquiryList?.length);
  }, [props.enquiryList?.length]);

  useEffect(() => {
    getEnquiryList(1, labelFilter);
  }, [debouncedSearch]);

  useEffect(() => {
    setEnquiryStatus(window.location.pathname.split("/").pop());
  }, [location.key]);

  useEffect(() => {
    getEnquiryList(1, labelFilter);
  }, [enquiryStatus]);

  useEffect(() => {
    if(type != null) {
      getEnquiryList(1, labelFilter);
    }
  }, [type]);

  const getEnquiryList = (page, label_filter) => {
    let data = {
      page: page,
      searchEnquiry: search,
      status: enquiryStatus,
      labelFilter: label_filter != null ? label_filter?.id : "",
      type: type != null ? type?.value : ""
    };
    props.listEnquiry(JSON.stringify(data));
    setPageNum(page);
  };

  const onLabelClick = () => {
      setLabelModalVisible(true);
  };

  const onAddEnquiryBtnClick = () => {
    setModalVisible(true);
  };

  const onAddEnquiry = (mobile) => {
    setModalVisible(false);
    navigate("/add_enquiry?mobile=" + mobile?.replace(/ /g,""));
  };

  const onEditEnquiry = (e, item) => {
    e.stopPropagation();
    if(item.status == 'enquiry') {
      navigate("/edit_enquiry/" + item?.uid);
    } else if(item.status == 'followup') {
      navigate("/edit_followup/" + item?.uid);
    }
  };

  const onCloseEnquiry = () => {
    setModalVisible(false);
  };

  const onSelectPage = (page) => {
    getEnquiryList(page, labelFilter);
  };

  const onSearchEnquiry = (val) => {
    setSearch(val);
  };

  const onEnquiryClick = (item) => {
    if (enquiryStatus == "followup") {
      window.location.href = "/followup/" + item?.uid;
    } else {
      if (item.status == 'followup') {
        window.location.href = "/followup/" + item?.uid;
      } else if (item.status == 'patient') {
        navigate("/patient_view?id=" + item?.patient_uid);
      } else {
        window.location.href = "/enquiry/" + item?.uid;
      }
    }
  };

  const onMoveToFollowup = (e, item) => {
    e.stopPropagation();
    setConfirmBoxVisible(true);
    setConfirmBoxData(item);
  };

  const onConfirmMoveToFollowup = () => {
    let data = {
      enquiryUid: confirmBoxData?.uid
    };
    props.moveToFollowup(JSON.stringify(data));
  };

  const onCancelMoveToFollowup = () => {
    setConfirmBoxVisible(false);
    setConfirmBoxData(null);
  };

  const onMoveToPatient = (e, item) => {
    e.stopPropagation();
    let first_name = "";
    let last_name = "";

    if(item?.name) {
      let enquiry_name = item.name;
      const arr = enquiry_name.split(" ");
      if (arr.length >= 2) {
        const name = arr.slice(0, 1).concat(arr.slice(1).join(" "));
        first_name = name[0];
        last_name = name[1];
      } else {
        first_name = enquiry_name;
      }
    }
    setSelectedEnquiry({
      uid: "",
      first_name: first_name,
      last_name: last_name,
      age: "",
      gender: "M",
      address: item?.location,
      mobile: item?.mobile,
      whatsapp: item?.whatsapp,
      email: "",
      enquiry_uid: item?.uid,
    });
    setPatientModalVisible(true);
  };

  const onAddEditPatient = () => {
    setSelectedEnquiry(null);
    setPatientModalVisible(false);
    window.location.href = "/patients";
  };

  const onCancelPatient = () => {
    setSelectedEnquiry(null);
    setPatientModalVisible(false);
  };

  const onCancelLabel = () => {
    setLabelModalVisible(false)
  };

  const onChangeLabel = (val) => {
    setLabelFilter(val)
    getEnquiryList(1, val);
  };

  return (
    <Container page={enquiryStatus}>
      <Grid container item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={styles.cardView}
        >
          <TableContainer className={styles.tableView}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={10}>
                    <Grid
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"flex-end"}
                      mt={2}
                    >
                      {enquiryStatus == "enquiry" ? (
                          <Grid item md={2} xs={2}>
                            <FormSelectBox
                              page={"enquiry"}
                              value={type}
                              setValue={setType}
                              placeholder={"Select Type"}
                              height={40}
                              rightIcon={<DownArrowIcon sx={{color: "#677788"}}/>}
                              data={type_list}
                            />
                          </Grid>)
                        : null}
                      <Grid item md={2} xs={2} ml={2}>
                        <FormTextInput
                          page={"enquiry"}
                          value={search}
                          setValue={(val) => onSearchEnquiry(val)}
                          height={40}
                          width={150}
                          placeholder={"Search.."}
                        />
                      </Grid>
                      <Grid item md={2} xs={2} ml={2}>
                        <FormSelectize
                          page={"enquiry"}
                          value={labelFilter}
                          multiple={false}
                          addOption={false}
                          onChange={(val) => onChangeLabel(val)}
                          options={props.labelList}
                          loading={props.labelLoading}
                          heightViewStyle={{minHeight: 38}}
                          height={38}
                          placeholder={'Label'}
                        />
                      </Grid>
                      <Grid item md={2} xs={2} ml={2}>
                        <FormButton
                          title="Labels"
                          btnStyleView={style.btnStyleView}
                          onSubmit={() => onLabelClick()}
                        />
                      </Grid>
                      {enquiryStatus == "enquiry" ? (
                        <Grid item md={2} xs={2} ml={2}>
                          <FormButton
                            title={
                              MOBILE_VIEW ? <PlusIcon /> : "Add New Enquiry"
                            }
                            startIcon={MOBILE_VIEW ? null : <PlusIcon />}
                            btnStyleView={style.btnStyleView}
                            onSubmit={() => onAddEnquiryBtnClick()}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Note</TableCell>
                  <TableCell>Received Date</TableCell>
                  {enquiryStatus == "followup" ?
                    <>
                      {/*<TableCell>Moved To Followup Date</TableCell>*/}
                      <TableCell>Next FollowUp</TableCell>
                    </>
                    : <TableCell>Type</TableCell>}
                  <TableCell>Actions</TableCell>
                  {enquiryStatus == "followup" ?
                    <TableCell></TableCell>
                    : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.enquiryLoading ? (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : props.enquiryList?.length > 0 ? (
                  props.enquiryList?.map((item, index) => (
                    <TableRow key={index}
                              onClick={() => onEnquiryClick(item)}
                              className={styles.tableRowView}>
                      <TableCell>
                        <Box>
                          {item?.name ? item.name : '-'}
                          <LabelListHover labels={item?.label}/>
                        </Box>
                      </TableCell>
                      <TableCell>{item?.mobile}</TableCell>
                      <TableCell>{item?.location ? item.location : '-'}</TableCell>
                      <TableCell>{item?.note ? item.note : '-'}</TableCell>
                      <TableCell>
                        {item?.received_date ? moment(item.received_date).format("DD-MM-YYYY") : '-'}
                      </TableCell>
                      {enquiryStatus == "followup" ?
                        <>
                          {/*<TableCell>*/}
                          {/*  {item?.moved_to_followup_date ? moment(item.moved_to_followup_date).format("DD-MM-YYYY") : '-'}*/}
                          {/*</TableCell>*/}
                          <TableCell>
                            {item?.next_follow_up ? moment(item.next_follow_up).format("DD-MM-YYYY") : '-'}
                          </TableCell>
                        </>
                        :
                        <TableCell>
                          <Box className={styles.statusView}>
                            <Typography className={styles.statusText}>
                              {item?.status}
                            </Typography>
                          </Box>
                        </TableCell>
                      }
                      <TableCell>
                        <Box display={'flex'} flexDirection={'row'}
                        alignItems={'center'}>

                          {item?.status != "patient" ?
                            <Box
                              mr={1}
                              onClick={(e) =>
                                onEditEnquiry(e, item)
                              }
                              className={styles.iconView}
                            >
                              <EditIcon fontSize="small"/>
                            </Box>
                            : null}

                          {enquiryStatus == "enquiry" ?
                            item?.status != "patient"
                              ? (item?.status == "enquiry" ? (
                                <Tooltip title="Move to Followup"
                                         placement="top"
                                         enterDelay={500} leaveDelay={200}>
                                  <Box
                                    mr={1}
                                    onClick={(e) =>
                                      onMoveToFollowup(e, item)
                                    }
                                    className={styles.iconView}
                                  >
                                    <MoveIcon fontSize="small"/>
                                  </Box>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Move to Patient"
                                         placement="top"
                                         enterDelay={500} leaveDelay={200}>
                                  <Box
                                    mr={1}
                                    onClick={(e) =>
                                      onMoveToPatient(e, item)
                                    }
                                    className={styles.iconView}
                                  >
                                    <MoveIcon fontSize="small"/>
                                  </Box>
                                </Tooltip>
                              )) : null
                            : null}
                        </Box>
                      </TableCell>
                      {enquiryStatus == "followup" ?
                        <TableCell className={styles.btnView}>
                          <FormButton
                            title={"Move to Patient"}
                            btnStyleView={style.actionBtnView}
                            onSubmit={(e) => onMoveToPatient(e, item)}
                          />
                        </TableCell>
                        : null}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      <Typography className={styles.noMatchFoundText}>
                        No{" "}
                        {`${
                          enquiryStatus == "enquiry"
                            ? "Enquiries"
                            : "Follow ups"
                        }`}{" "}
                        found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={{ md: "row", xs: "column" }}
            alignItems={"center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{ md: "flex-start", xs: "unset" }}
              mt={2}
              mb={{ md: 2, xs: 0 }}
              pl={{ md: 2, xs: 0 }}
            >
              <Typography className={styles.showingEntriesText}>
                Showing{" "}
                {listCount == 0
                  ? 0
                  : pageNum == 1
                  ? 1
                  : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
                to{" "}
                {listCount == 0
                  ? 0
                  : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
                of {totalListCount} entries
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{ md: "flex-end", xs: "unset" }}
              mt={2}
              mb={2}
              pr={{ md: 2, xs: 0 }}
            >
              <Page
                totalPages={props.enquiryTotalPages}
                pageNum={pageNum}
                onSelectPage={onSelectPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <LabelModal
        modalVisible={labelModalVisible}
        onCancel={onCancelLabel}
      />

      <AddEnquiryModal
        modalVisible={modalVisible}
        onClose={onCloseEnquiry}
        onSubmit={onAddEnquiry}
      />

      <AddEditPatientModal
        type={"add"}
        data={selectedEnquiry}
        modalVisible={patientModalVisible}
        onCancel={onCancelPatient}
        onSubmit={onAddEditPatient}
      />

      <ConfirmAlertBox
        confirmBox={confirmBoxVisible}
        icon={<ExclaimIcon sx={{ height: 80, width: 80, color: "#ffc700" }} />}
        title={"Are you sure?"}
        content={"Do you want to move this enquiry to followup?"}
        btnLoading={props.moveToFollowupLoading}
        firstBtn={"Yes"}
        secondBtn={"Cancel"}
        onConfirmClick={onConfirmMoveToFollowup}
        onCancelClick={onCancelMoveToFollowup}
      />

      <AlertMsg />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    enquiryTotalPages: state.userData.enquiryTotalPages,
    enquiryTotalList: state.userData.enquiryTotalList,
    enquiryList: state.userData.enquiryList,
    enquiryLoading: state.userData.enquiryLoading,
    enquiryBtnLoading: state.userData.enquiryBtnLoading,
    moveToFollowupLoading: state.userData.moveToFollowupLoading,
    labelList: state.userData.labelList,
    labelLoading: state.userData.labelLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listEnquiry: (data) => dispatch(listEnquiry(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
    moveToFollowup: (data) => dispatch(moveToFollowup(data)),
    listLabel: (data) => dispatch(listLabel(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Enquiry);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  actionBtnView: {
    height: 30,
    width: "fit-content",
    fontSize: `12px !important`,
    padding: "0px 7px",
    borderRadius: `3px !important`
  },
};
