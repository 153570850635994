//login
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAIL = "LOGIN_ADMIN_FAIL";

//register
export const REGISTER_ADMIN = "REGISTER_ADMIN";
export const REGISTER_ADMIN_SUCCESS = "REGISTER_ADMIN_SUCCESS";
export const REGISTER_ADMIN_FAIL = "REGISTER_ADMIN_FAIL";

//patients
export const ADD_PATIENT = "ADD_PATIENT";
export const ADD_PATIENT_SUCCESS = "ADD_PATIENT_SUCCESS";
export const ADD_PATIENT_FAIL = "ADD_PATIENT_FAIL";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS";
export const UPDATE_PATIENT_FAIL = "UPDATE_PATIENT_FAIL";
export const LIST_PATIENT = "LIST_PATIENT";
export const LIST_PATIENT_SUCCESS = "LIST_PATIENT_SUCCESS";
export const LIST_PATIENT_FAIL = "LIST_PATIENT_FAIL";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS";
export const DELETE_PATIENT_FAIL = "DELETE_PATIENT_FAIL";
export const GET_PATIENT_DETAIL = "GET_PATIENT_DETAIL";
export const GET_PATIENT_DETAIL_SUCCESS = "GET_PATIENT_DETAIL_SUCCESS";
export const GET_PATIENT_DETAIL_FAIL = "GET_PATIENT_DETAIL_FAIL";
export const ADD_CONSULTATION_NOTE = "ADD_CONSULTATION_NOTE";
export const ADD_CONSULTATION_NOTE_SUCCESS = "ADD_CONSULTATION_NOTE_SUCCESS";
export const ADD_CONSULTATION_NOTE_FAIL = "ADD_CONSULTATION_NOTE_FAIL";
export const LIST_CONSULTATION_NOTE = "LIST_CONSULTATION_NOTE";
export const LIST_CONSULTATION_NOTE_SUCCESS = "LIST_CONSULTATION_NOTE_SUCCESS";
export const LIST_CONSULTATION_NOTE_FAIL = "LIST_CONSULTATION_NOTE_FAIL";
export const UPDATE_CONSULTATION_NOTE = "UPDATE_CONSULTATION_NOTE";
export const UPDATE_CONSULTATION_NOTE_SUCCESS =
  "UPDATE_CONSULTATION_NOTE_SUCCESS";
export const UPDATE_CONSULTATION_NOTE_FAIL = "UPDATE_CONSULTATION_NOTE_FAIL";
export const DELETE_CONSULTATION_NOTE = "DELETE_CONSULTATION_NOTE";
export const DELETE_CONSULTATION_NOTE_SUCCESS =
  "DELETE_CONSULTATION_NOTE_SUCCESS";
export const DELETE_CONSULTATION_NOTE_FAIL = "DELETE_CONSULTATION_NOTE_FAIL";
export const LIST_APPOINTMENT = "LIST_APPOINTMENT";
export const LIST_APPOINTMENT_SUCCESS = "LIST_APPOINTMENT_SUCCESS";
export const LIST_APPOINTMENT_FAIL = "LIST_APPOINTMENT_FAIL";

//appointment
export const GET_ALL_PATIENT = "GET_ALL_PATIENT";
export const GET_ALL_PATIENT_SUCCESS = "GET_ALL_PATIENT_SUCCESS";
export const GET_ALL_PATIENT_FAIL = "GET_ALL_PATIENT_FAIL";
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const ADD_APPOINTMENT_SUCCESS = "ADD_APPOINTMENT_SUCCESS";
export const ADD_APPOINTMENT_FAIL = "ADD_APPOINTMENT_FAIL";
export const GET_APPOINTMENT = "GET_APPOINTMENT";
export const GET_APPOINTMENT_SUCCESS = "GET_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENT_FAIL = "GET_APPOINTMENT_FAIL";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const UPDATE_APPOINTMENT_SUCCESS = "UPDATE_APPOINTMENT_SUCCESS";
export const UPDATE_APPOINTMENT_FAIL = "UPDATE_APPOINTMENT_FAIL";
export const UPDATE_APPOINTMENT_STATUS = "UPDATE_APPOINTMENT_STATUS";
export const UPDATE_APPOINTMENT_STATUS_SUCCESS =
  "UPDATE_APPOINTMENT_STATUS_SUCCESS";
export const UPDATE_APPOINTMENT_STATUS_FAIL = "UPDATE_APPOINTMENT_STATUS_FAIL";
export const GET_PATIENT_INVOICE_LIST = "GET_PATIENT_INVOICE_LIST";
export const GET_PATIENT_INVOICE_LIST_SUCCESS = "GET_PATIENT_INVOICE_LIST_SUCCESS";
export const GET_PATIENT_INVOICE_LIST_FAIL = "GET_PATIENT_INVOICE_LIST_FAIL";
export const UPDATE_INVOICE_APPOINTMENT = "UPDATE_INVOICE_APPOINTMENT";
export const UPDATE_INVOICE_APPOINTMENT_SUCCESS = "UPDATE_INVOICE_APPOINTMENT_SUCCESS";
export const UPDATE_INVOICE_APPOINTMENT_FAIL = "UPDATE_INVOICE_APPOINTMENT_FAIL";

//invoice
export const GET_APPOINTMENT_INVOICE = "GET_APPOINTMENT_INVOICE";
export const GET_APPOINTMENT_INVOICE_SUCCESS =
  "GET_APPOINTMENT_INVOICE_SUCCESS";
export const GET_APPOINTMENT_INVOICE_FAIL = "GET_APPOINTMENT_INVOICE_FAIL";
export const ADD_APPOINTMENT_INVOICE = "ADD_APPOINTMENT_INVOICE";
export const ADD_APPOINTMENT_INVOICE_SUCCESS = "ADD_APPOINTMENT_INVOICE_SUCCESS";
export const ADD_APPOINTMENT_INVOICE_FAIL = "ADD_APPOINTMENT_INVOICE_FAIL";
export const UPDATE_APPOINTMENT_INVOICE = "UPDATE_APPOINTMENT_INVOICE";
export const UPDATE_APPOINTMENT_INVOICE_SUCCESS = "UPDATE_APPOINTMENT_INVOICE_SUCCESS";
export const UPDATE_APPOINTMENT_INVOICE_FAIL = "UPDATE_APPOINTMENT_INVOICE_FAIL";
export const DELETE_APPOINTMENT_INVOICE = "DELETE_APPOINTMENT_INVOICE";
export const DELETE_APPOINTMENT_INVOICE_SUCCESS = "DELETE_APPOINTMENT_INVOICE_SUCCESS";
export const DELETE_APPOINTMENT_INVOICE_FAIL = "DELETE_APPOINTMENT_INVOICE_FAIL";
export const ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD = "ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD";
export const ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD_SUCCESS = "ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD_SUCCESS";
export const ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD_FAIL = "ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD_FAIL";
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAIL = "GET_INVOICE_FAIL";

//dashboard
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL";

//doctors
export const ADD_DOCTOR = "ADD_DOCTOR";
export const ADD_DOCTOR_SUCCESS = "ADD_DOCTOR_SUCCESS";
export const ADD_DOCTOR_FAIL = "ADD_DOCTOR_FAIL";
export const UPDATE_DOCTOR = "UPDATE_DOCTOR";
export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_SUCCESS";
export const UPDATE_DOCTOR_FAIL = "UPDATE_DOCTOR_FAIL";
export const LIST_DOCTOR = "LIST_DOCTOR";
export const LIST_DOCTOR_SUCCESS = "LIST_DOCTOR_SUCCESS";
export const LIST_DOCTOR_FAIL = "LIST_DOCTOR_FAIL";
export const DELETE_DOCTOR = "DELETE_DOCTOR";
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS";
export const DELETE_DOCTOR_FAIL = "DELETE_DOCTOR_FAIL";
export const GET_ALL_DOCTOR = "GET_ALL_DOCTOR";
export const GET_ALL_DOCTOR_SUCCESS = "GET_ALL_DOCTOR_SUCCESS";
export const GET_ALL_DOCTOR_FAIL = "GET_ALL_DOCTOR_FAIL";
export const GET_DOCTOR_DETAIL = "GET_DOCTOR_DETAIL";
export const GET_DOCTOR_DETAIL_SUCCESS = "GET_DOCTOR_DETAIL_SUCCESS";
export const GET_DOCTOR_DETAIL_FAIL = "GET_DOCTOR_DETAIL_FAIL";

//users
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const LIST_USER = "LIST_USER";
export const LIST_USER_SUCCESS = "LIST_USER_SUCCESS";
export const LIST_USER_FAIL = "LIST_USER_FAIL";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_FAIL = "UPDATE_USER_PASSWORD_FAIL";

//invoices
export const LIST_INVOICE = "LIST_INVOICE";
export const LIST_INVOICE_SUCCESS = "LIST_INVOICE_SUCCESS";
export const LIST_INVOICE_FAIL = "LIST_INVOICE_FAIL";
export const ADD_INVOICE = "ADD_INVOICE";
export const ADD_INVOICE_SUCCESS = "ADD_INVOICE_SUCCESS";
export const ADD_INVOICE_FAIL = "ADD_INVOICE_FAIL";
export const GET_PATIENT_INVOICE = "GET_PATIENT_INVOICE";
export const GET_PATIENT_INVOICE_SUCCESS = "GET_PATIENT_INVOICE_SUCCESS";
export const GET_PATIENT_INVOICE_FAIL = "GET_PATIENT_INVOICE_FAIL";

//products
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";
export const LIST_PRODUCT = "LIST_PRODUCT";
export const LIST_PRODUCT_SUCCESS = "LIST_PRODUCT_SUCCESS";
export const LIST_PRODUCT_FAIL = "LIST_PRODUCT_FAIL";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";
export const GET_ALL_PRODUCT_CATEGORY = "GET_ALL_PRODUCT_CATEGORY";
export const GET_ALL_PRODUCT_CATEGORY_SUCCESS = "GET_ALL_PRODUCT_CATEGORY_SUCCESS";
export const GET_ALL_PRODUCT_CATEGORY_FAIL = "GET_ALL_PRODUCT_CATEGORY_FAIL";
export const UPDATE_PRODUCT_STATUS = "UPDATE_PRODUCT_STATUS";
export const UPDATE_PRODUCT_STATUS_SUCCESS = "UPDATE_PRODUCT_STATUS_SUCCESS";
export const UPDATE_PRODUCT_STATUS_FAIL = "UPDATE_PRODUCT_STATUS_FAIL";

//product category
export const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";
export const ADD_PRODUCT_CATEGORY_SUCCESS = "ADD_PRODUCT_CATEGORY_SUCCESS";
export const ADD_PRODUCT_CATEGORY_FAIL = "ADD_PRODUCT_CATEGORY_FAIL";
export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = "UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_FAIL = "UPDATE_PRODUCT_CATEGORY_FAIL";
export const LIST_PRODUCT_CATEGORY = "LIST_PRODUCT_CATEGORY";
export const LIST_PRODUCT_CATEGORY_SUCCESS = "LIST_PRODUCT_CATEGORY_SUCCESS";
export const LIST_PRODUCT_CATEGORY_FAIL = "LIST_PRODUCT_CATEGORY_FAIL";
export const DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY";
export const DELETE_PRODUCT_CATEGORY_SUCCESS = "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_FAIL = "DELETE_PRODUCT_CATEGORY_FAIL";

//product enquiry
export const LIST_PRODUCT_ENQUIRY = "LIST_PRODUCT_ENQUIRY";
export const LIST_PRODUCT_ENQUIRY_SUCCESS = "LIST_PRODUCT_ENQUIRY_SUCCESS";
export const LIST_PRODUCT_ENQUIRY_FAIL = "LIST_PRODUCT_ENQUIRY_FAIL";

//enquiry
export const GET_ENQUIRY_BY_MOBILE = "GET_ENQUIRY_BY_MOBILE";
export const GET_ENQUIRY_BY_MOBILE_SUCCESS = "GET_ENQUIRY_BY_MOBILE_SUCCESS";
export const GET_ENQUIRY_BY_MOBILE_FAIL = "GET_ENQUIRY_BY_MOBILE_FAIL";
export const ADD_ENQUIRY = "ADD_ENQUIRY";
export const ADD_ENQUIRY_SUCCESS = "ADD_ENQUIRY_SUCCESS";
export const ADD_ENQUIRY_FAIL = "ADD_ENQUIRY_FAIL";
export const UPDATE_ENQUIRY = "UPDATE_ENQUIRY";
export const UPDATE_ENQUIRY_SUCCESS = "UPDATE_ENQUIRY_SUCCESS";
export const UPDATE_ENQUIRY_FAIL = "UPDATE_ENQUIRY_FAIL";
export const LIST_ENQUIRY = "LIST_ENQUIRY";
export const LIST_ENQUIRY_SUCCESS = "LIST_ENQUIRY_SUCCESS";
export const LIST_ENQUIRY_FAIL = "LIST_ENQUIRY_FAIL";
export const MOVE_TO_FOLLOWUP = "MOVE_TO_FOLLOWUP";
export const MOVE_TO_FOLLOWUP_SUCCESS = "MOVE_TO_FOLLOWUP_SUCCESS";
export const MOVE_TO_FOLLOWUP_FAIL = "MOVE_TO_FOLLOWUP_FAIL";

//enquiry detail
export const GET_ENQUIRY_DETAIL = "GET_ENQUIRY_DETAIL";
export const GET_ENQUIRY_DETAIL_SUCCESS = "GET_ENQUIRY_DETAIL_SUCCESS";
export const GET_ENQUIRY_DETAIL_FAIL = "GET_ENQUIRY_DETAIL_FAIL";
export const ADD_ENQUIRY_NOTE = "ADD_ENQUIRY_NOTE";
export const ADD_ENQUIRY_NOTE_SUCCESS = "ADD_ENQUIRY_NOTE_SUCCESS";
export const ADD_ENQUIRY_NOTE_FAIL = "ADD_ENQUIRY_NOTE_FAIL";
export const LIST_ENQUIRY_NOTE = "LIST_ENQUIRY_NOTE";
export const LIST_ENQUIRY_NOTE_SUCCESS = "LIST_ENQUIRY_NOTE_SUCCESS";
export const LIST_ENQUIRY_NOTE_FAIL = "LIST_ENQUIRY_NOTE_FAIL";
export const UPDATE_ENQUIRY_NOTE = "UPDATE_ENQUIRY_NOTE";
export const UPDATE_ENQUIRY_NOTE_SUCCESS =
  "UPDATE_ENQUIRY_NOTE_SUCCESS";
export const UPDATE_ENQUIRY_NOTE_FAIL = "UPDATE_ENQUIRY_NOTE_FAIL";
export const DELETE_ENQUIRY_NOTE = "DELETE_ENQUIRY_NOTE";
export const DELETE_ENQUIRY_NOTE_SUCCESS =
  "DELETE_ENQUIRY_NOTE_SUCCESS";
export const DELETE_ENQUIRY_NOTE_FAIL = "DELETE_ENQUIRY_NOTE_FAIL";

//label
export const ADD_LABEL = "ADD_LABEL";
export const ADD_LABEL_SUCCESS = "ADD_LABEL_SUCCESS";
export const ADD_LABEL_FAIL = "ADD_LABEL_FAIL";
export const UPDATE_LABEL = "UPDATE_LABEL";
export const UPDATE_LABEL_SUCCESS = "UPDATE_LABEL_SUCCESS";
export const UPDATE_LABEL_FAIL = "UPDATE_LABEL_FAIL";
export const LIST_LABEL = "LIST_LABEL";
export const LIST_LABEL_SUCCESS = "LIST_LABEL_SUCCESS";
export const LIST_LABEL_FAIL = "LIST_LABEL_FAIL";
export const DELETE_LABEL = "DELETE_LABEL";
export const DELETE_LABEL_SUCCESS = "DELETE_LABEL_SUCCESS";
export const DELETE_LABEL_FAIL = "DELETE_LABEL_FAIL";

//income/expense
export const ADD_INCOME_EXPENSE = "ADD_INCOME_EXPENSE";
export const ADD_INCOME_EXPENSE_SUCCESS = "ADD_INCOME_EXPENSE_SUCCESS";
export const ADD_INCOME_EXPENSE_FAIL = "ADD_INCOME_EXPENSE_FAIL";
export const UPDATE_INCOME_EXPENSE = "UPDATE_INCOME_EXPENSE";
export const UPDATE_INCOME_EXPENSE_SUCCESS = "UPDATE_INCOME_EXPENSE_SUCCESS";
export const UPDATE_INCOME_EXPENSE_FAIL = "UPDATE_INCOME_EXPENSE_FAIL";
export const DELETE_INCOME_EXPENSE = "DELETE_INCOME_EXPENSE";
export const DELETE_INCOME_EXPENSE_SUCCESS = "DELETE_INCOME_EXPENSE_SUCCESS";
export const DELETE_INCOME_EXPENSE_FAIL = "DELETE_INCOME_EXPENSE_FAIL";
export const LIST_INCOME_EXPENSE = "LIST_INCOME_EXPENSE";
export const LIST_INCOME_EXPENSE_SUCCESS = "LIST_INCOME_EXPENSE_SUCCESS";
export const LIST_INCOME_EXPENSE_FAIL = "LIST_INCOME_EXPENSE_FAIL";

//global search
export const GLOBAL_SEARCH = "GLOBAL_SEARCH";
export const GLOBAL_SEARCH_SUCCESS = "GLOBAL_SEARCH_SUCCESS";
export const GLOBAL_SEARCH_FAIL = "GLOBAL_SEARCH_FAIL";

export const UPDATE_ALERT_MSG = "UPDATE_ALERT_MSG";
export const UPDATE_DRAWER_OPEN = "UPDATE_DRAWER_OPEN";
export const UPDATE_API_DATA = "UPDATE_API_DATA";
export const UPDATE_API_STATUS = "UPDATE_API_STATUS";
export const LOG_OUT = "LOG_OUT";
