import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import dayjs from "dayjs";

import {createRandomString} from "../../assets/functions/common";

import { connect } from "react-redux";
import {
  getAppointmentInvoice,
  getInvoice,
} from "../../redux/actions/userDataActions";

import {
  GST,
  CLINIC_ADDRESS,
  CLINIC_ADDRESS2,
  CLINIC_MOBILE,
} from "../../constants";

import styles from "./PrintInvoice.module.css";

import { ReactComponent as LogoIcon } from "../../assets/img/logo.svg";

const PrintInvoice = (props) => {
  const [invoiceNo, setInvoiceNo] = useState("");
  const [date, setDate] = useState("");
  const [patient, setPatient] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [itemArray, setItemArray] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [gstPrice, setGstPrice] = useState(0);
  const [taxablePrice, setTaxablePrice] = useState(0);
  const [printInvoice, setPrintInvoice] = useState(false);
  const [consultantDetail, setConsultantDetail] = useState("");

  useEffect(() => {
    const invoice_uid = window.location.pathname.split("/").pop();
    let data = {
      invoiceUid: invoice_uid,
    };
    props.getInvoice(JSON.stringify(data));
  }, []);

  useEffect(() => {
    if (props.appointmentInvoiceDetail && !printInvoice) {
      let data = props.appointmentInvoiceDetail;
      setPatient(data?.patient);
      setDoctor(data?.doctor);
      if (data?.uid) {
        setConsultantDetail(data?.consultant_detail)
        setItemArray(data?.invoice_item);
        setDate(dayjs(data?.invoice_date));
        setInvoiceNo(data?.invoice_no);
      }
    }
  }, [props.appointmentInvoiceDetail, printInvoice]);

  useEffect(() => {
    let fileName = createRandomString(16);
    if (printInvoice) {
      var tempTitle = document.title;
      document.title = fileName;
      window.print();
      document.title = tempTitle;
    }
  }, [printInvoice]);

  useEffect(() => {
    if (!printInvoice && itemArray.length) {
      let taxable = 0;
      let gst = 0;
      let total = 0;
      itemArray.map((x) => {
        let price = 0;

        if (x?.price) {
          price = x.price;
          if(x?.discount > 0) {
            price = parseFloat(x.price) - parseFloat(x.discount);
          }
          taxable = taxable + parseFloat(price) * parseFloat(x?.quantity ? x.quantity : 1);

          if (x?.gst) {
            gst = gst + (parseFloat(price) * parseFloat(x?.quantity ? x.quantity : 1)) * (x?.gst / 100);
          }
        }

      }, []);
      setTaxablePrice(taxable);
      setGstPrice(gst);
      total = parseFloat(taxable) + parseFloat(gst)
      setTotalPrice(total);
      setPrintInvoice(true);
    }
  }, [itemArray, printInvoice]);

  return (
    <Grid
      item
      md={12}
      xs={12}
      container
      display={"flex"}
      flexDirection={"column"}
      className={styles.cardView}
      sx={{ minHeight: window.innerHeight }}
    >
      <Grid
        item
        md={12}
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        p={4}
      >
        <Grid item md={6} xs={6}>
          <Grid item md={12} xs={12} className={styles.logoView}>
            <LogoIcon height={60} />
          </Grid>

          <Grid item md={8} xs={8} mt={3}>
            <Typography className={styles.contentText}>
              {CLINIC_ADDRESS}
            </Typography>
            <Typography className={styles.contentText}>
              {CLINIC_ADDRESS2}
            </Typography>
            <Typography className={styles.contentText}>
              {CLINIC_MOBILE}
            </Typography>
            <Typography className={styles.gstText}>GSTIN: {GST}</Typography>
          </Grid>
        </Grid>

        <Grid
          item
          md={6}
          xs={6}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-end"}
        >
          <Box display={"flex"} flexDirection={"row"}>
            <Typography className={styles.invoiceText}>Invoice</Typography>
            <Typography className={styles.invoiceValueText} ml={1}>
              #{invoiceNo}
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"row"} mt={1}>
            <Typography className={styles.dateText}>Date:</Typography>
            <Typography className={styles.dateValueText} ml={1}>
              {dayjs(date).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid className={styles.borderBottomLine} mb={1} />

      <Grid container item md={12} xs={12} px={4} pt={1} pb={3}>
        <Grid item md={6} xs={6} mb={1}>
          <Typography className={styles.patientTitleText}>Patient</Typography>
          <Typography className={styles.patientDataText}>
            {patient?.first_name} {patient?.last_name}
          </Typography>
        </Grid>
        {/*{doctor?.name ? (*/}
        {/*  <Grid item md={6} xs={6} display={"flex"} justifyContent={"flex-end"}>*/}
        {/*    <Box sx={{ minWidth: 200 }}>*/}
        {/*      <Typography className={styles.patientTitleText}>*/}
        {/*        Referred By*/}
        {/*      </Typography>*/}
        {/*      <Typography className={styles.patientDataText}>*/}
        {/*        {doctor?.name}*/}
        {/*      </Typography>*/}
        {/*      <Typography className={styles.patientDataText}>*/}
        {/*        {doctor?.mobile}*/}
        {/*      </Typography>*/}
        {/*      <Typography className={styles.patientDataText}>*/}
        {/*        {doctor?.hospital}*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*  </Grid>*/}
        {/*) : null}*/}
      </Grid>

      <Grid className={styles.borderTableTopLine} />

      <Grid item md={12} xs={12} mb={2}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Price</TableCell>
                {(itemArray?.length == 1 && itemArray?.[0]?.discount == 0)
                  ? null
                  : <TableCell>Discount</TableCell>
                }
                <TableCell>GST</TableCell>
                <TableCell>SGST</TableCell>
                <TableCell>CGST</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemArray?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className={styles.itemTitle}>
                      {item?.item}
                    </TableCell>
                    <TableCell>{item?.quantity}</TableCell>
                    <TableCell>₹{Number(item?.price).toFixed(2)}</TableCell>
                    {itemArray?.length == 1 && item?.discount == 0 ? null :
                      <TableCell>₹{item?.discount ? item.discount : 0}</TableCell>
                    }
                    <TableCell>{item?.gst ? item.gst : 0}%</TableCell>
                    <TableCell>{item?.gst ? item.gst/2 : 0}%</TableCell>
                    <TableCell>{item?.gst ? item.gst/2 : 0}%</TableCell>
                    <TableCell>
                      ₹{item?.price
                      ? (
                        Number(item?.quantity) * Number(item?.discount > 0 ? (parseFloat(item.price) - parseFloat(item.discount)) : item.price)
                      ).toFixed(2)
                      : 0}
                    </TableCell>
                  </TableRow>
                );
              })}
              {itemArray.length == 0 ? (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <Typography className={styles.noFoundText}>
                      No Items found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid
        item
        md={12}
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={'flex-start'}
        p={4}
      >
        <Grid item md={8} xs={8}>
          <Typography className={styles.consultantDetailText}>{consultantDetail}</Typography>
        </Grid>

        <Grid item md={2} xs={2}/>

        <Grid item md={2} xs={2} display={"flex"} flexDirection={"column"}>
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            mt={1}
          >
            <Grid
              item
              md={6.5}
              xs={6.5}
              display={"flex"}
              justifyContent={"flex-start"}
            >
              <Typography className={styles.subLabelText}>Taxable:</Typography>
            </Grid>

            <Grid
              item
              md={4}
              xs={4}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Typography className={styles.subValueText}>
                ₹{taxablePrice?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            mt={1}
          >
            <Grid
              item
              md={6.5}
              xs={6.5}
              display={"flex"}
              justifyContent={"flex-start"}
            >
              <Typography className={styles.subLabelText}>GST:</Typography>
            </Grid>

            <Grid
              item
              md={4}
              xs={4}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Typography className={styles.subValueText}>
                ₹{gstPrice?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            mt={3}
          >
            <Grid
              item
              md={6.5}
              xs={6.5}
              display={"flex"}
              justifyContent={"flex-start"}
            >
              <Typography className={styles.labelText}>Total:</Typography>
            </Grid>

            <Grid
              item
              md={4}
              xs={4}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Typography className={styles.valueText}>
                ₹{totalPrice?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    appointmentInvoiceDetailLoading:
      state.userData.appointmentInvoiceDetailLoading,
    appointmentInvoiceDetail: state.userData.appointmentInvoiceDetail,
    appointmentInvoiceBtnLoading: state.userData.appointmentInvoiceBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentInvoice: (data) => dispatch(getAppointmentInvoice(data)),
    getInvoice: (data) => dispatch(getInvoice(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintInvoice);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
};
