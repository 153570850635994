import React, {useRef} from 'react';
import {Box, Typography, Select, MenuItem, Grid, FormControl} from "@mui/material";

import styles from "./FormSelectBox.module.css";

const FormSelectBox = (props) => {
  return (
    <Grid item md={12} xs={12}
          container>
      {props.label ?
        <Grid display={"flex"} flexDirection={"row"} item md={12} xs={12} container>
          <Typography className={styles.labelText}>{props.label}</Typography>
          {props?.required ?
            <Box>
              <Typography className={styles.requiredText} mt={-0.3} pl={0.3}>*</Typography>
            </Box>
            : null}
        </Grid>
        : null}
      <Grid item md={12} xs={12}
            container
            sx={[style.textInputView, props?.height ? {height: props?.height} : null]}>
        <FormControl style={{width: '100%'}}>
          <Select
            disabled={props?.disabled}
            onClose={() => {
              setTimeout(() => {
                document.activeElement.blur();
              }, 0);
            }}
            required={props?.required ? true : false}
            variant={props?.variant ? props?.variant : "standard"}
            value={props.value}
            onChange={e => props.setValue(e.target.value)}
            sx={{
              '& .MuiSelect-select .notranslate::after': props?.placeholder
                ? {
                  content: `"${props?.placeholder}"`,
                  textTransform: 'none',
                  color: '#CCC',
                  fontSize: 14,
                  opacity: 1,
                }
                : {},
              ...style.selectedItemView,
            }}
            MenuProps={{
              disableScrollLock: true,
            }}
            disableUnderline
            inputProps={props?.rightIcon ? {IconComponent: () => props?.rightIcon} : null}
          >
            {props.data.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
                style={style.textInputValue}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {props.error ? (
        <Box mt={0.7} className={styles.errorView}>
          <Typography className={styles.errorText}>{props.error}</Typography>
        </Box>
      ) : null}
    </Grid>
  )
}

export default FormSelectBox;

const style = {
  textInputView: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF',
    border: '1px solid #CCC',
    height: 36,
    borderRadius: 1,
  },
  textInputValue: {
    fontFamily: 'IBMPlexSansRegular',
    textTransform: 'none',
    color: '#677788',
    fontSize: 14,
    px: 1.5,
  },
  selectedItemView: {
    fontFamily: 'IBMPlexSansRegular',
    backgroundColor: '#FFF',
    textTransform: 'none',
    color: '#677788',
    fontSize: 14,
    px: 1.5,
  },
};
