import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import {useNavigate} from "react-router-dom";

import AlertMsg from "./AlertMsg";
import Page from "./Page";
import {UTC_OFFSET} from "../constants";

import { connect } from "react-redux";
import {listPatient} from "../redux/actions/userDataActions";

import styles from "./DoctorReferredPatientList.module.css";

const DoctorReferredPatientList = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(15);

  const navigate = useNavigate();

  useEffect(() => {
    setTotalListCount(props.patientTotalList);
  }, [props.patientTotalList]);

  useEffect(() => {
    setListCount(props.patientList?.length);
  }, [props.patientList?.length]);

  useEffect(() => {
    getPatientList(1);
  }, []);

  const getPatientList = (page) => {
    let data = {
      page: page,
      doctorUid: props.doctorUid,
      pageLimit: pageLimit,
    };
    props.listPatient(JSON.stringify(data));
    setPageNum(page);
  };

  const onSelectPage = (page) => {
    getPatientList(page);
  };

  const onViewClick = (data) => {
    navigate("/patient_view?id=" + data?.uid);
  };
  
  return (
    <>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={5}>
                  <Grid
                    mt={1}
                  />
                </TableCell>
              </TableRow>
            </TableHead>

            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Added Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.patientLoading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : props.patientList?.length > 0 ? (
                props.patientList?.map((item, index) => (
                  <TableRow
                    key={index}
                    onClick={(e) => onViewClick(item)}
                    className={styles.tableRowView}
                  >
                    <TableCell>{item?.first_name}</TableCell>
                    <TableCell>{item?.last_name ? item.last_name : '-'}</TableCell>
                    <TableCell>{item?.dob ? moment().diff(moment(item.dob), 'years') : ''}{item?.gender}</TableCell>
                    <TableCell>{item?.address ? item.address : "-"}</TableCell>
                    <TableCell>
                      {moment.utc(item?.timestamp).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography className={styles.noMatchFoundText}>
                      No Patients found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid
          item
          md={12}
          xs={12}
          display={"flex"}
          flexDirection={{ md: "row", xs: "column" }}
          alignItems={"center"}
        >
          <Grid
            item
            md={6}
            xs={12}
            display={"flex"}
            justifyContent={{ md: "flex-start", xs: "unset" }}
            mt={2}
            mb={{ md: 2, xs: 0 }}
            pl={{ md: 2, xs: 0 }}
          >
            <Typography className={styles.showingEntriesText}>
              Showing{" "}
              {listCount == 0
                ? 0
                : pageNum == 1
                  ? 1
                  : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
              to{" "}
              {listCount == 0
                ? 0
                : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
              of {totalListCount} entries
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            display={"flex"}
            justifyContent={{ md: "flex-end", xs: "unset" }}
            mt={2}
            mb={2}
            pr={{ md: 2, xs: 0 }}
          >
            <Page
              totalPages={props.patientTotalPages}
              pageNum={pageNum}
              onSelectPage={onSelectPage}
            />
          </Grid>
        </Grid>
      </Grid>

      <AlertMsg />

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    patientTotalPages: state.userData.patientTotalPages,
    patientTotalList: state.userData.patientTotalList,
    patientList: state.userData.patientList,
    patientLoading: state.userData.patientLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listPatient: (data) => dispatch(listPatient(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorReferredPatientList);
