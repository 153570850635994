import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import {v4 as uuidv4} from 'uuid';
import Dropzone from 'react-dropzone'

import {connect} from "react-redux";
import {updateAlertMsg} from "../redux/actions/userDataActions";

import styles from '../components/ImageUpload.module.css';

const MultiImageUpload = (props) => {

  const uploadFile = acceptedFiles => {
    let fileList = [];
    acceptedFiles.map(file => {
      let image = URL.createObjectURL(file);
      fileList.push({
        id: uuidv4(),
        image: image,
        file: file,
      })
    });
    props.onChange(fileList);
  };

  const uploadError = (err) => {
    if (err.length) {
      if (err[0]['errors'].length)
        if (err[0]['errors'][0]['code'] == 'too-many-files') {
          if (props.maxFiles == 1) {
            props.updateAlertMsg({open: true, message: 'Only 1 image is allowed to upload', msgType: 'error'});
          } else {
            props.updateAlertMsg({
              open: true,
              message: `Only ${props.maxFiles} images are allowed to upload at a time`,
              msgType: 'error'
            });
          }
        } else {
          props.updateAlertMsg({open: true, message: err[0]['errors'][0]['message'], msgType: 'error'});
        }
    }
  };

  const onRemoveImage = (x) => {
    props.onRemove(x);
  };

  return (
    <Box>
      <Dropzone
        maxFiles={props.maxFiles}
        onDropRejected={err => uploadError(err)}
        accept={{'image/*': ['.jpeg', '.jpg', '.png']}}
        onDrop={uploadFile}
      >
        {({getRootProps, getInputProps, open}) => (
          <Grid
            item md={12} xs={12} mt={2} container
            p={4}
            className={[styles.imageUploadView, props.image.length == 0 ? styles.dataCenterView : null]}
            {...(getRootProps())}>
            <input {...getInputProps()} />
            {props.image?.length > 0 ?
              <Box
                display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
                {props.image?.map((x) => {
                  return (
                    <Box className={[styles.uploadMainImgView]} mr={4} mb={3} key={x.image}>
                      <Box display={"flex"}
                           alignItems={"center"}
                           justifyContent={"center"}
                           flexDirection={'column'}
                           className={styles.uploadImgView}>
                        <img src={x?.image} className={styles.uploadImgStyle}/>
                        <Box className={styles.borderBottomLine}/>
                        <Box
                          style={{cursor: 'pointer'}}
                          onClick={(e) => {e.stopPropagation(); onRemoveImage(x)}}
                          pt={1}>
                          <Typography className={styles.removeFileText}>Remove File</Typography>
                        </Box>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
              :
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography className={styles.dropFileText}>Drop files here or click to upload</Typography>
              </Box>
            }
          </Grid>
        )}
      </Dropzone>

      {props?.error ?
        <Box mt={2} ml={0.5} className={styles.errorView}>
          <Typography className={styles.errorText}>{props.error}</Typography>
        </Box>
        : null}
    </Box>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    updateAlertMsg: data => dispatch(updateAlertMsg(data)),
  };
};

export default connect(null, mapDispatchToProps)(MultiImageUpload);
