import React, {useEffect, useState, useRef} from "react";
import {
  Grid,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";

import FormButton from "../components/FormButton";
import FormTextInput from "./FormTextInput";

import {connect} from "react-redux";
import {
  addUser,
  updateApiStatus,
  updateUser,
} from "../redux/actions/userDataActions";
import {
  ADD_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
} from "../redux/actions/types";

import styles from "./AddEditUserModal.module.css";

import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EyeOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';

const AddEditUserModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [userUid, setUserUid] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [passwordEyeVisible, setPasswordEyeVisible] = useState(false);
  const [confirmPasswordEyeVisible, setConfirmPasswordEyeVisible] = useState(false);

  const usernameRef = useRef(null);

  useEffect(() => {
    if (
      (props.apiStatus == ADD_USER_SUCCESS ||
        props.apiStatus == UPDATE_USER_SUCCESS) &&
      props.modalVisible
    ) {
      props.updateApiStatus("");
      props.onSubmit();
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if (props.data != null) {
      setUserUid(props.data?.uid);
      setUsername(props.data?.username);
      setEmail(props.data?.email);
      setPassword("");
      setConfirmPassword("");
      setLoading(false);
    } else {
      setUserUid("");
      setUsername("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError(null);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.modalVisible) {
      if (props.type == "add") {
        setLoading(false);
      }
      setVisible(true);
    } else {
      setUserUid("");
      setUsername("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError(null);
    }
  }, [props.modalVisible]);

  useEffect(() => {
    if(visible) {
      if(usernameRef?.current) {
        usernameRef?.current?.focus()
      }
    }
  },[visible]);

  const onSubmitClick = () => {
    let errorText = {};
    if (username == "") {
      errorText = {username: "Enter username"};
    } else {
      if (username?.length < 6) {
        errorText = {username: 'Username must contain atleast 6 characters'};
      }
    }
    if (email == "") {
      errorText = {...errorText, email: "Enter email"};
    } else {
      if (!/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$/.test(email)) {
        errorText = {...errorText, email: 'Enter valid email address'};
      }
    }

    if (props.type == "add") {
      if (password == '') {
        errorText = {...errorText, password: 'Enter password'};
      } else {
        if (password?.length < 6) {
          errorText = {...errorText, password: 'Password must contain atleast 6 characters'};
        }
      }
      if (confirmPassword == '') {
        errorText = {...errorText, confirmPassword: 'Enter Confirm password'};
      } else {
        if (password) {
          if (password != confirmPassword) {
            errorText = {...errorText, confirmPassword: 'Password not match'};
          }
        }
      }
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    onSubmit();
  };

  const onSubmit = () => {
    let data = {
      userUid: userUid,
      username: username,
      email: email,
      password: password,
    };

    if (props.type == "add") {
      props.addUser(JSON.stringify(data));
    } else {
      props.updateUser(JSON.stringify(data));
    }
  };

  const onCancelClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onCancel();
    }, [100]);
  };

  const onChange = (val, type) => {
    let errorText = error;
    if (type == 'username') {
      if (val != '') {
        if (val?.length < 6) {
          errorText = {...error, username: 'Username must contain atleast 6 characters'};
        } else {
          errorText = {...error, username: ''};
        }
      } else {
        errorText = {...error, username: 'Enter username'};
      }
      setUsername(val)
    } else if (type == 'email') {
      if (val != '') {
        if (!/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$/.test(val)) {
          errorText = {...error, email: 'Enter valid email address'};
        } else {
          errorText = {...error, email: ''};
        }
      } else {
        errorText = {...error, email: 'Enter email'};
      }
      setEmail(val)
    } else if (type == 'password') {
      if (val != '') {
        if (val?.length < 6) {
          errorText = {...error, password: 'Password must contain atleast 6 characters'};
        } else {
          errorText = {...error, password: ''};
        }
      } else {
        errorText = {...error, password: 'Enter password'};
      }
      setPassword(val)
    } else if (type == 'confirmPassword') {
      if (val != '') {
        if (val != password) {
          errorText = {...error, confirmPassword: 'Password not match'};
        } else {
          errorText = {...error, confirmPassword: ''};
        }
      } else {
        errorText = {...error, confirmPassword: 'Enter Confirm password'};
      }
      setConfirmPassword(val)
    }
    setError(errorText);
  };

  const onPasswordEyeIconClick = () => {
    if (passwordEyeVisible) {
      setPasswordEyeVisible(false)
    } else {
      setPasswordEyeVisible(true)
    }
  };

  const onConfirmPasswordEyeIconClick = () => {
    if (confirmPasswordEyeVisible) {
      setConfirmPasswordEyeVisible(false)
    } else {
      setConfirmPasswordEyeVisible(true)
    }
  };

  return (
    <Modal
      onClose={onCancelClick}
      disableBackdropClick
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{md: 8, xs: 4}}
      >
        <Grid
          onClick={() => onCancelClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle}/>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            {props?.type == "add" ? "Add " : "Edit "} User
          </Typography>
        </Grid>

        {loading ? (
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress/>
          </Grid>
        ) : (
          <>
            <Grid item md={props.type == "add" ? 5.8 : 12} xs={12} mt={1}>
              <FormTextInput
                page={"AddEditUserModal"}
                required={true}
                inputRef={usernameRef}
                error={error?.username}
                value={username}
                setValue={(val) => onChange(val, "username")}
                label={"USERNAME"}
              />
            </Grid>
            <Grid item md={props.type == "add" ? 5.8 : 12} xs={12} mt={1}>
              <FormTextInput
                page={"AddEditUserModal"}
                required={true}
                error={error?.email}
                value={email}
                setValue={(val) => onChange(val, "email")}
                label={"EMAIL"}
              />
            </Grid>

            {props.type == "add" ?
              <Grid item md={12} xs={12} display={'flex'}
                    flexDirection={{xs: 'column', md: 'row'}}
                    justifyContent={'space-between'}>
                <Grid item md={5.8} xs={12} mt={1}>
                  <FormTextInput
                    page={"AddEditUserModal"}
                    required={true}
                    error={error?.password}
                    value={password}
                    setValue={(val) => onChange(val, "password")}
                    label={"PASSWORD"}
                    type={passwordEyeVisible ? 'text' : 'password'}
                    icon={true}
                    rightIcon={passwordEyeVisible ? <EyeIcon sx={style.eyeIconStyle}/> : <EyeOffIcon sx={style.eyeIconStyle}/>}
                    onIconClick={onPasswordEyeIconClick}
                  />
                </Grid>
                <Grid item md={5.8} xs={12} mt={1}>
                  <FormTextInput
                    page={"AddEditUserModal"}
                    required={true}
                    error={error?.confirmPassword}
                    value={confirmPassword}
                    setValue={(val) => onChange(val, "confirmPassword")}
                    label={"CONFIRM PASSWORD"}
                    type={confirmPasswordEyeVisible ? 'text' : 'password'}
                    icon={true}
                    rightIcon={confirmPasswordEyeVisible ? <EyeIcon sx={style.eyeIconStyle}/> : <EyeOffIcon sx={style.eyeIconStyle}/>}
                    onIconClick={onConfirmPasswordEyeIconClick}
                  />
                </Grid>
              </Grid>
              : null}
            <Grid
              item
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection="row"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item md={2} xs={2}>
                <FormButton
                  loading={props.userBtnLoading}
                  btnStyleView={style.btnStyleView}
                  btnType={"btn1"}
                  title={props.type == "add" ? "Submit" : "Update"}
                  onSubmit={onSubmitClick}
                />
              </Grid>
              <Grid ml={{md: 2, xs: 4}} item md={2} xs={2}>
                <FormButton
                  btnStyleView={style.btnStyleView}
                  btnType={"btn2"}
                  title={"Cancel"}
                  onSubmit={onCancelClick}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data) => dispatch(addUser(data)),
    updateUser: (data) => dispatch(updateUser(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    userBtnLoading: state.userData.userBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditUserModal);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
  eyeIconStyle:{
    color: '#888',
    height: 15,
    width: 15
  }
};
