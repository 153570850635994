import React, { useEffect, useState } from "react";
import {
  Popover,
  Box,
  MenuItem,
  MenuList,
  Typography,
  Grid,
} from "@mui/material";

import styles from "../containers/Invoice/Invoice.module.css";

import FormTextInput from "./FormTextInput";

import CloseIcon from "@mui/icons-material/CloseOutlined";

const AddEditInvoiceItem = (props) => {
  const onChange = (val, type) => {
    props.onChange(props.item, val, type);
  };

  const onRemoveItemClick = (data) => {
    props.onRemove(data);
  };

  let data = props?.item;

  return (
    <Grid item md={12} xs={12} mt={2}>
      <Grid
        item
        md={12}
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        mb={1}
      >
        <Grid item md={3.5} xs={3.5}>
          <Typography className={styles.itemPriceFieldTitleText}>
            Item
          </Typography>
        </Grid>
        <Grid item md={2} xs={2}>
          <Typography className={styles.itemPriceFieldTitleText}>
            Quantity
          </Typography>
        </Grid>

        <Grid item md={2} xs={2} pl={2}>
          <Typography className={styles.itemPriceFieldTitleText}>
            Price
          </Typography>
        </Grid>

        <Grid item md={2} xs={2} pl={2}>
          <Typography className={styles.itemPriceFieldTitleText}>
            Discount
          </Typography>
        </Grid>

        <Grid item md={2} xs={2} pl={2}>
          <Typography className={styles.itemPriceFieldTitleText}>
            GST(%)
          </Typography>
        </Grid>

        <Grid item md={2} xs={2} pl={2}>
          <Typography className={styles.itemPriceFieldTitleText}>
            SGST
          </Typography>
        </Grid>

        <Grid item md={2} xs={2}>
          <Typography className={styles.itemPriceFieldTitleText}>
            CGST
          </Typography>
        </Grid>

        <Grid item md={2} xs={2}>
          <Typography className={styles.itemPriceFieldTitleText}>
            Total
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        md={12}
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        className={styles.itemPriceFieldMainView}
      >
        <Grid
          p={2}
          item
          md={12}
          xs={12}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item md={3.5} xs={3.5}>
            <FormTextInput
              page={"Invoice"}
              error={props.item?.error?.item}
              value={props.item?.item}
              setValue={(val) => onChange(val, "item")}
            />
          </Grid>
          <Grid item md={2} xs={2} pl={2}>
            <FormTextInput
              page={"Invoice"}
              error={props.item?.error?.quantity}
              value={props.item?.quantity}
              setValue={(val) => onChange(val, "quantity")}
            />
          </Grid>
          <Grid item md={2} xs={2} pl={2}>
            <FormTextInput
              page={"Invoice"}
              error={props.item?.error?.price}
              value={props.item?.price}
              setValue={(val) => onChange(val, "price")}
            />
          </Grid>
          <Grid item md={2} xs={2} pl={2}>
            <FormTextInput
              page={"Invoice"}
              error={props.item?.error?.discount}
              value={props.item?.discount}
              setValue={(val) => onChange(val, "discount")}
            />
          </Grid>
          <Grid item md={2} xs={2} pl={2}>
            <FormTextInput
              page={"Invoice"}
              error={props.item?.error?.gst}
              value={props.item?.gst}
              setValue={(val) => onChange(val, "gst")}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography className={styles.totalPrice}>
             {props.item?.gst ? (props.item?.gst / 2) : 0}%
            </Typography>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography className={styles.totalPrice}>
              {props.item?.gst ? (props.item?.gst / 2) : 0}%
            </Typography>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography className={styles.totalPrice}>
              ₹
              {props.item?.price
                ? (
                  Number(props.item?.quantity) * Number(props.item?.discount > 0 ? (parseFloat(props.item.price) - parseFloat(props.item.discount)) : props.item.price)
                ).toFixed(2)
                : 0}
            </Typography>
          </Grid>
        </Grid>

        <Grid className={styles.borderLine} />

        <Grid
          item
          md={0.4}
          xs={0.4}
          display={"flex"}
          justifyContent={"center"}
          className={styles.iconView}
          pt={1}
        >
          <Box onClick={() => onRemoveItemClick(data)}>
            <CloseIcon sx={{ color: "#677788", height: 20, width: 20 }} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddEditInvoiceItem;
