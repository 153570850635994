import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  Grow,
} from "@mui/material";

import FormButton from "./FormButton";

import styles from "./PopUpAlertBox.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      {...props}
      style={
        {
          // transformOrigin: '0 1'
        }
      }
      {...(true ? { timeout: 500 } : {})}
    />
  );
});

const PopUpAlertBox = (props) => {

  const onSubmitClick = () => {
    props.onSubmitClick();
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={props?.popUpBox}
      sx={style.mainBoxView}
    >
      <Box sx={{ width: "80%" }}>
        <DialogContent>
          <Box display={"flex"} justifyContent={"center"}>
            {props?.icon}
          </Box>
          <Box mt={3}>
            <Typography className={styles.contentText}>
              {props?.content}
            </Typography>
          </Box>

        </DialogContent>
        <Grid
          item
          md={12}
          xs={12}
          container
          mt={{ md: 2, xs: 1 }}
          mb={3}
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          justifyContent="center"
        >
          <FormButton
            btnStyleView={style.btnStyleView}
            loading={props?.btnLoading}
            title={props?.btn}
            btnType={"btn1"}
            onSubmit={onSubmitClick}
          />
        </Grid>
      </Box>
    </Dialog>
  );
};

export default PopUpAlertBox;

const style = {
  mainBoxView: {
    "& .MuiDialog-paper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 1,
      width: { md: "35%", xs: "90%" },
      minHeight: { md: "50%", xs: "38%" },
    },
  },
  btnStyleView: {
    height: 40,
    width: "48%",
  },
};
