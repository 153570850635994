import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import userDataReducer from './userDataReducer';

const persistConfig = {
  timeout: 0,
  key: 'root',
  storage,
  blacklist: ['userData'],
};

const userDataPersistConfig = {
  key: 'userData',
  storage,
  blacklist: [
    'drawerOpen',
    'msg',
    'apiStatus',
    'apiData',
    'enquiryDetail',
    'loginBtnLoading',
    'registerBtnLoading',
    'patientBtnLoading',
    'consultationNoteBtnLoading',
    'appointmentBtnLoading',
    'appointmentInvoiceBtnLoading',
    'doctorBtnLoading',
    'userBtnLoading',
    'productBtnLoading',
    'productCategoryBtnLoading',
    'enquiryByMobileBtnLoading',
    'enquiryBtnLoading',
    'enquiryNoteBtnLoading',
    'labelBtnLoading',
    'incomeExpenseBtnLoading'
  ],
};


const rootReducer = combineReducers({
  userData: persistReducer(userDataPersistConfig, userDataReducer),
});

export default persistReducer(persistConfig, rootReducer);
