import * as React from 'react';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import {connect} from "react-redux";
import {updateAlertMsg} from "../redux/actions/userDataActions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertMsg = (props) => {

  let msgAlert = props.msg;

  const onCloseClick = () => {
    props.updateAlertMsg({open: false, message: '', msgType: ''});
  };

  if (!msgAlert.open) {
    return null;
  }

  return (
    <Snackbar open={msgAlert.open} autoHideDuration={3000} onClose={() => onCloseClick()}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}>
      <Alert onClose={() => onCloseClick()} severity={msgAlert.msgType} sx={{width: '100%'}}>
        {msgAlert.message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => {
  return {
    msg: state.userData.msg,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAlertMsg: data => dispatch(updateAlertMsg(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertMsg);
