import React, {useEffect, useState, useRef} from "react";
import {
  Grid,
  Typography,
  Modal,
  CircularProgress,
  Box
} from "@mui/material";

import FormButton from "../components/FormButton";
import FormTextInput from "./FormTextInput";

import {connect} from "react-redux";
import {
  getEnquiryByMobile,
  updateApiStatus
} from "../redux/actions/userDataActions";
import {GET_ENQUIRY_BY_MOBILE_SUCCESS} from "../redux/actions/types";

import styles from "./AddEnquiryModal.module.css";

import CloseIcon from '@mui/icons-material/CloseOutlined';

const AddEnquiryModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const mobileRef = useRef(null);

  useEffect(() => {
    if (props.modalVisible) {
      setMobile('');
      setShowDetail(false)
      setVisible(true);
      setLoading(false);
      setError(null);
    }
  }, [props.modalVisible]);

  useEffect(() => {
    if(visible) {
      if(mobileRef?.current) {
        mobileRef?.current?.focus()
      }
    }
  },[visible])

  useEffect(() => {
    if (props.apiStatus == GET_ENQUIRY_BY_MOBILE_SUCCESS) {
      if (mobile != "") {
        if (props.enquiryByMobileList.length > 0) {
          setShowDetail(true)
        } else {
          props.onSubmit(mobile);
        }
      }
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  const onSearchClick = (val) => {
    let errorText = {};
    if (val == "") {
      errorText = {mobile: "Please enter mobile"};
    } else {
      if (!/^[+]*[ 0-9]+$/.test(val)) {
        errorText = {...errorText, mobile: "Invalid mobile"};
      }
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      mobile: val
    };

    props.getEnquiryByMobile(JSON.stringify(data));
  };

  const onCloseClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onClose();
    }, [100]);
  };

  const onChange = (val, type) => {
    let errorText = error;
    if (type == "mobile") {
      if (val != "") {
        if (!/^[+]*[ 0-9]+$/.test(val)) {
          errorText = {...errorText, mobile: "Invalid mobile"};
        } else {
          errorText = {...error, mobile: ""};
        }
      } else {
        errorText = {...error, mobile: "Please enter mobile"};
      }
      setMobile(val);
    }
    setError(errorText);
  };

  const onAddEnquiry = () => {
    props.onSubmit(mobile);
  };

  return (
    <Modal
      onClose={onCloseClick}
      disableBackdropClick
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        style={{
          height: showDetail ? (props.enquiryByMobileList?.length > 0 ? '80%' : '70%') : '50%'
        }}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{md: 8, xs: 4}}
      >
        <Grid
          onClick={() => onCloseClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle}/>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            Add Enquiry
          </Typography>
        </Grid>

        {loading ? (
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress/>
          </Grid>
        ) : (
          <>
            <Grid item md={12} xs={12} mt={1}>
              <FormTextInput
                page={"AddEnquiryModal"}
                required={true}
                autoFocus={true}
                error={error?.mobile}
                value={mobile}
                setValue={(val) => onChange(val, "mobile")}
                label={"MOBILE"}
                keyDown={true}
                inputRef={mobileRef}
                onEnter={(val) => onSearchClick(val)}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection="row"
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Grid item md={2} xs={2}>
                <FormButton
                  loading={props.enquiryByMobileBtnLoading}
                  btnStyleView={style.btnStyleView}
                  btnType={"btn1"}
                  title={"Search"}
                  onSubmit={() => onSearchClick(mobile)}
                />
              </Grid>
              <Grid ml={{md: 2, xs: 4}} item md={2} xs={2}>
                <FormButton
                  btnStyleView={style.btnStyleView}
                  btnType={"btn2"}
                  title={"Close"}
                  onSubmit={onCloseClick}
                />
              </Grid>
            </Grid>

            {showDetail ?
              props.enquiryByMobileLoading ?
                <Grid
                  item
                  md={12}
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <CircularProgress/>
                </Grid> :
                <Grid item md={12} xs={12} mt={4}>
                  {props.enquiryByMobileList?.length > 0 ?
                    <>
                      <Box className={styles.detailFoundView}>
                        <Typography className={styles.detailFound} mb={2}>Details Found</Typography>
                      </Box>

                      {props.enquiryByMobileList?.map((item, index) => {
                        return (
                          <>
                            <Box key={index}>
                              <Typography className={styles.detailText}>Name: {item?.name}</Typography>
                              <Typography className={styles.detailText}>Mobile: {item?.mobile}</Typography>
                              <Typography className={styles.detailText}>Whatsapp: {item?.whatsapp}</Typography>
                              <Typography className={styles.detailText}>Location: {item?.location}</Typography>
                              <Typography className={styles.detailText}>Status: {item?.status}</Typography>
                            </Box>
                            {props.enquiryByMobileList?.length - 1 == index ? null :
                            <Box className={styles.bottomLineView} mt={1} mb={1}/>}
                          </>
                        )
                      })
                      }
                    </>
                    :
                    <Typography className={styles.detailFound}>No Details Found</Typography>
                  }
                  <Grid container item md={12} xs={12} display={'flex'}
                        alignItems={'center'} justifyContent={'center'} mt={2}>
                    <FormButton
                      btnStyleView={style.enquiryBtnStyleView}
                      btnType={"btn1"}
                      title={props.enquiryByMobileList.length > 0 ? "Ignore and Add New Enquiry" : "Add New Enquiry"}
                      onSubmit={onAddEnquiry}
                    />
                  </Grid>
                </Grid>
              : null}
          </>
        )}
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEnquiryByMobile: (data) => dispatch(getEnquiryByMobile(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    enquiryByMobileList: state.userData.enquiryByMobileList,
    enquiryByMobileBtnLoading: state.userData.enquiryByMobileBtnLoading,
    enquiryByMobileLoading: state.userData.enquiryByMobileLoading,
    apiStatus: state.userData.apiStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEnquiryModal);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
  enquiryBtnStyleView:{
    height: 40,
    width: "50%",
  }
};
