import React from "react";
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import moment from "moment";

import styles from "../components/DashboardUpcomingAppointmentList.module.css";

const DashboardUpcomingAppointmentList = (props) => {
  return (

    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              Upcoming Appointments
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props?.data != null ?
              props?.data?.upcomingAppointmentList?.length > 0 ?
                props?.data?.upcomingAppointmentList?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.first_name && item.last_name ?
                      (item.first_name + ' ' + item.last_name)
                      : (item.first_name)}</TableCell>
                    <TableCell>
                      {moment(item.start_date).format("DD-MM-YYYY") + ' ' + moment(
                        item.start_time,
                        "Hmm"
                      ).format("hh:mm A") + '-' + moment(
                        item.end_time,
                        "Hmm"
                      ).format("hh:mm A")}
                    </TableCell>
                  </TableRow>
                )) :
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <Typography className={styles.noMatchFoundText}>No Appointments found</Typography>
                  </TableCell>
                </TableRow>
              : <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography className={styles.noMatchFoundText}>No Appointments found</Typography>
                </TableCell>
              </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardUpcomingAppointmentList;
