import React, {useEffect, useState} from "react";
import {Grid, Box, Typography, CircularProgress} from "@mui/material";
import moment from "moment";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import TabButtons from "../../components/TabButtons";

import {connect} from "react-redux";
import {
  getDoctorDetail,
  deleteDoctor,
  updateApiStatus,
} from "../../redux/actions/userDataActions";

import AddEditDoctorModal from "../../components/AddEditDoctorModal";
import DoctorReferredPatientList from "../../components/DoctorReferredPatientList";
import FormButton from "../../components/FormButton";
import ConfirmAlertBox from "../../components/ConfirmAlertBox";

import styles from "./DoctorView.module.css";

import UserIcon from '@mui/icons-material/PersonOutlineOutlined';
import ExclaimIcon from '@mui/icons-material/ErrorOutlineOutlined';

import {
  DELETE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_SUCCESS,
} from "../../redux/actions/types";

const doctorTabsList = [
  {
    label: "Patients",
    value: "patients",
    icon: <UserIcon/>,
    iconType: "fill",
  },
];

const DoctorView = (props) => {
  const [doctorUid, setDoctorUid] = useState("");
  const [doctorDetail, setDoctorDetail] = useState(null);
  const [selectedTab, setSelectedTab] = useState("patients");
  const [editDoctorModalVisible, setEditDoctorModalVisible] = useState(false);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let doctor_uid = params.get("id");
    if (doctor_uid) {
      setDoctorUid(doctor_uid);
      let data = {
        doctorUid: doctor_uid,
      };
      props.getDoctorDetail(JSON.stringify(data));
    }
  }, []);

  useEffect(() => {
    setDoctorDetail(props.doctorDetail);
  }, [props.doctorDetail]);

  useEffect(() => {
    if (props.apiStatus == DELETE_DOCTOR_SUCCESS && confirmBoxVisible) {
      setConfirmBoxVisible(false);
      props.updateApiStatus("");
      window.location.href = "doctors";
    }
  }, [props.apiStatus]);

  const onSelectTab = (item) => {
    setSelectedTab(item?.value);
  };

  const onConfirmDelete = () => {
    let data = {
      doctorUid: doctorUid,
    };
    props.deleteDoctor(JSON.stringify(data));
  };

  const onCancelDelete = () => {
    setConfirmBoxVisible(false);
  };

  const onEditDoctorBtnClick = () => {
    setDoctorDetail(JSON.parse(JSON.stringify(props.doctorDetail)));
    setEditDoctorModalVisible(true);
  };

  const onEditDoctor = () => {
    setEditDoctorModalVisible(false);
  };

  const onCancelEditDoctor = () => {
    setEditDoctorModalVisible(false);
  };

  const onDeleteDoctorBtnClick = () => {
    setConfirmBoxVisible(true);
  };

  if (props.doctorDetailLoading || doctorDetail == null) {
    return (
      <Box
        style={{minHeight: window.innerHeight - 140}}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress/>
      </Box>
    );
  }

  return (
    <Container page={"doctors"}>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Grid
          item
          md={4}
          xs={12}
          container
          display={"flex"}
          flexDirection={"column"}
          className={styles.cardView}
          p={3}
        >
          <Grid item md={12} xs={12} pt={2}>
            <Typography className={styles.detailText}>Details</Typography>
          </Grid>

          <Grid item md={12} xs={12} mt={1} mb={3}>
            <Box className={styles.borderView}/>
          </Grid>

          <Grid item md={12} xs={12}>
            {doctorDetail?.name ? (
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>
                  Name:
                </Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {doctorDetail?.name}
                </Typography>
              </Box>
            ) : null}

            <Box
              mb={2}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <Typography className={styles.detailLabelText}>
                Status:
              </Typography>
              <Grid ml={1} className={styles.detailValueStatusView}>
                <Typography className={styles.detailValueStatusText}>
                  {doctorDetail?.status}
                </Typography>
              </Grid>
            </Box>

            {doctorDetail?.mobile ? (
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>Mobile:</Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {doctorDetail.mobile}
                </Typography>
              </Box>
            ) : null}

            {doctorDetail?.hospital ? (
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>
                  Hospital:
                </Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {doctorDetail.hospital}
                </Typography>
              </Box>
            ) : null}

            <Grid
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid md={4} xs={12}>
                <FormButton
                  title={"Edit"}
                  btnType={"btn1"}
                  btnStyleView={style.btnStyleView}
                  onSubmit={onEditDoctorBtnClick}
                />
              </Grid>
              <Grid ml={2} md={4} xs={12}>
                <FormButton
                  title={"Delete"}
                  btnType={"btn4"}
                  btnStyleView={style.btnStyleView}
                  onSubmit={onDeleteDoctorBtnClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          md={7.8}
          xs={12}
          mt={{md: 0, xs: 2}}
          display={"flex"}
          flexDirection={"column"}
        >
          <Grid item md={12} xs={12} display={"flex"} flexDirection={"row"}>
            <TabButtons
              selectedTab={selectedTab}
              data={doctorTabsList}
              onButtonClick={onSelectTab}
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            mt={2}
          >
            {selectedTab == "patients" ? (
              <Box className={styles.cardView} px={3}>
                <DoctorReferredPatientList doctorUid={doctorUid}/>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      {doctorDetail != null ? (
        <AddEditDoctorModal
          type={"edit"}
          data={doctorDetail}
          modalVisible={editDoctorModalVisible}
          onCancel={onCancelEditDoctor}
          onSubmit={onEditDoctor}
        />
      ) : null}

      <ConfirmAlertBox
        confirmBox={confirmBoxVisible}
        icon={<ExclaimIcon sx={{height: 80, width: 80, color: '#ffc700'}}/>}
        title={"Are you sure?"}
        content={"You won't be able to revert this!"}
        btnLoading={props.doctorBtnLoading}
        firstBtn={"Yes, delete it!"}
        secondBtn={"Cancel"}
        onConfirmClick={onConfirmDelete}
        onCancelClick={onCancelDelete}
      />

      <AlertMsg/>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    doctorDetailLoading: state.userData.doctorDetailLoading,
    doctorDetail: state.userData.doctorDetail,
    doctorBtnLoading: state.userData.doctorBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDoctorDetail: (data) => dispatch(getDoctorDetail(data)),
    deleteDoctor: (data) => dispatch(deleteDoctor(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorView);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: '#AAA',
    height: 120,
    width: 120
  }
};
