import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import Container from "../../components/Container";
import Page from "../../components/Page";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import AddEditProductModal from "../../components/AddEditProductModal";
import ToggleSwitch from "../../components/ToggleSwitch";
import UseDebounce from "../../components/UseDebounce";
import ConfirmAlertBox from "../../components/ConfirmAlertBox";
import AlertMsg from "../../components/AlertMsg";
import { MOBILE_VIEW } from "../../components/Sidebar";

import { connect } from "react-redux";
import {
  listProduct,
  deleteProduct,
  updateApiStatus,
  updateProductStatus
} from "../../redux/actions/userDataActions";
import {
  DELETE_PRODUCT_SUCCESS,
} from "../../redux/actions/types";

import styles from "./Products.module.css";

import "../../assets/css/custom.css";

import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PlusIcon from '@mui/icons-material/AddOutlined';
import ExclaimIcon from '@mui/icons-material/ErrorOutlineOutlined';

import {UTC_OFFSET} from "../../constants";

let status_filter = {
  active: { bgColor: "#dff9ec", color: "#39da8a" },
  disabled: { bgColor: "#e7ebef", color: "#69809a" },
};

const Products = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [selectedProductData, setSelectedProductData] = useState(null);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);

  const debouncedSearch = UseDebounce(search, 500);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == DELETE_PRODUCT_SUCCESS && confirmBoxVisible) {
      setSelectedProductData(null);
      setConfirmBoxVisible(false);
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  useEffect(() => {
    setTotalListCount(props.productTotalList);
  }, [props.productTotalList]);

  useEffect(() => {
    setListCount(props.productList?.length);
  }, [props.productList?.length]);

  useEffect(() => {
    getProductList(1);
  }, [debouncedSearch]);

  useEffect(() => {
    getProductList(1);
  }, []);

  const getProductList = (page) => {
    let data = {
      page: page,
      searchProduct: search,
    };
    props.listProduct(JSON.stringify(data));
    setPageNum(page);
  };

  const onAddEditProductBtnClick = (e, val, item) => {
    e.stopPropagation();
    setSelectedProductData(item);
    setType(val);
    setModalVisible(true);
  };

  const onAddEditProduct = () => {
    setSelectedProductData(null);
    setModalVisible(false);
  };

  const onCancelProduct = () => {
    setSelectedProductData(null);
    setModalVisible(false);
  };

  const onSelectPage = (page) => {
    getProductList(page);
  };

  const onSearchProducts = (val) => {
    setSearch(val);
  };

  const onDeleteProductBtnClick = (item) => {
    setSelectedProductData(item);
    setConfirmBoxVisible(true);
  };

  const onConfirmDeleteProduct = () => {
    let data = {
      productUid: selectedProductData?.uid,
    };
    props.deleteProduct(JSON.stringify(data));
  };

  const onCancelDeleteProduct = () => {
    setSelectedProductData(null);
    setConfirmBoxVisible(false);
  };

  const onToggle = (item) => {
    let status_val = item?.status;
    if (status_val == 'active') {
      status_val = 'disabled'
    } else {
      status_val = 'active'
    }
    let data = {
      productUid: item?.uid,
      status: status_val
    };
    props.updateProductStatus(JSON.stringify(data));
  };

  return (
    <Container page={"products"}>
      <Grid container item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={styles.cardView}
        >
          <TableContainer className={styles.tableView}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={8}>
                    <Grid
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"flex-end"}
                      mt={2}
                    >
                      <Grid item md={2} xs={2}>
                        <FormTextInput
                          page={"Products"}
                          value={search}
                          setValue={(val) => onSearchProducts(val)}
                          height={40}
                          width={150}
                          placeholder={"Search.."}
                        />
                      </Grid>
                      <Grid item md={2} xs={2} ml={2}>
                        <FormButton
                          title={
                            MOBILE_VIEW ? (
                              <PlusIcon />
                            ) : (
                              "Add New Product"
                            )
                          }
                          startIcon={
                            MOBILE_VIEW ? null : (
                              <PlusIcon />
                            )
                          }
                          btnStyleView={style.btnStyleView}
                          onSubmit={(e) =>
                            onAddEditProductBtnClick(e, "add", null)
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Added Date</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.productLoading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : props.productList?.length > 0 ? (
                  props.productList?.map((item, index) => (
                    <TableRow
                      key={index}
                      className={styles.tableRowView}
                    >
                      <TableCell>
                        <Box className={styles.nameImgTableCellView}>
                          <img src={item?.image.split(",")[0]} height={30} width={30} className={styles.listImageView}/>
                          {item?.name}
                        </Box>
                      </TableCell>
                      <TableCell>{item?.category}</TableCell>
                      <TableCell>₹{item?.price}</TableCell>
                      <TableCell>₹{item?.discount}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            backgroundColor:
                              status_filter[item.status]["bgColor"],
                          }}
                          className={styles.statusView}>
                          <Typography
                            sx={{ color: status_filter[item.status]["color"] }}
                            className={styles.statusText}>
                            {item.status}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{moment.utc(item?.timestamp).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A")}</TableCell>
                      <TableCell>
                        <ToggleSwitch
                          data={item}
                          checkedVal={item.status == 'active' ? 1 : 0}
                          onToggle={onToggle}/>
                      </TableCell>
                      <TableCell>
                        <Box className={styles.iconMainView}>
                          <Box
                            onClick={(e) =>
                              onAddEditProductBtnClick(e, "edit", item)
                            }
                            className={styles.iconView}
                          >
                            <EditIcon fontSize="small" />
                          </Box>
                          <Box
                            onClick={(e) => onDeleteProductBtnClick(item)}
                            ml={1}
                            className={styles.iconView}
                          >
                            <DeleteIcon fontSize="small" />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography className={styles.noMatchFoundText}>
                        No Products found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={{ md: "row", xs: "column" }}
            alignItems={"center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{ md: "flex-start", xs: "unset" }}
              mt={2}
              mb={{ md: 2, xs: 0 }}
              pl={{ md: 2, xs: 0 }}
            >
              <Typography className={styles.showingEntriesText}>
                Showing{" "}
                {listCount == 0
                  ? 0
                  : pageNum == 1
                    ? 1
                    : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
                to{" "}
                {listCount == 0
                  ? 0
                  : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
                of {totalListCount} entries
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{ md: "flex-end", xs: "unset" }}
              mt={2}
              mb={2}
              pr={{ md: 2, xs: 0 }}
            >
              <Page
                totalPages={props.productTotalPages}
                pageNum={pageNum}
                onSelectPage={onSelectPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AddEditProductModal
        type={type}
        data={selectedProductData}
        modalVisible={modalVisible}
        onCancel={onCancelProduct}
        onSubmit={onAddEditProduct}
      />

      <ConfirmAlertBox
        confirmBox={confirmBoxVisible}
        icon={<ExclaimIcon sx={{height: 80, width: 80, color: '#ffc700'}}/>}
        title={"Are you sure?"}
        content={"You won't be able to revert this!"}
        btnLoading={props.productBtnLoading}
        firstBtn={"Yes, delete it!"}
        secondBtn={"Cancel"}
        onConfirmClick={onConfirmDeleteProduct}
        onCancelClick={onCancelDeleteProduct}
      />

      <AlertMsg />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    productTotalPages: state.userData.productTotalPages,
    productTotalList: state.userData.productTotalList,
    productList: state.userData.productList,
    productLoading: state.userData.productLoading,
    productBtnLoading: state.userData.productBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listProduct: (data) => dispatch(listProduct(data)),
    deleteProduct: (data) => dispatch(deleteProduct(data)),
    updateProductStatus: (data) => dispatch(updateProductStatus(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
};
