import React, {useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";

import FormTextInput from "../../components/FormTextInput";
import FormButton from "../../components/FormButton";
import AlertMsg from "../../components/AlertMsg";

import {connect} from "react-redux";
import {registerAdmin} from "../../redux/actions/userDataActions";

import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EyeOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import {ReactComponent as Checkbox} from "../../assets/img/checkbox.svg";
import {ReactComponent as UnCheckbox} from "../../assets/img/uncheckbox.svg";
import {ReactComponent as LogoIcon} from "../../assets/img/logo.svg";

import AuthRightBottomImg from '../../assets/img/auth_right_bottom.png';
import AuthTopLeftImg from '../../assets/img/auth_top_left.png';

import styles from "./Register.module.css"

const Register = (props) => {
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordEyeVisible, setPasswordEyeVisible] = useState(false);
  const [error, setError] = useState(null);
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();

  const onSignUp = () => {
    let errorText = {};
    if (userName == '') {
      errorText = {userName: 'Please enter username'};
    } else {
      if (userName.length < 6) {
        errorText = {userName: 'Username must contain atleast 6 characters'};
      }
    }
    if (userEmail == '') {
      errorText = {...errorText, userEmail: 'Please enter your email'};
    } else {
      if (!/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$/.test(userEmail)) {
        errorText = {...errorText, userEmail: 'Please enter valid email address'};
      }
    }
    if (password == '') {
      errorText = {...errorText, password: 'Please enter your password'};
    } else {
      if (password.length < 6) {
        errorText = {...errorText, password: 'Password must contain atleast 6 characters'};
      }
    }
    if (!checked) {
      errorText = {...errorText, terms: 'Please agree terms & conditions'};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      username: userName,
      email: userEmail,
      password: password
    };
    props.registerAdmin(JSON.stringify(data))

  };

  const onPasswordEyeIconClick = () => {
    if (passwordEyeVisible) {
      setPasswordEyeVisible(false)
    } else {
      setPasswordEyeVisible(true)
    }
  };

  const onSignInClick = () => {
    navigate("/login");
  };

  const onChange = (val, type) => {
    let errorText = error;
    if (type == 'username') {
      if (val != '') {
        if (val.length < 6) {
          errorText = {...error, userName: 'Username must contain atleast 6 characters'};
        } else {
          errorText = {...error, userName: ''};
        }
      } else {
        errorText = {...error, userName: 'Please enter username'};
      }
      setUserName(val)
    } else if (type == 'email') {
      if (val != '') {
        if (!/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$/.test(val)) {
          errorText = {...error, userEmail: 'Please enter valid email address'};
        } else {
          errorText = {...error, userEmail: ''};
        }
      } else {
        errorText = {...error, userEmail: 'Please enter your email'};
      }
      setUserEmail(val)
    } else if (type == 'password') {
      if (val != '') {
        if (val.length < 6) {
          errorText = {...error, password: 'Password must contain atleast 6 characters'};
        } else {
          errorText = {...error, password: ''};
        }
      } else {
        errorText = {...error, password: 'Please enter your password'};
      }
      setPassword(val)
    }
    setError(errorText);
  };

  const onTermsCheck = () => {
    let errorText = error;
    if (!checked) {
      errorText = {...error, terms: ''};
      setError(errorText);
    }
    setChecked(!checked)
  };

  return (
    <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"}
          alignItems={"center"} justifyContent={"center"} className={styles.cardMainView}>


      <Grid item md={2} xs={12}>
        <Box className={styles.boxLeftView}>
          <img alt="" src={AuthTopLeftImg} className={styles.imgStyle}/>
        </Box>
      </Grid>


      <Grid item md={8} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Grid className={styles.cardView} sx={{
          minHeight: window.innerHeight - 200,
          width: {md: '40%', sm: '85%', xs: '85%'}
        }}>
          <Box className={styles.logoView}>
            <LogoIcon height={80}/>
          </Box>
          <Typography className={styles.subTitleText} mt={1}>Adventure starts here 🚀</Typography>
          <Typography className={styles.contentText} mt={0.5}>Make your app management easy and fun!</Typography>

          <Grid container item md={12} xs={12} mt={4}>
            <FormTextInput
              page={'Register'}
              error={error?.userName}
              value={userName}
              setValue={(val) => onChange(val, 'username')}
              label={'USERNAME'}
              placeholder={'Enter your username'}
            />
          </Grid>

          <Grid container item md={12} xs={12} mt={2.5}>
            <FormTextInput
              page={'Register'}
              error={error?.userEmail}
              value={userEmail}
              setValue={(val) => onChange(val, 'email')}
              label={'EMAIL'}
              type={"email"}
              placeholder={'Enter your email'}
            />
          </Grid>

          <Grid container item md={12} xs={12} mt={2.5}>
            <FormTextInput
              page={'Register'}
              error={error?.password}
              value={password}
              setValue={(val) => onChange(val, 'password')}
              label={'PASSWORD'}
              placeholder={'..........'}
              type={passwordEyeVisible ? 'text' : 'password'}
              icon={true}
              rightIcon={passwordEyeVisible ? <EyeIcon sx={style.eyeIconStyle}/> : <EyeOffIcon sx={style.eyeIconStyle}/>}
              onIconClick={onPasswordEyeIconClick}
            />
          </Grid>

          <Box
            className={styles.policyTermsView}
            display={"flex"} flexDirection={"row"}
            alignItems={"center"}
            mt={2}>
            <Box display={"flex"} flexDirection={"row"}
                 alignItems={"center"}
                 onClick={() => onTermsCheck()}>
              {checked ?
                <Checkbox height={20} width={20}/> :
                <UnCheckbox height={20} width={20}/>}
              <Typography className={styles.agreeText} ml={0.5}>I agree to</Typography>
            </Box>
            <Box>
              <Typography className={styles.policyTermsText}>privacy policy & terms</Typography>
            </Box>
          </Box>
          {error?.terms ?
            <Box mt={0.7} className={styles.errorView}>
              <Typography className={styles.errorText}>{error?.terms}</Typography>
            </Box>
            : null}

          <Grid container
                item md={12} xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                mt={4}>
            <FormButton
              loading={props.registerBtnLoading}
              title={'Sign up'}
              btnStyleView={style.btnStyleView}
              onSubmit={onSignUp}
            />
          </Grid>

          <Grid className={styles.accountMainView}>
            <Typography className={styles.alreadyAccountText}>Already have an account?</Typography>
            <Box
              onClick={() => onSignInClick()}
              className={styles.signInView}>
              <Typography className={styles.signInText}>Sign in instead</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={2} xs={12}>
        <Box className={styles.boxRightView}>
          <img alt="" src={AuthRightBottomImg} className={styles.imgStyle}/>
        </Box>
      </Grid>


      <AlertMsg/>

    </Grid>
  )
};

const mapStateToProps = (state) => {
  return {
    registerBtnLoading: state.userData.registerBtnLoading,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    registerAdmin: data => dispatch(registerAdmin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);

const style = {
  btnStyleView: {
    height: 40,
    width: '100%'
  },
  eyeIconStyle:{
    color: '#888',
    height: 15,
    width: 15
  }
}
