import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import Container from "../../components/Container";
import Page from "../../components/Page";
import FormTextInput from "../../components/FormTextInput";
import UseDebounce from "../../components/UseDebounce";
import AlertMsg from "../../components/AlertMsg";

import { connect } from "react-redux";

import {
  listProductEnquiry,
} from "../../redux/actions/userDataActions";

import styles from "./ProductEnquiry.module.css";

import "../../assets/css/custom.css";

import {UTC_OFFSET} from "../../constants";

const ProductEnquiry = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState("");
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);

  const debouncedSearch = UseDebounce(search, 500);

  const navigate = useNavigate();

  useEffect(() => {
    setTotalListCount(props.productEnquiryTotalList);
  }, [props.productEnquiryTotalList]);

  useEffect(() => {
    setListCount(props.productEnquiryList?.length);
  }, [props.productEnquiryList?.length]);

  useEffect(() => {
    getProductEnquiryList(1);
  }, [debouncedSearch]);

  useEffect(() => {
    getProductEnquiryList(1);
  }, []);

  const getProductEnquiryList = (page) => {
    let data = {
      page: page,
      searchProductEnquiry: search,
    };
    props.listProductEnquiry(JSON.stringify(data));
    setPageNum(page);
  };

  const onSelectPage = (page) => {
    getProductEnquiryList(page);
  };

  const onSearchProductEnquiry = (val) => {
    setSearch(val);
  };

  return (
    <Container page={"product_enquiry"}>
      <Grid container item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={styles.cardView}
        >
          <TableContainer className={styles.tableView}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={8}>
                    <Grid
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"flex-end"}
                      mt={2}
                    >
                      <Grid item md={2} xs={2}>
                        <FormTextInput
                          page={"ProductEnquiry"}
                          value={search}
                          setValue={(val) => onSearchProductEnquiry(val)}
                          height={40}
                          width={150}
                          placeholder={"Search.."}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Whatsapp</TableCell>
                  <TableCell>City/District</TableCell>
                  <TableCell>Product For</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Added Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.productEnquiryLoading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : props.productEnquiryList?.length > 0 ? (
                  props.productEnquiryList?.map((item, index) => (
                    <TableRow
                      key={index}
                      className={styles.tableRowView}
                    >
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>{item?.product_name}</TableCell>
                      <TableCell>{item?.whatsapp}</TableCell>
                      <TableCell>{item?.city_district}</TableCell>
                      <TableCell>{item?.product_for}</TableCell>
                      <TableCell>{item?.message}</TableCell>
                      <TableCell>
                        <Box className={styles.statusView}>
                          <Typography className={styles.statusText}>
                            {item?.status}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{moment.utc(item?.timestamp).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A")}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography className={styles.noMatchFoundText}>
                        No Enquiries found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={{ md: "row", xs: "column" }}
            alignItems={"center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{ md: "flex-start", xs: "unset" }}
              mt={2}
              mb={{ md: 2, xs: 0 }}
              pl={{ md: 2, xs: 0 }}
            >
              <Typography className={styles.showingEntriesText}>
                Showing{" "}
                {listCount == 0
                  ? 0
                  : pageNum == 1
                    ? 1
                    : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
                to{" "}
                {listCount == 0
                  ? 0
                  : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
                of {totalListCount} entries
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{ md: "flex-end", xs: "unset" }}
              mt={2}
              mb={2}
              pr={{ md: 2, xs: 0 }}
            >
              <Page
                totalPages={props.productEnquiryTotalPages}
                pageNum={pageNum}
                onSelectPage={onSelectPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AlertMsg />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    productEnquiryTotalPages: state.userData.productEnquiryTotalPages,
    productEnquiryTotalList: state.userData.productEnquiryTotalList,
    productEnquiryList: state.userData.productEnquiryList,
    productEnquiryLoading: state.userData.productEnquiryLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listProductEnquiry: (data) => dispatch(listProductEnquiry(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductEnquiry);
