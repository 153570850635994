import React, { useState } from "react";
import { Grid, Typography, Box, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import styles from "./FormSelectize.module.css";

const filter = createFilterOptions();

const FormSelectize = (props) => {
  return (
    <Grid item md={12} xs={12} container display={"flex"}>
      {props?.label ? (
        <Grid item md={12} xs={12} container>
          <Typography className={styles.labelText}>{props.label}</Typography>
          {props?.required ?
            <Box>
              <Typography className={styles.requiredText} mt={-0.3} pl={0.3}>*</Typography>
            </Box>
            : null}
        </Grid>
      ) : null}
      <Grid
        sx={[
          style.textInputView,
          props?.heightViewStyle ? props.heightViewStyle : style.heightView,
          props?.disabled
            ? style.disabledTextInputBgView
            : style.textInputBgView
        ]}
        className={[props.error ? styles.errorBorderStyle : styles.borderStyle]}
        item
        md={12}
        xs={12}
        container
      >
        <Autocomplete
          multiple={
            typeof props?.multiple !== "undefined" ? props?.multiple : true
          }
          size="small"
          disableClearable={props?.disableClearable ? props.disableClearable : false}
          options={props.options}
          disabled={props?.disabled ? props?.disabled : false}
          loading={props?.loading ? props.loading : false}
          isOptionEqualToValue={(option, value) => option?.uid === value?.uid}
          onChange={(e, val) => {
            if (props.addOption) {
              if (props?.multiple) {
                let list = val.filter((x) => typeof x.inputValue != "undefined");
                if (list.length) {
                  props.onAdd(list[0].inputValue);
                } else {
                  props.onChange(val);
                }
              } else {
                if (typeof val === "string") {
                  setTimeout(() => {
                    props.onAdd(val);
                  });
                } else if (val && val.inputValue) {
                  props.onAdd(val.inputValue);
                } else {
                  props.onChange(val);
                }
              }
            } else {
              props.onChange(val);
            }
          }}
          value={props.value}
          sx={{
            "&.MuiAutocomplete-root": {
              width: "100%",
            },
            "& .MuiInputBase-input, .MuiAutocomplete-popper": {
              fontFamily: "IBMPlexSansRegular",
              fontSize: 14,
            },
          }}
          ListboxProps={{
            sx: {
              fontSize: 14,
              fontFamily: "IBMPlexSansRegular",
            },
          }}
          ChipProps={{
            sx: {
              "& .MuiChip-label": {
                fontSize: 12,
              },
            },
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (props.addOption) {
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`,
                });
              }
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name;
          }}
          renderOption={(props, option) =>
            <li {...props}>
              {option?.color ?
                <Box
                  component="span"
                  className={styles.colorBox}
                  style={{backgroundColor: option.color}}
                />
                : null}
              {option?.difference ? (option.name + option.difference ): option.name}
            </li>}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={props?.inputRef}
              placeholder={props?.placeholder}
              variant={props?.variant ? props?.variant : "standard"}
              disabled={props?.disabled ? props?.disabled : false}
              sx={{
                "& .MuiInput-root": {
                  "&:before, :after, :hover:not(.Mui-disabled):before": {
                    borderBottom: 0,
                  },
                  transition: "0.2s",
                  paddingLeft: 1.5,
                },
                "& .Mui-focused": {
                  border: "1px solid #5a8dee",
                  minHeight: props?.height ? props.height : 36,
                  borderRadius: 1,
                  transition: "0.2s",
                  paddingLeft: 2,
                },
                input: {
                  "&::placeholder": {
                    color: "#CCC",
                    fontSize: 14,
                    fontFamily: "IBMPlexSansRegular",
                    opacity: 1,
                  },
                },
              }}
            />
          )}
        />
      </Grid>
      {props.error ? (
        <Box mt={0.7} className={styles.errorView}>
          <Typography className={styles.errorText}>{props.error}</Typography>
        </Box>
      ) : null}
    </Grid>
  );
};

export default FormSelectize;

const style = {
  textInputView: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFF",
    border: "1px solid #CCC",
    borderRadius: 1,
  },
  heightView: {
    minHeight: 36,
  },
  textInputBgView: {
    backgroundColor: "#FFF",
  },
  disabledTextInputBgView: {
    backgroundColor: "#e9ecee",
  },
  textInputValue: {
    fontFamily: "IBMPlexSansRegular",
    color: "#000",
    fontSize: 14,
    px: 1.5,
  },
};
