import React, {useEffect, useState, useRef} from "react";
import {
  Grid,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";

import FormButton from "../components/FormButton";
import FormTextInput from "./FormTextInput";
import Loader from "./Loader";

import {connect} from "react-redux";
import {
  addLabel,
  updateApiStatus,
  updateLabel,
} from "../redux/actions/userDataActions";
import {
  ADD_LABEL_SUCCESS,
  UPDATE_LABEL_SUCCESS,
} from "../redux/actions/types";

import {capitalizeFirstLetter} from "../assets/functions/common";

import {LABEL_COLOR} from '../constants'

import styles from "./AddEditLabelModal.module.css";

import CloseIcon from '@mui/icons-material/CloseOutlined';

const AddEditLabelModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [labelUid, setLabelUid] = useState("");
  const [name, setName] = useState("");
  const [shortForm, setShortForm] = useState("");
  const [color, setColor] = useState("");
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);

  const nameRef = useRef(null);

  useEffect(() => {
    if (
      (props.apiStatus == ADD_LABEL_SUCCESS ||
        props.apiStatus == UPDATE_LABEL_SUCCESS) &&
      props.modalVisible
    ) {
      props.updateApiStatus("");
      props.onSubmit();
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if (props.data != null) {
      setLabelUid(props.data?.uid);
      setName(props.data?.name);
      setShortForm(props.data?.short_form);
      setColor(props.data?.color);
      setLoading(false);
    } else {
      setLabelUid("");
      setName("");
      setShortForm("");
      setColor(LABEL_COLOR);
      setError(null);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.modalVisible) {
      if (props.type == "add") {
        setLoading(false);
      }
      setVisible(true);
    } else {
      setLabelUid("");
      setName("");
      setShortForm("");
      setColor(LABEL_COLOR);
      setError(null);
    }
  }, [props.modalVisible]);

  useEffect(() => {
    if(visible) {
      if(nameRef?.current) {
        nameRef?.current?.focus()
      }
    }
  },[visible]);

  const onSubmitClick = () => {
    let errorText = {};
    if (name == "") {
      errorText = {name: "Enter label"};
    }

    if (shortForm == "") {
      errorText = {...errorText, shortForm: "Enter short form"};
    }
    if (color == "") {
      errorText = {...errorText, color: "Enter color"};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    onSubmit();
  };

  const onSubmit = () => {
    let data = {
      labelUid: labelUid,
      name: name,
      shortForm: shortForm,
      color: color,
    };

    if (props.type == "add") {
      props.addLabel(JSON.stringify(data));
    } else {
      props.updateLabel(JSON.stringify(data));
    }
  };

  const onCancelClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onCancel();
    }, [100]);
  };

  const onChange = (val, type) => {
    let errorText = error;
    if (type == 'name') {
      if (val != '') {
        errorText = {...error, name: ''};
      } else {
        errorText = {...error, name: 'Enter label'};
      }
      val = capitalizeFirstLetter(val)
      setName(val)
    } else if (type == 'shortForm') {
      if (val != '') {
        errorText = {...error, shortForm: ''};
      } else {
        errorText = {...error, shortForm: 'Enter short form'};
      }
      setShortForm(val)
    } else if (type == 'color') {
      if (val != '') {
        errorText = {...error, color: ''};
      } else {
        errorText = {...error, color: 'Enter color'};
      }
      setColor(val)
    }
    setError(errorText);
  };

  return (
    <Modal
      disableBackdropClick
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
      onClose={onCancelClick}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{md: 8, xs: 4}}
      >
        <Grid
          onClick={() => onCancelClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle}/>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            {props?.type == "add" ? "Add " : "Edit "} Label
          </Typography>
        </Grid>

        {loading ? (
          <Loader />
        ) : (
          <>
            <Grid item md={5.8} xs={12} mt={1}>
              <FormTextInput
                page={"AddEditlabelModal"}
                required={true}
                inputRef={nameRef}
                error={error?.name}
                value={name}
                setValue={(val) => onChange(val, "name")}
                label={"LABEL"}
              />
            </Grid>
            <Grid item md={5.8} xs={12} mt={1}>
              <FormTextInput
                page={"AddEditlabelModal"}
                required={true}
                error={error?.shortForm}
                value={shortForm}
                setValue={(val) => onChange(val, "shortForm")}
                label={"SHORT FORM"}
              />
            </Grid>
            <Grid item md={12} xs={12} mt={1}>
              <FormTextInput
                page={"AddEditlabelModal"}
                required={true}
                error={error?.color}
                value={color}
                setValue={(val) => onChange(val, "color")}
                label={"COLOR"}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection="row"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item md={2} xs={2}>
                <FormButton
                  loading={props.labelBtnLoading}
                  btnStyleView={style.btnStyleView}
                  btnType={"btn1"}
                  title={props.type == "add" ? "Submit" : "Update"}
                  onSubmit={onSubmitClick}
                />
              </Grid>
              <Grid ml={{md: 2, xs: 4}} item md={2} xs={2}>
                <FormButton
                  btnStyleView={style.btnStyleView}
                  btnType={"btn2"}
                  title={"Cancel"}
                  onSubmit={onCancelClick}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addLabel: (data) => dispatch(addLabel(data)),
    updateLabel: (data) => dispatch(updateLabel(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    labelBtnLoading: state.userData.labelBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditLabelModal);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
  eyeIconStyle:{
    color: '#888',
    height: 15,
    width: 15
  }
};
