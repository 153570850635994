import React, {useEffect, useState} from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import Loader from "./Loader";

import styles from "./GlobalSearch.module.css";

import {connect} from "react-redux";
import {globalSearch} from "../redux/actions/userDataActions";

import {UTC_OFFSET} from "../constants";

let status_filter = {
  active: {bgColor: "#dff9ec", color: "#39da8a"},
  completed: {bgColor: "#e5edfc", color: "#5a8dee"},
  cancelled: {bgColor: "#ffe5e5", color: "#ff5b5c"},
};

const GlobalSearch = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    globalSearch();
  }, [props?.search]);

  const globalSearch = () => {
    let data = {
      globalSearch: props?.search,
    };
    props.globalSearch(JSON.stringify(data));
  };

  useEffect(() => {
  }, [props?.globalSearchList])

  const onRowClick = (title, uid) => {
    if (title == 'doctors') {
      navigate("/doctor_view?id=" + uid);
    } else if (title == 'patients' || title == 'consultation notes') {
      navigate("/patient_view?id=" + uid);
    } else if (title == 'invoices') {
      navigate("/invoice/" + uid);
    } else if (title == 'enquiries' || title == 'enquiry notes') {
      window.location.href = "/enquiry/" + uid;
    } else if (title == 'followups' || title == 'followup notes') {
      window.location.href = "/followup/" + uid;
    } else if (title == 'appointments') {
    }
  };

  if (props.globalSearchLoading) {
    return (
      <Loader/>
    );
  }

  return (
    <Grid container item md={12} xs={12}>
      {props?.globalSearchList?.map((item, index) => {
        return (
          <Grid
            item
            md={12}
            xs={12}
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            key={index}
            className={styles.cardView}
            mb={2}
          >
            <TableContainer className={styles.tableView}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell size='small' colSpan={13}>
                      <Grid
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"flex-start"}
                      >
                        <Grid item md={2} xs={2}>
                          {item.title}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableHead>
                  <TableRow>
                    {item?.data?.heading?.map((item1, index1) => {
                      return (
                        item1 != 'uid' ?
                          <TableCell size='small' key={index1}>{item1?.replace("_", " ").toUpperCase()}</TableCell>
                          : null
                      )
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {item?.data?.list?.length > 0 ?
                    item?.data?.list?.map((item2, index2) => {
                      return (
                        <TableRow key={index2}
                                  style={{cursor: 'pointer'}}
                                  onClick={() => onRowClick(item.title, item2?.uid)}>
                          {item?.data?.heading?.map((item3, index3) => {
                            return (
                              item3 != 'uid' ?
                                item3 == 'added_date' ?
                                  <TableCell
                                    size='small'
                                    key={index3}>{moment.utc(item2?.[item3]).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A")}</TableCell>
                                  :
                                  (item3 == 'status' ?
                                      <TableCell
                                        size='small'
                                        key={index3}>
                                        <Box
                                          sx={{
                                            backgroundColor:
                                              status_filter[item2?.[item3]]?.["bgColor"],
                                          }}
                                          className={styles.statusView}
                                        >
                                          <Typography
                                            sx={{color: status_filter[item2?.[item3]]?.["color"]}}
                                            className={styles.statusText}
                                          >
                                            {item2?.[item3]}
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      :
                                      (item3 == 'invoice_date' || item3 == 'received_date' || item3 == 'next_followup') ?
                                        <TableCell
                                          size='small'
                                          key={index3}>
                                          {item2?.[item3] ? moment(item2?.[item3]).format(
                                            "DD-MM-YYYY"
                                          ) : '-'}
                                        </TableCell>
                                        :
                                        <TableCell
                                          size='small'
                                          key={index3}>{item2?.[item3]}</TableCell>
                                  )
                                : null
                            )
                          })}
                        </TableRow>
                      )
                    }) :
                    <TableRow>
                      <TableCell colSpan={13} align="center">
                        <Typography className={styles.noMatchFoundText}>
                          No Data
                        </Typography>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

        )
      })}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    globalSearchList: state.userData.globalSearchList,
    globalSearchLoading: state.userData.globalSearchLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    globalSearch: (data) => dispatch(globalSearch(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
