import { put, takeLatest, call } from "redux-saga/effects";
import moment from 'moment';

import {
  LOGIN_ADMIN,
  REGISTER_ADMIN,
  ADD_PATIENT,
  LIST_PATIENT,
  UPDATE_PATIENT,
  DELETE_PATIENT,
  GET_PATIENT_DETAIL,
  ADD_CONSULTATION_NOTE,
  LIST_CONSULTATION_NOTE,
  UPDATE_CONSULTATION_NOTE,
  DELETE_CONSULTATION_NOTE,
  GET_ALL_PATIENT,
  ADD_APPOINTMENT,
  GET_APPOINTMENT,
  UPDATE_APPOINTMENT,
  LIST_APPOINTMENT,
  UPDATE_APPOINTMENT_STATUS,
  GET_APPOINTMENT_INVOICE,
  ADD_APPOINTMENT_INVOICE,
  UPDATE_APPOINTMENT_INVOICE,
  DELETE_APPOINTMENT_INVOICE,
  ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD,
  GET_DASHBOARD,
  ADD_DOCTOR,
  UPDATE_DOCTOR,
  DELETE_DOCTOR,
  LIST_DOCTOR,
  GET_ALL_DOCTOR,
  GET_DOCTOR_DETAIL,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  LIST_USER,
  UPDATE_USER_PASSWORD,
  LIST_INVOICE,
  GET_INVOICE,
  ADD_INVOICE,
  GET_PATIENT_INVOICE,
  GET_PATIENT_INVOICE_LIST,
  UPDATE_INVOICE_APPOINTMENT,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  LIST_PRODUCT,
  DELETE_PRODUCT,
  GET_ALL_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_STATUS,
  ADD_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  LIST_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  LIST_PRODUCT_ENQUIRY,
  GET_ENQUIRY_BY_MOBILE,
  ADD_ENQUIRY,
  UPDATE_ENQUIRY,
  LIST_ENQUIRY,
  GET_ENQUIRY_DETAIL,
  DELETE_ENQUIRY_NOTE,
  UPDATE_ENQUIRY_NOTE,
  LIST_ENQUIRY_NOTE,
  ADD_ENQUIRY_NOTE,
  ADD_LABEL,
  UPDATE_LABEL,
  LIST_LABEL,
  DELETE_LABEL,
  MOVE_TO_FOLLOWUP,
  ADD_INCOME_EXPENSE,
  UPDATE_INCOME_EXPENSE,
  DELETE_INCOME_EXPENSE,
  LIST_INCOME_EXPENSE,
  GLOBAL_SEARCH
} from "../actions/types";
import {
  loginAdminSuccess,
  loginAdminFail,
  registerAdminSuccess,
  registerAdminFail,
  addPatientSuccess,
  addPatientFail,
  listPatientSuccess,
  listPatientFail,
  updatePatientSuccess,
  updatePatientFail,
  deletePatientSuccess,
  deletePatientFail,
  getPatientDetailSuccess,
  getPatientDetailFail,
  addConsultationNoteSuccess,
  addConsultationNoteFail,
  listConsultationNoteSuccess,
  listConsultationNoteFail,
  updateConsultationNoteSuccess,
  updateConsultationNoteFail,
  deleteConsultationNoteSuccess,
  deleteConsultationNoteFail,
  getAllPatientSuccess,
  getAllPatientFail,
  addAppointmentSuccess,
  addAppointmentFail,
  getAppointmentSuccess,
  getAppointmentFail,
  updateAppointmentSuccess,
  updateAppointmentFail,
  listAppointmentSuccess,
  listAppointmentFail,
  updateAppointmentStatusSuccess,
  updateAppointmentStatusFail,
  getAppointmentInvoiceSuccess,
  getAppointmentInvoiceFail,
  addAppointmentInvoiceSuccess,
  addAppointmentInvoiceFail,
  updateAppointmentInvoiceSuccess,
  updateAppointmentInvoiceFail,
  deleteAppointmentInvoiceSuccess,
  deleteAppointmentInvoiceFail,
  addAppointmentInvoicePaymentMethodSuccess,
  addAppointmentInvoicePaymentMethodFail,
  getDashboardSuccess,
  getDashboardFail,
  addDoctorSuccess,
  addDoctorFail,
  updateDoctorSuccess,
  updateDoctorFail,
  deleteDoctorSuccess,
  deleteDoctorFail,
  listDoctorSuccess,
  listDoctorFail,
  getAllDoctorSuccess,
  getAllDoctorFail,
  getDoctorDetailSuccess,
  getDoctorDetailFail,
  addUserSuccess,
  addUserFail,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  listUserSuccess,
  listUserFail,
  updateUserPasswordSuccess,
  updateUserPasswordFail,
  listInvoiceSuccess,
  listInvoiceFail,
  getInvoiceSuccess,
  getInvoiceFail,
  addInvoiceSuccess,
  addInvoiceFail,
  getPatientInvoiceSuccess,
  getPatientInvoiceFail,
  getPatientInvoiceListSuccess,
  getPatientInvoiceListFail,
  updateInvoiceAppointmentSuccess,
  updateInvoiceAppointmentFail,
  addProductSuccess,
  addProductFail,
  updateProductSuccess,
  updateProductFail,
  listProductSuccess,
  listProductFail,
  deleteProductSuccess,
  deleteProductFail,
  getAllProductCategorySuccess,
  getAllProductCategoryFail,
  updateProductStatusSuccess,
  updateProductStatusFail,
  addProductCategorySuccess,
  addProductCategoryFail,
  updateProductCategorySuccess,
  updateProductCategoryFail,
  listProductCategorySuccess,
  listProductCategoryFail,
  deleteProductCategorySuccess,
  deleteProductCategoryFail,
  listProductEnquirySuccess,
  listProductEnquiryFail,
  getEnquiryByMobileSuccess,
  getEnquiryByMobileFail,
  addEnquirySuccess,
  addEnquiryFail,
  updateEnquirySuccess,
  updateEnquiryFail,
  listEnquirySuccess,
  listEnquiryFail,
  getEnquiryDetailSuccess,
  getEnquiryDetailFail,
  addEnquiryNoteSuccess,
  addEnquiryNoteFail,
  listEnquiryNoteSuccess,
  listEnquiryNoteFail,
  updateEnquiryNoteSuccess,
  updateEnquiryNoteFail,
  deleteEnquiryNoteSuccess,
  deleteEnquiryNoteFail,
  addLabelSuccess,
  addLabelFail,
  updateLabelSuccess,
  updateLabelFail,
  listLabelSuccess,
  listLabelFail,
  deleteLabelSuccess,
  deleteLabelFail,
  moveToFollowupSuccess,
  moveToFollowupFail,
  addIncomeExpenseSuccess,
  addIncomeExpenseFail,
  updateIncomeExpenseSuccess,
  updateIncomeExpenseFail,
  deleteIncomeExpenseSuccess,
  deleteIncomeExpenseFail,
  listIncomeExpenseSuccess,
  listIncomeExpenseFail,
  globalSearchSuccess,
  globalSearchFail
} from "../actions/userDataActions";

import * as Api from "../../api";
import {UTC_OFFSET} from "../../constants";

function* onLoginAdmin(data) {
  try {
    const response = yield call(Api.adminLogin, data.payload);
    if (response.status == "Success") {
      yield put(loginAdminSuccess(response));
    } else {
      yield put(loginAdminFail(response));
    }
  } catch (error) {
    yield put(loginAdminFail({ msg: "Failed" }));
  }
}

function* onRegisterAdmin(data) {
  try {
    const response = yield call(Api.adminRegister, data.payload);
    if (response.status == "Success") {
      yield put(registerAdminSuccess(response));
      window.location.href = "login";
    } else {
      yield put(registerAdminFail(response));
    }
  } catch (error) {
    yield put(registerAdminFail({ msg: "Failed" }));
  }
}

function* onAddPatient(data) {
  try {
    const response = yield call(Api.addPatient, data.payload);
    if (response.status == "Success") {
      yield put(addPatientSuccess(response));
    } else {
      yield put(addPatientFail(response));
    }
  } catch (error) {
    yield put(addPatientFail({ msg: "Failed" }));
  }
}

function* onListPatient(data) {
  try {
    const response = yield call(Api.listPatient, data.payload);
    if (response.status == "Success") {
      yield put(listPatientSuccess(response));
    } else {
      yield put(listPatientFail(response));
    }
  } catch (error) {
    yield put(listPatientFail("Failed"));
  }
}

function* onUpdatePatient(data) {
  try {
    const response = yield call(Api.updatePatient, data.payload);
    if (response.status == "Success") {
      yield put(updatePatientSuccess(response));
    } else {
      yield put(updatePatientFail(response));
    }
  } catch (error) {
    yield put(updatePatientFail({ msg: "Failed" }));
  }
}

function* onDeletePatient(data) {
  try {
    const response = yield call(Api.deletePatient, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deletePatientSuccess({ ...response, data: payload_data }));
    } else {
      yield put(deletePatientFail(response));
    }
  } catch (error) {
    yield put(deletePatientFail({ msg: "Failed" }));
  }
}

function* onGetPatientDetail(data) {
  try {
    const response = yield call(Api.getPatientDetail, data.payload);
    if (response.status == "Success") {
      yield put(getPatientDetailSuccess(response));
    } else {
      window.location.href = "patients";
      yield put(getPatientDetailFail(response));
    }
  } catch (error) {
    window.location.href = "patients";
    yield put(getPatientDetailFail({ msg: "Failed" }));
  }
}

function* onAddConsultationNote(data) {
  try {
    const response = yield call(Api.addConsultationNote, data.payload);
    if (response.status == "Success") {
      yield put(addConsultationNoteSuccess(response));
    } else {
      yield put(addConsultationNoteFail(response));
    }
  } catch (error) {
    yield put(addConsultationNoteFail({ msg: "Failed" }));
  }
}

function* onListConsultationNote(data) {
  try {
    const response = yield call(Api.listConsultationNote, data.payload);
    if (response.status == "Success") {
      yield put(listConsultationNoteSuccess(response));
    } else {
      yield put(listConsultationNoteFail(response));
    }
  } catch (error) {
    yield put(listConsultationNoteFail("Failed"));
  }
}

function* onUpdateConsultationNote(data) {
  try {
    const response = yield call(Api.updateConsultationNote, data.payload);
    if (response.status == "Success") {
      yield put(updateConsultationNoteSuccess(response));
    } else {
      yield put(updateConsultationNoteFail(response));
    }
  } catch (error) {
    yield put(updateConsultationNoteFail({ msg: "Failed" }));
  }
}

function* onDeleteConsultationNote(data) {
  try {
    const response = yield call(Api.deleteConsultationNote, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(
        deleteConsultationNoteSuccess({ ...response, data: payload_data })
      );
    } else {
      yield put(deleteConsultationNoteFail(response));
    }
  } catch (error) {
    yield put(deleteConsultationNoteFail({ msg: "Failed" }));
  }
}

function* onGetAllPatient() {
  try {
    const response = yield call(Api.getAllPatient);
    if (response.status == "Success") {
      yield put(getAllPatientSuccess(response));
    } else {
      yield put(getAllPatientFail(response));
    }
  } catch (error) {
    yield put(getAllPatientFail({ msg: "Failed" }));
  }
}

function* onAddAppointment(data) {
  try {
    const response = yield call(Api.addAppointment, data.payload);
    if (response.status == "Success") {
      yield put(addAppointmentSuccess(response));
    } else {
      yield put(addAppointmentFail(response));
    }
  } catch (error) {
    yield put(addAppointmentFail({ msg: "Failed" }));
  }
}

function* onGetAppointment(data) {
  try {
    const response = yield call(Api.getAppointment, data.payload);
    if (response.status == "Success") {
      yield put(getAppointmentSuccess(response));
    } else {
      yield put(getAppointmentFail(response));
    }
  } catch (error) {
    yield put(getAppointmentFail("Failed"));
  }
}

function* onUpdateAppointment(data) {
  try {
    const response = yield call(Api.updateAppointment, data.payload);
    if (response.status == "Success") {
      yield put(updateAppointmentSuccess(response));
    } else {
      yield put(updateAppointmentFail(response));
    }
  } catch (error) {
    yield put(updateAppointmentFail({ msg: "Failed" }));
  }
}

function* onListAppointment(data) {
  try {
    const response = yield call(Api.listAppointment, data.payload);
    if (response.status == "Success") {
      yield put(listAppointmentSuccess(response));
    } else {
      yield put(listAppointmentFail(response));
    }
  } catch (error) {
    yield put(listAppointmentFail("Failed"));
  }
}

function* onUpdateAppointmentStatus(data) {
  try {
    const response = yield call(Api.updateAppointmentStatus, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(
        updateAppointmentStatusSuccess({ ...response, data: payload_data })
      );
    } else {
      yield put(updateAppointmentStatusFail(response));
    }
  } catch (error) {
    yield put(updateAppointmentStatusFail({ msg: "Failed" }));
  }
}

function* onGetAppointmentInvoice(data) {
  try {
    const response = yield call(Api.getAppointmentInvoice, data.payload);
    if (response.status == "Success") {
      yield put(getAppointmentInvoiceSuccess(response));
    } else {
      window.location.href = "appointments";
      yield put(getAppointmentInvoiceFail(response));
    }
  } catch (error) {
    window.location.href = "appointments";
    yield put(getAppointmentInvoiceFail({ msg: "Failed" }));
  }
}

function* onAddAppointmentInvoice(data) {
  try {
    const response = yield call(Api.addAppointmentInvoice, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(
        addAppointmentInvoiceSuccess({
          ...response,
          data: {
            invoice_date: payload_data.invoiceDate,
            invoice_item: payload_data.invoiceItem,
            consultant_detail: payload_data.consultantDetail,
            note: payload_data.note,
            payment_method: payload_data.paymentMethod,
            timestamp: moment.utc(),
            ...response.data,
          },
        })
      );
    } else {
      yield put(addAppointmentInvoiceFail(response));
    }
  } catch (error) {
    yield put(addAppointmentInvoiceFail({ msg: "Failed" }));
  }
}

function* onUpdateAppointmentInvoice(data) {
  try {
    const response = yield call(Api.updateAppointmentInvoice, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(
        updateAppointmentInvoiceSuccess({
          ...response,
          data: {
            invoice_date: payload_data.invoiceDate,
            invoice_item: payload_data.invoiceItem,
            consultant_detail: payload_data.consultantDetail,
            note: payload_data.note,
            payment_method: payload_data.paymentMethod,
          },
        })
      );
    } else {
      yield put(updateAppointmentInvoiceFail(response));
    }
  } catch (error) {
    yield put(updateAppointmentInvoiceFail({ msg: "Failed" }));
  }
}

function* onDeleteAppointmentInvoice(data) {
  try {
    const response = yield call(Api.deleteAppointmentInvoice, data.payload);
    if (response.status == "Success") {
      let invoice_no = create_random_number(6);
      yield put(
        deleteAppointmentInvoiceSuccess({
          ...response,
          data: { invoice_no: invoice_no },
        })
      );
    } else {
      yield put(deleteAppointmentInvoiceFail(response));
    }
  } catch (error) {
    yield put(deleteAppointmentInvoiceFail({ msg: "Failed" }));
  }
}

function* onAddAppointmentInvoicePaymentMethod(data) {
  try {
    const response = yield call(
      Api.addAppointmentInvoicePaymentMethod,
      data.payload
    );
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(
        addAppointmentInvoicePaymentMethodSuccess({
          ...response,
          data: { payment_method: payload_data.paymentMethod },
        })
      );
    } else {
      yield put(addAppointmentInvoicePaymentMethodFail(response));
    }
  } catch (error) {
    yield put(addAppointmentInvoicePaymentMethodFail({ msg: "Failed" }));
  }
}

function* onGetDashboard(data) {
  try {
    const response = yield call(Api.getDashboard, data.payload);
    if (response.status == "Success") {
      yield put(getDashboardSuccess(response));
    } else {
      yield put(getDashboardFail(response));
    }
  } catch (error) {
    yield put(getDashboardFail({ msg: "Failed" }));
  }
}

function* onAddDoctor(data) {
  try {
    const response = yield call(Api.addDoctor, data.payload);
    if (response.status == "Success") {
      yield put(addDoctorSuccess(response));
    } else {
      yield put(addDoctorFail(response));
    }
  } catch (error) {
    yield put(addDoctorFail({ msg: "Failed" }));
  }
}

function* onListDoctor(data) {
  try {
    const response = yield call(Api.listDoctor, data.payload);
    if (response.status == "Success") {
      yield put(listDoctorSuccess(response));
    } else {
      yield put(listDoctorFail(response));
    }
  } catch (error) {
    yield put(listDoctorFail("Failed"));
  }
}

function* onUpdateDoctor(data) {
  try {
    const response = yield call(Api.updateDoctor, data.payload);
    if (response.status == "Success") {
      yield put(updateDoctorSuccess(response));
    } else {
      yield put(updateDoctorFail(response));
    }
  } catch (error) {
    yield put(updateDoctorFail({ msg: "Failed" }));
  }
}

function* onDeleteDoctor(data) {
  try {
    const response = yield call(Api.deleteDoctor, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deleteDoctorSuccess({ ...response, data: payload_data }));
    } else {
      yield put(deleteDoctorFail(response));
    }
  } catch (error) {
    yield put(deleteDoctorFail({ msg: "Failed" }));
  }
}

function* onGetAllDoctor() {
  try {
    const response = yield call(Api.getAllDoctor);
    if (response.status == "Success") {
      yield put(getAllDoctorSuccess(response));
    } else {
      yield put(getAllDoctorFail(response));
    }
  } catch (error) {
    yield put(getAllDoctorFail({ msg: "Failed" }));
  }
}

function* onGetDoctorDetail(data) {
  try {
    const response = yield call(Api.getDoctorDetail, data.payload);
    if (response.status == "Success") {
      yield put(getDoctorDetailSuccess(response));
    } else {
      window.location.href = "doctors";
      yield put(getDoctorDetailFail(response));
    }
  } catch (error) {
    window.location.href = "doctors";
    yield put(getDoctorDetailFail({ msg: "Failed" }));
  }
}

function* onAddUser(data) {
  try {
    const response = yield call(Api.addUser, data.payload);
    if (response.status == "Success") {
      yield put(addUserSuccess(response));
    } else {
      yield put(addUserFail(response));
    }
  } catch (error) {
    yield put(addUserFail({ msg: "Failed" }));
  }
}

function* onListUser(data) {
  try {
    const response = yield call(Api.listUser, data.payload);
    if (response.status == "Success") {
      yield put(listUserSuccess(response));
    } else {
      yield put(listUserFail(response));
    }
  } catch (error) {
    yield put(listUserFail("Failed"));
  }
}

function* onUpdateUser(data) {
  try {
    const response = yield call(Api.updateUser, data.payload);
    if (response.status == "Success") {
      yield put(updateUserSuccess(response));
    } else {
      yield put(updateUserFail(response));
    }
  } catch (error) {
    yield put(updateUserFail({ msg: "Failed" }));
  }
}

function* onDeleteUser(data) {
  try {
    const response = yield call(Api.deleteUser, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deleteUserSuccess({ ...response, data: payload_data }));
    } else {
      yield put(deleteUserFail(response));
    }
  } catch (error) {
    yield put(deleteUserFail({ msg: "Failed" }));
  }
}

function* onUpdateUserPassword(data) {
  try {
    const response = yield call(Api.updateUserPassword, data.payload);
    if (response.status == "Success") {
      yield put(updateUserPasswordSuccess(response));
    } else {
      yield put(updateUserPasswordFail(response));
    }
  } catch (error) {
    yield put(updateUserPasswordFail({ msg: "Failed" }));
  }
}

function* onListInvoice(data) {
  try {
    const response = yield call(Api.listInvoice, data.payload);
    if (response.status == "Success") {
      yield put(listInvoiceSuccess(response));
    } else {
      yield put(listInvoiceFail(response));
    }
  } catch (error) {
    yield put(listInvoiceFail("Failed"));
  }
}

function* onGetInvoice(data) {
  try {
    const response = yield call(Api.getInvoice, data.payload);
    if (response.status == "Success") {
      yield put(getInvoiceSuccess(response));
    } else {
      window.location.href = "invoices";
      yield put(getInvoiceFail(response));
    }
  } catch (error) {
    window.location.href = "invoices";
    yield put(getInvoiceFail("Failed"));
  }
}

function* onAddInvoice(data) {
  try {
    const response = yield call(Api.addInvoice, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(
        addInvoiceSuccess({
          ...response,
          data: {
            invoice_date: payload_data.invoiceDate,
            invoice_item: payload_data.invoiceItem,
            payment_method: payload_data.paymentMethod,
            consultant_detail: payload_data.consultantDetail,
            note: payload_data.note,
            ...response.data,
          },
        })
      );
    } else {
      yield put(addInvoiceFail(response));
    }
  } catch (error) {
    yield put(addInvoiceFail({ msg: "Failed" }));
  }
}

function* onGetPatientInvoice(data) {
  try {
    const response = yield call(Api.getPatientInvoice, data.payload);
    if (response.status == "Success") {
      yield put(getPatientInvoiceSuccess(response));
    } else {
      window.location.href = "invoices";
      yield put(getPatientInvoiceFail(response));
    }
  } catch (error) {
    window.location.href = "invoices";
    yield put(getPatientInvoiceFail({ msg: "Failed" }));
  }
}

function* onGetPatientInvoiceList(data) {
  try {
    const response = yield call(Api.getPatientInvoiceList, data.payload);
    if (response.status == "Success") {
      yield put(getPatientInvoiceListSuccess(response));
    } else {
      yield put(getPatientInvoiceListFail(response));
    }
  } catch (error) {
    yield put(getPatientInvoiceListFail({ msg: "Failed" }));
  }
}

function* onUpdateInvoiceAppointment(data) {
  try {
    const response = yield call(Api.updateInvoiceAppointment, data.payload);
    if (response.status == "Success") {
      yield put(updateInvoiceAppointmentSuccess(response));
    } else {
      yield put(updateInvoiceAppointmentFail(response));
    }
  } catch (error) {
    yield put(updateInvoiceAppointmentFail({ msg: "Failed" }));
  }
}

function* onAddProduct(data) {
  try {
    const response = yield call(Api.addProduct, data.payload);
    if (response.status == "Success") {
      yield put(addProductSuccess(response));
    } else {
      yield put(addProductFail(response));
    }
  } catch (error) {
    yield put(addProductFail({ msg: "Failed" }));
  }
}

function* onListProduct(data) {
  try {
    const response = yield call(Api.listProduct, data.payload);
    if (response.status == "Success") {
      yield put(listProductSuccess(response));
    } else {
      yield put(listProductFail(response));
    }
  } catch (error) {
    yield put(listProductFail("Failed"));
  }
}

function* onDeleteProduct(data) {
  try {
    const response = yield call(Api.deleteProduct, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deleteProductSuccess({ ...response, data: payload_data }));
    } else {
      yield put(deleteProductFail(response));
    }
  } catch (error) {
    yield put(deleteProductFail({ msg: "Failed" }));
  }
}

function* onUpdateProduct(data) {
  try {
    const response = yield call(Api.updateProduct, data.payload);
    if (response.status == "Success") {
      yield put(updateProductSuccess(response));
    } else {
      yield put(updateProductFail(response));
    }
  } catch (error) {
    yield put(updateProductFail({ msg: "Failed" }));
  }
}

function* onGetAllProductCategory() {
  try {
    const response = yield call(Api.getAllProductCategory);
    if (response.status == "Success") {
      yield put(getAllProductCategorySuccess(response));
    } else {
      yield put(getAllProductCategoryFail(response));
    }
  } catch (error) {
    yield put(getAllProductCategoryFail({ msg: "Failed" }));
  }
}

function* onUpdateProductStatus(data) {
  try {
    const response = yield call(Api.updateProductStatus, data.payload);
    if (response.status == "Success") {
      yield put(updateProductStatusSuccess(response));
    } else {
      yield put(updateProductStatusFail(response));
    }
  } catch (error) {
    yield put(updateProductStatusFail({ msg: "Failed" }));
  }
}

function* onAddProductCategory(data) {
  try {
    const response = yield call(Api.addProductCategory, data.payload);
    if (response.status == "Success") {
      yield put(addProductCategorySuccess(response));
    } else {
      yield put(addProductCategoryFail(response));
    }
  } catch (error) {
    yield put(addProductCategoryFail({ msg: "Failed" }));
  }
}

function* onListProductCategory(data) {
  try {
    const response = yield call(Api.listProductCategory, data.payload);
    if (response.status == "Success") {
      yield put(listProductCategorySuccess(response));
    } else {
      yield put(listProductCategoryFail(response));
    }
  } catch (error) {
    yield put(listProductCategoryFail("Failed"));
  }
}

function* onUpdateProductCategory(data) {
  try {
    const response = yield call(Api.updateProductCategory, data.payload);
    if (response.status == "Success") {
      yield put(updateProductCategorySuccess(response));
    } else {
      yield put(updateProductCategoryFail(response));
    }
  } catch (error) {
    yield put(updateProductCategoryFail({ msg: "Failed" }));
  }
}

function* onDeleteProductCategory(data) {
  try {
    const response = yield call(Api.deleteProductCategory, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(
        deleteProductCategorySuccess({ ...response, data: payload_data })
      );
    } else {
      yield put(deleteProductCategoryFail(response));
    }
  } catch (error) {
    yield put(deleteProductCategoryFail({ msg: "Failed" }));
  }
}

function* onListProductEnquiry(data) {
  try {
    const response = yield call(Api.listProductEnquiry, data.payload);
    if (response.status == "Success") {
      yield put(listProductEnquirySuccess(response));
    } else {
      yield put(listProductEnquiryFail(response));
    }
  } catch (error) {
    yield put(listProductEnquiryFail("Failed"));
  }
}

function* onGetEnquiryByMobile(data) {
  try {
    const response = yield call(Api.getEnquiryByMobile, data.payload);
    if (response.status == "Success") {
      yield put(getEnquiryByMobileSuccess(response));
    } else {
      yield put(getEnquiryByMobileFail(response));
    }
  } catch (error) {
    yield put(getEnquiryByMobileFail("Failed"));
  }
}

function* onAddEnquiry(data) {
  try {
    const response = yield call(Api.addEnquiry, data.payload);
    if (response.status == "Success") {
      yield put(addEnquirySuccess(response));
      window.location.href = "enquiry";
    } else {
      yield put(addEnquiryFail(response));
    }
  } catch (error) {
    yield put(addEnquiryFail({ msg: "Failed" }));
  }
}

function* onListEnquiry(data) {
  try {
    const response = yield call(Api.listEnquiry, data.payload);
    if (response.status == "Success") {
      yield put(listEnquirySuccess(response));
    } else {
      yield put(listEnquiryFail(response));
    }
  } catch (error) {
    yield put(listEnquiryFail("Failed"));
  }
}

function* onGetEnquiryDetail(data) {
  try {
    const response = yield call(Api.getEnquiryDetail, data.payload);
    if (response.status == "Success") {
      yield put(getEnquiryDetailSuccess(response));
    } else {
      window.location.href = "/enquiry";
      yield put(getEnquiryDetailFail(response));
    }
  } catch (error) {
    window.location.href = "/enquiry";
    yield put(getEnquiryDetailFail({ msg: "Failed" }));
  }
}

function* onUpdateEnquiry(data) {
  try {
    const response = yield call(Api.updateEnquiry, data.payload);
    if (response.status == "Success") {
      yield put(updateEnquirySuccess(response));
      window.history.go(-1);
    } else {
      yield put(updateEnquiryFail(response));
    }
  } catch (error) {
    yield put(updateEnquiryFail({ msg: "Failed" }));
  }
}

function* onAddEnquiryNote(data) {
  try {
    const response = yield call(Api.addEnquiryNote, data.payload);
    if (response.status == "Success") {
      yield put(addEnquiryNoteSuccess(response));
    } else {
      yield put(addEnquiryNoteFail(response));
    }
  } catch (error) {
    yield put(addEnquiryNoteFail({ msg: "Failed" }));
  }
}

function* onListEnquiryNote(data) {
  try {
    const response = yield call(Api.listEnquiryNote, data.payload);
    if (response.status == "Success") {
      yield put(listEnquiryNoteSuccess(response));
    } else {
      yield put(listEnquiryNoteFail(response));
    }
  } catch (error) {
    yield put(listEnquiryNoteFail("Failed"));
  }
}

function* onUpdateEnquiryNote(data) {
  try {
    const response = yield call(Api.updateEnquiryNote, data.payload);
    if (response.status == "Success") {
      yield put(updateEnquiryNoteSuccess(response));
    } else {
      yield put(updateEnquiryNoteFail(response));
    }
  } catch (error) {
    yield put(updateEnquiryNoteFail({ msg: "Failed" }));
  }
}

function* onDeleteEnquiryNote(data) {
  try {
    const response = yield call(Api.deleteEnquiryNote, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deleteEnquiryNoteSuccess({ ...response, data: payload_data }));
    } else {
      yield put(deleteEnquiryNoteFail(response));
    }
  } catch (error) {
    yield put(deleteEnquiryNoteFail({ msg: "Failed" }));
  }
}

function* onAddLabel(data) {
  try {
    const response = yield call(Api.addLabel, data.payload);
    if (response.status == "Success") {
      yield put(addLabelSuccess(response));
    } else {
      yield put(addLabelFail(response));
    }
  } catch (error) {
    yield put(addLabelFail({ msg: "Failed" }));
  }
}

function* onListLabel(data) {
  try {
    const response = yield call(Api.listLabel, data.payload);
    if (response.status == "Success") {
      yield put(listLabelSuccess(response));
    } else {
      yield put(listLabelFail(response));
    }
  } catch (error) {
    yield put(listLabelFail("Failed"));
  }
}

function* onDeleteLabel(data) {
  try {
    const response = yield call(Api.deleteLabel, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deleteLabelSuccess({ ...response, data: payload_data }));
    } else {
      yield put(deleteLabelFail(response));
    }
  } catch (error) {
    yield put(deleteLabelFail({ msg: "Failed" }));
  }
}

function* onUpdateLabel(data) {
  try {
    const response = yield call(Api.updateLabel, data.payload);
    if (response.status == "Success") {
      yield put(updateLabelSuccess(response));
    } else {
      yield put(updateLabelFail(response));
    }
  } catch (error) {
    yield put(updateLabelFail({ msg: "Failed" }));
  }
}

function* onMoveToFollowup(data) {
  try {
    const response = yield call(Api.moveToFollowup, data.payload);
    if (response.status == "Success") {
      yield put(moveToFollowupSuccess(response));
    } else {
      yield put(moveToFollowupFail(response));
    }
  } catch (error) {
    yield put(moveToFollowupFail({ msg: "Failed" }));
  }
}

function* onAddIncomeExpense(data) {
  try {
    const response = yield call(Api.addIncomeExpense, data.payload);
    if (response.status == "Success") {
      yield put(addIncomeExpenseSuccess(response));
    } else {
      yield put(addIncomeExpenseFail(response));
    }
  } catch (error) {
    yield put(addIncomeExpenseFail({ msg: "Failed" }));
  }
}

function* onListIncomeExpense(data) {
  try {
    const response = yield call(Api.listIncomeExpense, data.payload);
    if (response.status == "Success") {
      yield put(listIncomeExpenseSuccess(response));
    } else {
      yield put(listIncomeExpenseFail(response));
    }
  } catch (error) {
    yield put(listIncomeExpenseFail("Failed"));
  }
}

function* onUpdateIncomeExpense(data) {
  try {
    const response = yield call(Api.updateIncomeExpense, data.payload);
    if (response.status == "Success") {
      yield put(updateIncomeExpenseSuccess(response));
    } else {
      yield put(updateIncomeExpenseFail(response));
    }
  } catch (error) {
    yield put(updateIncomeExpenseFail({ msg: "Failed" }));
  }
}

function* onDeleteIncomeExpense(data) {
  try {
    const response = yield call(Api.deleteIncomeExpense, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deleteIncomeExpenseSuccess({ ...response, data: payload_data }));
    } else {
      yield put(deleteIncomeExpenseFail(response));
    }
  } catch (error) {
    yield put(deleteIncomeExpenseFail({ msg: "Failed" }));
  }
}

function* onGlobalSearch(data) {
  try {
    const response = yield call(Api.globalSearch, data.payload);
    if (response.status == "Success") {
      yield put(globalSearchSuccess(response));
    } else {
      yield put(globalSearchFail(response));
    }
  } catch (error) {
    yield put(globalSearchFail("Failed"));
  }
}

const create_random_number = (length) => {
  let result = "";
  let characters = "0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

function* userDataSaga() {
  yield takeLatest(LOGIN_ADMIN, onLoginAdmin);
  yield takeLatest(REGISTER_ADMIN, onRegisterAdmin);
  yield takeLatest(ADD_PATIENT, onAddPatient);
  yield takeLatest(LIST_PATIENT, onListPatient);
  yield takeLatest(UPDATE_PATIENT, onUpdatePatient);
  yield takeLatest(DELETE_PATIENT, onDeletePatient);
  yield takeLatest(GET_PATIENT_DETAIL, onGetPatientDetail);
  yield takeLatest(ADD_CONSULTATION_NOTE, onAddConsultationNote);
  yield takeLatest(LIST_CONSULTATION_NOTE, onListConsultationNote);
  yield takeLatest(UPDATE_CONSULTATION_NOTE, onUpdateConsultationNote);
  yield takeLatest(DELETE_CONSULTATION_NOTE, onDeleteConsultationNote);
  yield takeLatest(GET_ALL_PATIENT, onGetAllPatient);
  yield takeLatest(ADD_APPOINTMENT, onAddAppointment);
  yield takeLatest(GET_APPOINTMENT, onGetAppointment);
  yield takeLatest(UPDATE_APPOINTMENT, onUpdateAppointment);
  yield takeLatest(LIST_APPOINTMENT, onListAppointment);
  yield takeLatest(UPDATE_APPOINTMENT_STATUS, onUpdateAppointmentStatus);
  yield takeLatest(GET_APPOINTMENT_INVOICE, onGetAppointmentInvoice);
  yield takeLatest(ADD_APPOINTMENT_INVOICE, onAddAppointmentInvoice);
  yield takeLatest(UPDATE_APPOINTMENT_INVOICE, onUpdateAppointmentInvoice);
  yield takeLatest(DELETE_APPOINTMENT_INVOICE, onDeleteAppointmentInvoice);
  yield takeLatest(
    ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD,
    onAddAppointmentInvoicePaymentMethod
  );
  yield takeLatest(GET_DASHBOARD, onGetDashboard);
  yield takeLatest(ADD_DOCTOR, onAddDoctor);
  yield takeLatest(UPDATE_DOCTOR, onUpdateDoctor);
  yield takeLatest(DELETE_DOCTOR, onDeleteDoctor);
  yield takeLatest(LIST_DOCTOR, onListDoctor);
  yield takeLatest(GET_ALL_DOCTOR, onGetAllDoctor);
  yield takeLatest(GET_DOCTOR_DETAIL, onGetDoctorDetail);
  yield takeLatest(ADD_USER, onAddUser);
  yield takeLatest(UPDATE_USER, onUpdateUser);
  yield takeLatest(DELETE_USER, onDeleteUser);
  yield takeLatest(LIST_USER, onListUser);
  yield takeLatest(UPDATE_USER_PASSWORD, onUpdateUserPassword);
  yield takeLatest(LIST_INVOICE, onListInvoice);
  yield takeLatest(GET_INVOICE, onGetInvoice);
  yield takeLatest(ADD_INVOICE, onAddInvoice);
  yield takeLatest(GET_PATIENT_INVOICE, onGetPatientInvoice);
  yield takeLatest(GET_PATIENT_INVOICE_LIST, onGetPatientInvoiceList);
  yield takeLatest(UPDATE_INVOICE_APPOINTMENT, onUpdateInvoiceAppointment);
  yield takeLatest(ADD_PRODUCT, onAddProduct);
  yield takeLatest(UPDATE_PRODUCT, onUpdateProduct);
  yield takeLatest(LIST_PRODUCT, onListProduct);
  yield takeLatest(DELETE_PRODUCT, onDeleteProduct);
  yield takeLatest(GET_ALL_PRODUCT_CATEGORY, onGetAllProductCategory);
  yield takeLatest(UPDATE_PRODUCT_STATUS, onUpdateProductStatus);
  yield takeLatest(ADD_PRODUCT_CATEGORY, onAddProductCategory);
  yield takeLatest(UPDATE_PRODUCT_CATEGORY, onUpdateProductCategory);
  yield takeLatest(LIST_PRODUCT_CATEGORY, onListProductCategory);
  yield takeLatest(DELETE_PRODUCT_CATEGORY, onDeleteProductCategory);
  yield takeLatest(LIST_PRODUCT_ENQUIRY, onListProductEnquiry);
  yield takeLatest(GET_ENQUIRY_BY_MOBILE, onGetEnquiryByMobile);
  yield takeLatest(ADD_ENQUIRY, onAddEnquiry);
  yield takeLatest(UPDATE_ENQUIRY, onUpdateEnquiry);
  yield takeLatest(LIST_ENQUIRY, onListEnquiry);
  yield takeLatest(GET_ENQUIRY_DETAIL, onGetEnquiryDetail);
  yield takeLatest(ADD_ENQUIRY_NOTE, onAddEnquiryNote);
  yield takeLatest(LIST_ENQUIRY_NOTE, onListEnquiryNote);
  yield takeLatest(UPDATE_ENQUIRY_NOTE, onUpdateEnquiryNote);
  yield takeLatest(DELETE_ENQUIRY_NOTE, onDeleteEnquiryNote);
  yield takeLatest(ADD_LABEL, onAddLabel);
  yield takeLatest(UPDATE_LABEL, onUpdateLabel);
  yield takeLatest(LIST_LABEL, onListLabel);
  yield takeLatest(DELETE_LABEL, onDeleteLabel);
  yield takeLatest(MOVE_TO_FOLLOWUP, onMoveToFollowup);
  yield takeLatest(LIST_INCOME_EXPENSE, onListIncomeExpense);
  yield takeLatest(ADD_INCOME_EXPENSE, onAddIncomeExpense);
  yield takeLatest(UPDATE_INCOME_EXPENSE, onUpdateIncomeExpense);
  yield takeLatest(DELETE_INCOME_EXPENSE, onDeleteIncomeExpense);
  yield takeLatest(GLOBAL_SEARCH, onGlobalSearch);
}

export default userDataSaga;
