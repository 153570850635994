import React, { useEffect, useState } from "react";
import {Grid, Box, Typography, Chip} from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import TabButtons from "../../components/TabButtons";
import AddEditEnquiryNotes from "../../components/AddEditEnquiryNotes";
import EnquiryNotes from "../../components/EnquiryNotes";

import { connect } from "react-redux";
import {
  addEnquiryNote,
  deleteEnquiryNote,
  getEnquiryDetail,
  moveToFollowup,
  updateApiStatus,
  updateEnquiryNote,
} from "../../redux/actions/userDataActions";
import {
  DELETE_ENQUIRY_NOTE_SUCCESS,
  MOVE_TO_FOLLOWUP_SUCCESS,
  UPDATE_ENQUIRY_NOTE_SUCCESS,
} from "../../redux/actions/types";

import Loader from "../../components/Loader";
import FormButton from "../../components/FormButton";
import ConfirmAlertBox from "../../components/ConfirmAlertBox";
import AddEditPatientModal from "../../components/AddEditPatientModal";
import LabelListHover from "../../components/LabelListHover";

import styles from "./EnquiryDetail.module.css";

import NoteIcon from "@mui/icons-material/NoteAltOutlined";
import ExclaimIcon from "@mui/icons-material/ErrorOutlineOutlined";

const TAB_LIST = [
  {
    label: "Enquiry Notes",
    value: "enquiry_notes",
    icon: <NoteIcon />,
    iconType: "fill",
  },
];

let CONFIRM_DATA = {
  enquiry_note: {
    content: "You won't be able to revert this!",
    firstBtn: "Yes, delete it!",
  },
  move_to_followup: {
    content: "Do you want to move this enquiry to followup?",
    firstBtn: "Yes",
  },
};

const EnquiryDetail = (props) => {
  const [enquiryUid, setEnquiryUid] = useState(
    window.location.pathname.split("/").pop()
  );
  const [enquiryStatus, setEnquiryStatus] = useState(
    window.location.pathname.split("/").length >= 3
      ? window.location.pathname.split("/")[1]
      : "enquiry"
  );
  const [enquiryDetail, setEnquiryDetail] = useState(null);
  const [selectedTab, setSelectedTab] = useState("enquiry_notes");
  const [selectedEnquiryNote, setSelectedEnquiryNote] = useState(null);
  const [selectedEnquiryNoteId, setSelectedEnquiryNoteId] = useState("");
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [patientModalVisible, setPatientModalVisible] = useState(false);

  useEffect(() => {
    let data = {
      enquiryUid: enquiryUid,
      status: enquiryStatus,
    };
    props.getEnquiryDetail(JSON.stringify(data));
  }, []);

  useEffect(() => {
    setEnquiryDetail(props.enquiryDetail);
  }, [props.enquiryDetail]);

  useEffect(() => {
    if (props.apiStatus == DELETE_ENQUIRY_NOTE_SUCCESS && confirmBoxVisible) {
      setSelectedEnquiryNoteId("");
      setDeleteType("");
      setConfirmBoxVisible(false);
      props.updateApiStatus("");
    } else if (props.apiStatus == UPDATE_ENQUIRY_NOTE_SUCCESS) {
      setSelectedEnquiryNote(null);
      props.updateApiStatus("");
    } else if (props.apiStatus == MOVE_TO_FOLLOWUP_SUCCESS) {
      props.updateApiStatus("");
      window.location.href = "/followup";
      setConfirmBoxVisible(false);
    }
  }, [props.apiStatus]);

  const onSelectTab = (item) => {
    setSelectedTab(item.value);
  };

  const onAddEditEnquiryNote = (data) => {
    if (selectedEnquiryNote) {
      props.updateEnquiryNote(JSON.stringify(data));
    } else {
      props.addEnquiryNote(JSON.stringify(data));
    }
  };

  const onEditEnquiryNoteBtnClick = (data) => {
    setSelectedEnquiryNote(data);
  };

  const onCancelEnquiryNote = () => {
    setSelectedEnquiryNote(null);
  };

  const onDeleteEnquiryNoteBtnClick = (data) => {
    setDeleteType("enquiry_note");
    setSelectedEnquiryNoteId(data?.id);
    setConfirmBoxVisible(true);
  };

  const onConfirmDelete = () => {
    if (deleteType == "enquiry_note") {
      let data = {
        enquiryUid: enquiryUid,
        enquiryStatus: enquiryStatus,
        enquiryNoteId: selectedEnquiryNoteId,
      };
      props.deleteEnquiryNote(JSON.stringify(data));
    } else if (deleteType == "enquiry_note") {
      let data = {
        enquiryUid: enquiryUid,
        enquiryStatus: enquiryStatus,
        enquiryNoteId: selectedEnquiryNoteId,
      };
      props.deleteEnquiryNote(JSON.stringify(data));
    } else if (deleteType == "move_to_followup") {
      let data = {
        enquiryUid: props.enquiryDetail?.uid,
      };
      props.moveToFollowup(JSON.stringify(data));
    }
  };

  const onCancelDelete = () => {
    if (deleteType == "enquiry_note") {
      setSelectedEnquiryNoteId("");
    }
    setDeleteType("");
    setConfirmBoxVisible(false);
  };

  const onEditEnquiryBtnClick = () => {
    if (enquiryStatus == "enquiry") {
      window.location.href = "/edit_enquiry/" + enquiryDetail?.uid;
    } else if (enquiryStatus == "followup") {
      window.location.href = "/edit_followup/" + enquiryDetail?.uid;
    }
  };

  const onMoveToFollowup = () => {
    setDeleteType("move_to_followup");
    setConfirmBoxVisible(true);
  };

  const onMoveToPatient = () => {
    let item = props.enquiryDetail;
    let first_name = "";
    let last_name = "";

    if(item?.name) {
      let enquiry_name = item.name;
      const arr = enquiry_name.split(" ");
      if (arr.length >= 2) {
        const name = arr.slice(0, 1).concat(arr.slice(1).join(" "));
        first_name = name[0];
        last_name = name[1];
      } else {
        first_name = enquiry_name;
      }
    }
    setSelectedEnquiry({
      uid: "",
      first_name: first_name,
      last_name: last_name,
      age: "",
      gender: "M",
      address: item?.location,
      mobile: item?.mobile,
      whatsapp: item?.whatsapp,
      email: "",
      enquiry_uid: item?.uid,
    });
    setPatientModalVisible(true);
  };

  const onAddEditPatient = () => {
    setSelectedEnquiry(null);
    setPatientModalVisible(false);
    if (props.enquiryDetail?.patient_uid) {
      window.location.href = "/patients";
    }
  };

  const onCancelPatient = () => {
    setSelectedEnquiry(null);
    setPatientModalVisible(false);
  };

  const onWhatsappClick = (mobile) => {
    let link = 'https://wa.me/'+[mobile]
    window.open(link)
  };

  if (props.enquiryDetailLoading || enquiryDetail == null) {
    return (
      <Container page={"enquiry"}>
        <Loader />
      </Container>
    );
  }

  return (
    <Container page={enquiryStatus}>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Grid
          item
          md={4}
          xs={12}
          container
          display={"flex"}
          flexDirection={"column"}
          className={styles.cardView}
          p={3}
        >
          <Grid item md={12} xs={12} pt={2}>
            <Typography className={styles.detailText}>Details</Typography>
          </Grid>

          <Grid item md={12} xs={12} mt={1} mb={3}>
            <Box className={styles.borderView} />
          </Grid>

          <Box display={'flex'} flexDirection={'row'} mt={-1} mb={1}
               className={styles.labelView}>
            {enquiryDetail.label?.map((val, i) => {
              return (
                <Chip
                  key={i}
                  sx={{
                    backgroundColor: val?.color,
                    mr: 0.5,
                    mb: 0.6,
                  }}
                  className={styles.labelText}
                  label={val?.name}
                  size="small"
                />
              );
            })}
          </Box>

          <Grid item md={12} xs={12}>
            <Box
              mb={2}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <Typography className={styles.detailLabelText}>
                Status:
              </Typography>
              <Grid ml={1} className={styles.detailValueStatusView}>
                <Typography className={styles.detailValueStatusText}>
                  {enquiryDetail?.status}
                </Typography>
              </Grid>
            </Box>

            {enquiryDetail?.name ?
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>Name:</Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {enquiryDetail?.name ? enquiryDetail.name : '-'}
                </Typography>
              </Box>
              : null}

            {enquiryDetail?.location ?
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>
                  Location:
                </Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {enquiryDetail?.location ? enquiryDetail.location : '-'}
                </Typography>
              </Box>
              : null}

            <Box
              mb={2}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <Typography className={styles.detailLabelText}>
                Mobile:
              </Typography>
              <Typography ml={1} className={styles.detailValueText}>
                {enquiryDetail?.mobile}
              </Typography>
            </Box>

            {enquiryDetail?.whatsapp ?
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>
                  Whatsapp:
                </Typography>
                <Typography
                  onClick={(e) => onWhatsappClick(enquiryDetail.whatsapp)}
                  ml={1} className={styles.whatsappNumberText}>
                  {enquiryDetail?.whatsapp ? enquiryDetail.whatsapp : '-'}
                </Typography>
              </Box>
              : null}

            {enquiryDetail?.received_date ?
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>
                  Received Date:
                </Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {enquiryDetail?.received_date ? moment(enquiryDetail.received_date).format("DD-MM-YYYY") : '-'}
                </Typography>
              </Box>
              : null}

            {enquiryDetail?.next_follow_up ?
              <Box
                mb={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography className={styles.detailLabelText}>
                  Next FollowUp:
                </Typography>
                <Typography ml={1} className={styles.detailValueText}>
                  {enquiryDetail?.next_follow_up ? moment(enquiryDetail.next_follow_up).format("DD-MM-YYYY") : '-'}
                </Typography>
              </Box>
              : null}

            <Grid
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid container item xs={12} justifyContent={"space-between"}>
                <Grid item xs={12} md={5.8} mb={1}>
                  <FormButton
                    title={"Edit"}
                    btnType={"btn1"}
                    btnStyleView={style.btnStyleView}
                    onSubmit={onEditEnquiryBtnClick}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mb={1}>
                  {enquiryStatus == "enquiry" ? (
                    <FormButton
                      title={"Move to Followup"}
                      btnStyleView={style.btnStyleView}
                      onSubmit={onMoveToFollowup}
                    />
                  ) : (
                    <FormButton
                      title={"Move to Patient"}
                      btnStyleView={style.btnStyleView}
                      onSubmit={onMoveToPatient}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          md={7.8}
          xs={12}
          mt={{ md: 0, xs: 2 }}
          display={"flex"}
          flexDirection={"column"}
        >
          <Grid item md={12} xs={12} display={"flex"} flexDirection={"row"}>
            <TabButtons
              selectedTab={selectedTab}
              data={TAB_LIST}
              onButtonClick={onSelectTab}
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            mt={2}
          >
            {selectedTab == "enquiry_notes" ? (
              <Box>
                <Box className={styles.cardView} p={3}>
                  <AddEditEnquiryNotes
                    data={selectedEnquiryNote}
                    enquiryUid={enquiryUid}
                    enquiryStatus={enquiryStatus}
                    onAddEdit={onAddEditEnquiryNote}
                    onCancel={onCancelEnquiryNote}
                  />
                </Box>

                <Box className={styles.cardView} p={3} mt={2}>
                  <EnquiryNotes
                    enquiryUid={enquiryUid}
                    enquiryStatus={enquiryStatus}
                    onEdit={onEditEnquiryNoteBtnClick}
                    onDelete={onDeleteEnquiryNoteBtnClick}
                  />
                </Box>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <AddEditPatientModal
        type={"add"}
        data={selectedEnquiry}
        modalVisible={patientModalVisible}
        onCancel={onCancelPatient}
        onSubmit={onAddEditPatient}
      />

      <ConfirmAlertBox
        confirmBox={confirmBoxVisible}
        icon={<ExclaimIcon sx={{ height: 80, width: 80, color: "#ffc700" }} />}
        title={"Are you sure?"}
        content={CONFIRM_DATA?.[deleteType]?.["content"]}
        btnLoading={props.enquiryNoteBtnLoading || props.moveToFollowupLoading}
        firstBtn={CONFIRM_DATA?.[deleteType]?.["firstBtn"]}
        secondBtn={"Cancel"}
        onConfirmClick={onConfirmDelete}
        onCancelClick={onCancelDelete}
      />

      <AlertMsg />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    enquiryDetailLoading: state.userData.enquiryDetailLoading,
    enquiryDetail: state.userData.enquiryDetail,
    enquiryNoteBtnLoading: state.userData.enquiryNoteBtnLoading,
    moveToFollowupLoading: state.userData.moveToFollowupLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEnquiryDetail: (data) => dispatch(getEnquiryDetail(data)),
    addEnquiryNote: (data) => dispatch(addEnquiryNote(data)),
    updateEnquiryNote: (data) => dispatch(updateEnquiryNote(data)),
    deleteEnquiryNote: (data) => dispatch(deleteEnquiryNote(data)),
    moveToFollowup: (data) => dispatch(moveToFollowup(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnquiryDetail);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: "#AAA",
    height: 120,
    width: 120,
  },
};
