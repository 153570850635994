import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";

import FormButton from "../components/FormButton";
import FormTextInput from "./FormTextInput";
import FormTextArea from "./FormTextArea";

import {capitalizeFirstLetter} from "../assets/functions/common";

import { connect } from "react-redux";
import {
  addDoctor,
  updateApiStatus,
  updateDoctor,
} from "../redux/actions/userDataActions";
import {
  ADD_DOCTOR_FAIL,
  ADD_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAIL,
  UPDATE_DOCTOR_SUCCESS,
} from "../redux/actions/types";

import styles from "./AddEditDoctorModal.module.css";

import { THEME_COLOR } from "../constants";

import CloseIcon from '@mui/icons-material/CloseOutlined';

const AddEditDoctorModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [doctorUid, setDoctorUid] = useState("");
  const [name, setName] = useState("");
  const [hospital, setHospital] = useState("");
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);

  const nameRef = useRef(null);

  useEffect(() => {
    if (
      (props.apiStatus == ADD_DOCTOR_SUCCESS ||
        props.apiStatus == UPDATE_DOCTOR_SUCCESS) &&
      props.modalVisible
    ) {
      props.updateApiStatus("");
      props.onSubmit();
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if (props.data != null) {
      setDoctorUid(props.data?.uid);
      setName(props.data?.name);
      setHospital(props.data?.hospital);
      setMobile(props.data?.mobile);
      setLoading(false);
    } else {
      setDoctorUid("");
      setName("");
      setHospital("");
      setMobile("");
      setError(null);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.modalVisible) {
      if (props.type == "add") {
        setLoading(false);
      }
      setVisible(true);
    } else {
      setDoctorUid("");
      setName("");
      setHospital("");
      setMobile("");
      setError(null);
    }
  }, [props.modalVisible]);

  useEffect(() => {
    if(visible) {
      if(nameRef?.current) {
        nameRef?.current?.focus()
      }
    }
  },[visible]);

  const onSubmitClick = () => {
    let errorText = {};
    if (name == "") {
      errorText = { name: "Please enter name" };
    }
    if (mobile == "") {
      errorText = { ...errorText, mobile: "Please enter mobile" };
    } else {
      if (!/^[0-9]{10}$/.test(mobile)) {
        errorText = { ...errorText, mobile: "Invalid mobile" };
      }
    }
    if (hospital == "") {
      errorText = { ...errorText, hospital: "Please enter hospital" };
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    onSubmit();
  };

  const onSubmit = () => {
    let data = {
      doctorUid: doctorUid,
      name: name,
      hospital: hospital,
      mobile: mobile,
    };

    if (props.type == "add") {
      props.addDoctor(JSON.stringify(data));
    } else {
      props.updateDoctor(JSON.stringify(data));
    }
  };

  const onCancelClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onCancel();
    }, [100]);
  };

  const onChange = (val, type) => {
    let errorText = error;
    if (type == "name") {
      if (val != "") {
        errorText = { ...error, name: "" };
      } else {
        errorText = { ...error, name: "Please enter name" };
      }
      val = capitalizeFirstLetter(val)
      setName(val);
    } else if (type == "mobile") {
      if (val != "") {
        if (!/^[0-9]{10}$/.test(val)) {
          errorText = { ...errorText, mobile: "Invalid mobile" };
        } else {
          errorText = { ...error, mobile: "" };
        }
      } else {
        errorText = { ...error, mobile: "Please enter mobile" };
      }
      setMobile(val);
    } else if (type == "hospital") {
      if (val != "") {
        errorText = { ...error, hospital: "" };
      } else {
        errorText = { ...error, hospital: "Please enter hospital" };
      }
      setHospital(val);
    }
    setError(errorText);
  };

  return (
    <Modal
      onClose={onCancelClick}
      disableBackdropClick
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{ md: 8, xs: 4 }}
      >
        <Grid
          onClick={() => onCancelClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle} />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            {props?.type == "add" ? "Add " : "Edit "} Doctor
          </Typography>
        </Grid>

        {loading ? (
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item md={5.8} xs={12} mt={1}>
              <FormTextInput
                page={"AddEditDoctorModal"}
                required={true}
                inputRef={nameRef}
                error={error?.name}
                value={name}
                setValue={(val) => onChange(val, "name")}
                label={"NAME"}
              />
            </Grid>
            <Grid item md={5.8} xs={12} mt={1}>
              <FormTextInput
                page={"AddEditDoctorModal"}
                required={true}
                error={error?.mobile}
                value={mobile}
                setValue={(val) => onChange(val, "mobile")}
                label={"MOBILE"}
              />
            </Grid>
            <Grid item md={12} xs={12} mt={1}>
              <FormTextInput
                page={"AddEditDoctorModal"}
                required={true}
                error={error?.hospital}
                value={hospital}
                setValue={(val) => onChange(val, "hospital")}
                label={"HOSPITAL"}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection="row"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item md={2} xs={2}>
                <FormButton
                  loading={props.doctorBtnLoading}
                  btnStyleView={style.btnStyleView}
                  btnType={"btn1"}
                  title={props.type == "add" ? "Submit" : "Update"}
                  onSubmit={onSubmitClick}
                />
              </Grid>
              <Grid ml={{ md: 2, xs: 4 }} item md={2} xs={2}>
                <FormButton
                  btnStyleView={style.btnStyleView}
                  btnType={"btn2"}
                  title={"Cancel"}
                  onSubmit={onCancelClick}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDoctor: (data) => dispatch(addDoctor(data)),
    updateDoctor: (data) => dispatch(updateDoctor(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    doctorBtnLoading: state.userData.doctorBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditDoctorModal);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
};
