import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import moment from "moment";

import Page from "./Page";

import { connect } from "react-redux";
import { listEnquiryNote } from "../redux/actions/userDataActions";

import styles from "./EnquiryNotes.module.css";

import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";

import {UTC_OFFSET} from "../constants";

const EnquiryNotes = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(15);

  useEffect(() => {
    setTotalListCount(props.enquiryNoteTotalList);
  }, [props.enquiryNoteTotalList]);

  useEffect(() => {
    setListCount(props.enquiryNoteList?.length);
  }, [props.enquiryNoteList?.length]);

  useEffect(() => {
    getEnquiryNoteList(1);
  }, []);

  const getEnquiryNoteList = (page) => {
    let data = {
      page: page,
      limit: pageLimit,
      enquiryUid: props.enquiryUid,
      enquiryStatus: props.enquiryStatus,
    };
    props.listEnquiryNote(JSON.stringify(data));
    setPageNum(page);
  };

  const onSelectPage = (page) => {
    getEnquiryNoteList(page);
  };

  return (
    <Grid
      item
      md={12}
      xs={12}
      container
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Notes</TableCell>
              <TableCell>Added Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.enquiryNoteLoading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : props.enquiryNoteList?.length > 0 ? (
              props.enquiryNoteList?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item?.note}</TableCell>
                  <TableCell>{moment.utc(item?.timestamp).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A")}</TableCell>
                  <TableCell>
                    <Box className={styles.iconMainView}>
                      <Box
                        onClick={() => props.onEdit(item)}
                        className={styles.iconView}
                      >
                        <EditIcon fontSize="small" />
                      </Box>
                      <Box
                        onClick={() => props.onDelete(item)}
                        ml={1}
                        className={styles.iconView}
                      >
                        <DeleteIcon fontSize="small" />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography className={styles.noMatchFoundText}>
                    No Notes found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        item
        md={12}
        xs={12}
        display={"flex"}
        flexDirection={{ md: "row", xs: "column" }}
        alignItems={"center"}
      >
        <Grid
          item
          md={6}
          xs={12}
          display={"flex"}
          justifyContent={{ md: "flex-start", xs: "unset" }}
          mt={2}
          mb={{ md: 2, xs: 0 }}
          pl={{ md: 2, xs: 0 }}
        >
          <Typography className={styles.showingEntriesText}>
            Showing{" "}
            {listCount == 0
              ? 0
              : pageNum == 1
              ? 1
              : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
            to{" "}
            {listCount == 0
              ? 0
              : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
            of {totalListCount} entries
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          display={"flex"}
          justifyContent={{ md: "flex-end", xs: "unset" }}
          mt={2}
          mb={2}
          pr={{ md: 2, xs: 0 }}
        >
          <Page
            totalPages={props.enquiryNoteTotalPages}
            pageNum={pageNum}
            onSelectPage={onSelectPage}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    enquiryNoteList: state.userData.enquiryNoteList,
    enquiryNoteTotalPages: state.userData.enquiryNoteTotalPages,
    enquiryNoteTotalList: state.userData.enquiryNoteTotalList,
    enquiryNoteLoading: state.userData.enquiryNoteLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listEnquiryNote: (data) => dispatch(listEnquiryNote(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnquiryNotes);
