import * as React from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

import { NAVBAR_HEIGHT, FOOTER_HEIGHT } from "../constants";

const Loader = (props) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={
        props?.style
          ? props?.style
          : {
              minHeight:
                window.innerHeight - NAVBAR_HEIGHT - FOOTER_HEIGHT - 50,
            }
      }
    >
      <CircularProgress />
    </Grid>
  );
};

export default Loader;
