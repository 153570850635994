import React, {useEffect, useState} from "react";
import {
  Grid,
  Typography,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import {v4 as uuidv4} from "uuid";

import FormButton from "../components/FormButton";
import FormTextInput from "./FormTextInput";
import FormSelectize from "./FormSelectize";
import ImageUpload from "./ImageUpload";

import {capitalizeFirstLetter} from "../assets/functions/common";

import {connect} from "react-redux";
import {
  addProduct,
  updateApiStatus,
  updateProduct,
  getAllProductCategory
} from "../redux/actions/userDataActions";
import {
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
} from "../redux/actions/types";

import styles from "./AddEditProductModal.module.css";

import CloseIcon from '@mui/icons-material/CloseOutlined';

const AddEditProductModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [productUid, setProductUid] = useState("");
  const [productImageList, setProductImageList] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [category, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (
      (props.apiStatus == ADD_PRODUCT_SUCCESS ||
        props.apiStatus == UPDATE_PRODUCT_SUCCESS) &&
      props.modalVisible
    ) {
      props.updateApiStatus("");
      props.onSubmit();
    }
  }, [props.apiStatus]);

  useEffect(() => {
    props.getAllProductCategory()
  }, [])

  useEffect(() => {
    if (props.data != null) {
      setProductUid(props.data?.uid);
      let img = [];
      const imgArray = props.data?.image.split(',');
      imgArray.map((x) => {
        img = [...img, {
          id: uuidv4(),
          file: '',
          image: x
        }]
      });
      setProductImageList(img);

      setName(props.data?.name);
      setPrice(props.data?.price);
      setDiscount(props.data?.discount);

      let cat = [];
      const categoryIdArray = props.data?.category_id.split(',');
      categoryIdArray.map((x) => {
        let catIndex = props?.allProductCategoryList.findIndex((y) => y.uid == x);
        if (catIndex >= 0) {
          cat = [...cat, {
            uid: x,
            name: props?.allProductCategoryList[catIndex].name
          }]
        }
      });

      setCategory(cat);

      setLoading(false);
    } else {
      setProductUid("");
      setProductImageList([])
      setName("");
      setPrice("");
      setDiscount("");
      setCategory([]);
      setError(null);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.modalVisible) {
      if (props.type == "add") {
        setLoading(false);
      }
      setVisible(true);
    } else {
      setProductUid("");
      setProductImageList([])
      setName("");
      setPrice("");
      setDiscount("");
      setCategory([]);
      setError(null);
    }
  }, [props.modalVisible]);

  const onSubmitClick = () => {
    let errorText = {};

    if (productImageList?.length == 0) {
      errorText = {image: "Please upload image"};
    }
    if (name == "") {
      errorText = {...errorText, name: "Please enter name"};
    }
    if (category?.length == 0) {
      errorText = {...errorText, category: "Please select category"};
    }
    if (price == "") {
      errorText = {...errorText, price: "Please enter price"};
    } else {
      if (!/^[0-9.]{1,100}$/.test(price)) {
        errorText = {...errorText, price: "Invalid price"};
      }
    }
    if (discount != "") {
      if (!/^[0-9.]{1,100}$/.test(discount)) {
        errorText = {...errorText, discount: "Invalid discount"};
      }
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    onSubmit();
  };

  const onSubmit = () => {
    let formData = new FormData();
    formData.append('productUid', productUid);
    formData.append('name', name);
    formData.append('category', JSON.stringify(category));
    formData.append('price', price);
    formData.append('discount', discount);

    let prdt_image_list = [...productImageList];
    let newImg = prdt_image_list.filter((x) => x.file != '');
    let oldImg = prdt_image_list.filter((x) => x.file == '');

    if (productUid) {
      let final_old_img = [];
      oldImg.forEach(y => {
        final_old_img = [...final_old_img, y?.image.substring(y?.image.lastIndexOf('/') + 1)]
      });
      formData.append("oldProductImage", JSON.stringify(final_old_img));
    }

    if (newImg.length > 0) {
      newImg.forEach(y => {
        formData.append("productImage", y.file);
      });
    }

    if (props.type == "add") {
      props.addProduct(formData);
    } else {
      props.updateProduct(formData);
    }
  };

  const onCancelClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onCancel();
    }, [100]);
  };

  const onChange = (val, type) => {
    let errorText = error;
    if (type == "name") {
      if (val != "") {
        errorText = {...error, name: ""};
      } else {
        errorText = {...error, name: "Please enter name"};
      }
      val = capitalizeFirstLetter(val)
      setName(val);
    } else if (type == "price") {
      if (val != "") {
        if (!/^[0-9.]{1,100}$/.test(val)) {
          errorText = {...errorText, price: "Invalid price"};
        } else {
          errorText = {...error, price: ""};
        }
      } else {
        errorText = {...error, price: "Please enter price"};
      }
      setPrice(val);
    } else if (type == "discount") {
      if (val != "") {
        if (!/^[0-9.]{1,100}$/.test(val)) {
          errorText = {...errorText, discount: "Invalid discount"};
        } else {
          errorText = {...error, discount: ""};
        }
      }
      setDiscount(val);
    }
    setError(errorText);
  };

  const onChangeImage = (fileList) => {
    let prdt_image_list = [...productImageList, ...fileList];
    setProductImageList(prdt_image_list);
  }

  const onRemoveImage = (data) => {
    let img_list = [...productImageList];
    let imgIndex = img_list.findIndex((x) => x.id == data?.id);
    if (imgIndex >= 0) {
      img_list.splice(imgIndex, 1);
    }
    setProductImageList(img_list)
  }

  return (
    <Modal
      disableBackdropClick
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
      hideBackdrop={true}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{md: 8, xs: 4}}
      >
        <Grid
          onClick={() => onCancelClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle}/>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            {props?.type == "add" ? "Add " : "Edit "} Product
          </Typography>
        </Grid>

        {loading ? (
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress/>
          </Grid>
        ) : (
          <>
            <Grid display={"flex"} flexDirection={"column"} item md={12} xs={12} mt={1}
                  container>
              <Grid display={"flex"} flexDirection={"row"} item md={12} xs={12}
                    container>
                <Typography className={styles.labelText}>IMAGE</Typography>
                <Box pl={0.5}>
                  <Typography className={styles.requiredText}>*</Typography>
                </Box>
              </Grid>
              <ImageUpload
                maxFiles={100}
                image={productImageList}
                error={error?.image}
                onRemove={onRemoveImage}
                onChange={onChangeImage}/>
            </Grid>
            <Grid item md={12} xs={12} mt={2}>
              <FormTextInput
                page={"AddEditProductModal"}
                required={true}
                error={error?.name}
                value={name}
                setValue={(val) => onChange(val, "name")}
                label={"NAME"}
              />
            </Grid>
            <Grid item md={12} xs={12} mt={2}>
              <FormSelectize
                page={"AddEditProductModal"}
                value={category}
                label={"CATEGORY"}
                required={true}
                multiple={true}
                addOption={false}
                onChange={(val) => {
                  setCategory(val);
                }}
                options={props.allProductCategoryList}
                error={error?.category}
              />
            </Grid>
            <Grid item md={5.8} xs={12} mt={2}>
              <FormTextInput
                page={"AddEditProductModal"}
                required={true}
                error={error?.price}
                value={price}
                setValue={(val) => onChange(val, "price")}
                label={"PRICE"}
              />
            </Grid>
            <Grid item md={5.8} xs={12} mt={2}>
              <FormTextInput
                page={"AddEditProductModal"}
                required={false}
                error={error?.discount}
                value={discount}
                setValue={(val) => onChange(val, "discount")}
                label={"DISCOUNT"}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection="row"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item md={2} xs={2}>
                <FormButton
                  loading={props.productBtnLoading}
                  btnStyleView={style.btnStyleView}
                  btnType={"btn1"}
                  title={props.type == "add" ? "Submit" : "Update"}
                  onSubmit={onSubmitClick}
                />
              </Grid>
              <Grid ml={{md: 2, xs: 4}} item md={2} xs={2}>
                <FormButton
                  btnStyleView={style.btnStyleView}
                  btnType={"btn2"}
                  title={"Cancel"}
                  onSubmit={onCancelClick}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (data) => dispatch(addProduct(data)),
    updateProduct: (data) => dispatch(updateProduct(data)),
    getAllProductCategory: (data) => dispatch(getAllProductCategory(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    productBtnLoading: state.userData.productBtnLoading,
    allProductCategoryList: state.userData.allProductCategoryList,
    apiStatus: state.userData.apiStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditProductModal);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
};
