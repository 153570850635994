import * as actionType from "../actions/types";
import moment from "moment";

const initialState = {
  loginBtnLoading: false,
  userName: "",
  userEmail: "",
  userToken: "",
  registerBtnLoading: false,
  patientList: [],
  patientTotalPages: 0,
  patientTotalList: 0,
  patientLoading: false,
  patientBtnLoading: false,
  patientDetailLoading: true,
  patientDetail: null,
  patientExistDetail: [],
  consultationNoteBtnLoading: false,
  consultationNoteList: [],
  consultationNoteTotalPages: 0,
  consultationNoteTotalList: 0,
  consultationNoteLoading: false,
  allPatientListLoading: false,
  allPatientList: [],
  appointmentBtnLoading: false,
  appointmentList: [],
  appointmentLoading: true,
  patientAppointmentList: [],
  patientAppointmentTotalPages: 0,
  patientAppointmentTotalList: 0,
  patientAppointmentLoading: false,
  appointmentInvoiceBtnLoading: false,
  dashboardList: null,
  dashboardLoading: false,
  doctorList: [],
  doctorTotalPages: 0,
  doctorTotalList: 0,
  doctorLoading: false,
  doctorBtnLoading: false,
  allDoctorList: [],
  allDoctorListLoading: false,
  doctorDetailLoading: true,
  doctorDetail: null,
  userList: [],
  userTotalPages: 0,
  userTotalList: 0,
  userLoading: false,
  userBtnLoading: false,
  invoiceList: [],
  invoiceTotalPages: 0,
  invoiceTotalList: 0,
  invoiceLoading: false,
  patientInvoiceListLoading: false,
  patientInvoiceList: [],
  productList: [],
  productTotalPages: 0,
  productTotalList: 0,
  productLoading: false,
  productBtnLoading: false,
  allProductCategoryListLoading: false,
  allProductCategoryList: [],
  productCategoryList: [],
  productCategoryTotalPages: 0,
  productCategoryTotalList: 0,
  productCategoryLoading: false,
  productCategoryBtnLoading: false,
  productEnquiryList: [],
  productEnquiryTotalPages: 0,
  productEnquiryTotalList: 0,
  productEnquiryLoading: false,
  enquiryByMobileList: [],
  enquiryByMobileBtnLoading: false,
  enquiryByMobileLoading: false,
  enquiryList: [],
  enquiryTotalPages: 0,
  enquiryTotalList: 0,
  enquiryLoading: false,
  enquiryBtnLoading: false,
  enquiryDetailLoading: true,
  enquiryDetail: null,
  enquiryNoteBtnLoading: false,
  enquiryNoteList: [],
  enquiryNoteTotalPages: 0,
  enquiryNoteTotalList: 0,
  enquiryNoteLoading: false,
  moveToFollowupLoading: false,
  labelList: [],
  labelBtnLoading: false,
  labelLoading: false,
  incomeExpenseList: [],
  incomeExpenseTotalPages: 0,
  incomeExpenseTotalList: 0,
  incomeExpenseLoading: false,
  incomeExpenseBtnLoading: false,
  globalSearchList: [],
  globalSearchLoading: false,
  apiStatus: "",
  apiData: null,
  drawerOpen: true,
  msg: { open: false, message: "", msgType: "" },
  appointmentInvoiceDetailLoading: false,
  appointmentInvoiceDetail: null,
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    //login
    case actionType.LOGIN_ADMIN:
      return {
        ...state,
        loginBtnLoading: true,
      };

    case actionType.LOGIN_ADMIN_SUCCESS:
      return {
        ...state,
        userName: action.payload.userName,
        userEmail: action.payload.userEmail,
        userToken: action.payload.userToken,
        userType: action.payload.userType,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        loginBtnLoading: false,
      };

    case actionType.LOGIN_ADMIN_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        loginBtnLoading: false,
      };

    //register
    case actionType.REGISTER_ADMIN:
      return {
        ...state,
        registerBtnLoading: true,
      };

    case actionType.REGISTER_ADMIN_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        registerBtnLoading: false,
      };

    case actionType.REGISTER_ADMIN_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        registerBtnLoading: false,
      };

    //patients
    case actionType.ADD_PATIENT:
      return {
        ...state,
        patientBtnLoading: true,
        apiStatus: actionType.ADD_PATIENT,
        patientExistDetail: [],
      };

    case actionType.ADD_PATIENT_SUCCESS:
      let enquiry_list1 = [...state.enquiryList];
      let enquiry_detail1 = state.enquiryDetail;
      let enquiry_total_list = state.enquiryTotalList;
      if(action.payload.data?.enquiry_uid) {
        if (enquiry_list1.length) {
          let enquiryListIndex = enquiry_list1.findIndex(
            (x) => x.uid == action.payload.data.enquiry_uid
          );
          if (enquiryListIndex >= 0) {
            enquiry_list1.splice(enquiryListIndex, 1);
            enquiry_total_list = parseInt(state.enquiryTotalList) - 1;
          }
        }

        if (enquiry_detail1) {
          enquiry_detail1 = { ...enquiry_detail1, patient_uid: action.payload.data.uid };
        }
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        enquiryList: enquiry_list1,
        enquiryDetail: enquiry_detail1,
        patientList: [action.payload.data, ...state.patientList],
        allPatientList: [
          {
            uid: action.payload.data.uid,
            name:
              action.payload.data.first_name +
              " " +
              action.payload.data.last_name,
          },
          ...state.allPatientList,
        ],
        patientTotalList: parseInt(state.patientTotalList) + 1,
        patientBtnLoading: false,
        patientExistDetail: [],
        enquiryTotalList: enquiry_total_list,
        apiStatus: actionType.ADD_PATIENT_SUCCESS,
      };

    case actionType.ADD_PATIENT_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        patientBtnLoading: false,
        patientExistDetail: action.payload?.data ? action.payload.data : [],
        apiStatus: actionType.ADD_PATIENT_FAIL,
      };

    case actionType.LIST_PATIENT:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        patientLoading: true,
      };

    case actionType.LIST_PATIENT_SUCCESS:
      return {
        ...state,
        patientList: action.payload.data,
        patientTotalPages: action.payload.total_pages,
        patientTotalList: action.payload.total_list,
        patientLoading: false,
      };

    case actionType.LIST_PATIENT_FAIL:
      return {
        ...state,
        patientLoading: false,
      };

    case actionType.UPDATE_PATIENT:
      return {
        ...state,
        patientBtnLoading: true,
        apiStatus: actionType.UPDATE_PATIENT,
      };

    case actionType.UPDATE_PATIENT_SUCCESS:
      let patient_list_update = [...state.patientList];
      let patientListUpdateIndex = patient_list_update.findIndex(
        (x) => x.uid == action.payload.data.uid
      );
      if (patientListUpdateIndex >= 0) {
        patient_list_update[patientListUpdateIndex] = {
          ...patient_list_update[patientListUpdateIndex],
          ...action.payload.data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        patientList: patient_list_update,
        patientDetail: {
          ...state.patientDetail,
          ...action.payload.data,
        },
        patientBtnLoading: false,
        patientExistDetail: [],
        apiStatus: actionType.UPDATE_PATIENT_SUCCESS,
      };

    case actionType.UPDATE_PATIENT_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        patientBtnLoading: false,
        patientExistDetail: action.payload?.data ? action.payload.data : [],
        apiStatus: actionType.UPDATE_PATIENT_FAIL,
      };

    case actionType.DELETE_PATIENT:
      return {
        ...state,
        patientBtnLoading: true,
        apiStatus: actionType.DELETE_PATIENT,
      };

    case actionType.DELETE_PATIENT_SUCCESS:
      let patient_list_delete = [...state.patientList];
      let patientListDeleteIndex = patient_list_delete.findIndex(
        (x) => x.uid == action.payload.data.patientUid
      );
      if (patientListDeleteIndex >= 0) {
        patient_list_delete.splice(patientListDeleteIndex, 1);
      }
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        patientList: patient_list_delete,
        patientTotalList: parseInt(state.patientTotalList) - 1,
        patientBtnLoading: false,
        apiStatus: actionType.DELETE_PATIENT_SUCCESS,
      };

    case actionType.DELETE_PATIENT_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        patientBtnLoading: false,
        apiStatus: actionType.DELETE_PATIENT_FAIL,
      };

    case actionType.GET_PATIENT_DETAIL:
      return {
        ...state,
        patientDetailLoading: true,
      };

    case actionType.GET_PATIENT_DETAIL_SUCCESS:
      return {
        ...state,
        patientDetail: action.payload.data,
        patientDetailLoading: false,
      };

    case actionType.GET_PATIENT_DETAIL_FAIL:
      return {
        ...state,
        patientDetailLoading: false,
      };

    case actionType.ADD_CONSULTATION_NOTE:
      return {
        ...state,
        consultationNoteBtnLoading: true,
      };

    case actionType.ADD_CONSULTATION_NOTE_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        consultationNoteList: [
          action.payload.data,
          ...state.consultationNoteList,
        ],
        consultationNoteTotalList:
          parseInt(state.consultationNoteTotalList) + 1,
        consultationNoteBtnLoading: false,
      };

    case actionType.ADD_CONSULTATION_NOTE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        consultationNoteBtnLoading: false,
      };

    case actionType.LIST_CONSULTATION_NOTE:
      return {
        ...state,
        consultationNoteLoading: true,
      };

    case actionType.LIST_CONSULTATION_NOTE_SUCCESS:
      return {
        ...state,
        consultationNoteList: action.payload.data,
        consultationNoteTotalPages: action.payload.total_pages,
        consultationNoteTotalList: action.payload.total_list,
        consultationNoteLoading: false,
      };

    case actionType.LIST_CONSULTATION_NOTE_FAIL:
      return {
        ...state,
        consultationNoteLoading: false,
      };

    case actionType.UPDATE_CONSULTATION_NOTE:
      return {
        ...state,
        consultationNoteBtnLoading: true,
        apiStatus: actionType.UPDATE_CONSULTATION_NOTE,
      };

    case actionType.UPDATE_CONSULTATION_NOTE_SUCCESS:
      let consultation_note_list_update = [...state.consultationNoteList];
      let consultationNoteListUpdateIndex =
        consultation_note_list_update.findIndex(
          (x) => x.id == action.payload.data.id
        );
      if (consultationNoteListUpdateIndex >= 0) {
        consultation_note_list_update[consultationNoteListUpdateIndex] = {
          ...consultation_note_list_update[consultationNoteListUpdateIndex],
          ...action.payload.data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        consultationNoteList: consultation_note_list_update,
        consultationNoteBtnLoading: false,
        apiStatus: actionType.UPDATE_CONSULTATION_NOTE_SUCCESS,
      };

    case actionType.UPDATE_CONSULTATION_NOTE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        consultationNoteBtnLoading: false,
        apiStatus: actionType.UPDATE_CONSULTATION_NOTE_FAIL,
      };

    case actionType.DELETE_CONSULTATION_NOTE:
      return {
        ...state,
        consultationNoteBtnLoading: true,
        apiStatus: actionType.DELETE_CONSULTATION_NOTE,
      };

    case actionType.DELETE_CONSULTATION_NOTE_SUCCESS:
      let consultation_note_list_delete = [...state.consultationNoteList];
      let consultationNoteListDeleteIndex =
        consultation_note_list_delete.findIndex(
          (x) => x.id == action.payload.data.consultationNoteId
        );
      if (consultationNoteListDeleteIndex >= 0) {
        consultation_note_list_delete.splice(
          consultationNoteListDeleteIndex,
          1
        );
      }
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        consultationNoteList: consultation_note_list_delete,
        consultationNoteTotalList:
          parseInt(state.consultationNoteTotalList) - 1,
        consultationNoteBtnLoading: false,
        apiStatus: actionType.DELETE_CONSULTATION_NOTE_SUCCESS,
      };

    case actionType.DELETE_CONSULTATION_NOTE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        consultationNoteBtnLoading: false,
        apiStatus: actionType.DELETE_CONSULTATION_NOTE_FAIL,
      };

    //appointment
    case actionType.GET_ALL_PATIENT:
      return {
        ...state,
        allPatientListLoading: true,
      };

    case actionType.GET_ALL_PATIENT_SUCCESS:
      return {
        ...state,
        allPatientList: action.payload.data,
        allPatientListLoading: false,
      };

    case actionType.GET_ALL_PATIENT_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        allPatientListLoading: false,
      };

    case actionType.ADD_APPOINTMENT:
      return {
        ...state,
        apiStatus: actionType.ADD_APPOINTMENT,
        appointmentBtnLoading: true,
      };

    case actionType.ADD_APPOINTMENT_SUCCESS:
      let appt_add_data = {...action.payload.data,
        start: moment(action.payload.data.start, "YYYY-MM-DD HHmm").format("YYYY-MM-DD HH:mm"),
        end: moment(action.payload.data.end, "YYYY-MM-DD HHmm").format("YYYY-MM-DD HH:mm"),
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        appointmentList: [appt_add_data, ...state.appointmentList],
        patientAppointmentList: [
          appt_add_data,
          ...state.patientAppointmentList,
        ],
        patientAppointmentTotalList:
          parseInt(state.patientAppointmentTotalList) + 1,
        appointmentBtnLoading: false,
        apiStatus: actionType.ADD_APPOINTMENT_SUCCESS,
      };

    case actionType.ADD_APPOINTMENT_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        appointmentBtnLoading: false,
        apiStatus: actionType.ADD_APPOINTMENT_FAIL,
      };

    case actionType.GET_APPOINTMENT:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        appointmentLoading: true,
      };

    case actionType.GET_APPOINTMENT_SUCCESS:
      let appt_list = [];
      action.payload.data.map((item, index) => {
        appt_list.push({...item,
          start: moment(item.start, "YYYY-MM-DD Hmm").format("YYYY-MM-DD HH:mm"),
          end: moment(item.end, "YYYY-MM-DD Hmm").format("YYYY-MM-DD HH:mm"),
        })
      })

      return {
        ...state,
        appointmentList: appt_list,
        appointmentLoading: false,
      };

    case actionType.GET_APPOINTMENT_FAIL:
      return {
        ...state,
        appointmentLoading: false,
      };

    case actionType.UPDATE_APPOINTMENT:
      return {
        ...state,
        appointmentBtnLoading: true,
        apiStatus: actionType.UPDATE_APPOINTMENT,
      };

    case actionType.UPDATE_APPOINTMENT_SUCCESS:
      let appt_update_data = {...action.payload.data,
        start: moment(action.payload.data.start, "YYYY-MM-DD HHmm").format("YYYY-MM-DD HH:mm"),
        end: moment(action.payload.data.end, "YYYY-MM-DD HHmm").format("YYYY-MM-DD HH:mm"),
      }
      let appointment_list_update = [...state.appointmentList];
      let appointmentListUpdateIndex = appointment_list_update.findIndex(
        (x) => x.id == action.payload.data.id
      );
      if (appointmentListUpdateIndex >= 0) {
        appointment_list_update[appointmentListUpdateIndex] = {
          ...appointment_list_update[appointmentListUpdateIndex],
          ...appt_update_data,
        };
      }

      let patient_appointment_list_update = [...state.patientAppointmentList];
      let patientAppointmentListUpdateIndex =
        patient_appointment_list_update.findIndex(
          (x) => x.id == action.payload.data.id
        );
      if (patientAppointmentListUpdateIndex >= 0) {
        patient_appointment_list_update[patientAppointmentListUpdateIndex] = {
          ...patient_appointment_list_update[patientAppointmentListUpdateIndex],
          ...appt_update_data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        appointmentList: appointment_list_update,
        patientAppointmentList: patient_appointment_list_update,
        appointmentBtnLoading: false,
        apiStatus: actionType.UPDATE_APPOINTMENT_SUCCESS,
      };

    case actionType.UPDATE_APPOINTMENT_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        appointmentBtnLoading: false,
        apiStatus: actionType.UPDATE_APPOINTMENT_FAIL,
      };

    case actionType.LIST_APPOINTMENT:
      return {
        ...state,
        patientAppointmentLoading: true,
        msg: { open: false, message: "", msgType: "" },
      };

    case actionType.LIST_APPOINTMENT_SUCCESS:
      let appt_list1 = [];
      action.payload.data.map((item, index) => {
        appt_list1.push({...item,
          start: moment(item.start, "YYYY-MM-DD Hmm").format("YYYY-MM-DD HH:mm"),
          end: moment(item.end, "YYYY-MM-DD Hmm").format("YYYY-MM-DD HH:mm"),
        })
      })

      return {
        ...state,
        patientAppointmentList: appt_list1,
        patientAppointmentTotalPages: action.payload.total_pages,
        patientAppointmentTotalList: action.payload.total_list,
        patientAppointmentLoading: false,
      };

    case actionType.LIST_APPOINTMENT_FAIL:
      return {
        ...state,
        patientAppointmentLoading: false,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
      };

    case actionType.UPDATE_APPOINTMENT_STATUS:
      return {
        ...state,
        appointmentBtnLoading: true,
        apiStatus: actionType.UPDATE_APPOINTMENT_STATUS,
      };

    case actionType.UPDATE_APPOINTMENT_STATUS_SUCCESS:
      let { data } = action.payload;
      let appointment_list = [...state.appointmentList];
      let patient_appointment_list = [...state.patientAppointmentList];
      if (data.status == "removed") {
        appointment_list = appointment_list.filter(
          (x) => x.id != data.appointmentUid
        );
        patient_appointment_list = patient_appointment_list.filter(
          (x) => x.id != data.appointmentUid
        );
      } else {
        let appt_list_index = appointment_list.findIndex(
          (x) => x.id == data.appointmentUid
        );
        let patient_appt_list_index = patient_appointment_list.findIndex(
          (x) => x.id == data.appointmentUid
        );
        let update_data = {
          status: data.status,
          classNames: ["appointment-" + data.status],
          reason: data?.reason ? data?.reason : "",
        };
        if (appt_list_index >= 0) {
          appointment_list[appt_list_index] = {
            ...appointment_list[appt_list_index],
            ...update_data,
          };
        }
        if (appt_list_index >= 0) {
          patient_appointment_list[patient_appt_list_index] = {
            ...patient_appointment_list[patient_appt_list_index],
            ...update_data,
          };
        }
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        appointmentList: appointment_list,
        patientAppointmentList: patient_appointment_list,
        patientAppointmentTotalList:
          data.status == "removed"
            ? parseInt(state.patientAppointmentTotalList) - 1
            : state.patientAppointmentTotalList,
        appointmentBtnLoading: false,
        apiStatus: actionType.UPDATE_APPOINTMENT_STATUS_SUCCESS,
      };

    case actionType.UPDATE_APPOINTMENT_STATUS_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        appointmentBtnLoading: false,
        apiStatus: actionType.UPDATE_APPOINTMENT_STATUS_FAIL,
      };

    case actionType.GET_PATIENT_INVOICE_LIST:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        patientInvoiceListLoading: true,
        patientInvoiceList: [],
      };

    case actionType.GET_PATIENT_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        patientInvoiceList: action.payload.data,
        patientInvoiceListLoading: false,
      };

    case actionType.GET_PATIENT_INVOICE_LIST_FAIL:
      return {
        ...state,
        patientInvoiceListLoading: false,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
      };

    case actionType.UPDATE_INVOICE_APPOINTMENT:
      return {
        ...state,
        apiStatus: actionType.UPDATE_INVOICE_APPOINTMENT,
      };

    case actionType.UPDATE_INVOICE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        apiStatus: actionType.UPDATE_INVOICE_APPOINTMENT_SUCCESS,
      };

    case actionType.UPDATE_INVOICE_APPOINTMENT_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        apiStatus: actionType.UPDATE_INVOICE_APPOINTMENT_FAIL,
      };

    //invoice
    case actionType.GET_APPOINTMENT_INVOICE:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        appointmentInvoiceDetailLoading: true,
        appointmentInvoiceDetail: null,
      };

    case actionType.GET_APPOINTMENT_INVOICE_SUCCESS:
      return {
        ...state,
        appointmentInvoiceDetail: action.payload.data,
        appointmentInvoiceDetailLoading: false,
      };

    case actionType.GET_APPOINTMENT_INVOICE_FAIL:
      return {
        ...state,
        appointmentInvoiceDetailLoading: false,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
      };

    case actionType.ADD_APPOINTMENT_INVOICE:
      return {
        ...state,
        apiStatus: actionType.ADD_APPOINTMENT_INVOICE,
        appointmentInvoiceBtnLoading: true,
      };

    case actionType.ADD_APPOINTMENT_INVOICE_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        appointmentInvoiceDetail: {
          ...state.appointmentInvoiceDetail,
          ...action.payload.data,
        },
        appointmentInvoiceBtnLoading: false,
        apiStatus: actionType.ADD_APPOINTMENT_INVOICE_SUCCESS,
      };

    case actionType.ADD_APPOINTMENT_INVOICE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        appointmentInvoiceBtnLoading: false,
        apiStatus: actionType.ADD_APPOINTMENT_INVOICE_FAIL,
      };

    case actionType.UPDATE_APPOINTMENT_INVOICE:
      return {
        ...state,
        appointmentInvoiceBtnLoading: true,
        apiStatus: actionType.UPDATE_APPOINTMENT_INVOICE,
      };

    case actionType.UPDATE_APPOINTMENT_INVOICE_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        appointmentInvoiceDetail: {
          ...state.appointmentInvoiceDetail,
          ...action.payload.data,
        },
        appointmentInvoiceBtnLoading: false,
        apiStatus: actionType.UPDATE_APPOINTMENT_INVOICE_SUCCESS,
      };

    case actionType.UPDATE_APPOINTMENT_INVOICE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        appointmentInvoiceBtnLoading: false,
        apiStatus: actionType.UPDATE_APPOINTMENT_INVOICE_FAIL,
      };

    case actionType.DELETE_APPOINTMENT_INVOICE:
      return {
        ...state,
        appointmentInvoiceBtnLoading: true,
        apiStatus: actionType.DELETE_APPOINTMENT_INVOICE,
      };

    case actionType.DELETE_APPOINTMENT_INVOICE_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        appointmentInvoiceDetail: {
          ...state.appointmentInvoiceDetail,
          ...action.payload.data,
        },
        appointmentInvoiceBtnLoading: false,
        apiStatus: actionType.DELETE_APPOINTMENT_INVOICE_SUCCESS,
      };

    case actionType.DELETE_APPOINTMENT_INVOICE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        appointmentInvoiceBtnLoading: false,
        apiStatus: actionType.DELETE_APPOINTMENT_INVOICE_FAIL,
      };

    case actionType.ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD:
      return {
        ...state,
        apiStatus: actionType.ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD,
        appointmentInvoiceBtnLoading: true,
      };

    case actionType.ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        appointmentInvoiceDetail: {
          ...state.appointmentInvoiceDetail,
          ...action.payload.data,
        },
        appointmentInvoiceBtnLoading: false,
        apiStatus: actionType.ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD_SUCCESS,
      };

    case actionType.ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        appointmentInvoiceBtnLoading: false,
        apiStatus: actionType.ADD_APPOINTMENT_INVOICE_PAYMENT_METHOD_FAIL,
      };

    case actionType.GET_INVOICE:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        appointmentInvoiceDetailLoading: true,
        appointmentInvoiceDetail: null,
      };

    case actionType.GET_INVOICE_SUCCESS:
      return {
        ...state,
        appointmentInvoiceDetail: action.payload.data,
        appointmentInvoiceDetailLoading: false,
      };

    case actionType.GET_INVOICE_FAIL:
      return {
        ...state,
        appointmentInvoiceDetailLoading: false,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
      };

    //dashboard
    case actionType.GET_DASHBOARD:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        dashboardLoading: true,
        apiStatus: actionType.GET_DASHBOARD,
      };

    case actionType.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardList: action.payload.data,
        dashboardLoading: false,
        apiStatus: actionType.GET_DASHBOARD_SUCCESS,
      };

    case actionType.GET_DASHBOARD_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        dashboardLoading: false,
        apiStatus: actionType.GET_DASHBOARD_FAIL,
      };

    //doctors
    case actionType.ADD_DOCTOR:
      return {
        ...state,
        doctorBtnLoading: true,
        apiStatus: actionType.ADD_DOCTOR,
      };

    case actionType.ADD_DOCTOR_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        doctorList: [action.payload.data, ...state.doctorList],
        doctorTotalList: parseInt(state.doctorTotalList) + 1,
        doctorBtnLoading: false,
        apiStatus: actionType.ADD_DOCTOR_SUCCESS,
      };

    case actionType.ADD_DOCTOR_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        doctorBtnLoading: false,
        apiStatus: actionType.ADD_DOCTOR_FAIL,
      };

    case actionType.LIST_DOCTOR:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        doctorLoading: true,
      };

    case actionType.LIST_DOCTOR_SUCCESS:
      return {
        ...state,
        doctorList: action.payload.data,
        doctorTotalPages: action.payload.total_pages,
        doctorTotalList: action.payload.total_list,
        doctorLoading: false,
      };

    case actionType.LIST_DOCTOR_FAIL:
      return {
        ...state,
        doctorLoading: false,
      };

    case actionType.UPDATE_DOCTOR:
      return {
        ...state,
        doctorBtnLoading: true,
        apiStatus: actionType.UPDATE_DOCTOR,
      };

    case actionType.UPDATE_DOCTOR_SUCCESS:
      let doctor_list_update = [...state.doctorList];
      let doctorListUpdateIndex = doctor_list_update.findIndex(
        (x) => x.uid == action.payload.data.uid
      );
      if (doctorListUpdateIndex >= 0) {
        doctor_list_update[doctorListUpdateIndex] = {
          ...doctor_list_update[doctorListUpdateIndex],
          ...action.payload.data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        doctorList: doctor_list_update,
        doctorDetail: {
          ...state.doctorDetail,
          ...action.payload.data,
        },
        doctorBtnLoading: false,
        apiStatus: actionType.UPDATE_DOCTOR_SUCCESS,
      };

    case actionType.UPDATE_DOCTOR_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        doctorBtnLoading: false,
        apiStatus: actionType.UPDATE_DOCTOR_FAIL,
      };

    case actionType.DELETE_DOCTOR:
      return {
        ...state,
        doctorBtnLoading: true,
        apiStatus: actionType.DELETE_DOCTOR,
      };

    case actionType.DELETE_DOCTOR_SUCCESS:
      let doctor_list_delete = [...state.doctorList];
      let doctorListDeleteIndex = doctor_list_delete.findIndex(
        (x) => x.uid == action.payload.data.doctorUid
      );
      if (doctorListDeleteIndex >= 0) {
        doctor_list_delete.splice(doctorListDeleteIndex, 1);
      }
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        doctorList: doctor_list_delete,
        doctorTotalList: parseInt(state.doctorTotalList) - 1,
        doctorBtnLoading: false,
        apiStatus: actionType.DELETE_DOCTOR_SUCCESS,
      };

    case actionType.DELETE_DOCTOR_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        doctorBtnLoading: false,
        apiStatus: actionType.DELETE_DOCTOR_FAIL,
      };

    case actionType.GET_ALL_DOCTOR:
      return {
        ...state,
        allDoctorListLoading: true,
      };

    case actionType.GET_ALL_DOCTOR_SUCCESS:
      return {
        ...state,
        allDoctorList: action.payload.data,
        allDoctorListLoading: false,
      };

    case actionType.GET_ALL_DOCTOR_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        allDoctorListLoading: false,
      };

    case actionType.GET_DOCTOR_DETAIL:
      return {
        ...state,
        doctorDetailLoading: true,
      };

    case actionType.GET_DOCTOR_DETAIL_SUCCESS:
      return {
        ...state,
        doctorDetail: action.payload.data,
        doctorDetailLoading: false,
      };

    case actionType.GET_DOCTOR_DETAIL_FAIL:
      return {
        ...state,
        doctorDetailLoading: false,
      };

    //users
    case actionType.ADD_USER:
      return {
        ...state,
        userBtnLoading: true,
        apiStatus: actionType.ADD_USER,
      };

    case actionType.ADD_USER_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        userList: [action.payload.data, ...state.userList],
        userTotalList: parseInt(state.userTotalList) + 1,
        userBtnLoading: false,
        apiStatus: actionType.ADD_USER_SUCCESS,
      };

    case actionType.ADD_USER_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        userBtnLoading: false,
        apiStatus: actionType.ADD_USER_FAIL,
      };

    case actionType.LIST_USER:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        userLoading: true,
      };

    case actionType.LIST_USER_SUCCESS:
      return {
        ...state,
        userList: action.payload.data,
        userTotalPages: action.payload.total_pages,
        userTotalList: action.payload.total_list,
        userLoading: false,
      };

    case actionType.LIST_USER_FAIL:
      return {
        ...state,
        userLoading: false,
      };

    case actionType.UPDATE_USER:
      return {
        ...state,
        userBtnLoading: true,
        apiStatus: actionType.UPDATE_USER,
      };

    case actionType.UPDATE_USER_SUCCESS:
      let user_list_update = [...state.userList];
      let userListUpdateIndex = user_list_update.findIndex(
        (x) => x.uid == action.payload.data.uid
      );
      if (userListUpdateIndex >= 0) {
        user_list_update[userListUpdateIndex] = {
          ...user_list_update[userListUpdateIndex],
          ...action.payload.data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        userList: user_list_update,
        userBtnLoading: false,
        apiStatus: actionType.UPDATE_USER_SUCCESS,
      };

    case actionType.UPDATE_USER_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        userBtnLoading: false,
        apiStatus: actionType.UPDATE_USER_FAIL,
      };

    case actionType.DELETE_USER:
      return {
        ...state,
        userBtnLoading: true,
        apiStatus: actionType.DELETE_USER,
      };

    case actionType.DELETE_USER_SUCCESS:
      let user_list_delete = [...state.userList];
      let userListDeleteIndex = user_list_delete.findIndex(
        (x) => x.uid == action.payload.data.userUid
      );
      if (userListDeleteIndex >= 0) {
        user_list_delete.splice(userListDeleteIndex, 1);
      }
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        userList: user_list_delete,
        userTotalList: parseInt(state.userTotalList) - 1,
        userBtnLoading: false,
        apiStatus: actionType.DELETE_USER_SUCCESS,
      };

    case actionType.DELETE_USER_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        userBtnLoading: false,
        apiStatus: actionType.DELETE_USER_FAIL,
      };

    case actionType.UPDATE_USER_PASSWORD:
      return {
        ...state,
        userBtnLoading: true,
        apiStatus: actionType.UPDATE_USER_PASSWORD,
      };

    case actionType.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        userBtnLoading: false,
        apiStatus: actionType.UPDATE_USER_PASSWORD_SUCCESS,
      };

    case actionType.UPDATE_USER_PASSWORD_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        userBtnLoading: false,
        apiStatus: actionType.UPDATE_USER_PASSWORD_FAIL,
      };

    //invoices
    case actionType.LIST_INVOICE:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        invoiceLoading: true,
      };

    case actionType.LIST_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceList: action.payload.data,
        invoiceTotalPages: action.payload.total_pages,
        invoiceTotalList: action.payload.total_list,
        invoiceLoading: false,
      };

    case actionType.LIST_INVOICE_FAIL:
      return {
        ...state,
        invoiceLoading: false,
      };

    case actionType.ADD_INVOICE:
      return {
        ...state,
        appointmentInvoiceBtnLoading: true,
        apiStatus: actionType.ADD_INVOICE,
      };

    case actionType.ADD_INVOICE_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        appointmentInvoiceDetail: {
          ...state.appointmentInvoiceDetail,
          ...action.payload.data,
        },
        appointmentInvoiceBtnLoading: false,
        apiStatus: actionType.ADD_INVOICE_SUCCESS,
      };

    case actionType.ADD_INVOICE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        appointmentInvoiceBtnLoading: false,
        apiStatus: actionType.ADD_INVOICE_FAIL,
      };

    case actionType.GET_PATIENT_INVOICE:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        appointmentInvoiceDetailLoading: true,
        appointmentInvoiceDetail: null,
      };

    case actionType.GET_PATIENT_INVOICE_SUCCESS:
      return {
        ...state,
        appointmentInvoiceDetail: action.payload.data,
        appointmentInvoiceDetailLoading: false,
      };

    case actionType.GET_PATIENT_INVOICE_FAIL:
      return {
        ...state,
        appointmentInvoiceDetailLoading: false,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
      };

    //products
    case actionType.ADD_PRODUCT:
      return {
        ...state,
        productBtnLoading: true,
        apiStatus: actionType.ADD_PRODUCT,
      };

    case actionType.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        productList: [action.payload.data, ...state.productList],
        productTotalList: parseInt(state.productTotalList) + 1,
        productBtnLoading: false,
        apiStatus: actionType.ADD_PRODUCT_SUCCESS,
      };

    case actionType.ADD_PRODUCT_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        productBtnLoading: false,
        apiStatus: actionType.ADD_PRODUCT_FAIL,
      };

    case actionType.LIST_PRODUCT:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        productList: [],
        productLoading: true,
      };

    case actionType.LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        productList: action.payload.data,
        productTotalPages: action.payload.total_pages,
        productTotalList: action.payload.total_list,
        productLoading: false,
      };

    case actionType.LIST_PRODUCT_FAIL:
      return {
        ...state,
        productLoading: false,
      };

    case actionType.UPDATE_PRODUCT:
      return {
        ...state,
        productBtnLoading: true,
        apiStatus: actionType.UPDATE_PRODUCT,
      };

    case actionType.UPDATE_PRODUCT_SUCCESS:
      let product_list_update = [...state.productList];
      let productListUpdateIndex = product_list_update.findIndex(
        (x) => x.uid == action.payload.data.uid
      );
      if (productListUpdateIndex >= 0) {
        product_list_update[productListUpdateIndex] = {
          ...product_list_update[productListUpdateIndex],
          ...action.payload.data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        productList: product_list_update,
        productBtnLoading: false,
        apiStatus: actionType.UPDATE_PRODUCT_SUCCESS,
      };

    case actionType.UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        productBtnLoading: false,
        apiStatus: actionType.UPDATE_PRODUCT_FAIL,
      };

    case actionType.DELETE_PRODUCT:
      return {
        ...state,
        productBtnLoading: true,
        apiStatus: actionType.DELETE_PRODUCT,
      };

    case actionType.DELETE_PRODUCT_SUCCESS:
      let product_list_delete = [...state.productList];
      let productListDeleteIndex = product_list_delete.findIndex(
        (x) => x.uid == action.payload.data.productUid
      );
      if (productListDeleteIndex >= 0) {
        product_list_delete.splice(productListDeleteIndex, 1);
      }
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        productList: product_list_delete,
        productTotalList: parseInt(state.productTotalList) - 1,
        productBtnLoading: false,
        apiStatus: actionType.DELETE_PRODUCT_SUCCESS,
      };

    case actionType.DELETE_PRODUCT_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        productBtnLoading: false,
        apiStatus: actionType.DELETE_PRODUCT_FAIL,
      };

    case actionType.GET_ALL_PRODUCT_CATEGORY:
      return {
        ...state,
        allProductCategoryListLoading: true,
      };

    case actionType.GET_ALL_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        allProductCategoryList: action.payload.data,
        allProductCategoryListLoading: false,
      };

    case actionType.GET_ALL_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        allProductCategoryListLoading: false,
      };

    case actionType.UPDATE_PRODUCT_STATUS:
      return {
        ...state,
        productBtnLoading: true,
        apiStatus: actionType.UPDATE_PRODUCT_STATUS,
      };

    case actionType.UPDATE_PRODUCT_STATUS_SUCCESS:
      let product_status_list_update = [...state.productList];
      let productStatusListUpdateIndex = product_status_list_update.findIndex(
        (x) => x.uid == action.payload.data.uid
      );
      if (productStatusListUpdateIndex >= 0) {
        product_status_list_update[productStatusListUpdateIndex] = {
          ...product_status_list_update[productStatusListUpdateIndex],
          ...action.payload.data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        productList: product_status_list_update,
        productBtnLoading: false,
        apiStatus: actionType.UPDATE_PRODUCT_STATUS_SUCCESS,
      };

    case actionType.UPDATE_PRODUCT_STATUS_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        productBtnLoading: false,
        apiStatus: actionType.UPDATE_PRODUCT_STATUS_FAIL,
      };

    //product category
    case actionType.ADD_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategoryBtnLoading: true,
        apiStatus: actionType.ADD_PRODUCT_CATEGORY,
      };

    case actionType.ADD_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        productCategoryList: [
          action.payload.data,
          ...state.productCategoryList,
        ],
        productCategoryTotalList: parseInt(state.productCategoryTotalList) + 1,
        productCategoryBtnLoading: false,
        apiStatus: actionType.ADD_PRODUCT_CATEGORY_SUCCESS,
      };

    case actionType.ADD_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        productCategoryBtnLoading: false,
        apiStatus: actionType.ADD_PRODUCT_CATEGORY_FAIL,
      };

    case actionType.LIST_PRODUCT_CATEGORY:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        productCategoryLoading: true,
      };

    case actionType.LIST_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategoryList: action.payload.data,
        productCategoryTotalPages: action.payload.total_pages,
        productCategoryTotalList: action.payload.total_list,
        productCategoryLoading: false,
      };

    case actionType.LIST_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        productCategoryLoading: false,
      };

    case actionType.UPDATE_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategoryBtnLoading: true,
        apiStatus: actionType.UPDATE_PRODUCT_CATEGORY,
      };

    case actionType.UPDATE_PRODUCT_CATEGORY_SUCCESS:
      let product_category_list_update = [...state.productCategoryList];
      let productCategoryListUpdateIndex =
        product_category_list_update.findIndex(
          (x) => x.uid == action.payload.data.uid
        );
      if (productCategoryListUpdateIndex >= 0) {
        product_category_list_update[productCategoryListUpdateIndex] = {
          ...product_category_list_update[productCategoryListUpdateIndex],
          ...action.payload.data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        productCategoryList: product_category_list_update,
        productCategoryBtnLoading: false,
        apiStatus: actionType.UPDATE_PRODUCT_CATEGORY_SUCCESS,
      };

    case actionType.UPDATE_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        productCategoryBtnLoading: false,
        apiStatus: actionType.UPDATE_PRODUCT_CATEGORY_FAIL,
      };

    case actionType.DELETE_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategoryBtnLoading: true,
        apiStatus: actionType.DELETE_PRODUCT_CATEGORY,
      };

    case actionType.DELETE_PRODUCT_CATEGORY_SUCCESS:
      let product_category_list_delete = [...state.productCategoryList];
      let productCategoryListDeleteIndex =
        product_category_list_delete.findIndex(
          (x) => x.uid == action.payload.data.productCategoryUid
        );
      if (productCategoryListDeleteIndex >= 0) {
        product_category_list_delete.splice(productCategoryListDeleteIndex, 1);
      }
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        productCategoryList: product_category_list_delete,
        productCategoryTotalList: parseInt(state.productCategoryTotalList) - 1,
        productCategoryBtnLoading: false,
        apiStatus: actionType.DELETE_PRODUCT_CATEGORY_SUCCESS,
      };

    case actionType.DELETE_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        msg:
          action.payload?.error == "error_popup"
            ? { open: false, message: "", msgType: "" }
            : {
                open: true,
                message: action.payload.msg,
                msgType: "error",
              },
        productCategoryBtnLoading: false,
        apiStatus: actionType.DELETE_PRODUCT_CATEGORY_FAIL,
        apiData: action.payload,
      };

    //product enquiry
    case actionType.LIST_PRODUCT_ENQUIRY:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        productEnquiryList: [],
        productEnquiryLoading: true,
      };

    case actionType.LIST_PRODUCT_ENQUIRY_SUCCESS:
      return {
        ...state,
        productEnquiryList: action.payload.data,
        productEnquiryTotalPages: action.payload.total_pages,
        productEnquiryTotalList: action.payload.total_list,
        productEnquiryLoading: false,
      };

    case actionType.LIST_PRODUCT_ENQUIRY_FAIL:
      return {
        ...state,
        productEnquiryLoading: false,
      };

    //enquiry
    case actionType.GET_ENQUIRY_BY_MOBILE:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        enquiryByMobileList: [],
        enquiryByMobileBtnLoading: true,
        enquiryByMobileLoading: true,
        apiStatus: actionType.GET_ENQUIRY_BY_MOBILE,
      };

    case actionType.GET_ENQUIRY_BY_MOBILE_SUCCESS:
      return {
        ...state,
        enquiryByMobileList: action.payload.data,
        enquiryByMobileBtnLoading: false,
        enquiryByMobileLoading: false,
        apiStatus: actionType.GET_ENQUIRY_BY_MOBILE_SUCCESS,
      };

    case actionType.GET_ENQUIRY_BY_MOBILE_FAIL:
      return {
        ...state,
        enquiryByMobileBtnLoading: false,
        enquiryByMobileLoading: false,
        apiStatus: actionType.GET_ENQUIRY_BY_MOBILE_FAIL,
      };

    case actionType.ADD_ENQUIRY:
      return {
        ...state,
        enquiryBtnLoading: true,
        apiStatus: actionType.ADD_ENQUIRY,
      };

    case actionType.ADD_ENQUIRY_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        // enquiryList: [action.payload.data, ...state.enquiryList],
        // enquiryTotalList: parseInt(state.enquiryTotalList) + 1,
        enquiryBtnLoading: false,
        apiStatus: actionType.ADD_ENQUIRY_SUCCESS,
      };

    case actionType.ADD_ENQUIRY_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        enquiryBtnLoading: false,
        apiStatus: actionType.ADD_ENQUIRY_FAIL,
      };

    case actionType.LIST_ENQUIRY:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        enquiryList: [],
        moveToFollowupLoading: false,
        enquiryLoading: true,
      };

    case actionType.LIST_ENQUIRY_SUCCESS:
      return {
        ...state,
        enquiryList: action.payload.data,
        enquiryTotalPages: action.payload.total_pages,
        enquiryTotalList: action.payload.total_list,
        enquiryLoading: false,
      };

    case actionType.LIST_ENQUIRY_FAIL:
      return {
        ...state,
        enquiryLoading: false,
      };

    case actionType.UPDATE_ENQUIRY:
      return {
        ...state,
        enquiryBtnLoading: true,
        apiStatus: actionType.UPDATE_ENQUIRY,
      };

    case actionType.UPDATE_ENQUIRY_SUCCESS:
      // let enquiry_list_update = [...state.enquiryList];
      // let enquiryListUpdateIndex = enquiry_list_update.findIndex(
      //   (x) => x.uid == action.payload.data.uid
      // );
      // if (enquiryListUpdateIndex >= 0) {
      //   enquiry_list_update[enquiryListUpdateIndex] = {
      //     ...enquiry_list_update[enquiryListUpdateIndex],
      //     ...action.payload.data,
      //   };
      // }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        // enquiryList: enquiry_list_update,
        enquiryBtnLoading: false,
        apiStatus: actionType.UPDATE_ENQUIRY_SUCCESS,
      };

    case actionType.UPDATE_ENQUIRY_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        enquiryBtnLoading: false,
        apiStatus: actionType.UPDATE_ENQUIRY_FAIL,
      };

    case actionType.MOVE_TO_FOLLOWUP:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        moveToFollowupLoading: true,
      };

    case actionType.MOVE_TO_FOLLOWUP_SUCCESS:
      let enquiry_list = [...state.enquiryList];
      if (enquiry_list.length) {
        let enquiryListIndex = enquiry_list.findIndex(
          (x) => x.uid == action.payload.data.uid
        );
        if (enquiryListIndex >= 0) {
          enquiry_list.splice(enquiryListIndex, 1);
        }
      }

      let enquiry_detail = state.enquiryDetail;
      if (enquiry_detail) {
        enquiry_detail = { ...enquiry_detail, ...action.payload.data };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        enquiryList: enquiry_list,
        enquiryDetail: enquiry_detail,
        enquiryTotalList: parseInt(state.enquiryTotalList) - 1,
        moveToFollowupLoading: false,
        apiStatus: actionType.MOVE_TO_FOLLOWUP_SUCCESS,
      };

    case actionType.MOVE_TO_FOLLOWUP_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        moveToFollowupLoading: false,
        apiStatus: actionType.MOVE_TO_FOLLOWUP_FAIL,
      };

    //enquiry detail
    case actionType.GET_ENQUIRY_DETAIL:
      return {
        ...state,
        enquiryDetail: null,
        enquiryDetailLoading: true,
        moveToFollowupLoading: false,
      };

    case actionType.GET_ENQUIRY_DETAIL_SUCCESS:
      return {
        ...state,
        enquiryDetail: action.payload.data,
        enquiryDetailLoading: false,
      };

    case actionType.GET_ENQUIRY_DETAIL_FAIL:
      return {
        ...state,
        enquiryDetailLoading: false,
      };

    case actionType.ADD_ENQUIRY_NOTE:
      return {
        ...state,
        enquiryNoteBtnLoading: true,
      };

    case actionType.ADD_ENQUIRY_NOTE_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        enquiryNoteList: [action.payload.data, ...state.enquiryNoteList],
        enquiryNoteTotalList: parseInt(state.enquiryNoteTotalList) + 1,
        enquiryNoteBtnLoading: false,
      };

    case actionType.ADD_ENQUIRY_NOTE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        enquiryNoteBtnLoading: false,
      };

    case actionType.LIST_ENQUIRY_NOTE:
      return {
        ...state,
        enquiryNoteLoading: true,
        enquiryNoteList: [],
      };

    case actionType.LIST_ENQUIRY_NOTE_SUCCESS:
      return {
        ...state,
        enquiryNoteList: action.payload.data,
        enquiryNoteTotalPages: action.payload.total_pages,
        enquiryNoteTotalList: action.payload.total_list,
        enquiryNoteLoading: false,
      };

    case actionType.LIST_ENQUIRY_NOTE_FAIL:
      return {
        ...state,
        enquiryNoteLoading: false,
      };

    case actionType.UPDATE_ENQUIRY_NOTE:
      return {
        ...state,
        enquiryNoteBtnLoading: true,
        apiStatus: actionType.UPDATE_ENQUIRY_NOTE,
      };

    case actionType.UPDATE_ENQUIRY_NOTE_SUCCESS:
      let enquiry_note_list_update = [...state.enquiryNoteList];
      let enquiryNoteListUpdateIndex = enquiry_note_list_update.findIndex(
        (x) => x.id == action.payload.data.id
      );
      if (enquiryNoteListUpdateIndex >= 0) {
        enquiry_note_list_update[enquiryNoteListUpdateIndex] = {
          ...enquiry_note_list_update[enquiryNoteListUpdateIndex],
          ...action.payload.data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        enquiryNoteList: enquiry_note_list_update,
        enquiryNoteBtnLoading: false,
        apiStatus: actionType.UPDATE_ENQUIRY_NOTE_SUCCESS,
      };

    case actionType.UPDATE_ENQUIRY_NOTE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        enquiryNoteBtnLoading: false,
        apiStatus: actionType.UPDATE_ENQUIRY_NOTE_FAIL,
      };

    case actionType.DELETE_ENQUIRY_NOTE:
      return {
        ...state,
        enquiryNoteBtnLoading: true,
        apiStatus: actionType.DELETE_ENQUIRY_NOTE,
      };

    case actionType.DELETE_ENQUIRY_NOTE_SUCCESS:
      let enquiry_note_list_delete = [...state.enquiryNoteList];
      let enquiryNoteListDeleteIndex = enquiry_note_list_delete.findIndex(
        (x) => x.id == action.payload.data.enquiryNoteId
      );
      if (enquiryNoteListDeleteIndex >= 0) {
        enquiry_note_list_delete.splice(enquiryNoteListDeleteIndex, 1);
      }
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        enquiryNoteList: enquiry_note_list_delete,
        enquiryNoteTotalList: parseInt(state.enquiryNoteTotalList) - 1,
        enquiryNoteBtnLoading: false,
        apiStatus: actionType.DELETE_ENQUIRY_NOTE_SUCCESS,
      };

    case actionType.DELETE_ENQUIRY_NOTE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        enquiryNoteBtnLoading: false,
        apiStatus: actionType.DELETE_ENQUIRY_NOTE_FAIL,
      };

    //label
    case actionType.ADD_LABEL:
      return {
        ...state,
        labelBtnLoading: true,
        apiStatus: actionType.ADD_LABEL,
      };

    case actionType.ADD_LABEL_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        labelList: [action.payload.data, ...state.labelList],
        labelBtnLoading: false,
        apiStatus: actionType.ADD_LABEL_SUCCESS,
      };

    case actionType.ADD_LABEL_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        labelBtnLoading: false,
        apiStatus: actionType.ADD_LABEL_FAIL,
      };

    case actionType.LIST_LABEL:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        labelList: [],
        labelLoading: true,
      };

    case actionType.LIST_LABEL_SUCCESS:
      return {
        ...state,
        labelList: action.payload.data,
        labelLoading: false,
      };

    case actionType.LIST_LABEL_FAIL:
      return {
        ...state,
        labelLoading: false,
      };

    case actionType.UPDATE_LABEL:
      return {
        ...state,
        labelBtnLoading: true,
        apiStatus: actionType.UPDATE_LABEL,
      };

    case actionType.UPDATE_LABEL_SUCCESS:
      let label_list_update = [...state.labelList];
      let labelListUpdateIndex = label_list_update.findIndex(
        (x) => x.uid == action.payload.data.uid
      );
      if (labelListUpdateIndex >= 0) {
        label_list_update[labelListUpdateIndex] = {
          ...label_list_update[labelListUpdateIndex],
          ...action.payload.data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        labelList: label_list_update,
        labelBtnLoading: false,
        apiStatus: actionType.UPDATE_LABEL_SUCCESS,
      };

    case actionType.UPDATE_LABEL_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        labelBtnLoading: false,
        apiStatus: actionType.UPDATE_LABEL_FAIL,
      };

    case actionType.DELETE_LABEL:
      return {
        ...state,
        labelBtnLoading: true,
        apiStatus: actionType.DELETE_LABEL,
      };

    case actionType.DELETE_LABEL_SUCCESS:
      let label_list_delete = [...state.labelList];
      let labelListDeleteIndex = label_list_delete.findIndex(
        (x) => x.uid == action.payload.data.labelUid
      );
      if (labelListDeleteIndex >= 0) {
        label_list_delete.splice(labelListDeleteIndex, 1);
      }
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        labelList: label_list_delete,
        labelBtnLoading: false,
        apiStatus: actionType.DELETE_LABEL_SUCCESS,
      };

    case actionType.DELETE_LABEL_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        labelBtnLoading: false,
        apiStatus: actionType.DELETE_LABEL_FAIL,
      };

    //income/expense
    case actionType.ADD_INCOME_EXPENSE:
      return {
        ...state,
        incomeExpenseBtnLoading: true,
        apiStatus: actionType.ADD_INCOME_EXPENSE,
      };

    case actionType.ADD_INCOME_EXPENSE_SUCCESS:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        incomeExpenseList: [action.payload.data, ...state.incomeExpenseList],
        incomeExpenseTotalList: parseInt(state.incomeExpenseTotalList) + 1,
        incomeExpenseBtnLoading: false,
        apiStatus: actionType.ADD_INCOME_EXPENSE_SUCCESS,
      };

    case actionType.ADD_INCOME_EXPENSE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        incomeExpenseBtnLoading: false,
        apiStatus: actionType.ADD_INCOME_EXPENSE_FAIL,
      };

    case actionType.UPDATE_INCOME_EXPENSE:
      return {
        ...state,
        incomeExpenseBtnLoading: true,
        apiStatus: actionType.UPDATE_INCOME_EXPENSE,
      };

    case actionType.UPDATE_INCOME_EXPENSE_SUCCESS:
      let income_expense_list_update = [...state.incomeExpenseList];
      let incomeExpenseListUpdateIndex = income_expense_list_update.findIndex(
        (x) => x.uid == action.payload.data.uid
      );
      if (incomeExpenseListUpdateIndex >= 0) {
        income_expense_list_update[incomeExpenseListUpdateIndex] = {
          ...income_expense_list_update[incomeExpenseListUpdateIndex],
          ...action.payload.data,
        };
      }

      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        incomeExpenseList: income_expense_list_update,
        incomeExpenseBtnLoading: false,
        apiStatus: actionType.UPDATE_INCOME_EXPENSE_SUCCESS,
      };

    case actionType.UPDATE_INCOME_EXPENSE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        incomeExpenseBtnLoading: false,
        apiStatus: actionType.UPDATE_INCOME_EXPENSE_FAIL,
      };

    case actionType.DELETE_INCOME_EXPENSE:
      return {
        ...state,
        incomeExpenseBtnLoading: true,
        apiStatus: actionType.DELETE_INCOME_EXPENSE,
      };

    case actionType.DELETE_INCOME_EXPENSE_SUCCESS:
      let income_expense_list_delete = [...state.incomeExpenseList];
      let incomeExpenseListDeleteIndex = income_expense_list_delete.findIndex(
        (x) => x.uid == action.payload.data.incomeExpenseUid
      );
      if (incomeExpenseListDeleteIndex >= 0) {
        income_expense_list_delete.splice(incomeExpenseListDeleteIndex, 1);
      }
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "success" },
        incomeExpenseList: income_expense_list_delete,
        incomeExpenseTotalList: parseInt(state.incomeExpenseTotalList) - 1,
        incomeExpenseBtnLoading: false,
        apiStatus: actionType.DELETE_INCOME_EXPENSE_SUCCESS,
      };

    case actionType.DELETE_INCOME_EXPENSE_FAIL:
      return {
        ...state,
        msg: { open: true, message: action.payload.msg, msgType: "error" },
        incomeExpenseBtnLoading: false,
        apiStatus: actionType.DELETE_INCOME_EXPENSE_FAIL,
      };

    case actionType.LIST_INCOME_EXPENSE:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        incomeExpenseLoading: true,
      };

    case actionType.LIST_INCOME_EXPENSE_SUCCESS:
      return {
        ...state,
        incomeExpenseList: action.payload.data,
        incomeExpenseTotalPages: action.payload.total_pages,
        incomeExpenseTotalList: action.payload.total_list,
        incomeExpenseLoading: false,
      };

    case actionType.LIST_INCOME_EXPENSE_FAIL:
      return {
        ...state,
        incomeExpenseLoading: false,
      };

    case actionType.GLOBAL_SEARCH:
      return {
        ...state,
        msg: { open: false, message: "", msgType: "" },
        globalSearchLoading: true,
      };

    case actionType.GLOBAL_SEARCH_SUCCESS:
      return {
        ...state,
        globalSearchList: action.payload.data,
        globalSearchLoading: false,
      };

    case actionType.GLOBAL_SEARCH_FAIL:
      return {
        ...state,
        globalSearchLoading: false,
      };

    case actionType.UPDATE_ALERT_MSG:
      return {
        ...state,
        msg: {
          open: action.payload.open,
          message: action.payload.message,
          msgType: action.payload.msgType,
        },
      };

    case actionType.UPDATE_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.payload,
      };

    case actionType.UPDATE_API_DATA:
      return {
        ...state,
        apiData: action.payload,
      };

    case actionType.UPDATE_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
      };

    case actionType.LOG_OUT:
      return {
        ...state,
        loginBtnLoading: false,
        userName: "",
        userEmail: "",
        userToken: "",
        registerBtnLoading: false,
        patientList: [],
        patientTotalPages: 0,
        patientTotalList: 0,
        patientLoading: false,
        patientBtnLoading: false,
        patientDetailLoading: true,
        patientDetail: null,
        patientExistDetail: [],
        consultationNoteBtnLoading: false,
        consultationNoteList: [],
        consultationNoteTotalPages: 0,
        consultationNoteTotalList: 0,
        consultationNoteLoading: false,
        allPatientListLoading: false,
        allPatientList: [],
        appointmentBtnLoading: false,
        appointmentList: [],
        appointmentLoading: false,
        patientAppointmentList: [],
        patientAppointmentTotalPages: 0,
        patientAppointmentTotalList: 0,
        patientAppointmentLoading: false,
        appointmentInvoiceBtnLoading: false,
        dashboardList: null,
        dashboardLoading: false,
        doctorList: [],
        doctorTotalPages: 0,
        doctorTotalList: 0,
        doctorLoading: false,
        doctorBtnLoading: false,
        allDoctorList: [],
        allDoctorListLoading: false,
        doctorDetailLoading: true,
        doctorDetail: null,
        userList: [],
        userTotalPages: 0,
        userTotalList: 0,
        userLoading: false,
        userBtnLoading: false,
        invoiceList: [],
        invoiceTotalPages: 0,
        invoiceTotalList: 0,
        invoiceLoading: false,
        patientInvoiceListLoading: false,
        patientInvoiceList: [],
        productList: [],
        productTotalPages: 0,
        productTotalList: 0,
        productLoading: false,
        productBtnLoading: false,
        allProductCategoryListLoading: false,
        allProductCategoryList: [],
        productCategoryList: [],
        productCategoryTotalPages: 0,
        productCategoryTotalList: 0,
        productCategoryLoading: false,
        productCategoryBtnLoading: false,
        productEnquiryList: [],
        productEnquiryTotalPages: 0,
        productEnquiryTotalList: 0,
        productEnquiryLoading: false,
        enquiryByMobileList: [],
        enquiryByMobileBtnLoading: false,
        enquiryByMobileLoading: false,
        enquiryList: [],
        enquiryTotalPages: 0,
        enquiryTotalList: 0,
        enquiryLoading: false,
        enquiryBtnLoading: false,
        enquiryDetailLoading: true,
        enquiryDetail: null,
        enquiryNoteBtnLoading: false,
        enquiryNoteList: [],
        enquiryNoteTotalPages: 0,
        enquiryNoteTotalList: 0,
        enquiryNoteLoading: false,
        moveToFollowupLoading: false,
        labelList: [],
        labelBtnLoading: false,
        labelLoading: false,
        incomeExpenseList: [],
        incomeExpenseTotalPages: 0,
        incomeExpenseTotalList: 0,
        incomeExpenseLoading: false,
        incomeExpenseBtnLoading: false,
        globalSearchList: [],
        globalSearchLoading: false,
        apiStatus: "",
        apiData: null,
        drawerOpen: true,
        msg: { open: false, message: "", msgType: "" },
        appointmentInvoiceDetailLoading: false,
        appointmentInvoiceDetail: null,
      };

    default:
      return { ...state };
  }
};

export default userDataReducer;
