import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";

import styles from "./AppointmentList.module.css";

import { connect } from "react-redux";
import { listAppointment } from "../redux/actions/userDataActions";

import AddEditPatientAppointment from "./AddEditPatientAppointment";
import AlertMsg from "./AlertMsg";
import FormButton from "./FormButton";
import Page from "./Page";

import EditIcon from '@mui/icons-material/EditOutlined';

import {UTC_OFFSET} from "../constants";

let status_filter = {
  active: { bgColor: "#dff9ec", color: "#39da8a" },
  completed: { bgColor: "#e5edfc", color: "#5a8dee" },
  cancelled: { bgColor: "#ffe5e5", color: "#ff5b5c" },
};

const AppointmentList = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(15);
  const [appointmentModalVisible, setAppointmentModalVisible] = useState(false);
  const [appointmentModalData, setAppointmentModalData] = useState(null);
  const [appointmentModalType, setAppointmentModalType] = useState("add");

  useEffect(() => {
    setTotalListCount(props.patientAppointmentTotalList);
  }, [props.patientAppointmentTotalList]);

  useEffect(() => {
    setListCount(props.patientAppointmentList?.length);
  }, [props.patientAppointmentList?.length]);

  useEffect(() => {
    getAppointmentList(1);
  }, []);

  const getAppointmentList = (page) => {
    let data = {
      page: page,
      patientUid: props.patientUid,
    };
    props.listAppointment(JSON.stringify(data));
    setPageNum(page);
  };

  const onCloseAppointmentModal = () => {
    setAppointmentModalVisible(false);
    setAppointmentModalType("add");
    setAppointmentModalData(null);
  };

  const onAddAppointment = () => {
    let duration_amount = 1;
    let duration_unit = "hours";
    setAppointmentModalVisible(true);
    setAppointmentModalType("add");
    setAppointmentModalData({
      patient_uid: props.patientUid,
      durationAmount: duration_amount,
      durationUnit: duration_unit,
    });
  };

  const onEditAppointment = (data) => {
    let duration_amount = 1;
    let duration_unit = "hours";
    setAppointmentModalType("edit");
    let data1 = {
      id: data.id,
      patient_uid: data?.patient_uid,
      appt_title: data?.appt_title,
      date: data?.start ? dayjs(data.start) : "",
      time: data?.start ? dayjs(data.start) : "",
      endTime: data?.end ? dayjs(data.end) : "",
      note: data?.note,
      provisional_diagnosis: data?.provisional_diagnosis ? data.provisional_diagnosis : "",
      consultation_fee: data?.consultation_fee ? data.consultation_fee : "",
      status: data?.status,
      reason: data?.reason,
      invoiceId: data?.invoice_id,
      invoiceUid: data?.invoice_uid,
      durationAmount: duration_amount,
      durationUnit: duration_unit,
    };
    setAppointmentModalData(data1);
    setAppointmentModalVisible(true);
  };

  const onSelectPage = (page) => {
    getAppointmentList(page);
  };

  return (
    <>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={13}>
                  <Grid
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                    mt={2}
                  >
                    <Grid item md={2} xs={2} ml={2}>
                      <FormButton
                        title={"Add"}
                        btnStyleView={style.btnStyleView}
                        onSubmit={onAddAppointment}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableHead>
              <TableRow>
                <TableCell>Appointment Date</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Provisional Diagnosis</TableCell>
                <TableCell>Consultation Fee</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Added Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.patientAppointmentLoading ? (
                <TableRow>
                  <TableCell colSpan={13} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : props.patientAppointmentList?.length > 0 ? (
                props.patientAppointmentList?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {item?.start ? moment(item.start, "YYYY-MM-DD HH:mm").format(
                        "DD-MM-YYYY hh:mm A"
                      ) + '-' +  moment(item.end, "YYYY-MM-DD HH:mm").format(
                        "hh:mm A"
                      ) : "-"}
                    </TableCell>
                    <TableCell>{item?.appt_title ? item.appt_title : "-"}</TableCell>
                    <TableCell>{item?.provisional_diagnosis ? item.provisional_diagnosis : "-"}</TableCell>
                    <TableCell>{item?.consultation_fee ? '₹'+item.consultation_fee : "-"}</TableCell>
                    <TableCell>{item?.note ? item.note : "-"}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          backgroundColor:
                            status_filter[item?.status]["bgColor"],
                        }}
                        className={styles.statusView}
                      >
                        <Typography
                          sx={{ color: status_filter[item.status]["color"] }}
                          className={styles.statusText}
                        >
                          {item?.status}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{moment.utc(item?.timestamp).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A")}</TableCell>
                    <TableCell>
                      <Box className={styles.iconMainView}>
                        <Box
                          onClick={() => onEditAppointment(item)}
                          className={styles.iconView}
                        >
                          <EditIcon fontSize="small" />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={13} align="center">
                    <Typography className={styles.noMatchFoundText}>
                      No Appointments
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid
          item
          md={12}
          xs={12}
          display={"flex"}
          flexDirection={{ md: "row", xs: "column" }}
          alignItems={"center"}
        >
          <Grid
            item
            md={6}
            xs={12}
            display={"flex"}
            justifyContent={{ md: "flex-start", xs: "unset" }}
            mt={2}
            mb={{ md: 2, xs: 0 }}
            pl={{ md: 2, xs: 0 }}
          >
            <Typography className={styles.showingEntriesText}>
              Showing{" "}
              {listCount == 0
                ? 0
                : pageNum == 1
                ? 1
                : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
              to{" "}
              {listCount == 0
                ? 0
                : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
              of {totalListCount} entries
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            display={"flex"}
            justifyContent={{ md: "flex-end", xs: "unset" }}
            mt={2}
            mb={2}
            pr={{ md: 2, xs: 0 }}
          >
            <Page
              totalPages={props.patientAppointmentTotalPages}
              pageNum={pageNum}
              onSelectPage={onSelectPage}
            />
          </Grid>
        </Grid>
      </Grid>

      <AlertMsg />
      <AddEditPatientAppointment
        showPatient={false}
        type={appointmentModalType}
        data={appointmentModalData}
        modalVisible={appointmentModalVisible}
        onClose={onCloseAppointmentModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    patientAppointmentList: state.userData.patientAppointmentList,
    patientAppointmentTotalPages: state.userData.patientAppointmentTotalPages,
    patientAppointmentTotalList: state.userData.patientAppointmentTotalList,
    patientAppointmentLoading: state.userData.patientAppointmentLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listAppointment: (data) => dispatch(listAppointment(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentList);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
};
