import React, { useEffect, useState, useRef } from "react";
import {Grid, Typography, Box, Modal, CircularProgress} from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";

import { connect } from "react-redux";
import {
  ADD_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_SUCCESS,
  ADD_PATIENT_SUCCESS,
  UPDATE_APPOINTMENT_STATUS_SUCCESS,
  UPDATE_INVOICE_APPOINTMENT_SUCCESS
} from "../redux/actions/types";
import {
  addAppointment,
  updateApiStatus,
  updateAppointment,
  addPatient,
  updateAppointmentStatus,
  updateInvoiceAppointment, getPatientInvoiceList
} from "../redux/actions/userDataActions";

import FormDatePicker from "./FormDatePicker";
import FormTimePicker from "./FormTimePicker";
import FormButton from "../components/FormButton";
import FormSelectize from "./FormSelectize";
import FormTextArea from "./FormTextArea";
import ConfirmAlertBox from "./ConfirmAlertBox";
import AddEditPatientModal from "./AddEditPatientModal";
import SelectPatientInvoiceModal from "../components/SelectPatientInvoiceModal";
import FormTextInput from "./FormTextInput";
import { MOBILE_VIEW } from "./Sidebar";

import {capitalizeFirstLetter} from "../assets/functions/common";

import styles from "./AddEditPatientAppointment.module.css";

import CloseIcon from '@mui/icons-material/CloseOutlined';
import ExclaimIcon from '@mui/icons-material/ErrorOutlineOutlined';

const AddEditPatientAppointment = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [patient, setPatient] = useState(null);
  const [title, setTitle] = useState("");
  const [dateVal, setDateVal] = useState("");
  const [timeVal, setTimeVal] = useState("");
  const [endTimeVal, setEndTimeVal] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState(null);
  const [statusVal, setStatusVal] = useState('');
  const [newStatusVal, setNewStatusVal] = useState('');
  const [visible, setVisible] = useState(false);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [confirmBoxType, setConfirmBoxType] = useState("");
  const [addPatientModalVisible, setAddPatientModalVisible] = useState(false);
  const [selectedPatientData, setSelectedPatientData] = useState(null);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [linkedInvoiceId, setLinkedInvoiceId] = useState("");
  const [startTimeList, setStartTimeList] = useState([]);
  const [endTimeList, setEndTimeList] = useState([]);
  const [appointmentNewStatus, setAppointmentNewStatus] = useState('');
  const [provisionalDiagnosis, setProvisionalDiagnosis] = useState('');
  const [consultationFee, setConsultationFee] = useState('');

  const patientRef = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    if (props.data != null) {
      if (props.type == "add") {
        setDateVal(props.data?.date ? props.data.date : dayjs());
        setTimeVal(props.data?.time ? dayjs(props.data.time).format("hh:mm A") : "");
        setEndTimeVal(props.data?.endTime ? dayjs(props.data.endTime).format("hh:mm A") : "");

        if (!props.showPatient) {
          let list = props.allPatientList.filter(
            (x) => x.uid == props.data?.patient_uid
          );
          if (list.length) {
            setPatient(list[0]);
          }
        }
        setTitle("");
        setNote("");
        setProvisionalDiagnosis("");
        setConsultationFee("");
        setStatusVal('');
        setDisabled(false);
        setError(null);
      } else {
        setDateVal(props.data?.date);
        setTimeVal(dayjs(props.data?.time).format("hh:mm A"));

        setEndTimeVal(props.data?.endTime ? dayjs(props.data?.endTime).format("hh:mm A") : "");
        setTitle(props.data?.appt_title);
        setNote(props.data?.note);
        setProvisionalDiagnosis(props.data?.provisional_diagnosis);
        if(props.data?.consultation_fee == 0) {
          setConsultationFee("");
        } else {
          setConsultationFee(props.data?.consultation_fee);
        }
        let list = props.allPatientList.filter(
          (x) => x.uid == props.data.patient_uid
        );
        if (list.length) {
          setPatient(list[0]);
        }
        let data1 = {
          patientUid: props?.data?.patient_uid
        };
        props.getPatientInvoiceList(JSON.stringify(data1));
      }

      setStatusVal(props?.data?.status);
      setDisabled(!(
        props.type == "add" ||
        (props.type == "edit" && props.data.status == "active")
      ))
    }
  }, [props.data]);

  useEffect(() => {
    if (props.modalVisible) {
      setVisible(true);
    } else {
      setPatient(null);
      setTitle("");
      setDateVal(dayjs());
      setTimeVal("");
      setEndTimeVal("");
      setNote("");
      setProvisionalDiagnosis("");
      setConsultationFee("");
      setStatusVal('');
      setDisabled(false);
      setError(null);
    }
  }, [props.modalVisible]);

  useEffect(() => {
    if(visible) {
      if(props?.showPatient) {
        if(patientRef?.current) {
          patientRef?.current?.focus()
        }
      } else {
        if(titleRef?.current) {
          titleRef?.current?.focus()
        }
      }
    }
  },[visible]);

  useEffect(() => {
    setLoading(false);
  },[props.patientInvoiceList])

  useEffect(() => {
    if (
      (props.apiStatus == ADD_APPOINTMENT_SUCCESS ||
        props.apiStatus == UPDATE_APPOINTMENT_SUCCESS) &&
      props.modalVisible
    ) {
      setError(null);
      setConfirmBoxVisible(false);
      setTimeout(() => {
        setConfirmBoxType("");
      }, 300);
      if(newStatusVal == 'completed') {
        setStatusVal('completed');
        setDisabled(true);

        if (props.apiStatus == UPDATE_APPOINTMENT_SUCCESS) {
          let data1 = {
            patientUid: patient?.uid
          };
          props.getPatientInvoiceList(JSON.stringify(data1));
        }

      } else {
        props.onClose();
      }
      props.updateApiStatus("");
    } else if (props.apiStatus == UPDATE_APPOINTMENT_STATUS_SUCCESS) {
      if (confirmBoxVisible) {
        setConfirmBoxVisible(false);
        setConfirmBoxType("");
        props.onClose();
      } else {
        if(newStatusVal == 'completed') {
          setStatusVal('completed');
          setDisabled(true);
        } else {
          props.onClose();
        }
      }
      props.updateApiStatus("");
    } else if (props.apiStatus == UPDATE_INVOICE_APPOINTMENT_SUCCESS) {
      if (invoiceModalVisible) {
        setInvoiceModalVisible(false);
        navigate("/invoice/" + linkedInvoiceId);
      } else {
        setLinkedInvoiceId("");
      }
      props.onClose();
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if (props.modalVisible) {
      let date_val = dayjs().format("YYYY-MM-DD");
      if (props.data?.date) {
        date_val = dayjs(props.data?.date).format("YYYY-MM-DD");
      }
      let current_date = dayjs().format("YYYY-MM-DD");

      if (props.type == 'add') {
        let start_time = moment('08:00', 'HH:mm').format('hh:mm A');
        let end_time = moment('20:00', 'HH:mm').format('hh:mm A');
        if (props.data?.time && props.data?.endTime) {
          let time1 = dayjs(props.data.time).format("hh:mm A");
          let end_time2 = moment(time1, 'hh:mm A').add(props?.data?.durationAmount, props?.data?.durationUnit).format('hh:mm A');
          let end_time3 = moment(time1, 'hh:mm A').add(15, 'm').format('hh:mm A');
          setTimeVal(time1);
          setEndTimeVal(end_time2);
          calculateStartTime(start_time, end_time, time1, end_time2);
          // calculateStartTime(time1, end_time, time1, end_time2);
          calculateEndTime(end_time3, end_time, time1, end_time2);
        } else {
          if (date_val == current_date) {
            let currentTime = moment().add(15, 'minutes');
            currentTime.minutes(Math.floor(currentTime.minutes() / 15) * 15);
            start_time = currentTime.format('hh:mm A');
          }

          let end_time2 = moment(start_time, 'hh:mm A').add(props?.data?.durationAmount, props?.data?.durationUnit).format('hh:mm A');
          let end_time3 = moment(start_time, 'hh:mm A').add(15, 'm').format('hh:mm A');
          setTimeVal(start_time);
          setEndTimeVal(end_time2);
          calculateStartTime(start_time, end_time, start_time, end_time2);
          calculateEndTime(end_time3, end_time, start_time, end_time2);
        }
      } else {
        let start_time = moment('08:00', 'HH:mm').format('hh:mm A');
        let time = moment('08:00', 'HH:mm').format('hh:mm A');
        let end_time = moment('20:00', 'HH:mm').format('hh:mm A');
        let end_time2 = moment(start_time, 'hh:mm A').add(15, 'm').format('hh:mm A');

        if (props.data?.time && props.data?.endTime) {
          time = dayjs(props.data.time).format("hh:mm A");
          // if (time < start_time) {
          //   start_time = time;
          // }

          end_time2 = dayjs(props.data?.time).format('hh:mm A');
          end_time2 = moment(end_time2, 'hh:mm A').add(15, 'm').format('hh:mm A');

          calculateStartTime(start_time, end_time, time, dayjs(props.data?.endTime).format('hh:mm A'));
          calculateEndTime(end_time2, end_time, time, dayjs(props.data?.endTime).format('hh:mm A'));

        } else if (props.data?.time) {
          time = dayjs(props.data.time).format("hh:mm A");
          // if (time < start_time) {
          //   start_time = time;
          // }

          end_time2 = dayjs(props.data?.time).format("hh:mm A");
          end_time2 = moment(end_time2, 'hh:mm A').add(15, 'm').format('hh:mm A');

          calculateStartTime(start_time, end_time, time, end_time2);
          calculateEndTime(end_time2, end_time, time, end_time2);
        }
      }
    }
  }, [props.modalVisible, props.type, props.data])

  const calculateStartTime = (start_time, end_time, s_time, e_time) => {
    let startTime = start_time;
    let endTime = end_time;

    let startTimeStops = [];

    if (moment(startTime, 'hh:mm A').format('HH:mm') >= moment('20:00', 'HH:mm').format('HH:mm')) {
      startTime = moment('08:00', 'HH:mm').format('hh:mm A');
    }

    if (moment(s_time, 'hh:mm A').format('HH:mm') >= moment('20:00', 'HH:mm').format('HH:mm')) {
      endTime = moment(s_time, 'hh:mm A').add(15, 'm').format('hh:mm A');
    }

    while (moment(startTime, 'hh:mm A').format('HH:mm') < moment(endTime, 'hh:mm A').format('HH:mm')) {
      startTimeStops.push(
        {
          uid: startTime,
          name: startTime,
          value: startTime
        }
      );
      startTime = moment(startTime, 'hh:mm A').add(15, 'm').format('hh:mm A')
    }

    setStartTimeList(startTimeStops);
  };

  const calculateEndTime = (start_time, end_time, time_val, e_time) => {
    let startTime = start_time;
    let endTime = end_time;
    let endTimeStops = [];

    endTime = moment(endTime, 'hh:mm A').add(15, 'm').format('hh:mm A')
    if ((moment(e_time, 'hh:mm A').format('HH:mm') == moment('24:00', 'HH:mm').format('HH:mm'))
      || (moment(e_time, 'hh:mm A').format('HH:mm') == moment('00:30', 'HH:mm').format('HH:mm'))) {
      setEndTimeVal(moment('20:00', 'HH:mm').format('hh:mm A'));
    }

    if (moment(e_time, 'hh:mm A').format('HH:mm') >= moment('20:00', 'HH:mm').format('HH:mm')) {
      endTime = moment('20:00', 'HH:mm').format('hh:mm A');
    }

    while (moment(startTime, 'hh:mm A').format('HH:mm') < moment(endTime, 'hh:mm A').format('HH:mm')) {
      let difference_text = '';
      if (time_val) {
        let diff_val = moment.duration(moment(startTime, 'hh:mm A').diff(moment(time_val, 'hh:mm A')));
        let diff_hrs = diff_val.hours();
        let diff_mins = diff_val.minutes();
        let difference = diff_val.hours() + ' hr ' + diff_val.minutes() + ' mins';
        if (diff_hrs == 0) {
          difference = diff_val.minutes() + ' mins';
        } else if (diff_mins == 0) {
          if (diff_val.hours() == 1) {
            difference = diff_val.hours() + ' hr';
          } else {
            difference = diff_val.hours() + ' hrs';
          }
        } else if ((diff_val.minutes() == 15) || (diff_val.minutes() == 30) || (diff_val.minutes() == 45)) {
          let min_to_hr = diff_val.minutes() / 60;
          difference = parseInt(diff_val.hours()) + min_to_hr + ' hrs'
        }
        difference_text = " (" + difference + ")";
      }

      endTimeStops.push({
        uid: startTime,
        name: startTime,
        value: startTime,
        difference: difference_text,
      });
      startTime = moment(startTime, 'hh:mm A').add(15, 'm').format('hh:mm A')
    }

    setEndTimeList(endTimeStops);
  };

  const onSubmitClick = (status) => {
    let errorText = {};
    if (patient == null) {
      errorText = { patient: "Select patient" };
    }

    if (title == "") {
      errorText = { ...errorText, title: "Title is required" };
    }

    if (dateVal == "" || dateVal == null) {
      errorText = { ...errorText, date: "Date is required" };
    }

    if (timeVal == "") {
      errorText = { ...errorText, time: "Start Time is required" };
    }

    if (endTimeVal == "") {
      errorText = { ...errorText, endTime: "End Time is required" };
    }

    if (consultationFee != "" && consultationFee != 0) {
      if (!/^[0-9.]{1,100}$/.test(consultationFee)) {
        errorText = { ...errorText, consultationFee: "Invalid Consultation Fee" };
      }
    }

    if(timeVal != "" && endTimeVal != "") {
      let time_val = dayjs(timeVal, 'HH:mm A').format("HHmm");
      let end_time_val = dayjs(endTimeVal, 'HH:mm A').format("HHmm");
      if (end_time_val <= time_val) {
        errorText = {...errorText, endTime: "End time must be greater than start time"};
      }
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    setAppointmentNewStatus(status)

    let time_val = dayjs(timeVal, 'hh:mm A').format("HHmm");
    if (time_val < 800 || time_val > 2000) {
      setConfirmBoxType('time');
      setConfirmBoxVisible(true);
      return
    }

    let end_time_val = dayjs(endTimeVal, 'hh:mm A').format("HHmm");
    if (end_time_val < 800 || end_time_val > 2000) {
      setConfirmBoxType('time');
      setConfirmBoxVisible(true);
      return
    }

    onAddUpdateAppointmentData(status);
  };

  const onAddUpdateAppointmentData = (status) => {
    setNewStatusVal(status);

    let data = {
      patientUid: patient?.uid,
      title: title,
      startDate: dayjs(dateVal).format("YYYY-MM-DD"),
      startTime: dayjs(timeVal, 'HH:mm A').format("HHmm"),
      endTime: dayjs(endTimeVal, 'HH:mm A').format("HHmm"),
      note: note,
      status: status,
      provisionalDiagnosis: provisionalDiagnosis,
      consultationFee: consultationFee,
    };

    if (props.type == "add") {
      props.addAppointment(JSON.stringify(data));
    } else {
      data = { ...data, appointmentUid: props.data?.id };
      props.updateAppointment(JSON.stringify(data));
    }
  }

  const onCloseClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onClose();
    }, [100]);
  };

  const onCancelClick = () => {
    setConfirmBoxType("cancel");
    setConfirmBoxVisible(true);
  };

  const onDeleteClick = () => {
    setConfirmBoxType("delete");
    setConfirmBoxVisible(true);
  };

  const onInvoiceClick = () => {
    if (props.data?.invoiceUid) {
      navigate("/invoice/" + props.data?.invoiceUid);
    } else {
      navigate("/invoice?id=" + props.data?.id);
    }
  };

  const onLinkInvoiceClick = () => {
    setInvoiceModalVisible(true);
  };

  const onUpdateApptStatus = (status, reason = null) => {
    setNewStatusVal(status);
    let data = {
      patientUid: patient?.uid,
      appointmentUid: props.data?.id,
      status: status,
      current_status: statusVal,
    };
    if (reason) {
      data = { ...data, reason: reason };
    }
    props.updateAppointmentStatus(JSON.stringify(data));
  };

  const onCancelDeleteAppointment = () => {
    setConfirmBoxVisible(false);
    setTimeout(() => {
      setConfirmBoxType("");
    }, 300);
  };

  const onConfirmAppointmentTime = () => {
    onAddUpdateAppointmentData(appointmentNewStatus);
  };

  const onAddNewPatient = (val) => {
    let first_name = "";
    let last_name = "";
    const arr = val.split(" ");
    if (arr.length >= 2) {
      const name = arr.slice(0, 1).concat(arr.slice(1).join(" "));
      first_name = capitalizeFirstLetter(name[0]);
      last_name = capitalizeFirstLetter(name[1]);
    } else {
      first_name = capitalizeFirstLetter(val);
    }
    setSelectedPatientData({
      uid: "",
      first_name: first_name,
      last_name: last_name,
      age: "",
      gender: "M",
      address: "",
      mobile: "",
      email: "",
    });
    setAddPatientModalVisible(true);
  };

  const onSubmitNewPatient = () => {
    setPatient(props.allPatientList[0]);
    setAddPatientModalVisible(false);
  };

  const onCancelPatient = () => {
    setAddPatientModalVisible(false);
  };

  const onSubmitPatientInvoice = (data) => {
    setLinkedInvoiceId(data?.uid);
    let dat = {
      invoice_uid: data?.uid,
      appointment_uid: props.data?.id
    };
    props.updateInvoiceAppointment(JSON.stringify(dat));
  };

  const onClosePatientInvoice = () => {
    setInvoiceModalVisible(false)
  };

  const onChangeDateTime = (type, date, start_time, end_time) => {
    let date_val = dayjs(date).format("YYYY-MM-DD");
    let current_date = dayjs().format("YYYY-MM-DD");

    let startTime = start_time;
    let endTime = end_time;
    let startTimeLists = moment('08:00', 'HH:mm').format('hh:mm A');
    let endTimeLists = moment('20:00', 'HH:mm').format('hh:mm A');

    if (type == 'date') {
      if (date_val == current_date) {
        let currentTime = moment().add(15, 'minutes');
        currentTime.minutes(Math.floor(currentTime.minutes() / 15) * 15);
        startTime = currentTime.format('hh:mm A');
      } else {
        startTime = startTimeLists;
      }
      endTime = moment(startTime, 'hh:mm A').add(props?.data?.durationAmount, props?.data?.durationUnit).format('hh:mm A')
      let endTime2 = moment(startTime, 'hh:mm A').add(15, 'm').format('hh:mm A')

      setTimeVal(startTime);
      setEndTimeVal(endTime);

      calculateStartTime(startTimeLists, endTimeLists, startTime, endTime);
      calculateEndTime(endTime2, endTimeLists, startTime, endTime);
    } else if (type == 'start_time') {
      // if (startTime < startTimeLists) {
      //   startTimeLists = startTime;
      // }

      endTime = moment(startTime, 'hh:mm A').add(props?.data?.durationAmount, props?.data?.durationUnit).format('hh:mm A');

      if (moment(endTime, 'hh:mm A').format('HH:mm') >= moment('20:00', 'HH:mm').format('HH:mm')) {
        endTime = moment('20:00', 'HH:mm').format('hh:mm A');
      }

      let endTime2 = moment(startTime, 'hh:mm A').add(15, 'm').format('hh:mm A')
      setEndTimeVal(endTime);

      calculateStartTime(startTimeLists, endTimeLists, startTime, endTime);
      calculateEndTime(endTime2, endTimeLists, startTime, endTime);
    } else if (type == 'end_time') {
    }
  }

  return (
    <Modal
      onClose={onCloseClick}
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
    >
      {loading ?
        <Grid
          container
          item
          md={6}
          xs={11}
          className={[
            styles.modalSize,
            visible ? styles.modalVisibleSize : styles.modalInitialSize,
          ]}
        >
          <CircularProgress/>
        </Grid>
        :
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{ md: 8, xs: 4 }}
      >
        <Grid
          onClick={() => onCloseClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle} />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            {props?.type == "add"
              ? "Add"
              : statusVal == "active"
              ? "Edit"
              : ""}{" "}
            Appointment
          </Typography>
        </Grid>

        <>
          <Grid item md={12} xs={12} mt={3}>
            {props?.showPatient ? (
              <FormSelectize
                page={"AddAppointmentModal"}
                value={patient}
                label={"PATIENT"}
                inputRef={patientRef}
                multiple={false}
                addOption={true}
                onChange={(val) => {
                  setPatient(val);
                }}
                options={props.allPatientList}
                disabled={disabled}
                error={error?.patient}
                onAdd={onAddNewPatient}
              />
            ) : null}
          </Grid>
          <Grid item md={12} xs={12} mt={3}>
            <FormTextInput
              page={"AddAppointmentModal"}
              error={error?.title}
              inputRef={titleRef}
              value={title}
              setValue={setTitle}
              label={"TITLE"}
              disabled={disabled}
            />
          </Grid>
          <Grid item md={12} xs={12} mt={3}>
            <FormDatePicker
              page={"AddAppointmentModal"}
              value={dateVal}
              // minDate={dayjs()}
              inputFormat="DD/MM/YYYY"
              onChange={(newValue) => {
                setDateVal(newValue);
                onChangeDateTime('date', newValue, timeVal, endTimeVal);
              }}
              label={"DATE"}
              disabled={disabled}
              error={error?.date}
            />
          </Grid>
          <Grid item md={5.8} xs={12} mt={3}>
            <FormSelectize
              page={"AddAppointmentModal"}
              value={timeVal}
              label={"START TIME"}
              multiple={false}
              addOption={false}
              onChange={(val) => {
                setTimeVal(val?.name);
                onChangeDateTime('start_time', dateVal, val?.name, endTimeVal);
              }}
              options={startTimeList}
              disabled={disabled}
              disableClearable={true}
              error={error?.time}
            />
          </Grid>
          <Grid item md={5.8} xs={12} mt={3}>
            <FormSelectize
              page={"AddAppointmentModal"}
              value={endTimeVal}
              label={"END TIME"}
              multiple={false}
              addOption={false}
              onChange={(val) => {
                setEndTimeVal(val?.name);
                onChangeDateTime('end_time', dateVal, timeVal, val?.name);
              }}
              options={endTimeList}
              disabled={disabled}
              disableClearable={true}
              error={error?.endTime}
            />
          </Grid>
          <Grid item md={12} xs={12} mt={3}>
            <FormTextInput
              page={"AddAppointmentModal"}
              error={error?.provisionalDiagnosis}
              value={provisionalDiagnosis}
              setValue={setProvisionalDiagnosis}
              label={"PROVISIONAL DIAGNOSIS"}
              disabled={disabled}
            />
          </Grid>
          {props.type == "edit" ? (
            <Grid item md={12} xs={12} mt={3}>
              <FormTextInput
                page={"AddAppointmentModal"}
                error={error?.consultationFee}
                value={consultationFee}
                setValue={setConsultationFee}
                label={"CONSULTATION FEE"}
                disabled={disabled}
              />
            </Grid>
          ) : null}
          <Grid item md={12} xs={12} mt={3}>
            <FormTextArea
              page={"AddAppointmentModal"}
              value={note}
              setValue={setNote}
              label={"COMMENTS"}
              disabled={disabled}
              error={error?.note}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            mt={2}
            display={"flex"}
            flexDirection={MOBILE_VIEW ? "column" : "row"}
            alignItems={"center"}
            justifyContent={"space-around"}
          >
            {props.type == "add" ||
            (props.type == "edit" && statusVal == "active") ? (
              <>
                {props.type == "edit" ? (
                  <Box className={styles.btnView} mt={1}>
                    <FormButton
                      btnStyleView={style.btnStyleView}
                      btnType={"btn4"}
                      title={"Cancel"}
                      onSubmit={onCancelClick}
                    />
                    <Box ml={{ md: 1, xs: 1 }}>
                      <FormButton
                        btnStyleView={style.btnStyleView}
                        btnType={"btn2"}
                        title={"Delete"}
                        onSubmit={onDeleteClick}
                      />
                    </Box>
                  </Box>
                ) : null}
                <Box className={styles.btnView} mt={1}>
                  <FormButton
                    btnStyleView={style.btnStyleView}
                    btnType={props.type == "add" ? "btn1" : "btn5"}
                    title={props.type == "add" ? "Submit" : "Update"}
                    onSubmit={() => onSubmitClick('active')}
                    loading={appointmentNewStatus == 'active' && props.appointmentBtnLoading}
                  />
                  {props.type == "edit" ? (
                    <Box ml={{ md: 1, xs: 1 }}>
                      <FormButton
                        btnStyleView={style.btnStyleView}
                        btnType={"btn1"}
                        title={"Complete"}
                        loading={appointmentNewStatus == 'completed' && props.appointmentBtnLoading}
                        onSubmit={() => onSubmitClick("completed")}
                      />
                    </Box>
                  ) : null}
                </Box>
              </>
            ) : null}
            {statusVal == "completed" ? (
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <FormButton
                  btnStyleView={style.btnStyleView}
                  btnType={"btn1"}
                  title={props.data?.invoiceId ? "View Invoice" : "Add Invoice"}
                  onSubmit={onInvoiceClick}
                />
                {props.patientInvoiceList?.length > 0 ?
                  props.data != null ?
                  (props.data?.invoiceUid == '' && statusVal == 'completed') ?
                    <Box ml={{md: 2, xs: 1}}>
                      <FormButton
                        btnStyleView={style.btnStyleView}
                        btnType={"btn1"}
                        title={"Link Invoice"}
                        onSubmit={onLinkInvoiceClick}
                      />
                    </Box>
                    : null
                  : null
                : null}
                <Box ml={{md: 2, xs: 1}}>
                  <FormButton
                    btnStyleView={style.btnStyleView}
                    btnType={"btn2"}
                    title={"Delete"}
                    onSubmit={onDeleteClick}
                  />
                </Box>
              </Box>
            ) : null}
            {props.data?.reason ? (
              <Box sx={{ width: "100%" }}>
                <span className={styles.reasonTitle}>Cancelled Reason: </span>
                <span className={styles.reasonValue}>{props.data?.reason}</span>
              </Box>
            ) : null}
          </Grid>
        </>

        <AddEditPatientModal
          type={"add"}
          data={selectedPatientData}
          modalVisible={addPatientModalVisible}
          onCancel={onCancelPatient}
          onSubmit={onSubmitNewPatient}
        />

        <SelectPatientInvoiceModal
          modalVisible={invoiceModalVisible}
          patient={patient}
          data={props.patientInvoiceList}
          onClose={onClosePatientInvoice}
          onSubmit={onSubmitPatientInvoice}
        />

        <ConfirmAlertBox
          confirmBox={confirmBoxVisible}
          icon={<ExclaimIcon sx={{height: 80, width: 80, color: '#ffc700'}}/>}
          title={"Are you sure?"}
          content={confirmBoxType == 'time' ?
            "Your are adding an appointment that's outside working hours. Do you wish to continue."
            : "You won't be able to revert this!"}
          btnLoading={confirmBoxType == 'time' ? false : props.appointmentBtnLoading}
          firstBtn={confirmBoxType == 'time' ? 'Yes' : `Yes, ${confirmBoxType} it!`}
          showTextInput={confirmBoxType == "cancel"}
          textInputMandatory={confirmBoxType == "cancel"}
          textInputPlaceholder={"Cancellation Reason"}
          secondBtn={confirmBoxType == "cancel" || confirmBoxType == 'time' ? "No" : "Cancel"}
          onConfirmClick={confirmBoxType == 'time' ?
            onConfirmAppointmentTime
            : (note) =>
            onUpdateApptStatus(
              confirmBoxType == "cancel" ? "cancelled" : "removed",
              note
            )
          }
          onCancelClick={onCancelDeleteAppointment}
        />

      </Grid>}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAppointment: (data) => dispatch(addAppointment(data)),
    updateAppointment: (data) => dispatch(updateAppointment(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
    addPatient: (data) => dispatch(addPatient(data)),
    updateAppointmentStatus: (data) => dispatch(updateAppointmentStatus(data)),
    updateInvoiceAppointment: (data) => dispatch(updateInvoiceAppointment(data)),
    getPatientInvoiceList: (data) => dispatch(getPatientInvoiceList(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    appointmentBtnLoading: state.userData.appointmentBtnLoading,
    apiStatus: state.userData.apiStatus,
    allPatientList: state.userData.allPatientList,
    patientInvoiceList: state.userData.patientInvoiceList,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditPatientAppointment);

const style = {
  btnStyleView: {
    height: 40,
    minWidth: 100,
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
};
