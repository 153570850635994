import React, {useEffect, useState} from "react";
import {Grid, Box, Card, Typography, styled} from "@mui/material";

import {connect} from "react-redux";
import {getDashboard} from "../../redux/actions/userDataActions";

import Container from "../../components/Container";
import DashboardUpcomingAppointmentList from "../../components/DashboardUpcomingAppointmentList";
import DashboardNextFollowUpList from "../../components/DashboardNextFollowUpList";
import Loader from "../../components/Loader";
import FormSelectBox from "../../components/FormSelectBox";

import styles from "../../components/Dashboard.module.css";

import DownArrowIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

export const GRAPH_VIEW_HEIGHT = 420;
const GRAPH_VIEW_PADDING = 1.5;

const month_week_list = [
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
];

const Dashboard = (props) => {
  const [monthWeek, setMonthWeek] = useState(month_week_list[0]);

  useEffect(() => {
    if (monthWeek != null) {
      let month_week = monthWeek?.value;
      getDashboardList(month_week);
    }
  }, [monthWeek]);

  const getDashboardList = (month_week) => {
    let data = {
      monthWeek: month_week
    };
    props.getDashboard(JSON.stringify(data));
  };

  if (props.dashboardLoading) {
    return (
      <Container page={"dashboard"}>
        <Loader/>
      </Container>
    );
  }

  return (
    <Container page={"dashboard"} title={"Dashboard"}>
      <Grid id={"dashboardDiv"} container item xs={12}>

        <Grid
          item
          md={2}
          xs={12}
          mb={2}
        >
          <FormSelectBox
            page={"Dashboard"}
            value={monthWeek}
            setValue={setMonthWeek}
            placeholder={"Select"}
            height={40}
            rightIcon={<DownArrowIcon sx={{color: "#677788"}}/>}
            data={month_week_list}
          />
        </Grid>

        <Grid container item xs={12}
              // mt={GRAPH_VIEW_PADDING + 2}
              display={'flex'}
              justifyContent={'space-between'}
        >
          <Grid
            item xs={12} md={5.9}
            className={styles.cardView}
          >
            <DashboardUpcomingAppointmentList
              data={props.dashboardList}/>
          </Grid>

          <Grid
            item
            xs={12}
            md={5.9}
            mt={{xs: GRAPH_VIEW_PADDING + 2, md: 0}}
            className={styles.cardView}
          >
            <DashboardNextFollowUpList
              data={props.dashboardList}/>
          </Grid>

        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerOpen: state.userData.drawerOpen,
    dashboardList: state.userData.dashboardList,
    dashboardLoading: state.userData.dashboardLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboard: (data) => dispatch(getDashboard(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
