import * as React from 'react';
import {Box} from '@mui/material';

import FormButton from "./FormButton";

const TabButtons = (props) => {

  const onButtonClick = (item) => {
    props.onButtonClick(item);
  };

  return (
    props.data?.map((item, index) => {
      return (
        <Box key={index} mr={2}
             sx={
               item?.iconType == 'stroke' ? {
                   path: {
                     stroke: props.selectedTab === item?.value ? '#FFF' : '#677788',
                   }
                 }
                 :
                 {
                   path: {
                     fill: props.selectedTab === item?.value ? '#FFF' : '#677788',
                   }
                 }
             }>
          <FormButton
            title={item?.label}
            startIcon={item?.icon}
            btnType={props.selectedTab == item?.value ? 'btn1' : 'btn3'}
            btnStyleView={style.btnStyleView}
            buttonHoverView={style.buttonHoverView}
            onSubmit={() => onButtonClick(item)}
          />
        </Box>
      )
    })
  );
};

export default TabButtons;

const style = {
  btnStyleView: {
    height: 40,
    width: '100%'
  },
  buttonHoverView: {
    backgroundColor: '#7ba4f1'
  }
}
