export const ENV = 'PROD';
export const AUTHENTICATION_FAILED = 'Authentication Failed';

export const THEME_COLOR = '#5a8dee';
export const THEME_COLOR2 = '#f3f4f4';
export const THEME_COLOR3 = '#677788';
export const LABEL_COLOR = '#008000';

export const GST = '32ASDPJ3313Q1ZS';
export const CLINIC_ADDRESS = 'Behind Indira Gandhi Cooperative Hospital, Subash Road, Kadavanthara.';
export const CLINIC_ADDRESS2 = 'Kochi - 682020';
export const CLINIC_MOBILE = '+91 95910 76990';

export const UTC_OFFSET = "+05:30";

export const NAVBAR_HEIGHT = 64;
export const FOOTER_HEIGHT = 40;
