import React from 'react';
import Pagination from '@mui/material/Pagination';
import {createTheme, ThemeProvider} from "@mui/material/styles";

import {THEME_COLOR} from "../constants";

const Page = (props) => {

  const theme = createTheme({
    palette: {
      theme_color: {
        main: THEME_COLOR,
      },
    },
  });

  const handleChange = (event, value) => {
    props.onSelectPage(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Pagination
        sx={{
          '& .MuiPaginationItem-root.Mui-selected': {
            color: '#FFF',
            ":hover": {
              backgroundColor: THEME_COLOR,
              color: "#FFF"
            }
          },
          '& .MuiPaginationItem-root': {
            backgroundColor: '#eeeff1',
            color: '#677788',
            ":hover": {
              backgroundColor: '#dce0e4',
              color: '#677788'
            }
          }
        }}
        count={props.totalPages}
        page={props.pageNum}
        onChange={handleChange}
        color="theme_color"
        shape={"rounded"}/>
    </ThemeProvider>
  );
};

export default Page;
