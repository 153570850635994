import React, {useEffect, useState} from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Container from "../../components/Container";
import Page from "../../components/Page";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import AddEditUserModal from "../../components/AddEditUserModal";
import UseDebounce from "../../components/UseDebounce";
import ConfirmAlertBox from "../../components/ConfirmAlertBox";
import AlertMsg from "../../components/AlertMsg";
import {MOBILE_VIEW} from "../../components/Sidebar";
import ChangePasswordModal from "../../components/ChangePasswordModal";

import {connect} from "react-redux";
import {DELETE_USER_SUCCESS} from "../../redux/actions/types";
import {
  listUser,
  deleteUser,
  updateApiStatus,
} from "../../redux/actions/userDataActions";

import styles from "./Users.module.css";

import "../../assets/css/custom.css";

import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyIcon from '@mui/icons-material/VpnKeyOutlined';
import PlusIcon from '@mui/icons-material/AddOutlined';
import ExclaimIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { UTC_OFFSET } from "../../constants";

const Users = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);

  const debouncedSearch = UseDebounce(search, 500);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == DELETE_USER_SUCCESS && confirmBoxVisible) {
      setSelectedUserData(null);
      setConfirmBoxVisible(false);
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  useEffect(() => {
    setTotalListCount(props.userTotalList);
  }, [props.userTotalList]);

  useEffect(() => {
    setListCount(props.userList?.length);
  }, [props.userList?.length]);

  useEffect(() => {
    getUserList(1);
  }, [debouncedSearch]);

  const getUserList = (page) => {
    let data = {
      page: page,
      searchUser: search,
    };
    props.listUser(JSON.stringify(data));
    setPageNum(page);
  };

  const onAddEditUserBtnClick = (e, val, item) => {
    e.stopPropagation();
    setSelectedUserData(item);
    setType(val);
    setModalVisible(true);
  };

  const onAddEditUser = () => {
    setSelectedUserData(null);
    setModalVisible(false);
  };

  const onCancelUser = () => {
    setSelectedUserData(null);
    setModalVisible(false);
  };

  const onSelectPage = (page) => {
    getUserList(page);
  };

  const onSearchUsers = (val) => {
    setSearch(val);
  };

  const onDeleteUserBtnClick = (e, item) => {
    e.stopPropagation();
    setSelectedUserData(item);
    setConfirmBoxVisible(true);
  };

  const onConfirmDeleteUser = () => {
    let data = {
      userUid: selectedUserData?.uid,
    };
    props.deleteUser(JSON.stringify(data));
  };

  const onCancelDeleteUser = () => {
    setSelectedUserData(null);
    setConfirmBoxVisible(false);
  };

  const onChangePasswordClick = (e, item) => {
    e.stopPropagation();
    setSelectedUserData(item);
    setChangePasswordModalVisible(true);
  };

  const onCancelChangePassword = () => {
    setSelectedUserData(null);
    setChangePasswordModalVisible(false);
  };

  const onChangePassword = () => {
    setSelectedUserData(null);
    setChangePasswordModalVisible(false);
  };

  return (
    <Container page={"users"}>
      <Grid container item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={styles.cardView}
        >
          <TableContainer className={styles.tableView}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>
                    <Grid
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"flex-end"}
                      mt={2}
                    >
                      <Grid item md={2} xs={2}>
                        <FormTextInput
                          page={"Users"}
                          value={search}
                          setValue={(val) => onSearchUsers(val)}
                          height={40}
                          width={150}
                          placeholder={"Search.."}
                        />
                      </Grid>
                      <Grid item md={2} xs={2} ml={2}>
                        <FormButton
                          title={
                            MOBILE_VIEW ? (
                              <PlusIcon/>
                            ) : (
                              "Add New User"
                            )
                          }
                          startIcon={
                            MOBILE_VIEW ? null : (
                              <PlusIcon/>
                            )
                          }
                          btnStyleView={style.btnStyleView}
                          onSubmit={(e) =>
                            onAddEditUserBtnClick(e, "add", null)
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Added Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.userLoading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress/>
                    </TableCell>
                  </TableRow>
                ) : props.userList?.length > 0 ? (
                  props.userList?.map((item, index) => (
                    <TableRow
                      key={index}
                      className={styles.tableRowView}
                    >
                      <TableCell>{item?.username}</TableCell>
                      <TableCell>{item?.email}</TableCell>
                      <TableCell>
                        <Box className={styles.statusView}>
                          <Typography className={styles.statusText}>
                            {item?.status}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{moment.utc(item?.timestamp).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A")}</TableCell>
                      <TableCell>
                        <Box className={styles.iconMainView}>
                          <Box
                            onClick={(e) =>
                              onAddEditUserBtnClick(e, "edit", item)
                            }
                            className={styles.iconView}
                          >
                            <EditIcon fontSize="small"/>
                          </Box>
                          <Box
                            onClick={(e) => onChangePasswordClick(e, item)}
                            ml={1}
                            className={styles.iconView}
                          >
                            <KeyIcon fontSize="small"/>
                          </Box>
                          <Box
                            onClick={(e) => onDeleteUserBtnClick(e, item)}
                            ml={1}
                            className={styles.iconView}
                          >
                            <DeleteIcon fontSize="small"/>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography className={styles.noMatchFoundText}>
                        No Users found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={{md: "row", xs: "column"}}
            alignItems={"center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{md: "flex-start", xs: "unset"}}
              mt={2}
              mb={{md: 2, xs: 0}}
              pl={{md: 2, xs: 0}}
            >
              <Typography className={styles.showingEntriesText}>
                Showing{" "}
                {listCount == 0
                  ? 0
                  : pageNum == 1
                    ? 1
                    : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
                to{" "}
                {listCount == 0
                  ? 0
                  : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
                of {totalListCount} entries
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{md: "flex-end", xs: "unset"}}
              mt={2}
              mb={2}
              pr={{md: 2, xs: 0}}
            >
              <Page
                totalPages={props.userTotalPages}
                pageNum={pageNum}
                onSelectPage={onSelectPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AddEditUserModal
        type={type}
        data={selectedUserData}
        modalVisible={modalVisible}
        onCancel={onCancelUser}
        onSubmit={onAddEditUser}
      />

      <ChangePasswordModal
        type={'user'}
        data={selectedUserData}
        modalVisible={changePasswordModalVisible}
        onCancel={onCancelChangePassword}
        onSubmit={onChangePassword}
      />

      <ConfirmAlertBox
        confirmBox={confirmBoxVisible}
        icon={<ExclaimIcon sx={{height: 80, width: 80, color: '#ffc700'}}/>}
        title={"Are you sure?"}
        content={"You won't be able to revert this!"}
        btnLoading={props.userBtnLoading}
        firstBtn={"Yes, delete it!"}
        secondBtn={"Cancel"}
        onConfirmClick={onConfirmDeleteUser}
        onCancelClick={onCancelDeleteUser}
      />

      <AlertMsg/>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    userTotalPages: state.userData.userTotalPages,
    userTotalList: state.userData.userTotalList,
    userList: state.userData.userList,
    userLoading: state.userData.userLoading,
    userBtnLoading: state.userData.userBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listUser: (data) => dispatch(listUser(data)),
    deleteUser: (data) => dispatch(deleteUser(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
};
