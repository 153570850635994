import React, {useEffect, useState, useRef} from "react";
import {
  Grid,
  Typography,
  Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer,
} from "@mui/material";
import {Chart} from 'chart.js/auto';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js'
import {Line} from "react-chartjs-2";

import RightBcIcon from '../../assets/img/left_angle.png';
import RightAcHearingIcon from '../../assets/img/left_arrow_circle.png';
import RightBcHearingIcon from '../../assets/img/left_arrow_angle.png';
import LeftBcIcon from '../../assets/img/right_angle.png';
import LeftAcHearingIcon from '../../assets/img/right_arrow_cross.png';
import LeftBcHearingIcon from '../../assets/img/right_arrow_angle.png';

import Container from "../../components/Container";
import FormSelectBox from "../../components/FormSelectBox";
import FormTextInput from "../../components/FormTextInput";

import "../../assets/css/custom.css";
import styles from "./AudioGraph.module.css";

const type_list = [
  {
    value: 'air_conduction',
    label: 'Air Conduction'
  },
  {
    value: 'bone_conduction',
    label: 'Bone Conduction'
  }
];

const X_LABEL = {
  0: 0,
  1: 125,
  2: 250,
  3: 500,
  4: 1000,
  5: 2000,
  6: 4000,
  7: 8000,
}

const table_list = [
  {
    value: 1,
    label: 125,
  },
  {
    value: 2,
    label: 250,
  },
  {
    value: 3,
    label: 500,
  },
  {
    value: 4,
    label: '1K',
  },
  {
    value: 5,
    label: '2K',
  },
  {
    value: 6,
    label: '4K',
  },
  {
    value: 7,
    label: '8K',
  }
]

const leftRightSymbolList = [
  {
    name: 'Air Conduction',
    right: 'O',
    left: 'X'
  },
  {
    name: 'Bone Conduction',
    right: '<',
    left: '>'
  },
  {
    name: 'No Response',
    right: '↙',
    left: '↘'
  },
]

const TEXTINPUT_WIDTH = 65;

let right_bc_icon = new Image(40, 30);
right_bc_icon.src = RightBcIcon;

let right_ac_hearing_icon = new Image(20, 20);
right_ac_hearing_icon.src = RightAcHearingIcon;

let right_bc_hearing_icon = new Image(25, 20);
right_bc_hearing_icon.src = RightBcHearingIcon;

let left_bc_icon = new Image(50, 40);
left_bc_icon.src = LeftBcIcon;

let left_ac_hearing_icon = new Image(40, 30);
left_ac_hearing_icon.src = LeftAcHearingIcon;

let left_bc_hearing_icon = new Image(40, 30);
left_bc_hearing_icon.src = LeftBcHearingIcon;

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
);

const AudioGraph = (props) => {

  const [type, setType] = useState(type_list[0]);
  const [leftEarAcAverage, setLeftEarAcAverage] = useState(0);
  const [leftEarBcAverage, setLeftEarBcAverage] = useState(0);
  const [leftEarAcHearingPointX, setLeftEarAcHearingPointX] = useState(0);
  const [leftEarAcHearingPointY, setLeftEarAcHearingPointY] = useState(0);
  const [leftEarBcHearingPointX, setLeftEarBcHearingPointX] = useState(0);
  const [leftEarBcHearingPointY, setLeftEarBcHearingPointY] = useState(0);
  const [rightEarAcAverage, setRightEarAcAverage] = useState(0);
  const [rightEarBcAverage, setRightEarBcAverage] = useState(0);
  const [rightEarAcHearingPointX, setRightEarAcHearingPointX] = useState(0);
  const [rightEarAcHearingPointY, setRightEarAcHearingPointY] = useState(0);
  const [rightEarBcHearingPointX, setRightEarBcHearingPointX] = useState(0);
  const [rightEarBcHearingPointY, setRightEarBcHearingPointY] = useState(0);
  const [rightEarDataList, setRightEarDataList] = useState({
    datasets: [
      {
        label: "",
        data: [],
        borderColor: "red",
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 5,
        pointBackgroundColor: '#fff'
      },
      {
        label: "",
        data: [],
        borderColor: "red",
      },
      {
        label: "",
        data: [],
        borderColor: "red",
      },
      {
        label: "",
        data: [],
        borderColor: "red",
      }
    ]
  });
  const [leftEarDataList, setLeftEarDataList] = useState({
    datasets: [
      {
        label: "",
        data: [],
        borderColor: "blue",
        pointStyle: 'crossRot',
        pointRadius: 7,
        pointHoverRadius: 7,
      },
      {
        label: "",
        data: [],
        borderColor: "blue",
      },
      {
        label: "",
        data: [],
        borderColor: "blue",
      },
      {
        label: "",
        data: [],
        borderColor: "blue",
      }
    ]
  });

  useEffect(() => {
    ChartJS.register({
        id: 'rightEarDataId',
        beforeUpdate: function (chart) {
          if (chart?.canvas?.id == 'rightEarDataId') {
            chart.config.data.datasets[1].pointStyle = right_bc_icon;
            chart.config.data.datasets[2].pointStyle = right_ac_hearing_icon;
            chart.config.data.datasets[3].pointStyle = right_bc_hearing_icon;
          }
        }
      },
      {
        id: 'leftEarDataId',
        beforeUpdate: function (chart) {
          if (chart?.canvas?.id == 'leftEarDataId') {
            chart.config.data.datasets[1].pointStyle = left_bc_icon;
            chart.config.data.datasets[2].pointStyle = left_ac_hearing_icon;
            chart.config.data.datasets[3].pointStyle = left_bc_hearing_icon;
          }
        }
      });
  }, [])

  useEffect(() => {
    let ac_average = 0;
    let bc_average = 0;
    table_list?.map((item, index) => {
      let range_x1 = 0;
      let range_x2 = 0;
      if (item.value == 3 || item.value == 4 || item.value == 5) {
        if (item.value == 3) {
          range_x1 = 3;
          range_x2 = 4;
        } else if (item.value == 4) {
          range_x1 = 4;
          range_x2 = 5;
        } else if (item.value == 5) {
          range_x1 = 5;
          range_x2 = 6;
        }
        let ac_filter = (rightEarDataList?.datasets?.[0]?.data?.filter(v => (v.x >= range_x1 && v.x <= range_x2)));
        if (ac_filter?.length >= 1) {
          ac_average = parseFloat(ac_average) + parseFloat(ac_filter[0]?.y);
        }
        let bc_filter = (rightEarDataList?.datasets?.[1]?.data?.filter(v => (v.x >= range_x1 && v.x <= range_x2)));
        if (bc_filter?.length >= 1) {
          bc_average = parseFloat(bc_average) + parseFloat(bc_filter[0]?.y);
        }
      }
    })
    if (ac_average > 0) {
      ac_average = ac_average / 3;
    }
    if (bc_average > 0) {
      bc_average = bc_average / 3;
    }
    setRightEarAcAverage(ac_average)
    setRightEarBcAverage(bc_average)
  }, [rightEarDataList])

  useEffect(() => {
    let ac_average = 0;
    let bc_average = 0;
    table_list?.map((item, index) => {
      let range_x1 = 0;
      let range_x2 = 0;
      if (item.value == 3 || item.value == 4 || item.value == 5) {
        if (item.value == 3) {
          range_x1 = 3;
          range_x2 = 4;
        } else if (item.value == 4) {
          range_x1 = 4;
          range_x2 = 5;
        } else if (item.value == 5) {
          range_x1 = 5;
          range_x2 = 6;
        }
        let ac_filter = (leftEarDataList?.datasets?.[0]?.data?.filter(v => (v.x >= range_x1 && v.x <= range_x2)));
        if (ac_filter?.length >= 1) {
          ac_average = parseFloat(ac_average) + parseFloat(ac_filter[0]?.y);
        }
        let bc_filter = (leftEarDataList?.datasets?.[1]?.data?.filter(v => (v.x >= range_x1 && v.x <= range_x2)));
        if (bc_filter?.length >= 1) {
          bc_average = parseFloat(bc_average) + parseFloat(bc_filter[0]?.y);
        }
      }
    })
    if (ac_average > 0) {
      ac_average = ac_average / 3;
    }
    if (bc_average > 0) {
      bc_average = bc_average / 3;
    }
    setLeftEarAcAverage(ac_average)
    setLeftEarBcAverage(bc_average)
  }, [leftEarDataList])

  useEffect(() => {
    if (rightEarAcHearingPointX > 0 && rightEarAcHearingPointY > 0) {
      if ((rightEarAcHearingPointX >= 125 && rightEarAcHearingPointX <= 8000) && (rightEarAcHearingPointY >= 0 && rightEarAcHearingPointY <= 120)) {
        let x_val = hearingPoint(rightEarAcHearingPointX)
        let data = {...rightEarDataList};
        data.datasets[2].data = [{x: x_val, y: rightEarAcHearingPointY}]
        setRightEarDataList(JSON.parse(JSON.stringify(data)))
      }
    }
  }, [rightEarAcHearingPointX, rightEarAcHearingPointY])

  useEffect(() => {
    if (rightEarBcHearingPointX > 0 && rightEarBcHearingPointY > 0) {
      if ((rightEarBcHearingPointX >= 125 && rightEarBcHearingPointX <= 8000) && (rightEarBcHearingPointY >= 0 && rightEarBcHearingPointY <= 120)) {
        let x_val = hearingPoint(rightEarBcHearingPointX)
        let data = {...rightEarDataList};
        data.datasets[3].data = [{x: x_val, y: rightEarBcHearingPointY}]
        setRightEarDataList(JSON.parse(JSON.stringify(data)))
      }
    }
  }, [rightEarBcHearingPointX, rightEarBcHearingPointY])

  useEffect(() => {
    if (leftEarAcHearingPointX > 0 && leftEarAcHearingPointY > 0) {
      if ((leftEarAcHearingPointX >= 125 && leftEarAcHearingPointX <= 8000) && (leftEarAcHearingPointY >= 0 && leftEarAcHearingPointY <= 120)) {
        let x_val = hearingPoint(leftEarAcHearingPointX)
        let data = {...leftEarDataList};
        data.datasets[2].data = [{x: x_val, y: leftEarAcHearingPointY}]
        setLeftEarDataList(JSON.parse(JSON.stringify(data)))
      }
    }
  }, [leftEarAcHearingPointX, leftEarAcHearingPointY])

  useEffect(() => {
    if (leftEarBcHearingPointX > 0 && leftEarBcHearingPointY > 0) {
      if ((leftEarBcHearingPointX >= 125 && leftEarBcHearingPointX <= 8000) && (leftEarBcHearingPointY >= 0 && leftEarBcHearingPointY <= 120)) {
        let x_val = hearingPoint(leftEarBcHearingPointX)
        let data = {...leftEarDataList};
        data.datasets[3].data = [{x: x_val, y: leftEarBcHearingPointY}]
        setLeftEarDataList(JSON.parse(JSON.stringify(data)))
      }
    }
  }, [leftEarBcHearingPointX, leftEarBcHearingPointY])

  const hearingPoint = (x) => {
    let cal_diff = 0;
    let cal_val = 0;
    if ((x >= 125 && x < 250)) {
      cal_diff = 250 - 125;
      cal_val = 0;
    }
    if ((x >= 250 && x < 500)) {
      cal_diff = 500 - 250;
      cal_val = 1;
    }
    if ((x >= 500 && x < 1000)) {
      cal_diff = 1000 - 500
      cal_val = 2;
    }
    if ((x >= 1000 && x < 2000)) {
      cal_diff = 2000 - 1000
      cal_val = 3;
    }
    if ((x >= 2000 && x < 4000)) {
      cal_diff = 4000 - 2000;
      cal_val = 4;
    }
    if ((x >= 4000 && x <= 8000)) {
      cal_diff = 8000 - 4000;
      cal_val = 5;
    }

    return cal_val + parseFloat(x / cal_diff)
  }

  const onSelectType = (val) => {
    setType(val)
  }

  const onChangeRightEarData = (value_x, val, type) => {
    if (val >= 0 && val <= 120) {
      let ac_bc_index = 0;
      if (type == 'bc') {
        ac_bc_index = 1;
      }
      let data = {...rightEarDataList};
      let find_index = data.datasets[ac_bc_index].data?.findIndex(z => z.x == value_x);
      if (find_index >= 0) {
        data.datasets[ac_bc_index].data[find_index] = {x: value_x, y: val};
      } else {
        data.datasets[ac_bc_index].data.push({x: value_x, y: val})
      }
      data.datasets[ac_bc_index].data = data.datasets[ac_bc_index].data?.sort((a, b) => {
        return a?.x - b?.x
      });
      setRightEarDataList(data)
    }
  }

  const onChangeLeftEarData = (value_x, val, type) => {
    if (val >= 0 && val <= 120) {
      let ac_bc_index = 0;
      if (type == 'bc') {
        ac_bc_index = 1;
      }
      let data = {...leftEarDataList};
      let find_index = data.datasets[ac_bc_index].data?.findIndex(z => z.x == value_x);
      if (find_index >= 0) {
        data.datasets[ac_bc_index].data[find_index] = {x: value_x, y: val};
      } else {
        data.datasets[ac_bc_index].data.push({x: value_x, y: val})
      }
      data.datasets[ac_bc_index].data = data.datasets[ac_bc_index].data?.sort((a, b) => {
        return a?.x - b?.x
      });
      setLeftEarDataList(data)
    }
  }

  return (
    <Container page={"audio_graph"}>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        className={styles.cardView}
        p={2}
      >
      <Grid container item md={12} sx={12} justifyContent={'space-between'}>

        <Grid item md={12} xs={12} mb={2}>
          <Grid item md={3} xs={4} display={'flex'}
                flexDirection={'row'} alignItems={'center'}>
            <Typography className={styles.labelText} pr={1}>Type:</Typography>
            <FormSelectBox
              page={"AudioGraph"}
              value={type}
              setValue={(val) => onSelectType(val)}
              height={40}
              data={type_list}
            />
          </Grid>
        </Grid>

        <Grid container item md={5.9} sx={12} justifyContent={'center'}>

          <Line
            id={"rightEarDataId"}
            data={rightEarDataList}
            height="200px"
            options={{
              onClick: function (evt, element) {
                let x_val = evt.chart.scales.x.getValueForPixel(evt.x);
                let y_val = evt.chart.scales.y.getValueForPixel(evt.y);

                if ((x_val >= 1 && x_val <= 7) && (y_val >= 0 && y_val <= 120)) {

                } else {
                  return;
                }

                y_val = Math.round(y_val);
                const x = "" + x_val + "";
                const y = "" + y_val + "";
                let right_ear_index = 0
                if (type?.value == 'air_conduction') {
                  right_ear_index = 0
                } else {
                  right_ear_index = 1
                }

                let data = {...rightEarDataList};

                if (typeof data?.datasets?.[right_ear_index]?.data != 'undefined') {
                  if ((x >= 1 && x < 2)) {
                    data.datasets[right_ear_index].data = data?.datasets?.[right_ear_index]?.data.filter(v => !(v.x >= 1 && v.x < 2));
                  }
                  if ((x >= 2 && x < 3)) {
                    data.datasets[right_ear_index].data = data?.datasets?.[right_ear_index]?.data.filter(v => !(v.x >= 2 && v.x < 3));
                  }
                  if ((x >= 3 && x < 4)) {
                    data.datasets[right_ear_index].data = data?.datasets?.[right_ear_index]?.data.filter(v => !(v.x >= 3 && v.x < 4));
                  }
                  if ((x >= 4 && x < 5)) {
                    data.datasets[right_ear_index].data = data?.datasets?.[right_ear_index]?.data.filter(v => !(v.x >= 4 && v.x < 5));
                  }
                  if ((x >= 5 && x < 6)) {
                    data.datasets[right_ear_index].data = data?.datasets?.[right_ear_index]?.data.filter(v => !(v.x >= 5 && v.x < 6));
                  }
                  if ((x >= 6 && x < 7)) {
                    data.datasets[right_ear_index].data = data?.datasets?.[right_ear_index]?.data.filter(v => !(v.x >= 6 && v.x < 7));
                  }
                }

                let index = data?.datasets?.[right_ear_index]?.data?.findIndex((z) => z?.x == x);
                if (index >= 0) {
                  data?.datasets?.[right_ear_index]?.data?.splice(index, 1)
                }

                data.datasets[right_ear_index] = {
                  ...data.datasets[right_ear_index],
                  data: [...data?.datasets?.[right_ear_index]?.data, {x: x, y: y}]?.sort((a, b) => {
                    return a?.x - b?.x
                  })
                }

                setRightEarDataList(JSON.parse(JSON.stringify(data)))

              },
              animation: {
                duration: 0
              },
              scales: {
                y:
                  {
                    min: -10,
                    max: 130,
                    ticks: {
                      stepSize: 10,
                      callback: function (value, index, ticks) {
                        if (value == 130) {
                          return "";
                        }
                        return value
                      }
                    },
                    reverse: true,
                    title: {
                      display: true,
                      text: 'Hearing Level (dB)',
                      color: '#000'
                    },
                  },
                x: {
                  type: 'linear',
                  min: 0,
                  max: 7.6,
                  ticks: {
                    stepSize: 1,
                    callback: function (value, index, ticks) {
                      if (typeof X_LABEL[value] != "undefined" && value != 0) {
                        return X_LABEL[value];
                      }
                      return "";
                    }
                  },
                  title: {
                    display: true,
                    text: 'Frequency (Hz)',
                    color: '#000'
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Right Ear",
                  color: 'red',
                },
                legend: {
                  display: false
                },
                tooltip: {
                  enabled: false
                },
              }
            }}
          />
          <TableContainer className={'audio-graph-table'}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {table_list?.map((item, index) => {
                    return (
                      <TableCell>{item?.label}</TableCell>
                    )
                  })}
                  <TableCell colSpan={2}>No Hearing Point (Hz:dB)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>AC</TableCell>
                  {table_list?.map((item, index) => {
                    let range_x1 = 0;
                    let range_x2 = 1;
                    if (item.value == 1) {
                      range_x1 = 1;
                      range_x2 = 2;
                    } else if (item.value == 2) {
                      range_x1 = 2;
                      range_x2 = 3;
                    } else if (item.value == 3) {
                      range_x1 = 3;
                      range_x2 = 4;
                    } else if (item.value == 4) {
                      range_x1 = 4;
                      range_x2 = 5;
                    } else if (item.value == 5) {
                      range_x1 = 5;
                      range_x2 = 6;
                    } else if (item.value == 6) {
                      range_x1 = 6;
                      range_x2 = 7;
                    } else if (item.value == 7) {
                      range_x1 = 7;
                      range_x2 = 8;
                    }
                    let filter = (rightEarDataList?.datasets?.[0]?.data?.filter(v => (v.x >= range_x1 && v.x < range_x2)));
                    let value = 0;
                    let value_x = range_x1;
                    if (filter?.length >= 1) {
                      value = filter[0]?.y;
                      value_x = filter[0]?.x;
                    }
                    return (
                      <TableCell key={index}>
                        <FormTextInput
                          page={"AudioGraph"}
                          type={'number'}
                          value={value}
                          setValue={(val) => onChangeRightEarData(value_x, val, 'ac')}
                          paddingRight={0.5}
                          paddingLeft={0.5}
                          inputpx={0.5}
                          width={TEXTINPUT_WIDTH}
                        />
                      </TableCell>
                    )
                  })}
                  <TableCell>
                    <FormTextInput
                      page={"AudioGraph"}
                      type={'number'}
                      value={rightEarAcHearingPointX}
                      setValue={(val) => setRightEarAcHearingPointX(val)}
                      paddingRight={0.5}
                      paddingLeft={0.5}
                      inputpx={0.5}
                      width={TEXTINPUT_WIDTH}
                    />
                  </TableCell>
                  <TableCell>
                    <FormTextInput
                      page={"AudioGraph"}
                      type={'number'}
                      value={rightEarAcHearingPointY}
                      setValue={(val) => setRightEarAcHearingPointY(val)}
                      paddingRight={0.5}
                      paddingLeft={0.5}
                      inputpx={0.5}
                      width={TEXTINPUT_WIDTH}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>BC</TableCell>
                  {table_list?.map((item, index) => {
                    let range_x1 = 0;
                    let range_x2 = 1;
                    if (item.value == 1) {
                      range_x1 = 1;
                      range_x2 = 2;
                    } else if (item.value == 2) {
                      range_x1 = 2;
                      range_x2 = 3;
                    } else if (item.value == 3) {
                      range_x1 = 3;
                      range_x2 = 4;
                    } else if (item.value == 4) {
                      range_x1 = 4;
                      range_x2 = 5;
                    } else if (item.value == 5) {
                      range_x1 = 5;
                      range_x2 = 6;
                    } else if (item.value == 6) {
                      range_x1 = 6;
                      range_x2 = 7;
                    } else if (item.value == 7) {
                      range_x1 = 7;
                      range_x2 = 8;
                    }
                    let filter = (rightEarDataList?.datasets?.[1]?.data?.filter(v => (v.x >= range_x1 && v.x < range_x2)));
                    let value = 0;
                    let value_x = range_x1;
                    if (filter?.length >= 1) {
                      value = filter[0]?.y;
                      value_x = filter[0]?.x;
                    }
                    return (
                      <TableCell key={index}>
                        <FormTextInput
                          page={"AudioGraph"}
                          type={'number'}
                          value={value}
                          setValue={(val) => onChangeRightEarData(value_x, val, 'bc')}
                          paddingRight={0.5}
                          paddingLeft={0.5}
                          inputpx={0.5}
                          width={TEXTINPUT_WIDTH}
                        />
                      </TableCell>
                    )
                  })}
                  <TableCell>
                    <FormTextInput
                      page={"AudioGraph"}
                      type={'number'}
                      value={rightEarBcHearingPointX}
                      setValue={(val) => setRightEarBcHearingPointX(val)}
                      paddingRight={0.5}
                      paddingLeft={0.5}
                      inputpx={0.5}
                      width={TEXTINPUT_WIDTH}
                    />
                  </TableCell>
                  <TableCell>
                    <FormTextInput
                      page={"AudioGraph"}
                      type={'number'}
                      value={rightEarBcHearingPointY}
                      setValue={(val) => setRightEarBcHearingPointY(val)}
                      paddingRight={0.5}
                      paddingLeft={0.5}
                      inputpx={0.5}
                      width={TEXTINPUT_WIDTH}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item md={12} xs={12} mt={2} display={'flex'} flexDirection={'column'} alignItems={'center'}
                justifyContent={'center'}>
            <Box>
              <Typography className={styles.averageText}>AC Average: {rightEarAcAverage?.toFixed(2)} dB</Typography>
            </Box>
            <Box mt={1}>
              <Typography className={styles.averageText}>BC Average: {rightEarBcAverage?.toFixed(2)} dB</Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container item md={5.9} sx={12} justifyContent={'center'}>
          <Line
            id={"leftEarDataId"}
            data={leftEarDataList}
            height="200px"
            options={{
              onClick: function (evt, element) {
                let x_val = evt.chart.scales.x.getValueForPixel(evt.x);
                let y_val = evt.chart.scales.y.getValueForPixel(evt.y);

                if ((x_val >= 1 && x_val <= 7) && (y_val >= 0 && y_val <= 120)) {

                } else {
                  return;
                }

                y_val = Math.round(y_val);
                const x = "" + x_val + "";
                const y = "" + y_val + "";

                let left_ear_index = 0
                if (type?.value == 'air_conduction') {
                  left_ear_index = 0
                } else {
                  left_ear_index = 1
                }

                let data = {...leftEarDataList};

                if (typeof data?.datasets?.[left_ear_index]?.data != 'undefined') {
                  if ((x >= 1 && x < 2)) {
                    data.datasets[left_ear_index].data = data?.datasets?.[left_ear_index]?.data.filter(v => !(v.x >= 1 && v.x < 2));
                  }
                  if ((x >= 2 && x < 3)) {
                    data.datasets[left_ear_index].data = data?.datasets?.[left_ear_index]?.data.filter(v => !(v.x >= 2 && v.x < 3));
                  }
                  if ((x >= 3 && x < 4)) {
                    data.datasets[left_ear_index].data = data?.datasets?.[left_ear_index]?.data.filter(v => !(v.x >= 3 && v.x < 4));
                  }
                  if ((x >= 4 && x < 5)) {
                    data.datasets[left_ear_index].data = data?.datasets?.[left_ear_index]?.data.filter(v => !(v.x >= 4 && v.x < 5));
                  }
                  if ((x >= 5 && x < 6)) {
                    data.datasets[left_ear_index].data = data?.datasets?.[left_ear_index]?.data.filter(v => !(v.x >= 5 && v.x < 6));
                  }
                  if ((x >= 6 && x < 7)) {
                    data.datasets[left_ear_index].data = data?.datasets?.[left_ear_index]?.data.filter(v => !(v.x >= 6 && v.x < 7));
                  }
                }

                let index = data?.datasets?.[0]?.data.findIndex((z) => z?.x == x);
                if (index >= 0) {
                  data?.datasets?.[0]?.data?.splice(index, 1)
                }

                data.datasets[left_ear_index] = {
                  ...data.datasets[left_ear_index],
                  data: [...data?.datasets?.[left_ear_index]?.data, {x: x, y: y}]?.sort((a, b) => {
                    return a?.x - b?.x
                  })
                }
                setLeftEarDataList(JSON.parse(JSON.stringify(data)))

              },
              animation: {
                duration: 0
              },
              scales: {
                y:
                  {
                    min: -10,
                    max: 130,
                    ticks: {
                      stepSize: 10,
                      callback: function (value, index, ticks) {
                        if (value == 130) {
                          return "";
                        }
                        return value
                      }
                    },
                    reverse: true,
                    title: {
                      display: true,
                      text: 'Hearing Level (dB)',
                      color: '#000'
                    },
                  },
                x: {
                  type: 'linear',
                  min: 0,
                  max: 7.6,
                  ticks: {
                    stepSize: 1,
                    callback: function (value, index, ticks) {
                      if (typeof X_LABEL[value] != "undefined" && value != 0) {
                        return X_LABEL[value];
                      }
                      return "";
                    }
                  },
                  title: {
                    display: true,
                    text: 'Frequency (Hz)',
                    color: '#000'
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Left Ear",
                  color: 'blue'
                },
                legend: {
                  display: false
                },
                tooltip: {
                  enabled: false
                }
              },
            }}
          />

          <TableContainer className={'audio-graph-table'}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {table_list?.map((item, index) => {
                    return (
                      <TableCell>{item?.label}</TableCell>
                    )
                  })}
                  <TableCell colSpan={2}>No Hearing Point (Hz:dB)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>AC</TableCell>
                  {table_list?.map((item, index) => {
                    let range_x1 = 0;
                    let range_x2 = 1;
                    if (item.value == 1) {
                      range_x1 = 1;
                      range_x2 = 2;
                    } else if (item.value == 2) {
                      range_x1 = 2;
                      range_x2 = 3;
                    } else if (item.value == 3) {
                      range_x1 = 3;
                      range_x2 = 4;
                    } else if (item.value == 4) {
                      range_x1 = 4;
                      range_x2 = 5;
                    } else if (item.value == 5) {
                      range_x1 = 5;
                      range_x2 = 6;
                    } else if (item.value == 6) {
                      range_x1 = 6;
                      range_x2 = 7;
                    } else if (item.value == 7) {
                      range_x1 = 7;
                      range_x2 = 8;
                    }
                    let filter = (leftEarDataList?.datasets?.[0]?.data?.filter(v => (v.x >= range_x1 && v.x < range_x2)));
                    let value = 0;
                    let value_x = range_x1;
                    if (filter?.length >= 1) {
                      value = filter[0]?.y;
                      value_x = filter[0]?.x;
                    }
                    return (
                      <TableCell key={index}>
                        <FormTextInput
                          page={"AudioGraph"}
                          type={'number'}
                          value={value}
                          setValue={(val) => onChangeLeftEarData(value_x, val, 'ac')}
                          paddingRight={0.5}
                          paddingLeft={0.5}
                          inputpx={0.5}
                          width={TEXTINPUT_WIDTH}
                        />
                      </TableCell>
                    )
                  })}
                  <TableCell>
                    <FormTextInput
                      page={"AudioGraph"}
                      type={'number'}
                      value={leftEarAcHearingPointX}
                      setValue={(val) => setLeftEarAcHearingPointX(val)}
                      paddingRight={0.5}
                      paddingLeft={0.5}
                      inputpx={0.5}
                      width={TEXTINPUT_WIDTH}
                    />
                  </TableCell>
                  <TableCell>
                    <FormTextInput
                      page={"AudioGraph"}
                      type={'number'}
                      value={leftEarAcHearingPointY}
                      setValue={(val) => setLeftEarAcHearingPointY(val)}
                      paddingRight={0.5}
                      paddingLeft={0.5}
                      inputpx={0.5}
                      width={TEXTINPUT_WIDTH}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>BC</TableCell>
                  {table_list?.map((item, index) => {
                    let range_x1 = 0;
                    let range_x2 = 1;
                    if (item.value == 1) {
                      range_x1 = 1;
                      range_x2 = 2;
                    } else if (item.value == 2) {
                      range_x1 = 2;
                      range_x2 = 3;
                    } else if (item.value == 3) {
                      range_x1 = 3;
                      range_x2 = 4;
                    } else if (item.value == 4) {
                      range_x1 = 4;
                      range_x2 = 5;
                    } else if (item.value == 5) {
                      range_x1 = 5;
                      range_x2 = 6;
                    } else if (item.value == 6) {
                      range_x1 = 6;
                      range_x2 = 7;
                    } else if (item.value == 7) {
                      range_x1 = 7;
                      range_x2 = 8;
                    }
                    let filter = (leftEarDataList?.datasets?.[1]?.data?.filter(v => (v.x >= range_x1 && v.x < range_x2)));
                    let value = 0;
                    let value_x = range_x1;
                    if (filter?.length >= 1) {
                      value = filter[0]?.y;
                      value_x = filter[0]?.x;
                    }
                    return (
                      <TableCell key={index}>
                        <FormTextInput
                          page={"AudioGraph"}
                          type={'number'}
                          value={value}
                          setValue={(val) => onChangeLeftEarData(value_x, val, 'bc')}
                          paddingRight={0.5}
                          paddingLeft={0.5}
                          inputpx={0.5}
                          width={TEXTINPUT_WIDTH}
                        />
                      </TableCell>
                    )
                  })}
                  <TableCell>
                    <FormTextInput
                      page={"AudioGraph"}
                      type={'number'}
                      value={leftEarBcHearingPointX}
                      setValue={(val) => setLeftEarBcHearingPointX(val)}
                      paddingRight={0.5}
                      paddingLeft={0.5}
                      inputpx={0.5}
                      width={TEXTINPUT_WIDTH}
                    />
                  </TableCell>
                  <TableCell>
                    <FormTextInput
                      page={"AudioGraph"}
                      type={'number'}
                      value={leftEarBcHearingPointY}
                      setValue={(val) => setLeftEarBcHearingPointY(val)}
                      paddingRight={0.5}
                      paddingLeft={0.5}
                      inputpx={0.5}
                      width={TEXTINPUT_WIDTH}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item md={12} xs={12} mt={2} display={'flex'} flexDirection={'column'} alignItems={'center'}
                justifyContent={'center'}>
            <Box>
              <Typography className={styles.averageText}>AC Average: {leftEarAcAverage?.toFixed(2)} dB</Typography>
            </Box>
            <Box mt={1}>
              <Typography className={styles.averageText}>BC Average: {leftEarBcAverage?.toFixed(2)} dB</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} mt={2} flexDirection={'column'} alignItems={'center'}
            justifyContent={'center'}>
        <Grid item md={12} xs={12} mt={1}>
          <Typography className={styles.averageEqText}>Average = (500 Hz + 1k Hz + 2k Hz) / 3</Typography>
        </Grid>

        <Grid item md={12} xs={12} mt={1}>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Right</TableCell>
                  <TableCell>Left</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leftRightSymbolList?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>
                        <Typography className={styles.rightSymbol}>{item?.right}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={styles.leftSymbol}>{item?.left}</Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      </Grid>
    </Container>
  );
};

export default AudioGraph;
