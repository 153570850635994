import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import Container from "../../components/Container";
import Page from "../../components/Page";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import AddEditProductCategoryModal from "../../components/AddEditProductCategoryModal";
import UseDebounce from "../../components/UseDebounce";
import ConfirmAlertBox from "../../components/ConfirmAlertBox";
import AlertMsg from "../../components/AlertMsg";
import { MOBILE_VIEW } from "../../components/Sidebar";
import PopUpAlertBox from "../../components/PopUpAlertBox";

import { connect } from "react-redux";

import {
  listProductCategory,
  deleteProductCategory,
  updateApiStatus,
  updateApiData,
} from "../../redux/actions/userDataActions";
import {DELETE_PRODUCT_CATEGORY_SUCCESS, DELETE_PRODUCT_CATEGORY_FAIL} from "../../redux/actions/types";

import styles from "./ProductCategory.module.css";

import "../../assets/css/custom.css";

import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PlusIcon from '@mui/icons-material/AddOutlined';
import ExclaimIcon from '@mui/icons-material/ErrorOutlineOutlined';

import {UTC_OFFSET} from "../../constants";

const ProductCategory = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [selectedProductCategoryData, setSelectedProductCategoryData] = useState(null);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [alertPopUpBoxVisible, setAlertPopUpBoxVisible] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);

  const debouncedSearch = UseDebounce(search, 500);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == DELETE_PRODUCT_CATEGORY_SUCCESS && confirmBoxVisible) {
      setSelectedProductCategoryData(null);
      setAlertPopUpBoxVisible(false);
      setConfirmBoxVisible(false);
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if (props.apiData?.error == 'error_popup' && props.apiStatus == DELETE_PRODUCT_CATEGORY_FAIL && confirmBoxVisible) {
      setSelectedProductCategoryData(null);
      setConfirmBoxVisible(false);
      setAlertPopUpBoxVisible(true);
      setPopUpMessage(props.apiData?.msg);
      props.updateApiData("")
    }
  }, [props.apiData]);

  useEffect(() => {
    setTotalListCount(props.productCategoryTotalList);
  }, [props.productCategoryTotalList]);

  useEffect(() => {
    setListCount(props.productCategoryList?.length);
  }, [props.productCategoryList?.length]);

  useEffect(() => {
    getProductCategoryList(1);
  }, [debouncedSearch]);

  useEffect(() => {
    getProductCategoryList(1);
  }, []);

  const getProductCategoryList = (page) => {
    let data = {
      page: page,
      searchProductCategory: search,
    };
    props.listProductCategory(JSON.stringify(data));
    setPageNum(page);
  };

  const onAddEditProductCategoryBtnClick = (e, val, item) => {
    e.stopPropagation();
    setSelectedProductCategoryData(item);
    setType(val);
    setModalVisible(true);
  };

  const onAddEditProductCategory = () => {
    setSelectedProductCategoryData(null);
    setModalVisible(false);
  };

  const onCancelProductCategory = () => {
    setSelectedProductCategoryData(null);
    setModalVisible(false);
  };

  const onSelectPage = (page) => {
    getProductCategoryList(page);
  };

  const onSearchProductsCategory = (val) => {
    setSearch(val);
  };

  const onDeleteProductCategoryBtnClick = (item) => {
    setSelectedProductCategoryData(item);
    setConfirmBoxVisible(true);
  };

  const onConfirmDeleteProductCategory = () => {
    let data = {
      productCategoryUid: selectedProductCategoryData?.uid,
    };
    props.deleteProductCategory(JSON.stringify(data));
  };

  const onCancelDeleteProductCategory = () => {
    setSelectedProductCategoryData(null);
    setConfirmBoxVisible(false);
  };

  const onSubmitClick = () => {
    setSelectedProductCategoryData(null);
    setAlertPopUpBoxVisible(false)
  }

  return (
    <Container page={"product_categories"}>
      <Grid container item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={styles.cardView}
        >
          <TableContainer className={styles.tableView}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Grid
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"flex-end"}
                      mt={2}
                    >
                      <Grid item md={2} xs={2}>
                        <FormTextInput
                          page={"ProductCategory"}
                          value={search}
                          setValue={(val) => onSearchProductsCategory(val)}
                          height={40}
                          width={150}
                          placeholder={"Search.."}
                        />
                      </Grid>
                      <Grid item md={2} xs={2} ml={2}>
                        <FormButton
                          title={
                            MOBILE_VIEW ? (
                              <PlusIcon />
                            ) : (
                              "Add New Category"
                            )
                          }
                          startIcon={
                            MOBILE_VIEW ? null : (
                              <PlusIcon />
                            )
                          }
                          btnStyleView={style.btnStyleView}
                          onSubmit={(e) =>
                            onAddEditProductCategoryBtnClick(e, "add", null)
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Added Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.productCategoryLoading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : props.productCategoryList?.length > 0 ? (
                  props.productCategoryList?.map((item, index) => (
                    <TableRow
                      key={index}
                      className={styles.tableRowView}
                    >
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>
                        <Box className={styles.statusView}>
                          <Typography className={styles.statusText}>
                            {item?.status}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{moment.utc(item?.timestamp).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A")}</TableCell>
                      <TableCell>
                        <Box className={styles.iconMainView}>
                          <Box
                            onClick={(e) =>
                              onAddEditProductCategoryBtnClick(e, "edit", item)
                            }
                            className={styles.iconView}
                          >
                            <EditIcon fontSize="small" />
                          </Box>
                          <Box
                            onClick={(e) => onDeleteProductCategoryBtnClick(item)}
                            ml={1}
                            className={styles.iconView}
                          >
                            <DeleteIcon fontSize="small" />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography className={styles.noMatchFoundText}>
                        No Categories found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={{ md: "row", xs: "column" }}
            alignItems={"center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{ md: "flex-start", xs: "unset" }}
              mt={2}
              mb={{ md: 2, xs: 0 }}
              pl={{ md: 2, xs: 0 }}
            >
              <Typography className={styles.showingEntriesText}>
                Showing{" "}
                {listCount == 0
                  ? 0
                  : pageNum == 1
                    ? 1
                    : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
                to{" "}
                {listCount == 0
                  ? 0
                  : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
                of {totalListCount} entries
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{ md: "flex-end", xs: "unset" }}
              mt={2}
              mb={2}
              pr={{ md: 2, xs: 0 }}
            >
              <Page
                totalPages={props.productCategoryTotalPages}
                pageNum={pageNum}
                onSelectPage={onSelectPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AddEditProductCategoryModal
        type={type}
        data={selectedProductCategoryData}
        modalVisible={modalVisible}
        onCancel={onCancelProductCategory}
        onSubmit={onAddEditProductCategory}
      />

      <ConfirmAlertBox
        confirmBox={confirmBoxVisible}
        icon={<ExclaimIcon sx={{height: 80, width: 80, color: '#ffc700'}}/>}
        title={"Are you sure?"}
        content={"You won't be able to revert this!"}
        btnLoading={props.productBtnLoading}
        firstBtn={"Yes, delete it!"}
        secondBtn={"Cancel"}
        onConfirmClick={onConfirmDeleteProductCategory}
        onCancelClick={onCancelDeleteProductCategory}
      />

      <PopUpAlertBox
        popUpBox={alertPopUpBoxVisible}
        icon={<ExclaimIcon sx={{height: 80, width: 80, color: '#ffc700'}}/>}
        content={popUpMessage}
        btnLoading={props.productBtnLoading}
        btn={"Ok"}
        onSubmitClick={onSubmitClick}
      />

      <AlertMsg />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    productCategoryTotalPages: state.userData.productCategoryTotalPages,
    productCategoryTotalList: state.userData.productCategoryTotalList,
    productCategoryList: state.userData.productCategoryList,
    productCategoryLoading: state.userData.productCategoryLoading,
    productCategoryBtnLoading: state.userData.productCategoryBtnLoading,
    apiStatus: state.userData.apiStatus,
    apiData: state.userData.apiData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listProductCategory: (data) => dispatch(listProductCategory(data)),
    deleteProductCategory: (data) => dispatch(deleteProductCategory(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
    updateApiData: (data) => dispatch(updateApiData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategory);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
};
