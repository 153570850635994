import React, {useEffect, useState} from 'react';
import {Grid, Box, CircularProgress, Typography} from '@mui/material';

import FormButton from "./FormButton";
import FormTextArea from "./FormTextArea";
import {connect} from "react-redux";

const AddEditConsultationNotes = (props) => {
  const [note, setNote] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.data != null) {
      setNote(props.data?.note)
    } else {
      setNote('')
    }
    setError(null);
  }, [props.data]);

  const onAddEditNote = () => {
    let errorText = {};
    if (note == '') {
      errorText = {note: 'Please enter note'};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      patientUid: props?.patientUid,
      consultationNote: note,
    };

    if (props.data != null) {
      data = {...data, consultationNoteId: props.data?.id}
    }

    setNote('');
    setError(null);

    props.onAddEdit(data);
  };

  const onCancelNote = () => {
    props.onCancel();
  };

  return (
    <Grid item md={12} xs={12}>
      <FormTextArea
        page={'ConsultationNotes'}
        error={error?.note}
        value={note}
        setValue={setNote}
        placeholder={'Notes'}
      />

      <Box mt={2} display={'flex'} alignItems={"center"} justifyContent={"flex-end"}>
        <FormButton
          loading={props.consultationNoteBtnLoading}
          title={props.data != null ? 'Update' : 'Add'}
          btnType={'btn1'}
          btnStyleView={style.btnStyleView}
          onSubmit={onAddEditNote}
        />
        {props.data != null ?
          <Box ml={2}>
            <FormButton
              title={'Cancel'}
              btnType={'btn2'}
              btnStyleView={style.btnStyleView}
              onSubmit={onCancelNote}
            />
          </Box>
          : null}
      </Box>
    </Grid>
  );
};


const mapStateToProps = (state) => {
  return {
    consultationNoteBtnLoading: state.userData.consultationNoteBtnLoading,
  };
};

export default connect(mapStateToProps, null)(AddEditConsultationNotes);

const style = {
  btnStyleView: {
    height: 40,
  }
}
