import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { connect } from "react-redux";

import Login from "./containers/Auth/Login";
import LogOut from "./containers/Auth/LogOut";
import Register from "./containers/Auth/Register";
import Dashboard from "./containers/Dashboard/Dashboard";
import Patients from "./containers/Patients/Patients";
import PatientView from "./containers/Patients/PatientView";
import Appointments from "./containers/Appointments/Appointments";
import Invoice from "./containers/Invoice/Invoice";
import Doctors from "./containers/Doctors/Doctors";
import DoctorView from "./containers/Doctors/DoctorView";
import PrintInvoice from "./containers/Invoice/PrintInvoice";
import Invoices from "./containers/Invoice/Invoices";
import Users from "./containers/Users/Users";
import Products from "./containers/Products/Products";
import ProductCategory from "./containers/ProductCategory/ProductCategory";
import ProductEnquiry from "./containers/ProductEnquiry/ProductEnquiry";
import Enquiry from "./containers/Enquiry/Enquiry";
import EnquiryDetail from "./containers/Enquiry/EnquiryDetail";
import AddEditEnquiry from "./containers/Enquiry/AddEditEnquiry";
import PriceCalculator from "./containers/PriceCalculator/PriceCalculator";
import IncomeExpense from "./containers/IncomeExpense/IncomeExpense";
import AudioGraph from "./containers/AudioGraph/AudioGraph";

import "./assets/css/custom.css";

const App = (props) => {
  let routes = (
    <Routes>
      <Route exact path="/login" element={<Login {...props} />} />
      {/*<Route exact path="/register" element={<Register {...props} />} />*/}
      <Route exact path="/" element={<Navigate replace to="/login" />} />
      <Route path="*" element={<Navigate replace to="/login" />} />
    </Routes>
  );

  if (props.userToken) {
    routes = (
      <Routes>
        <Route exact path="/index" element={<Navigate replace to="/"/>}/>
        <Route exact path="/" element={<Dashboard {...props} />}/>
        <Route exact path="/patients" element={<Patients {...props} />}/>
        <Route exact path="/patient_view" element={<PatientView {...props} />}/>
        <Route exact path="/appointments" element={<Appointments {...props} />}/>
        <Route exact path="/invoice" element={<Invoice {...props} />}/>
        <Route exact path="/invoice/:id" element={<Invoice {...props} />}/>
        <Route exact path="/doctors" element={<Doctors {...props} />}/>
        <Route exact path="/doctor_view" element={<DoctorView {...props} />}/>
        <Route exact path="/print_invoice" element={<PrintInvoice {...props} />}/>
        <Route exact path="/print_invoice/:id" element={<PrintInvoice {...props} />}/>
        <Route exact path="/invoices" element={<Invoices {...props} />}/>
        {/*<Route exact path="/products" element={<Products {...props} />}/>*/}
        {/*<Route exact path="/categories" element={<ProductCategory {...props} />}/>*/}
        {/*<Route exact path="/product_enquiry" element={<ProductEnquiry {...props} />}/>*/}
        <Route exact path="/enquiry" element={<Enquiry {...props} />}/>
        <Route exact path="/enquiry/:id" element={<EnquiryDetail {...props} />} />
        <Route exact path="/add_enquiry" element={<AddEditEnquiry {...props} />}/>
        <Route exact path="/edit_enquiry/:id" element={<AddEditEnquiry {...props} />}/>
        <Route exact path="/edit_followup/:id" element={<AddEditEnquiry {...props} />}/>
        <Route exact path="/followup" element={<Enquiry {...props} />}/>
        <Route exact path="/followup/:id" element={<EnquiryDetail {...props} />} />
        <Route exact path="/price_calculator" element={<PriceCalculator {...props} />}/>
        <Route exact path="/income_expense" element={<IncomeExpense {...props} />}/>
        <Route exact path="/audio_graph" element={<AudioGraph {...props} />}/>
        {props.userType == 'admin' ?
          <Route exact path="/users" element={<Users {...props} />}/>
          : null}
        <Route exact path="/logout" element={<LogOut {...props} />} />
        <Route path="/" element={<Navigate replace to="/" />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  return <BrowserRouter>{routes}</BrowserRouter>;
};

const mapStateToProps = (state) => {
  return {
    userToken: state.userData.userToken,
    userType: state.userData.userType,
  };
};

export default connect(mapStateToProps, null)(App);
