import React, {useEffect, useState} from "react";
import {
  Grid,
  Box,
  Typography,
  Collapse,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {TransitionGroup} from "react-transition-group";
import {v4 as uuidv4} from "uuid";
import dayjs from "dayjs";
import moment from 'moment';
import {jsPDF} from "jspdf";
import html2canvas from 'html2canvas';

import {connect} from "react-redux";
import {
  getAppointmentInvoice,
  addAppointmentInvoice,
  updateAppointmentInvoice,
  deleteAppointmentInvoice,
  addAppointmentInvoicePaymentMethod,
  updateApiStatus,
  updateAlertMsg,
  getInvoice,
  getPatientInvoice,
  addInvoice,
} from "../../redux/actions/userDataActions";
import {
  DELETE_APPOINTMENT_INVOICE_SUCCESS,
  ADD_INVOICE_SUCCESS,
} from "../../redux/actions/types";

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import FormSelectBox from "../../components/FormSelectBox";
import FormTextInput from "../../components/FormTextInput";
import FormDatePicker from "../../components/FormDatePicker";
import Loader from "../../components/Loader";
import AddEditInvoiceItem from "../../components/AddEditInvoiceItem";
import AlertMsg from "../../components/AlertMsg";
import ConfirmAlertBox from "../../components/ConfirmAlertBox";
import FormTextArea from "../../components/FormTextArea";

import {
  GST,
  CLINIC_ADDRESS,
  CLINIC_ADDRESS2,
  CLINIC_MOBILE,
  UTC_OFFSET
} from "../../constants";

import styles from "./Invoice.module.css";

import {ReactComponent as LogoIcon} from "../../assets/img/logo.svg";
import ExclaimIcon from "@mui/icons-material/ErrorOutlineOutlined";
import DownArrowIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const payment_list = [
  {
    label: "Upi Transfer",
    value: "upi_transfer",
  },
  {
    label: "Cash Payment",
    value: "cash_payment",
  },
  {
    label: "Debit/Credit Card",
    value: "debit_credit_card",
  },
  {
    label: "Bank Transfer",
    value: "bank_transfer",
  },
];

const Invoice = (props) => {
  const [invoiceNo, setInvoiceNo] = useState("");
  const [appointmentUid, setAppointmentUid] = useState("");
  const [invoiceUid, setInvoiceUid] = useState("");
  const [patientUid, setPatientUid] = useState("");
  const [date, setDate] = useState("");
  const [patient, setPatient] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [payment, setPayment] = useState(null);
  const [itemArray, setItemArray] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [gstPrice, setGstPrice] = useState(0);
  const [taxablePrice, setTaxablePrice] = useState(0);
  const [editable, setEditable] = useState(true);
  const [pageType, setPageType] = useState("add");
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [type, setType] = useState("");
  const [consultantDetail, setConsultantDetail] = useState("");
  const [note, setNote] = useState("");
  const [addedDate, setAddedDate] = useState("");

  useEffect(() => {
    if (
      props.apiStatus == DELETE_APPOINTMENT_INVOICE_SUCCESS &&
      confirmBoxVisible
    ) {
      let data = props.appointmentInvoiceDetail;
      setConfirmBoxVisible(false);
      setEditable(true);
      setPageType("add");
      let id = uuidv4();
      setItemArray([{id: id, item: "", price: "", discount: 0, quantity: 1, gst: 0}]);
      setDate(dayjs());
      setInvoiceNo(data?.invoice_no);
      setPayment(null);
      setPaymentMethod(false);
      setConsultantDetail("")
      setNote("")
      setAddedDate("")
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let appt_uid = params.get("id");
    if (appt_uid != null) {
      if (appt_uid) {
        let data = {
          appointmentUid: appt_uid,
        };
        setAppointmentUid(appt_uid);
        setType("appointmentInvoice");
        props.getAppointmentInvoice(JSON.stringify(data));
      }
    } else {
      let patient_uid = params.get("patient_id");
      if (patient_uid != null) {
        if (patient_uid) {
          let data = {
            patientUid: patient_uid,
          };
          setPatientUid(patient_uid);
          setType("patientInvoice");
          props.getPatientInvoice(JSON.stringify(data));
        }
      } else {
        const invoice_uid = window.location.pathname.split("/").pop();
        let data = {
          invoiceUid: invoice_uid,
        };
        setInvoiceUid(invoice_uid);
        setType("invoice");
        props.getInvoice(JSON.stringify(data));
      }
    }
  }, []);

  useEffect(() => {
    if (type == "patientInvoice") {
      if (
        props.apiStatus == ADD_INVOICE_SUCCESS ||
        props.apiStatus == DELETE_APPOINTMENT_INVOICE_SUCCESS
      ) {
        window.location.href = "../invoices";
        return;
      }
    }

    if (type == "invoice") {
      if (props.apiStatus == DELETE_APPOINTMENT_INVOICE_SUCCESS) {
        window.location.href = "../invoices";
        return;
      }
    }

    if (
      props.appointmentInvoiceDetail &&
      props.apiStatus != DELETE_APPOINTMENT_INVOICE_SUCCESS
    ) {
      let data = props.appointmentInvoiceDetail;
      setPatient(data?.patient);
      setDoctor(data?.doctor);

      if (data?.uid) {
        setPageType("edit");
        setEditable(false);
        setAppointmentUid(data?.appointment_uid);
        setItemArray(data?.invoice_item);
        setDate(dayjs(data?.invoice_date));
        setInvoiceNo(data?.invoice_no);
        setInvoiceUid(data?.uid);
        setConsultantDetail(data?.consultant_detail)
        setNote(data?.note)
        setAddedDate(moment.utc(data?.timestamp).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A"))

        setPayment(null);
        setPaymentMethod(false);

        if (data?.payment_method) {
          let findIndex = payment_list.findIndex(
            (x, i) => x.value == data.payment_method
          );
          if (findIndex >= 0) {
            let pay_method = payment_list[findIndex];
            setPayment(pay_method);
            setPaymentMethod(true);
          }
        }
      } else {
        let id = uuidv4();
        setPageType("add");
        setEditable(true);
        setAppointmentUid(data?.appointment_uid ? data.appointment_uid : "");
        if(data?.title || data?.consultation_fee) {
          setItemArray([{id: id, item: data?.title, price: data?.consultation_fee, discount: 0, quantity: 1, gst: 0}]);
        } else {
          setItemArray([{id: id, item: "", price: "", discount: 0, quantity: 1, gst: 0}]);
        }
        setDate(dayjs());
        setInvoiceNo(data?.invoice_no);
        setPayment(null);
        setPaymentMethod(false);
        setConsultantDetail("")
        setNote("")
        setAddedDate("")
      }
    }
  }, [props.appointmentInvoiceDetail]);

  useEffect(() => {
    let taxable = 0;
    let gst = 0;
    let total = 0;
    itemArray.map((x) => {
      let price = 0;

      if (x?.price) {
        price = x.price;
        if (x?.discount > 0) {
          price = parseFloat(x.price) - parseFloat(x.discount);
        }
        taxable = taxable + parseFloat(price) * parseFloat(x?.quantity ? x.quantity : 1);

        if (x?.gst) {
          gst = gst + (parseFloat(price) * parseFloat(x?.quantity ? x.quantity : 1)) * (x?.gst / 100);
        }
      }
    }, []);
    setTaxablePrice(taxable);
    setGstPrice(gst);
    total = parseFloat(taxable) + parseFloat(gst)
    setTotalPrice(total);
  }, [itemArray]);

  const onEditBtnClick = () => {
    setPageType("edit");
    setEditable(true);
  };

  const onDeleteBtnClick = () => {
    setConfirmBoxVisible(true);
  };

  const onConfirmDeleteInvoice = () => {
    let data = {
      invoiceUid: invoiceUid,
    };
    props.deleteAppointmentInvoice(JSON.stringify(data));
  };

  const onCancelDeleteInvoice = () => {
    setConfirmBoxVisible(false);
  };

  const onAddItemClick = () => {
    let arr = [...itemArray];
    let id = uuidv4();
    setItemArray([...arr, {id: id, item: "", price: "", discount: 0, quantity: 1, gst: 0}]);
  };

  const onChangeItem = (item, val, type) => {
    let list = [...itemArray];
    let index = list.findIndex((x) => x.id == item?.id);
    if (index >= 0) {
      let errorText = null;
      if (type == "price") {
        if (val != "") {
          if (!/^[0-9.]{1,100}$/.test(val)) {
            errorText = {price: "Invalid price"};
          } else {
            errorText = {price: ""};
          }
        } else {
          errorText = {price: "Price is required"};
        }
      } else if (type == "discount") {
        if (val != "" && val != 0) {
          if (!/^[0-9.]{1,100}$/.test(val)) {
            errorText = {discount: "Invalid discount"};
          } else {
            if (/^[0-9]{1,100}$/.test(val)) {
              val = val.replace(/^0+/, '')
            }
          }
        }
      } else if (type == "item") {
        if (val.trim() != "") {
          errorText = {item: ""};
          val = capitalizeFirstLetter(val);
        } else {
          errorText = {item: "Item is required"};
        }
      } else if (type == "quantity") {
        if (val != "") {
          if (!/^[0-9]+$/.test(val)) {
            errorText = {quantity: "Invalid Quantity"};
          } else {
            errorText = {quantity: ""};
          }
        } else {
          errorText = {quantity: "Quantity is required"};
        }
      } else if (type == 'gst') {
        if (val != '' && val != 0) {
          if (!/^[0-9.]{1,100}$/.test(val)) {
            errorText = {gst: "Invalid GST"};
          } else {
            if (/^[0-9]{1,100}$/.test(val)) {
              val = val.replace(/^0+/, '')
            }
          }
        }
      }
      list[index][type] = val;
      list[index]["error"] = {...list[index]["error"], ...errorText};
    }
    setItemArray(list);
  };

  const onRemoveItemClick = (item) => {
    setItemArray((prev) => [...prev.filter((i) => i.id !== item?.id)]);
  };

  const onSaveInvoice = () => {
    let list = [];
    let items = [];
    let err_count = 0;

    itemArray
      .filter((y) => y.item.trim() != "" || y.price != "" || y.price != 0)
      .map((x) => {
        let err = null;
        if (x?.item.trim() == "") {
          err = {...err, item: "Item is required"};
          err_count++;
        }
        if (x?.price == "" || x?.price == 0) {
          err = {...err, price: "Price is required"};
          err_count++;
        } else {
          if (!/^[0-9.]{1,100}$/.test(x.price)) {
            err = {...err, price: "Invalid price"};
            err_count++;
          }
        }
        if (x?.discount != "" && x?.discount != 0) {
          if (!/^[0-9.]{1,100}$/.test(x.discount)) {
            err = {...err, discount: "Invalid Discount"};
            err_count++;
          }
        } else {
          x.discount = 0;
        }
        if (x?.quantity == "" || x?.quantity == 0) {
          err = {...err, quantity: "Quantity is required"};
          err_count++;
        } else {
          if (!/^[0-9]+$/.test(x.quantity)) {
            err = {...err, quantity: "Invalid quantity"};
            err_count++;
          }
        }
        if (x?.gst != "" && x?.gst != 0) {
          if (!/^[0-9.]{1,100}$/.test(x.gst)) {
            err = {...err, gst: "Invalid GST"};
            err_count++;
          }
        } else {
          x.gst = 0;
        }
        list.push({...x, error: err});
        if (err == null) {
          items.push(x);
        }
      });

    if (err_count > 0) {
      setItemArray(list);
      return;
    }

    if (items.length == 0) {
      props.updateAlertMsg({
        open: true,
        message: "Atleast one item is required",
        msgType: "error",
      });
      return;
    }

    if (date == "" || date == null) {
      props.updateAlertMsg({
        open: true,
        message: "Date is required",
        msgType: "error",
      });
      return;
    }

    if (pageType == "add") {
      if (type == "patientInvoice") {
        let data = {
          patientUid: patientUid,
          invoiceNo: invoiceNo,
          invoiceDate: dayjs(date).format("YYYY-MM-DD"),
          total: totalPrice,
          invoiceItem: items,
          consultantDetail: consultantDetail,
          note: note,
          paymentMethod: payment != null ? payment?.value : ''
        };
        props.addInvoice(JSON.stringify(data));
      } else {
        let data = {
          appointmentUid: appointmentUid,
          invoiceNo: invoiceNo,
          invoiceDate: dayjs(date).format("YYYY-MM-DD"),
          total: totalPrice,
          invoiceItem: items,
          consultantDetail: consultantDetail,
          note: note,
          paymentMethod: payment != null ? payment?.value : ''
        };
        props.addAppointmentInvoice(JSON.stringify(data));
      }
    } else {
      let data = {
        appointmentUid: appointmentUid,
        invoiceUid: invoiceUid,
        invoiceDate: dayjs(date).format("YYYY-MM-DD"),
        total: totalPrice,
        invoiceItem: items,
        consultantDetail: consultantDetail,
        note: note,
        paymentMethod: payment != null ? payment?.value : ''
      };
      props.updateAppointmentInvoice(JSON.stringify(data));
    }
  };

  const onAddPayment = () => {
    let data = {
      invoiceUid: invoiceUid,
      paymentMethod: payment?.value,
    };
    props.addAppointmentInvoicePaymentMethod(JSON.stringify(data));
  };

  const onPrintInvoice = () => {
    window.open("../print_invoice/" + invoiceUid, "_blank");
  };

  const onDownloadInvoice = async () => {
    const input = document.getElementById('printableDiv');
    let file_name = dayjs(date).format("DD_MM_YYYY") + "_" + invoiceNo;

    html2canvas(input, {scale: 2}).then((canvas) => {
      let imgData = canvas.toDataURL('image/png');
      let doc = new jsPDF('p', 'mm', 'a4', true);
      let imgHeight = canvas.height * 208 / canvas.width;

      doc.addImage(imgData, 'PNG', 0, 0, 208, imgHeight)
      doc.save(file_name + '.pdf');
    })

  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  if (props.appointmentInvoiceDetailLoading) {
    return (
      <Container page={"invoice"}>
        <Loader/>
      </Container>
    );
  }

  return (
    <Container page={"invoice"}>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Grid
          item
          md={8.8}
          xs={12}
          container
          display={"flex"}
          flexDirection={"column"}
          className={styles.cardView}
          id={"printableDiv"}
        >
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            p={4}
          >
            <Grid item md={6} xs={6}>
              <Grid item md={12} xs={12} className={styles.logoView}>
                <LogoIcon height={60}/>
              </Grid>

              <Grid item md={8} xs={8} mt={3}>
                <Typography className={styles.contentText}>
                  {CLINIC_ADDRESS}
                </Typography>
                <Typography className={styles.contentText}>
                  {CLINIC_ADDRESS2}
                </Typography>
                <Typography className={styles.contentText}>
                  {CLINIC_MOBILE}
                </Typography>
                <Typography className={styles.gstText}>GSTIN: {GST}</Typography>
              </Grid>
            </Grid>

            {editable ? (
              <Grid
                item
                md={6}
                xs={6}
                display={"flex"}
                flexDirection={"column"}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  mt={2}
                >
                  <Grid
                    item
                    md={6.5}
                    xs={6.5}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Typography className={styles.invoiceText}>
                      Invoice #
                    </Typography>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormTextInput
                      page={"Invoice"}
                      disabled={true}
                      value={invoiceNo}
                      setValue={setInvoiceNo}
                      type={"text"}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  mt={-8}
                >
                  <Grid
                    item
                    md={6.5}
                    xs={6.5}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Typography className={styles.dateText}>Date:</Typography>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormDatePicker
                      page={"Invoice"}
                      value={date}
                      minDate={dayjs()}
                      inputFormat="YYYY-MM-DD"
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                    />
                  </Grid>
                </Grid>

                {addedDate != '' ?
                  <Grid
                    item
                    md={12}
                    xs={12}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    mt={-8}
                  >
                    <Grid
                      item
                      md={6.5}
                      xs={6.5}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      <Typography className={styles.dateText}>Added Date:</Typography>
                    </Grid>

                    <Grid item md={4} xs={4}>
                      <Typography className={styles.dateValueText}>
                        {addedDate}
                      </Typography>
                    </Grid>
                  </Grid>
                  : null}
              </Grid>
            ) : (
              <Grid
                item
                md={6}
                xs={6}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-end"}
              >
                <Box display={"flex"} flexDirection={"row"}>
                  <Typography className={styles.invoiceText}>
                    Invoice
                  </Typography>
                  <Typography className={styles.invoiceValueText} ml={1}>
                    #{invoiceNo}
                  </Typography>
                </Box>
                <Box display={"flex"} flexDirection={"row"} mt={1}>
                  <Typography className={styles.dateText}>Date:</Typography>
                  <Typography className={styles.dateValueText} ml={1}>
                    {dayjs(date).format("DD/MM/YYYY")}
                  </Typography>
                </Box>
                {addedDate != '' ?
                  <Box display={"flex"} flexDirection={"row"} mt={1}>
                    <Typography className={styles.dateText}>Added Date:</Typography>
                    <Typography className={styles.dateValueText} ml={1}>
                      {addedDate}
                    </Typography>
                  </Box>
                  : null}
              </Grid>
            )}
          </Grid>

          <Grid className={styles.borderBottomLine} mb={1}/>

          <Grid container item md={12} xs={12} px={4} pt={1} pb={3}>
            <Grid item md={6} xs={6} mb={1}>
              <Typography className={styles.patientTitleText}>
                Patient
              </Typography>
              <Typography className={styles.patientDataText}>
                {patient?.first_name && patient?.last_name ?
                  patient.first_name + ' ' + patient.last_name
                  : (patient?.first_name)}
              </Typography>
            </Grid>
            {/*{doctor?.name ? (*/}
            {/*  <Grid*/}
            {/*    item*/}
            {/*    md={6}*/}
            {/*    xs={6}*/}
            {/*    display={"flex"}*/}
            {/*    justifyContent={"flex-end"}*/}
            {/*  >*/}
            {/*    <Box sx={{ minWidth: 200 }}>*/}
            {/*      <Typography className={styles.patientTitleText}>*/}
            {/*        Referred By*/}
            {/*      </Typography>*/}
            {/*      <Typography className={styles.patientDataText}>*/}
            {/*        {doctor?.name}*/}
            {/*      </Typography>*/}
            {/*      <Typography className={styles.patientDataText}>*/}
            {/*        {doctor?.mobile}*/}
            {/*      </Typography>*/}
            {/*      <Typography className={styles.patientDataText}>*/}
            {/*        {doctor?.hospital}*/}
            {/*      </Typography>*/}
            {/*    </Box>*/}
            {/*  </Grid>*/}
            {/*) : null}*/}
          </Grid>

          {editable ? (
            <Grid className={styles.borderBottomLine}/>
          ) : (
            <Grid className={styles.borderTableTopLine}/>
          )}

          {editable ? (
            <Grid item md={12} xs={12} p={4}>
              <TransitionGroup>
                {itemArray?.map((item, index) => {
                  return (
                    <Collapse key={index}>
                      <AddEditInvoiceItem
                        item={item}
                        onChange={onChangeItem}
                        onRemove={onRemoveItemClick}
                      />
                    </Collapse>
                  );
                })}
              </TransitionGroup>

              <Grid item md={12} xs={12} mt={2} sx={{width: "fit-content"}}>
                <FormButton
                  btnStyleView={style.btnStyleView}
                  btnType={"btn1"}
                  title={"Add Item"}
                  onSubmit={onAddItemClick}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid item md={12} xs={12} mb={2}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell>GST</TableCell>
                      <TableCell>SGST</TableCell>
                      <TableCell>CGST</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itemArray?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className={styles.itemTitle}>
                            {item?.item}
                          </TableCell>
                          <TableCell>{item?.quantity}</TableCell>
                          <TableCell>
                            ₹{Number(item?.price).toFixed(2)}
                          </TableCell>
                          <TableCell>₹{item?.discount ? item.discount : 0}</TableCell>
                          <TableCell>{item?.gst ? item.gst : 0}%</TableCell>
                          <TableCell>{item?.gst ? item.gst / 2 : 0}%</TableCell>
                          <TableCell>{item?.gst ? item.gst / 2 : 0}%</TableCell>
                          <TableCell>
                            ₹{item?.price
                            ? (
                              Number(item?.quantity) * Number(item?.discount > 0 ? (parseFloat(item.price) - parseFloat(item.discount)) : item.price)
                            ).toFixed(2)
                            : 0}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {itemArray.length == 0 ? (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Typography className={styles.noFoundText}>
                            No Items found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          {editable ? <Grid className={styles.borderBottomLine}/> : null}

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={{md: "row", xs: "column"}}
            justifyContent={"space-between"}
            alignItems={{md: 'flex-start', xs: 'unset'}}
            p={4}
          >
            {editable ?
              <Grid item md={8} xs={12}>
                <FormTextArea
                  disabled={!editable}
                  page={"Invoice"}
                  value={consultantDetail}
                  setValue={setConsultantDetail}
                  placeholder={"Consultant Details"}
                />
              </Grid>
              :
              <Grid item md={8} xs={12}>
                <Typography className={styles.consultantDetailText}>{consultantDetail}</Typography>
              </Grid>
            }

            <Grid item md={2} xs={12}/>

            <Grid item md={2} xs={12} display={"flex"} flexDirection={"column"}>
              <Grid
                item
                md={12}
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                mt={1}
              >
                <Grid
                  item
                  md={6.5}
                  xs={6.5}
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <Typography className={styles.subLabelText}>Taxable:</Typography>
                </Grid>

                <Grid
                  item
                  md={4}
                  xs={4}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <Typography className={styles.subValueText}>
                    ₹{taxablePrice?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                mt={1}
              >
                <Grid
                  item
                  md={6.5}
                  xs={6.5}
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <Typography className={styles.subLabelText}>GST:</Typography>
                </Grid>

                <Grid
                  item
                  md={4}
                  xs={4}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <Typography className={styles.subValueText}>
                    ₹{gstPrice?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                mt={3}
              >
                <Grid
                  item
                  md={6.5}
                  xs={6.5}
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <Typography className={styles.labelText}>Total:</Typography>
                </Grid>

                <Grid
                  item
                  md={4}
                  xs={4}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <Typography className={styles.valueText}>
                    ₹{totalPrice?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          md={3}
          xs={12}
          mt={{md: 0, xs: 2}}
          display={"flex"}
          flexDirection={"column"}
        >
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            className={styles.cardView}
            p={3}
          >
            {editable ? (
              <FormButton
                loading={props.appointmentInvoiceBtnLoading}
                btnStyleView={style.btnStyleView}
                btnType={"btn1"}
                title={"Save"}
                onSubmit={() => onSaveInvoice()}
              />
            ) : (
              <Box>
                <Box>
                  <FormButton
                    btnStyleView={style.btnStyleView}
                    btnType={"btn1"}
                    title={"Edit"}
                    onSubmit={onEditBtnClick}
                  />
                  <Box mt={1}>
                    <FormButton
                      btnStyleView={style.btnStyleView}
                      btnType={"btn1"}
                      title={"Delete"}
                      onSubmit={onDeleteBtnClick}
                    />
                  </Box>
                  {!paymentMethod ? (
                    payment != null ? (
                      <Box mt={1}>
                        <FormButton
                          loading={props.appointmentInvoiceBtnLoading}
                          btnStyleView={style.btnStyleView}
                          btnType={"btn1"}
                          title={"Add Payment"}
                          onSubmit={() => onAddPayment()}
                        />
                      </Box>
                    ) : null
                  ) : null}
                </Box>
                {invoiceUid ? (
                  <>
                    <Box mt={1}>
                      <FormButton
                        btnStyleView={style.btnStyleView}
                        btnType={"btn1"}
                        title={"Print"}
                        onSubmit={() => onPrintInvoice()}
                      />
                    </Box>
                    <Box mt={1}>
                      <FormButton
                        btnStyleView={style.btnStyleView}
                        btnType={"btn1"}
                        title={"Download"}
                        onSubmit={() => onDownloadInvoice()}
                      />
                    </Box>
                  </>
                ) : null}
              </Box>
            )}
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            mt={2}
          >
            <Typography mb={2} className={styles.acceptPaymentViaText}>
              Accept payments via
            </Typography>
            <FormSelectBox
              disabled={!editable && paymentMethod}
              page={"Invoice"}
              value={payment}
              setValue={setPayment}
              placeholder={"Select Payment"}
              height={40}
              rightIcon={<DownArrowIcon sx={{color: "#677788"}}/>}
              data={payment_list}
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            mt={2}
          >
            <Typography mb={2} className={styles.acceptPaymentViaText}>
              Note
            </Typography>
            <FormTextArea
              disabled={!editable}
              page={"Invoice"}
              value={note}
              setValue={setNote}
            />
          </Grid>
        </Grid>
        <AlertMsg/>

        <ConfirmAlertBox
          confirmBox={confirmBoxVisible}
          icon={
            <ExclaimIcon sx={{height: 80, width: 80, color: "#ffc700"}}/>
          }
          title={"Are you sure?"}
          content={"You won't be able to revert this!"}
          btnLoading={props.appointmentInvoiceBtnLoading}
          firstBtn={"Yes, delete it!"}
          secondBtn={"Cancel"}
          onConfirmClick={onConfirmDeleteInvoice}
          onCancelClick={onCancelDeleteInvoice}
        />
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    appointmentInvoiceDetailLoading:
    state.userData.appointmentInvoiceDetailLoading,
    appointmentInvoiceDetail: state.userData.appointmentInvoiceDetail,
    appointmentInvoiceBtnLoading: state.userData.appointmentInvoiceBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentInvoice: (data) => dispatch(getAppointmentInvoice(data)),
    getInvoice: (data) => dispatch(getInvoice(data)),
    getPatientInvoice: (data) => dispatch(getPatientInvoice(data)),
    addAppointmentInvoice: (data) => dispatch(addAppointmentInvoice(data)),
    addInvoice: (data) => dispatch(addInvoice(data)),
    updateAppointmentInvoice: (data) =>
      dispatch(updateAppointmentInvoice(data)),
    deleteAppointmentInvoice: (data) =>
      dispatch(deleteAppointmentInvoice(data)),
    addAppointmentInvoicePaymentMethod: (data) =>
      dispatch(addAppointmentInvoicePaymentMethod(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
    updateAlertMsg: (data) => dispatch(updateAlertMsg(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
};
