import React, {useEffect, useState, useRef} from "react";
import {Grid, Typography, Box, Modal, CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {connect} from "react-redux";
import {
  getAllPatient,
} from "../redux/actions/userDataActions";

import FormButton from "../components/FormButton";
import FormSelectize from "./FormSelectize";
import {MOBILE_VIEW} from "./Sidebar";

import styles from "./SelectPatientModal.module.css";

import CloseIcon from '@mui/icons-material/CloseOutlined';

const SelectPatientModal = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState(null);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);

  const patientRef = useRef(null);

  useEffect(() => {
    props.getAllPatient();
  }, []);

  useEffect(() => {
    if (props.modalVisible) {
      setVisible(true);
    } else {
      setPatient(null);
      setError(null);
    }
  }, [props.modalVisible]);

  useEffect(() => {
    if(visible) {
      if(patientRef?.current) {
        patientRef?.current?.focus()
      }
    }
  },[visible]);

  useEffect(() => {
    setLoading(false);
  }, [props.allPatientList])

  const onCloseClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onClose();
    }, [100]);
  };

  const onNextClick = () => {
    let errorText = {};
    if (patient == null) {
      errorText = {patient: "Select patient"};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    props.onSubmit(patient);
  };

  return (
    <Modal
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
      onClose={onCloseClick}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{md: 8, xs: 4}}
      >
        <Grid
          onClick={() => onCloseClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle}/>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            Choose Patient
          </Typography>
        </Grid>

        {loading ?
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress/>
          </Grid>
          :
          <>
            <Grid item md={12} xs={12} mt={3}>
              <FormSelectize
                page={"SelectPatientModal"}
                value={patient}
                label={"PATIENT"}
                multiple={false}
                addOption={false}
                inputRef={patientRef}
                onChange={(val) => {
                  setPatient(val);
                }}
                options={props.allPatientList}
                error={error?.patient}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection={MOBILE_VIEW ? "column" : "row"}
              alignItems={"center"}
              justifyContent={"space-around"}
            >
              <FormButton
                btnStyleView={style.btnStyleView}
                btnType={"btn1"}
                title={"Next"}
                onSubmit={onNextClick}
              />
            </Grid>
          </>
        }
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllPatient: (data) => dispatch(getAllPatient(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    allPatientList: state.userData.allPatientList,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPatientModal);

const style = {
  btnStyleView: {
    height: 40,
    minWidth: 100,
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
};
