import React, {useEffect, useState} from "react";
import {
  Grid,
  Typography,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";

import FormButton from "../components/FormButton";
import FormTextInput from "./FormTextInput";

import {capitalizeFirstLetter} from "../assets/functions/common";

import {connect} from "react-redux";
import {
  addProductCategory,
  updateApiStatus,
  updateProductCategory,
} from "../redux/actions/userDataActions";
import {
  ADD_PRODUCT_CATEGORY_FAIL,
  ADD_PRODUCT_CATEGORY_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_FAIL,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
} from "../redux/actions/types";

import styles from "./AddEditProductCategoryModal.module.css";

import CloseIcon from '@mui/icons-material/CloseOutlined';

const AddEditProductCategoryModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [productCategoryUid, setProductCategoryUid] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (
      (props.apiStatus == ADD_PRODUCT_CATEGORY_SUCCESS ||
        props.apiStatus == UPDATE_PRODUCT_CATEGORY_SUCCESS) &&
      props.modalVisible
    ) {
      props.updateApiStatus("");
      props.onSubmit();
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if (props.data != null) {
      setProductCategoryUid(props.data?.uid);
      setName(props.data?.name);
      setLoading(false);
    } else {
      setProductCategoryUid("");
      setName("");
      setError(null);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.modalVisible) {
      if (props.type == "add") {
        setLoading(false);
      }
      setVisible(true);
    } else {
      setProductCategoryUid("");
      setName("");
      setError(null);
    }
  }, [props.modalVisible]);

  const onSubmitClick = () => {
    let errorText = {};
    if (name == "") {
      errorText = {name: "Please enter category"};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    onSubmit();
  };

  const onSubmit = () => {
    let data = {
      productCategoryUid: productCategoryUid,
      name: name,
    };

    if (props.type == "add") {
      props.addProductCategory(JSON.stringify(data));
    } else {
      props.updateProductCategory(JSON.stringify(data));
    }
  };

  const onCancelClick = () => {
    setVisible(false);
    setTimeout(() => {
      props.onCancel();
    }, [100]);
  };

  const onChange = (val, type) => {
    let errorText = error;
    if (type == "name") {
      if (val != "") {
        errorText = {...error, name: ""};
      } else {
        errorText = {...error, name: "Please enter category"};
      }
      val = capitalizeFirstLetter(val)
      setName(val);
    }
    setError(errorText);
  };

  return (
    <Modal
      disableBackdropClick
      disableScrollLock={true}
      open={props.modalVisible}
      className={styles.modelView}
      hideBackdrop={true}
    >
      <Grid
        container
        item
        md={6}
        xs={11}
        className={[
          styles.modalSize,
          visible ? styles.modalVisibleSize : styles.modalInitialSize,
        ]}
        p={{md: 8, xs: 4}}
      >
        <Grid
          onClick={() => onCancelClick()}
          item
          md={12}
          xs={12}
          className={styles.iconView}
        >
          <CloseIcon sx={style.iconStyle}/>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography className={styles.titleText}>
            {props?.type == "add" ? "Add " : "Edit "} Category
          </Typography>
        </Grid>

        {loading ? (
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress/>
          </Grid>
        ) : (
          <>
            <Grid item md={12} xs={12} mt={1}>
              <FormTextInput
                page={"AddEditProductCategoryModal"}
                required={true}
                error={error?.name}
                value={name}
                setValue={(val) => onChange(val, "name")}
                label={"NAME"}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
              mt={3}
              display={"flex"}
              flexDirection="row"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item md={2} xs={2}>
                <FormButton
                  loading={props.productCategoryBtnLoading}
                  btnStyleView={style.btnStyleView}
                  btnType={"btn1"}
                  title={props.type == "add" ? "Submit" : "Update"}
                  onSubmit={onSubmitClick}
                />
              </Grid>
              <Grid ml={{md: 2, xs: 4}} item md={2} xs={2}>
                <FormButton
                  btnStyleView={style.btnStyleView}
                  btnType={"btn2"}
                  title={"Cancel"}
                  onSubmit={onCancelClick}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProductCategory: (data) => dispatch(addProductCategory(data)),
    updateProductCategory: (data) => dispatch(updateProductCategory(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    productCategoryBtnLoading: state.userData.productCategoryBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditProductCategoryModal);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
  iconStyle: {
    color: '#69809a',
    height: 15,
    width: 15
  },
};
