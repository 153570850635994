import {AUTHENTICATION_FAILED, ENV} from '../constants';
import axios from 'axios';

import {store} from '../redux/store';
import {logOut} from "../redux/actions/userDataActions";

let apiUrl = '';

if (ENV === 'PROD') {
  apiUrl = 'https://api.celestahearing.com/admin/';
} else {
  apiUrl = 'http://localhost:5432/admin/';
}

function userToken() {
  const state = store.getState();
  let token = state.userData.userToken;
  return token;
}

export async function adminLogin(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('login', config);
}

export async function adminRegister(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('register', config);
}

export async function addPatient(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_patient', config);
}

export async function listPatient(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_patient', config);
}

export async function updatePatient(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_patient', config);
}

export async function deletePatient(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_patient', config);
}

export async function getPatientDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_patient_detail', config);
}

export async function addConsultationNote(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_consultation_note', config);
}

export async function listConsultationNote(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_consultation_note', config);
}

export async function updateConsultationNote(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_consultation_note', config);
}

export async function deleteConsultationNote(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_consultation_note', config);
}

export async function getAllPatient() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_all_patient', config);
}

export async function addAppointment(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_appointment', config);
}

export async function getAppointment(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_appointment', config);
}

export async function updateAppointment(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_appointment', config);
}

export async function listAppointment(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_appointment', config);
}

export async function updateAppointmentStatus(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_appointment_status', config);
}

export async function getAppointmentInvoice(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_appointment_invoice', config);
}

export async function addAppointmentInvoice(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_appointment_invoice', config);
}

export async function updateAppointmentInvoice(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_appointment_invoice', config);
}

export async function deleteAppointmentInvoice(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_appointment_invoice', config);
}

export async function addAppointmentInvoicePaymentMethod(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_appointment_invoice_payment_method', config);
}

export async function getDashboard(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_dashboard', config);
}

export async function addDoctor(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_doctor', config);
}

export async function listDoctor(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_doctor', config);
}

export async function updateDoctor(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_doctor', config);
}

export async function deleteDoctor(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_doctor', config);
}

export async function getAllDoctor() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_all_doctor', config);
}

export async function getDoctorDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_doctor_detail', config);
}

export async function addUser(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_user', config);
}

export async function listUser(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_user', config);
}

export async function updateUser(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_user', config);
}

export async function deleteUser(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_user', config);
}

export async function updateUserPassword(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_user_password', config);
}

export async function listInvoice(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_invoice', config);
}

export async function getInvoice(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_invoice', config);
}

export async function addInvoice(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_invoice', config);
}

export async function getPatientInvoice(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_patient_invoice', config);
}

export async function getPatientInvoiceList(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_patient_invoice_list', config);
}

export async function updateInvoiceAppointment(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_invoice_appointment', config);
}

export async function addProduct(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_product', config);
}

export async function listProduct(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_product', config);
}

export async function updateProduct(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('update_product', config);
}

export async function deleteProduct(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_product', config);
}

export async function updateProductStatus(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_product_status', config);
}

export async function getAllProductCategory() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_all_product_category', config);
}

export async function addProductCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_product_category', config);
}

export async function listProductCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_product_category', config);
}

export async function updateProductCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_product_category', config);
}

export async function deleteProductCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_product_category', config);
}

export async function listProductEnquiry(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_product_enquiry', config);
}

export async function getEnquiryByMobile(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_enquiry_by_mobile', config);
}

export async function addEnquiry(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_enquiry', config);
}

export async function listEnquiry(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_enquiry', config);
}

export async function updateEnquiry(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_enquiry', config);
}

export async function getEnquiryDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_enquiry_detail', config);
}

export async function addEnquiryNote(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_enquiry_note', config);
}

export async function listEnquiryNote(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_enquiry_note', config);
}

export async function updateEnquiryNote(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_enquiry_note', config);
}

export async function deleteEnquiryNote(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_enquiry_note', config);
}

export async function addLabel(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_label', config);
}

export async function listLabel(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_label', config);
}

export async function updateLabel(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_label', config);
}

export async function deleteLabel(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_label', config);
}

export async function moveToFollowup(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('move_to_followup', config);
}

export async function addIncomeExpense(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_income_expense', config);
}

export async function listIncomeExpense(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_income_expense', config);
}

export async function updateIncomeExpense(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_income_expense', config);
}

export async function deleteIncomeExpense(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_income_expense', config);
}

export async function globalSearch(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('global_search', config);
}

/**
 * Private functions
 */
async function callApi(endpoint, config) {
  const url = `${apiUrl}${endpoint}`;

  console.log('Url: ', url);
  console.log('Config: ', config);

  return axios({
    ...config,
    url: url,
  })
    .then(async (response) => {
      console.log('-----API RESPONSE-----', response.data);
      if (response.data.msg === AUTHENTICATION_FAILED) {
        await store.dispatch(logOut(''));
        return {status: 'Failed', msg: response.data.msg};
      } else {
        return response.data;
      }
    })
    .catch(function (error) {
      console.log(
        'There has been a problem with your fetch operation: ' + error.message,
      );
      if (error?.message) {
        return {
          status: 'Failed',
          msg: error.message,
        };
      }
      return {
        status: 'Failed',
        msg: 'Api Error',
      };
    });
}
