import React from "react";
import {
  Box,
  CircularProgress, Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import moment from "moment";

import styles from "../components/DashboardNextFollowUpList.module.css";

const DashboardNextFollowUpList = (props) => {
  return (

    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              Follow Ups
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props?.data != null ?
              props?.data?.nextFollowUpList?.length > 0 ?
                props?.data?.nextFollowUpList?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item?.name}</TableCell>
                    <TableCell>{item?.next_follow_up ? moment(item.next_follow_up).format("DD-MM-YYYY") : ''}</TableCell>
                  </TableRow>
                )) :
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <Typography className={styles.noMatchFoundText}>No Follow ups found</Typography>
                  </TableCell>
                </TableRow>
              : <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography className={styles.noMatchFoundText}>No Follow ups found</Typography>
                </TableCell>
              </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardNextFollowUpList;
