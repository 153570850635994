import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { updateDrawerOpen } from "../redux/actions/userDataActions";

import styles from "./Navbar.module.css";

import FormTextInput from "./FormTextInput";
import UseDebounce from "./UseDebounce";

import { NAVBAR_HEIGHT, THEME_COLOR2, THEME_COLOR3 } from "../constants";
import { SMALL_DRAWER_WIDTH, DRAWER_WIDTH, MOBILE_VIEW } from "./Sidebar";

import MenuIcon from '@mui/icons-material/MenuOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import UserCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import {useEffect} from "react";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  width: `calc(100% - ${SMALL_DRAWER_WIDTH}px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: MOBILE_VIEW ? 0 : DRAWER_WIDTH,
    width: `calc(100% - ${MOBILE_VIEW ? 0 : DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar = (props) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = React.useState('');

  const debouncedSearch = UseDebounce(search, 500);

  useEffect(() => {
    props.onSearch(search);
  },[debouncedSearch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSearch = (val) => {
    setSearch(val);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <AppBar
      id={"navbarView"}
      position="fixed"
      open={props.drawerOpen}
      sx={{
        backgroundColor: THEME_COLOR2,
        height: NAVBAR_HEIGHT,
        boxShadow: "unset",
      }}
    >
      <Toolbar>
        {/*<Box sx={{ width: props?.title ? "10%" : "0%", display: "flex", alignItems: "center"}}>*/}
        {/*  {MOBILE_VIEW ? (*/}
        {/*    <MenuIcon*/}
        {/*      onClick={() => props.updateDrawerOpen(true)}*/}
        {/*      style={{ marginRight: 10, color:'#677788' }}*/}
        {/*    />*/}
        {/*  ) : null}*/}
        {/*  <Typography*/}
        {/*    noWrap*/}
        {/*    component="div"*/}
        {/*    sx={{*/}
        {/*      color: THEME_COLOR3,*/}
        {/*      fontSize: "18px",*/}
        {/*      fontFamily: "IBMPlexSansRegular",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {props?.title}*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
        <Box sx={{ width: {md:"30%",xs:"80%"}, display: "flex", alignItems: "center"}}>
          <FormTextInput
            page={"Navbar"}
            value={search}
            setValue={(val) => onSearch(val)}
            height={40}
            width={150}
            rightIcon={<SearchIcon sx={{color: '#888', height: 20, width: 20}}/>}
            placeholder={"Search.."}
          />
        </Box>
        <Box sx={{ width: {md:"70%",xs:"20%"}, display: "flex", alignItems: "center", justifyContent:'flex-end' }}>
          {/*<DarkIcon width={25} height={25} />*/}
          {/*<GridIcon width={25} height={25} style={{ marginLeft: 14 }} />*/}
          {/*<BellIcon width={25} height={25} style={{ marginLeft: 14 }} />*/}
          <Box onClick={handleClick} style={{ marginLeft: 14, borderRadius: "50%", cursor: "pointer" }}>
            <UserCircleIcon sx={{color: '#777', height: 45, width: 45}}/>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{ mt: 1 }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Card style={{ minWidth: 200, color: THEME_COLOR3 }}>
              <Box p={2} className={styles.profileView}>
                <Box className={styles.profileImg}>
                  <UserCircleIcon sx={{color: '#777', height: 40, width: 40}}/>
                </Box>
                <Box>
                  <Typography className={styles.userNameText}>
                    {props.userName}
                  </Typography>
                  <Typography className={styles.adminText}>Admin</Typography>
                </Box>
              </Box>
              <Divider />
              <Box
                p={2}
                className={styles.logoutView}
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/logout");
                }}
              >
                <LogoutIcon sx={{color: '#677788', height: 20, width: 20}}/>
                <Typography className={styles.logoutText}>Log Out</Typography>
              </Box>
            </Card>
          </Popover>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDrawerOpen: (data) => dispatch(updateDrawerOpen(data)),
  };
};

export default connect(null, mapDispatchToProps)(Navbar);
